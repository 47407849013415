import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';

const MySwal = withReactContent(Swal);

const head_rows = [
  {
    id: 'docs_send_no_reserve_id',
    sortable: true,
    disablePadding: false,
    label: 'ลำดับ',
    align: 'center',
  }, 
  {
    id: 'reserved_send_no',
    sortable: true,
    disablePadding: false,
    label: 'เลขทะเบียนที่จองได้',
    align: 'center',
  }
];

class ReserveDocSendNoAddFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      year: moment().year() + 543,
      organization_name: '-',
      department_name: '-',
      division_name: '-',
      sub_division_name: '-',
      book_cate_data: [],
      user_data: {},
      book_cate_id: '',
      reserve_amount: '',
      allow_reserve: false,
      show_reserved_send_no: false,
      check_reserve_data: {},
      table_data: [],
      current_page: 1,
      last_page: 1,
      page: 1,
    };
  }

  setData = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');
  onNumerInputKeyDown = (event) => {
    if(event.key === "e" || event.key === "+" || event.key === "-" || event.key === ".") event.preventDefault();
  }

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/reserve-doc-send-no');
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/reserve-doc-send-no');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  setMetaData = async () => {
    const book_cate_req = await API.get('/console/book_cate/all?trans_type=2&all_division=0');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }

    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      let organization_name = '-';
      let department_name = '-';
      let division_name = '-';
      let sub_division_name = '-';

      if (!!profile_req.data.organization) {
        organization_name = this.replaceEmpty(profile_req.data.organization.name);
      }

      if (!!profile_req.data.department) {
        department_name = this.replaceEmpty(profile_req.data.department.name);
      }

      let allow_reserve = false;
      if (!!profile_req.data.division) {
        allow_reserve = true;
        division_name = this.replaceEmpty(profile_req.data.division.name);
      }

      if (!!profile_req.data.sub_division) {
        allow_reserve = true;
        sub_division_name = this.replaceEmpty(profile_req.data.sub_division.name);
      }

      this.setState({
        organization_name,
        department_name,
        division_name,
        sub_division_name,
        allow_reserve,
        user_data: profile_req.data,
      });
    }

    this.setState({ loading: false });
  }

  checkReseveDocNo = async () => {
    const { book_cate_id, reserve_amount } = this.state;
    let isValid = true;
    let error = {};
    if (book_cate_id === '') {
      isValid = false;
      error.book_cate = ['กรุณาระบุทะเบียนส่ง'];
    }

    if (reserve_amount === '') {
      isValid = false;
      error.reserve_amount = ['กรุณาระบุจำนวนเลขที่จอง'];
    }

    if (isValid) {
      this.displayAlert('loading');
    
      const response = await API.get(
        `/console/reserve_doc_no/check_reserve?book_cate_id=${book_cate_id}&reserve_amount=${reserve_amount}`
      );
  
      if (response.success) {
        this.setState(
          {
            show_reserved_send_no: true,
            check_reserve_data: response.data,
            table_data: response.data.docs_send_no_reserves,
            per_page: response.count,
          },
          () => MySwal.close()
        );
      } else {
        this.displayAlert('error', response.error);
      }
    } else {
      this.displayAlert('error', error);
    }
  };

  componentDidUpdate(prev_props, prev_state) {
  }

  componentDidMount() {
    this.setMetaData();
  }

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { check_reserve_data } = this.state;
      const reserveResult = await API.post(
        `/console/reserve_doc_no/reseve`,
        check_reserve_data
      );
      if (reserveResult.success) {
        this.displayAlert('success');
      } else {
        this.displayAlert('error', reserveResult.error);

        if (!!reserveResult.data) {
          this.setState(
            {
              check_reserve_data: reserveResult.data,
              table_data: reserveResult.data.docs_send_no_reserves,
            }
          );
        }
      }
    } catch (e) {}
  };

  render() {
    const { ecs_permission } = this.props;
    const {
      loading,
      year,
      organization_name,
      department_name,
      division_name,
      sub_division_name,
      allow_reserve,
      book_cate_id,
      book_cate_data,
      show_reserved_send_no,
      reserve_amount,
    } = this.state;

    if (loading) {
      return null;
    } else {
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });

      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>จองเลขทะเบียนส่ง</h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                  ปี
              </StyledLabelRight>
              <div className='col-md-2'>
                  <StyledTextField
                      id='year'
                      type='number'
                      value={year}
                      placeholder='ปี'
                      disabled='true'
                      onKeyDown={e => {this.onNumerInputKeyDown(e);}}
                      onChange={e => {
                          this.setData('year', e.target.value);
                      }}
                  />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                กระทรวง
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {organization_name}
              </StyledLabelLeft>
              
              <StyledLabelRight className='col-md-2 col-form-label'>
                กรม
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {department_name}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงาน
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {division_name}
              </StyledLabelLeft>

              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {sub_division_name}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color={(allow_reserve ? 'red' : 'gray')}>
                *ทะเบียนส่ง
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  margin='normal'
                  disabled={!allow_reserve}
                  value={book_cate_id}
                  onChange={(e) => {
                    this.setData('book_cate_id', e.target.value);
                  }}
                >
                  {list_book_cate}
                </StyledTextField>
              </div>

              <StyledLabelRight className='col-md-2 col-form-label' color={(allow_reserve ? 'red' : 'gray')}>
                *จำนวนเลขที่จอง
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                    id='reserve_amount'
                    type='number'
                    value={reserve_amount}
                    InputProps={{ inputProps: { min: 1, max: 99999 } }}
                    disabled={!allow_reserve}
                    onKeyDown={e => {this.onNumerInputKeyDown(e);}}
                    onChange={e => {
                      this.setData('reserve_amount', e.target.value);
                    }}
                />
              </div>
            </div>
          </form>
          <div className='form-group row'>
            <div className='col-md-2'></div>
            <StyledButtonLeft className='col-md-6'>
              {(allow_reserve) && (<>
              <Button
                variant='success'
                onClick={(e) => {
                  e.preventDefault();
                  this.checkReseveDocNo();
                }}
                disabled={!(ecs_permission.division_settings.docs_send_no_reserve.add && allow_reserve)}
              >
                ตรวจสอบเลขทะเบียน
              </Button>
              </>)}
              <Button
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </Button>
            </StyledButtonLeft>
            <br />
          </div>
          {(show_reserved_send_no) && (<>
            <div className='form-group row' style={{ marginBottom: 0 }}>
              <div className='col-md-1'></div>
              <div className='col-md-8'>
                <CustomTable
                  title='รายการเลขทะเบียนส่ง'
                  hideSelect={true}
                  showManage={false}
                  head_rows={head_rows}
                  rows={this.state.table_data ? this.state.table_data : []}
                  history={this.props.history}
                  reloadData={this.getData}
                  current_page={this.state.current_page}
                  last_page={this.state.last_page}
                  rowsPerPage={this.state.per_page}
                  handleChangePage={this.handleChangePage}
                />
              </div>
            </div>
            <div className='form-group row' style={{ marginBottom: 0 }}>
              <div className='col-md-2'></div>
              <StyledButtonLeft className='col-md-3'>
                <Button
                  variant='info'
                  onClick={() => this.displayAlert('save')}
                >
                  บันทึกจอง
                </Button>
                
              </StyledButtonLeft>
            </div>
            <br />
          </>)}
        </div>
      );
    }

  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(ReserveDocSendNoAddFormPage));