import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { TextField, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';

class ReceiveMultiModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      received_at: moment().format('YYYY-MM-DD'),
      book_cate_id: null,
    };
    this.handleShow = () => {
      this.setState({ show: true });
    };
    this.handleHide = () => {
      this.setState({ show: false, received_at: moment().format('YYYY-MM-DD'), book_cate_id: null });
    };
  }
  render() {
    const { book_cate_data, displayAlert } = this.props;
    const { show } = this.state;
    const list_book_cate = _.map(book_cate_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.book_cate_id}>
          {value.name}
        </MenuItem>
      );
    });
    return (
      <>
        <Button
          variant='success'
          style={{ minWidth: 100 }}
          onClick={this.handleShow}
        >
          {this.props.btn_text}
        </Button>
        <Modal
          show={show}
          onHide={this.handleHide}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              รับเอกสาร
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label' color='red'>
                *วันที่ลงทะเบียนรับ
              </StyledLabelRight>
              <div className='col-md-6'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={this.state.received_at ? new Date(this.state.received_at) : null}
                  onChange={date => {
                    try {
                        this.setState({ received_at: date ? moment(date).format('YYYY-MM-DD') : null });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onKeyDown={e => e.preventDefault()}
                />
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label' color='red'>
                *เลือกเล่มทะเบียนรับ
              </StyledLabelRight>
              <div className='col-md-6' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  onChange={(e) => {
                    this.setState({ book_cate_id: e.target.value });
                  }}
                  margin='normal'
                >
                  {list_book_cate}
                </StyledTextField>
              </div>
            </div>
          </Modal.Body>
          <div className='form-group row'>
            <StyledButtonRight className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='success'
                selected={this.state.received_at ? new Date(this.state.received_at) : null}
                onClick={() => {
                  this.handleHide();
                  displayAlert('receive', {
                    received_at: this.state.received_at,
                    book_cate_id: this.state.book_cate_id,
                  });
                }}
              >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='danger'
                onClick={this.handleHide}
              >
                ยกเลิก
              </Button>
            </StyledButtonLeft>
          </div>
        </Modal>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default ReceiveMultiModal;
