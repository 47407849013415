import React from 'react';
import RegisterSendAddFormPage from '../register-send-add-form/RegisterSendAddFormPage';
export default function Function() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterSendAddFormPage />
        </div>
      </div>
    </>
  );
}
