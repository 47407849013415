import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

const head_rows = [
   {
    id: 'organization_name',
    sortable: true,
    disablePadding: false,
    label: 'กระทรวง',
    align: 'center',
  },
  {
    id: 'department_name',
    sortable: true,
    disablePadding: false,
    label: 'กรม',
    align: 'center',
  },
  {
    id: 'division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงาน',
    align: 'center',
  },
  {
    id: 'sub_division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงานย่อย',
    align: 'center',
  },
];
var rows = [];

class RegisterBookPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query_string: null,
      trans_type: 0,
      page_title_text: '',
      book_cate_text: '',
      table_title_text: '',
      book_cate_data: [],
      division_data: [],
      sub_division_data: [],
      book_cate_id: '',
      division_id: '',
      sub_division_id: '',
      table_data: [],
      current_page: 1,
      last_page: 1,
      page: 1,
    };
  }

  setData = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const trans_type = this.unicodeToChar(url_params.get('trans_type'));

    this.setState(
      {
        query_string,
        trans_type
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };

  setMetaData = async () => {
    let book_cate_text = '';
    switch (this.state.trans_type) {
      case '1':
        book_cate_text = 'ทะเบียนรับ';
        break;
      case '2':
        book_cate_text = 'ทะเบียนส่ง';
        break;
      default: {
        break;
      }
    }
    let page_title_text = 'กำหนด' + book_cate_text;
    let table_title_text = 'รายการเล่ม' + book_cate_text + 'ในแต่ละหน่วยงาน';

    this.setState({
      page_title_text,
      book_cate_text,
      table_title_text,
      book_cate_data: [],
      division_data: [],
      sub_division_data: [],
      book_cate_id: '',
      division_id: '',
      sub_division_id: '',
      current_page: 1,
      last_page: 1,
      page: 1,
    });

    const book_cate_req = await API.get('/console/book_cate/all?trans_type=' + this.state.trans_type + '&all_division=1');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }

    const division_req = await API.get('/console/divisions/all');
    if (division_req.success) {
      this.setState({
        division_data: division_req.data,
      });
    }

    this.getData();
  }

  getData = async () => {
    this.displayAlert('loading');
    const { trans_type, book_cate_id, division_id, sub_division_id, page } = this.state;
    const register_book = await API.get(
      `/console/register_book?trans_type=${trans_type}&book_cate_id=${book_cate_id}&division_id=${division_id}&sub_division_id=${sub_division_id}&page=${page}`
    );

    if (register_book.success) {

      if (register_book.data != null) {
        for (var idx = 0; idx < register_book.data.length; idx++) {
          register_book.data[idx].id = trans_type + '-' + register_book.data[idx].division_id + '-' + register_book.data[idx].sub_division_id;
        }
      }

      this.setState(
        {
          table_data: register_book.data,
          current_page: register_book.current_page,
          last_page: register_book.last_page,
          per_page: register_book.count,
          loading: false,
        },
        () => MySwal.close()
      );
    } else {
      this.setState({ loading: false }, () => MySwal.close());
    }
  };

  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  onChangeDivision = async division_id => {
    this.displayAlert('loading');
    const sub_division_req = await API.get(
      `/console/sub_divisions/all?division_id=${division_id}`
    );
    if (sub_division_req.success) {
      this.setData('sub_division_data', sub_division_req.data);
      this.setData('sub_division_id', '');
    }
    MySwal.close();
  };

  render() {
    const { ecs_permission } = this.props;
    const {
      loading,
      page_title_text,
      book_cate_data,
      division_data,
      sub_division_data,
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_division = _.map(division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.division_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_sub_division = _.map(sub_division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.sub_division_id}>
            {value.name}
          </MenuItem>
        );
      });

      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>{page_title_text}</h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                {this.state.book_cate_text}
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  margin='normal'
                  value={this.state.book_cate_id}
                  onChange={(e) => {
                    this.setData('book_cate_id', e.target.value);
                  }}
                >
                  {list_book_cate}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงาน
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='division_id'
                  select
                  margin='normal'
                  value={this.state.division_id}
                  onChange={(e) => {
                    this.onChangeDivision(e.target.value);
                    this.setData('division_id', e.target.value);
                  }}
                >
                  {list_division}
                </StyledTextField>
              </div>

              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='sub_division_id'
                  select
                  margin='normal'
                  value={this.state.sub_division_id}
                  onChange={(e) => {
                    this.setData('sub_division_id', e.target.value);
                  }}
                >
                  {list_sub_division}
                </StyledTextField>
              </div>

              <div className='col-md-2 offset-md-1'>
                <Button
                  variant='success'
                  onClick={() => {
                    this.handleChangePage(1);
                  }}
                >
                  ค้นหา
              </Button>
              </div>
            </div>
          </form>
          <CustomTable
            title={ this.state.table_title_text }
            hideSelect={true}
            showManage={true}
            head_rows={head_rows}
            rows={this.state.table_data ? this.state.table_data : rows}
            history={this.props.history}
            linkManage={'/register-book-edit/'}
            btnManageName='แก้ไข'
            btnManageAlign='left'
            reloadData={this.getData}
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            handleChangePage={this.handleChangePage}
            btnManageDisabled={!(this.state.trans_type == '1' ? ecs_permission.settings.register_book_receive.edit : ecs_permission.settings.register_book_send.edit)}
          />
        </div>
      );
    }

  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(RegisterBookPage));