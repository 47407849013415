import React from 'react';
import Page from '../news-management/NewsManagementPage';
export default function Function() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <Page />
        </div>
      </div>
    </>
  );
}
