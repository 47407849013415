import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TextField } from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

class ConfirmRejectReceiveModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      remark: '',
    };
    this.handleShow = () => {
      this.setState({ show: true, remark: '' });
    };
    this.handleHide = () => {
      this.setState({ show: false, remark: '' });
    };
  }
  render() {
    const { id, displayAlert, styleButton, remark } = this.props;
    const { show } = this.state;
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id='reject'>ปฏิเสธ</Tooltip>} placement='top'>
          <Button style={styleButton} variant='danger' onClick={this.handleShow}>
            <Icon className='flaticon2-trash' />
          </Button>
        </OverlayTrigger>
        <Modal show={show} onHide={this.handleHide} dialogClassName='modal-lg' aria-labelledby='example-custom-modal-styling-title'>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              เหตุผลการปฎิเสธหนังสือ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <div className='col-12'>
                <StyledTextField
                  id='remark'
                  value={this.state.remark}
                  multiline
                  rows='4'
                  margin='normal'
                  variant='outlined'
                  onChange={e => {
                    this.setState({ remark: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <div className='form-group row'>
            <StyledButtonRight className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='success'
                onClick={() => {
                  this.handleHide();
                  displayAlert('reject', {
                    id: id,
                    remark: this.state.remark
                  });
                }}
              >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='danger'
                onClick={this.handleHide}
              >
                ยกเลิก
              </Button>
            </StyledButtonLeft>
          </div>
        </Modal>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default ConfirmRejectReceiveModal;