import React from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { TextField, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import API from '../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class LendingModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      name: null,
      remark: null,
      docs_lending_status: null,
      stores_docs_id: this.props.stores_docs_id,
    };
    this.handleShow = async () => {
      await this.getSearch();
      this.setState({ show: true });
    };
    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  getSearch = async () => {
    const { id } = this.props;
    const docs_lending_status = await API.get(
      '/console/static/docs_lending_status/all'
    );
    if (docs_lending_status.success) {
      this.setState({ docs_lending_status_data: docs_lending_status.data });
    }
    if (id) {
      this.getData(id);
    }
  };
  getData = async (id) => {
    await API.get(`/console/lending/${id}/edit`).then(({ success, data }) => {
      if (success) {
        this.setState({
          id,
          ...data,
        });
      }
    });
  };

  onSave = async () => {
    const { id } = this.props;
    this.displayAlert('loading');
    try {
      const { name, remark, docs_lending_status, stores_docs_id } = this.state;
      let data = {
        name,
        remark,
        docs_lending_status,
        stores_docs_id,
      };
      if (id) {
        const responce = await API.post(`/console/lending/${id}/edit`, data);
        responce.success
          ? this.displayAlert('success', responce.data)
          : this.displayAlert('error', responce.error);
      } else {
        const responce = await API.post(`/console/lending/add`, data);
        responce.success
          ? this.displayAlert('success', responce.data)
          : this.displayAlert('error', responce.error);
          this.setState({ name: '', remark: '', docs_lending_status: null })
      }
    } catch (e) {}
  };

  // componentDidMount() {
  //   this.getSearch();
  // }
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.handleHide();
          this.props.reloadData();
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default: break;
    }
  };

  render() {
    const { styleButton, btnName, btnColor } = this.props;
    const { show, docs_lending_status_data } = this.state;
    const list_docs_lending_status = _.map(
      docs_lending_status_data,
      (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      }
    );
    return (
      <>
        <OverlayTrigger
          overlay={<Tooltip id='lending'>ยืม</Tooltip>}
          placement='top'
        >
          <Button
            style={styleButton}
            variant={btnColor ? btnColor : 'info'}
            onClick={this.handleShow}
          >
            {btnName ? btnName : <Icon className='flaticon-edit-1' />}
          </Button>
        </OverlayTrigger>
        <Modal
          show={show}
          onHide={this.handleHide}
          dialogClassName='modal-lg'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              ยืมหนังสือ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-3 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-6' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='docs_lending_status'
                  select
                  value={
                    this.state.docs_lending_status != null
                      ? this.state.docs_lending_status
                      : ''
                  }
                  onChange={(e) => {
                    this.setState({ docs_lending_status: e.target.value });
                  }}
                  margin='normal'
                >
                  {list_docs_lending_status}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-3 col-form-label'>
                ชื่อ
              </StyledLabelRight>
              <div className='col-md-6'>
                <StyledTextField
                  id='name'
                  placeholder='ชื่อ'
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-3 col-form-label'>
                รายละเอียด
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='remark'
                  placeholder='รายละเอียด'
                  multiline
                  rows='5'
                  variant='outlined'
                  value={this.state.remark}
                  onChange={(e) => {
                    this.setState({ remark: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <div className='form-group row'>
            <StyledButtonRight className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='success'
                onClick={() => this.displayAlert('save')}
              >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='danger'
                onClick={this.handleHide}
              >
                ยกเลิก
              </Button>
            </StyledButtonLeft>
          </div>
        </Modal>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default LendingModal;
