import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MenuItem, TextField } from '@material-ui/core';

import DatePicker from 'react-datepicker';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

class ReceiveModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      received_at: moment().format('YYYY-MM-DD'),
      book_cate_id: null,
    };
    this.handleShow = () => {
      this.setState({ show: true, received_at: props.received_at ? props.received_at : moment().format('YYYY-MM-DD'), book_cate_id: props.book_cate_id });
    };
    this.handleHide = () => {
      this.setState({ show: false, received_at: moment().format('YYYY-MM-DD'), book_cate_id: null });
    };
  }
  render() {
    const { id, book_cate_data, displayAlert, styleButton, received_at, book_cate_id } = this.props;
    const { show } = this.state;
    const list_book_cate = _.map(book_cate_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.book_cate_id}>
          {value.name}
        </MenuItem>
      );
    });
    const can_edit_received_at = (received_at ? false : true);
    const can_edit_book_cate = (book_cate_id ? false : true);
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id='receive'>รับ</Tooltip>} placement='top'>
          <Button style={styleButton} variant='success' onClick={this.handleShow}>
            <Icon className='flaticon-doc' />
          </Button>
        </OverlayTrigger>
        <Modal show={show} onHide={this.handleHide} dialogClassName='modal-90w' aria-labelledby='example-custom-modal-styling-title'>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              รับเอกสาร
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label' color='red'>
                *วันที่ลงทะเบียนรับ
              </StyledLabelRight>
              <div className='col-md-6'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={this.state.received_at ? new Date(this.state.received_at) : null}
                  onChange={date => {
                    try {
                        this.setState({ received_at: date ? moment(date).format('YYYY-MM-DD') : null });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onKeyDown={e => e.preventDefault()}
                  disabled={!can_edit_received_at}
                />
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label' color='red'>
                *เลือกเล่มทะเบียนรับ
              </StyledLabelRight>
              <div className='col-md-6' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  value={this.state.book_cate_id}
                  onChange={(e) => {
                    this.setState({ book_cate_id: e.target.value });
                  }}
                  margin='normal'
                  disabled={!can_edit_book_cate}
                >
                  {list_book_cate}
                </StyledTextField>
              </div>
            </div>
          </Modal.Body>
          <div className='form-group row'>
            <StyledButtonRight className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='success'
                onClick={() => {
                  this.handleHide();
                  displayAlert('receive', {
                    id: id,
                    received_at: this.state.received_at,
                    book_cate_id: this.state.book_cate_id,
                  });
                }}
              >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='danger'
                onClick={this.handleHide}
              >
                ยกเลิก
              </Button>
            </StyledButtonLeft>
          </div>
        </Modal>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default ReceiveModal;
