import moment from 'moment';
const current_year = new Date().getFullYear();
const current_yearTH = current_year + 543;
const start_date_of_year = new Date('1/1/' + current_year);
const start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
const end_date = moment().format('YYYY-MM-DD');
export default {
  header: {
    self: {},
    items: [
      {
        title: 'Dashboards',
        root: true,
        alignment: 'left',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
      },
      {
        title: 'Material UI',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: [
          {
            title: 'Layout',
            bullet: 'dot',
            submenu: [
              {
                title: 'Box',
                bullet: 'line',
                page: 'google-material/layout/box',
              },
              {
                title: 'Container',
                bullet: 'line',
                page: 'google-material/layout/container',
              },
              {
                title: 'Grid',
                bullet: 'line',
                page: 'google-material/layout/grid',
              },
              {
                title: 'Grid list',
                bullet: 'line',
                page: 'google-material/layout/grid-list',
              },
              {
                title: 'Hidden',
                bullet: 'line',
                page: 'google-material/layout/hidden',
              },
            ],
          },
          {
            title: 'Inputs',
            bullet: 'dot',
            submenu: [
              {
                title: 'Autocomplete',
                page: 'google-material/inputs/autocomplete',
                bullet: 'line',
                permission: 'accessToECommerceModule',
              },
              {
                title: 'Buttons',
                page: 'google-material/inputs/buttons',
                bullet: 'line',
              },
              {
                title: 'Checkboxes',
                page: 'google-material/inputs/checkboxes',
                bullet: 'line',
              },
              {
                title: 'Pickers',
                page: 'google-material/inputs/pickers',
                bullet: 'line',
              },
              {
                title: 'Radio Buttons',
                page: 'google-material/inputs/radio-buttons',
                bullet: 'line',
              },
              {
                title: 'Selects',
                page: 'google-material/inputs/selects',
                bullet: 'line',
              },
              {
                title: 'Switches',
                page: 'google-material/inputs/switches',
                bullet: 'line',
              },
              {
                title: 'Text Fields',
                page: 'google-material/inputs/text-fields',
                bullet: 'line',
              },
              {
                title: 'Transfer List',
                page: 'google-material/inputs/transfer-list',
                bullet: 'line',
              },
            ],
          },
          {
            title: 'Navigation',
            bullet: 'dot',
            submenu: [
              {
                title: 'Bottom Navigation',
                bullet: 'line',
                page: 'google-material/navigation/bottom-navigation',
              },
              {
                title: 'Breadcrumbs',
                bullet: 'line',
                page: 'google-material/navigation/breadcrumbs',
              },
              {
                title: 'Drawers',
                bullet: 'line',
                page: 'google-material/navigation/drawer',
              },
              {
                title: 'Links',
                bullet: 'line',
                page: 'google-material/navigation/links',
              },
              {
                title: 'Menus',
                bullet: 'line',
                page: 'google-material/navigation/menus',
              },
              {
                title: 'Steppers',
                bullet: 'line',
                page: 'google-material/navigation/steppers',
              },
              {
                title: 'Tabs',
                bullet: 'line',
                page: 'google-material/navigation/tabs',
              },
            ],
          },
          {
            title: 'Surfaces',
            bullet: 'dot',
            submenu: [
              {
                title: 'App Bar',
                bullet: 'line',
                page: 'google-material/surfaces/app-bar',
              },
              {
                title: 'Paper',
                bullet: 'line',
                page: 'google-material/surfaces/paper',
              },
              {
                title: 'Cards',
                bullet: 'line',
                page: 'google-material/surfaces/cards',
              },
              {
                title: 'Expansion Panels',
                bullet: 'line',
                page: 'google-material/surfaces/expansion-panels',
              },
            ],
          },
          {
            title: 'Feedback',
            bullet: 'dot',
            submenu: [
              {
                title: 'Progress',
                bullet: 'line',
                page: 'google-material/feedback/progress',
              },
              {
                title: 'Dialogs',
                bullet: 'line',
                page: 'google-material/feedback/dialogs',
              },
              {
                title: 'Snackbars',
                bullet: 'line',
                page: 'google-material/feedback/snackbars',
              },
            ],
          },
          {
            title: 'Data Display',
            bullet: 'dot',
            submenu: [
              {
                title: 'Avatars',
                bullet: 'line',
                page: 'google-material/data-displays/avatars',
              },
              {
                title: 'Badges',
                bullet: 'line',
                page: 'google-material/data-displays/badges',
              },
              {
                title: 'Chips',
                bullet: 'line',
                page: 'google-material/data-displays/chips',
              },
              {
                title: 'Dividers',
                bullet: 'line',
                page: 'google-material/data-displays/dividers',
              },
              {
                title: 'Icons',
                bullet: 'line',
                page: 'google-material/data-displays/icons',
              },
              {
                title: 'Lists',
                bullet: 'line',
                page: 'google-material/data-displays/lists',
              },
              {
                title: 'Tables',
                bullet: 'line',
                page: 'google-material/data-displays/tables',
              },
              {
                title: 'Tooltips',
                bullet: 'line',
                page: 'google-material/data-displays/tooltips',
              },
              {
                title: 'Typography',
                bullet: 'line',
                page: 'google-material/data-displays/typography',
              },
            ],
          },
          {
            title: 'Utils',
            bullet: 'dot',
            submenu: [
              {
                title: 'Click Away Listener',
                bullet: 'line',
                page: 'google-material/utils/click-away-listener',
              },
              {
                title: 'Modal',
                bullet: 'line',
                page: 'google-material/utils/modal',
              },
              {
                title: 'No SSR',
                bullet: 'line',
                page: 'google-material/utils/no-ssr',
              },
              {
                title: 'Popover',
                bullet: 'line',
                page: 'google-material/utils/popover',
              },
              {
                title: 'Popper',
                bullet: 'line',
                page: 'google-material/utils/popper',
              },
              {
                title: 'Portal',
                bullet: 'line',
                page: 'google-material/utils/portal',
              },
              {
                title: 'Transitions',
                bullet: 'line',
                page: 'google-material/utils/transitions',
              },
              {
                title: 'useMediaQuery',
                bullet: 'line',
                page: 'google-material/utils/use-media-query',
              },
            ],
          },
        ],
      },
      {
        title: 'Bootstrap',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: {
          type: 'mega',
          width: '800px',
          alignment: 'left',
          columns: [
            {
              items: [
                {
                  title: 'Alerts',
                  page: 'react-bootstrap/alert',
                },
                {
                  title: 'Accordion',
                  page: 'react-bootstrap/accordion',
                },
                {
                  title: 'Badge',
                  page: 'react-bootstrap/badge',
                },
                {
                  title: 'Breadcrumb',
                  page: 'react-bootstrap/breadcrumb',
                },
                {
                  title: 'Buttons',
                  page: 'react-bootstrap/buttons',
                },
                {
                  title: 'Button Group',
                  page: 'react-bootstrap/button-group',
                },
                {
                  title: 'Cards',
                  page: 'react-bootstrap/cards',
                },
              ],
            },
            {
              items: [
                {
                  title: 'Carousel',
                  page: 'react-bootstrap/carousel',
                  bullet: 'line',
                },
                {
                  title: 'Dropdowns',
                  page: 'react-bootstrap/dropdowns',
                  bullet: 'line',
                },
                {
                  title: 'Forms',
                  page: 'react-bootstrap/forms',
                  bullet: 'line',
                },
                {
                  title: 'Input Group',
                  page: 'react-bootstrap/input-group',
                  bullet: 'line',
                },
                {
                  title: 'Images',
                  page: 'react-bootstrap/images',
                  bullet: 'line',
                },
                {
                  title: 'Figures',
                  page: 'react-bootstrap/figures',
                  bullet: 'line',
                },
                {
                  title: 'Jumbotron',
                  page: 'react-bootstrap/jumbotron',
                  bullet: 'line',
                },
              ],
            },
            {
              items: [
                {
                  title: 'List group',
                  page: 'react-bootstrap/list-group',
                  bullet: 'dot',
                },
                {
                  title: 'Modal',
                  page: 'react-bootstrap/modal',
                  bullet: 'dot',
                },
                {
                  title: 'Navs',
                  page: 'react-bootstrap/navs',
                  bullet: 'dot',
                },
                {
                  title: 'Navbar',
                  page: 'react-bootstrap/navbar',
                  bullet: 'dot',
                },
                {
                  title: 'Overlays',
                  page: 'react-bootstrap/overlays',
                  bullet: 'dot',
                },
                {
                  title: 'Pagination',
                  page: 'react-bootstrap/pagination',
                  bullet: 'dot',
                },
                {
                  title: 'Popovers',
                  page: 'react-bootstrap/popovers',
                  bullet: 'dot',
                },
              ],
            },
            {
              items: [
                {
                  title: 'Progress',
                  page: 'react-bootstrap/progress',
                },
                {
                  title: 'Spinners',
                  page: 'react-bootstrap/spinners',
                },
                {
                  title: 'Table',
                  page: 'react-bootstrap/table',
                },
                {
                  title: 'Tabs',
                  page: 'react-bootstrap/tabs',
                },
                {
                  title: 'Tooltips',
                  page: 'react-bootstrap/tooltips',
                },
                {
                  title: 'Toasts',
                  page: 'react-bootstrap/toasts',
                },
              ],
            },
          ],
        },
      },
      //
      //
      {
        title: 'Custom',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: [
          {
            title: 'Layout Builder',
            icon: 'flaticon2-expand',
            page: 'builder',
          },
          {
            title: 'Error Pages',
            bullet: 'dot',
            icon: 'flaticon2-warning',
            submenu: [
              {
                title: 'Error 1',
                page: 'error/error-v1',
              },
              {
                title: 'Error 2',
                page: 'error/error-v2',
              },
              {
                title: 'Error 3',
                page: 'error/error-v3',
              },
              {
                title: 'Error 4',
                page: 'error/error-v4',
              },
              {
                title: 'Error 5',
                page: 'error/error-v5',
              },
              {
                title: 'Error 6',
                page: 'error/error-v6',
              },
            ],
          },
        ],
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'แดชบอร์ด',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        page: 'dashboard',
        translate: 'แดชบอร์ด',
        bullet: 'dot',
      },
      {
        title: 'ลงทะเบียนรับ',
        root: true,
        custom_icon: 'register-receive',
        toggle: 'click',
        translate: 'ลงทะเบียนรับ',
        bullet: 'dot',
        submenu: [
          {
            title: 'ลงทะเบียนรับ',
            page: `register-receive-add`,
            permission: { main: 'documents', sub: 'register_receive', action: 'add', },
          },
          {
            title: 'รอลงทะเบียนรับ',
            page: `register-receive-paginate/pending?doc_status=5&year_from=${current_yearTH - 1}&year_to=${current_yearTH}&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_receive', action: 'view', },
          },
          {
            title: 'ทะเบียนรับ',
            page: `register-receive-paginate/receive?doc_status=6&year_from=${current_yearTH - 1}&year_to=${current_yearTH}&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_receive', action: 'view', },
          },
          {
            title: 'ปฎิเสธ/ตีกลับ',
            page: `register-receive-paginate/reject?doc_status=4,25&year_from=${current_yearTH - 1}&year_to=${current_yearTH}&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_receive', action: 'view', },
          },
          {
            title: 'ค้นหา',
            page: `register-receive-paginate/all?year_from=${current_yearTH - 1}&year_to=${current_yearTH}&start_date=${start_date}&end_date=${end_date}&page=1&display_filters=true`,
            permission: { main: 'documents', sub: 'register_receive', action: 'view', },
          },
        ],
      },
      {
        title: 'ลงทะเบียนส่ง',
        root: true,
        custom_icon: 'register-send',
        toggle: 'click',
        translate: 'ลงทะเบียนส่ง',
        bullet: 'dot',
        submenu: [
          {
            title: 'ลงทะเบียนส่ง',
            page: `register-send-add`,
            permission: { main: 'documents', sub: 'register_send', action: 'add' },
          },
          {
            title: 'ร่าง',
            page: `register-send-paginate/draft?doc_status=0&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_send', action: 'view' },
          },
          {
            title: 'ดึงเรื่องคืน',
            page: `register-send-paginate/reject?doc_status=8&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_send', action: 'view' },
          },
          {
            title: 'ทะเบียนส่ง',
            page: `register-send-paginate/send?doc_status=1&start_date=${start_date}&end_date=${end_date}&page=1`,
            permission: { main: 'documents', sub: 'register_send', action: 'view' },
          },
          {
            title: 'ค้นหา',
            page: `register-send-paginate/all?start_date=${start_date}&end_date=${end_date}&page=1&display_filters=true`,
            permission: { main: 'documents', sub: 'register_send', action: 'view' },
          },
        ],
      },
      {
        title: 'จัดเก็บหนังสือ',
        root: true,
        custom_icon: 'store',
        toggle: 'click',
        translate: 'จัดเก็บหนังสือ',
        bullet: 'dot',
        submenu: [
          {
            title: 'ทะเบียนจัดเก็บหนังสือรับ',
            page: `store-paginate/1?cate_stores_id=&sub_cate_stores_id=&stores_docs_type=&cate_destroy_stores_id=&stores_docs_status=&year=${moment().format(
              'YYYY'
            )}&trans_type=1`,
            permission: {
              main: 'store_doc',
              sub: 'receive',
              action: 'view',
            },
          },
          {
            title: 'ทะเบียนจัดเก็บหนังสือส่ง',
            page: `store-paginate/2?cate_stores_id=&sub_cate_stores_id=&stores_docs_type=&cate_destroy_stores_id=&stores_docs_status=&year=${moment().format(
              'YYYY'
            )}&trans_type=2`,
            permission: {
              main: 'store_doc',
              sub: 'send',
              action: 'view',
            },
          },
        ],
      },
      {
        title: 'E-Form',
        root: true,
        custom_icon: 'e-form',
        toggle: 'click',
        bullet: 'dot',
        submenu: [
          {
            title: 'หนังสือส่งภายนอก',
            page: 'e-form-build/external?form_type=external',
            permission: {
              main: 'eform',
              sub: 'eform_external',
              action: 'view',
            },
          },
          {
            title: 'หนังสือส่งภายใน/บันทึกข้อความ',
            page: 'e-form-build/internal?form_type=internal',
            permission: {
              main: 'eform',
              sub: 'eform_internal',
              action: 'view',
            },
          },
          {
            title: 'หนังสือประทับตรา',
            page: 'e-form-build/stamp?form_type=stamp',
            permission: {
              main: 'eform',
              sub: 'eform_stamp',
              action: 'view',
            },
          },
          {
            title: 'หนังสือคำสั่ง',
            page: 'e-form-build/command?form_type=command',
            permission: {
              main: 'eform',
              sub: 'eform_command',
              action: 'view',
            },
          },
          {
            title: 'หนังสือประกาศ',
            page: 'e-form-build/announce?form_type=announce',
            permission: {
              main: 'eform',
              sub: 'eform_announce',
              action: 'view',
            },
          },
          {
            title: 'รายการ e-Form',
            page: 'e-form-list/t1?type=search',
            permission: {
              main: 'eform',
              sub: 'eform_list',
              action: 'view',
            },
          },
          {
            title: 'คืนต้นเรื่อง',
            page: 'e-form-list/t2?type=sent-back',
            permission: {
              main: 'eform',
              sub: 'eform_sent_back',
              action: 'view',
            },
          },
          {
            title: 'รอกลั่นกรอง',
            page: 'e-form-list/t3?type=wait-review',
            permission: {
              main: 'eform',
              sub: 'eform_wait_review',
              action: 'view',
            },
          },
          {
            title: 'รอลงนาม',
            page: 'e-form-list/t4?type=wait-endorse',
            permission: {
              main: 'eform',
              sub: 'eform_wait_endorse',
              action: 'view',
            },
          },
          {
            title: 'รอหน้าห้อง',
            page: 'e-form-list/t5?type=wait-send-executive-secretary',
            permission: {
              main: 'eform',
              sub: 'eform_wait_send_executive_secretary',
              action: 'view',
            },
          },
          {
            title: 'รอหน้าห้อง ผู้บริหาร',
            page: 'e-form-list/t6?type=wait-send-executive',
            permission: {
              main: 'eform',
              sub: 'eform_wait_send_executive',
              action: 'view',
            },
          },
          {
            title: 'รอส่ง',
            page: 'e-form-list/t7?type=wait-send',
            permission: {
              main: 'eform',
              sub: 'eform_wait_send',
              action: 'view',
            },
          },
        ],
      },
      {
        title: 'รายงาน',
        root: true,
        custom_icon: 'chart',
        toggle: 'click',
        bullet: 'dot',
        submenu: [
          {
            title: 'ทะเบียนรับ',
            page: 'receive-report',
            permission: {
              main: 'report',
              sub: 'register_receive',
              action: 'view',
            },
          },
          {
            title: 'ทะเบียนส่ง',
            page: 'send-report',
            permission: {
              main: 'report',
              sub: 'register_send',
              action: 'view',
            },
          },
          // {
          //   title: 'หนังสือราชการที่ต้องปฏิบัติ',
          //   page: 'operate-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'ประวัติการปฎิบัติงานส่วนบุคคล',
          //   page: 'person-work-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'ประวิติการเข้าใช้งานระบบตามช่วงเวลา',
          //   page: 'person-log-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สรุปจำนวนเลขรับ-ส่งหนังสือ',
          //   page: 'summary-receive-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สถิติจำนวนเรื่อง รับเข้า,ส่งออก',
          //   page: 'statistics-receive-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สถิติจำนวนเรื่อง ค้างรับ,ค้างส่งออก',
          //   page: 'stale-receive-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สถิติจำนวนครั้งที่ รับ,ส่ง หนังสือ',
          //   page: 'count-receive-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สรุปจำนวนหนังสือส่งออกจำแนกตามหน่วยงานภายนอก',
          //   page: 'outside-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สถิติการสร้างหนังสือ,ออกเลข',
          //   page: 'statistics-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'การตีเรื่องกลับ',
          //   page: 'reject-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'การปิดเรื่อง',
          //   page: 'close-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'การยกเลิกเรื่อง',
          //   page: 'cancel-send-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สรุปจำนวนหนังสือรับเข้าจำแนกตามหน่วยงานภายนอก',
          //   page: 'outside-receive-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
          // {
          //   title: 'สรุปจำนวนหนังสือรับเข้าจำแนกตามหน่วยงานภายใน',
          //   page: 'inside-receive-report',
          //   permission: {
          //     main: 'report',
          //     sub: 'register_send',
          //     action: 'view',
          //   },
          // },
        ],
      },
      {
        title: 'ข่าวประชาสัมพันธ์',
        root: true,
        custom_icon: 'register-send',
        toggle: 'click',
        translate: 'ข่าวประชาสัมพันธ์',
        bullet: 'dot',
        submenu: [
          {
            title: 'ข่าวประชาสัมพันธ์',
            page: `news`,
            permission: { main: 'news', sub: 'news', action: 'view' },
          },
          {
            title: 'บริหารข่าวประชาสัมพันธ์',
            page: `news-management`,
            permission: { main: 'news', sub: 'news_management', action: 'view' },
          },
        ],
      },
      {
        title: 'การตั้งค่า',
        root: true,
        custom_icon: 'register-book',
        toggle: 'click',
        translate: 'การตั้งค่า',
        bullet: 'dot',
        submenu: [
          {
            title: 'เล่มทะเบียนส่ง',
            page: 'register-book/send?trans_type=2',
            permission: { main: 'settings', sub: 'register_book_send', action: 'view' },
          },
          {
            title: 'เล่มทะเบียนรับ',
            page: 'register-book/receive?trans_type=1',
            permission: { main: 'settings', sub: 'register_book_receive', action: 'view' },
          },
          {
             title: 'เลขทะเบียนส่ง',
             page: 'register-doc-no/send?trans_type=2',
             permission: { main: 'settings', sub: 'register_send_no', action: 'view' },
          },
          {
             title: 'เลขทะเบียนรับ',
             page: 'register-doc-no/receive?trans_type=1',
             permission: { main: 'settings', sub: 'register_receive_no', action: 'view' },
          },
        ],
      },
      {
        title: 'การตั้งค่าหน่วยงาน',
        root: true,
        custom_icon: 'register-book',
        toggle: 'click',
        translate: 'การตั้งค่าหน่วยงาน',
        bullet: 'dot',
        submenu: [
          {
            title: 'ตั้งค่า e-Form',
            page: 'e-form-setting',
            permission: { main: 'division_settings', sub: 'docs_send_no_reserve', action: 'view' },
          },
          {
            title: 'จองเลขทะเบียนส่ง',
            page: 'reserve-doc-send-no',
            permission: { main: 'division_settings', sub: 'eform_settings', action: 'view' },
          }
        ],
      },
      {
        title: 'สมาชิก',
        root: true,
        icon: 'flaticon-user-add',
        page: 'user',
        translate: 'สมาชิก',
        bullet: 'dot',
        permission: { main: 'users', sub: 'user_management', action: 'view' },
      },
      {
        title: 'สิทธิ์การเข้าถึงข้อมูล',
        root: true,
        icon: 'flaticon2-console',
        page: 'role',
        translate: 'สิทธิ์การเข้าถึงข้อมูล',
        bullet: 'dot',
        permission: {
          main: 'users',
          sub: 'user_roles',
          action: 'view',
        },
      },
      {
        title: 'กระทรวง',
        root: true,
        icon: 'flaticon-settings-1',
        page: 'organization',
        translate: 'กระทรวง',
        bullet: 'dot',
        permission: {
          main: 'organizations',
          sub: 'organizations_management',
          action: 'view',
        },
      },
      {
        title: 'กรม',
        root: true,
        icon: 'flaticon-settings-1',
        page: 'department',
        translate: 'กรม',
        bullet: 'dot',
        permission: {
          main: 'organizations',
          sub: 'departments_management',
          action: 'view',
        },
      },
      {
        title: 'หน่วยงาน',
        root: true,
        icon: 'flaticon-settings-1',
        page: 'division',
        translate: 'หน่วยงาน',
        bullet: 'dot',
        permission: {
          main: 'organizations',
          sub: 'divisions_management',
          action: 'view',
        },
      },
      {
        title: 'หน่วยงานย่อย',
        root: true,
        icon: 'flaticon-settings-1',
        page: 'sub-division',
        translate: 'หน่วยงานย่อย',
        bullet: 'dot',
        permission: {
          main: 'organizations',
          sub: 'sub_divisions_management',
          action: 'view',
        },
      },
      {
        title: 'ค้นหาข้อมูล',
        root: true,
        custom_icon: 'register-book',
        toggle: 'click',
        translate: 'ค้นหาข้อมูล',
        bullet: 'dot',
        submenu: [
          {
            title: 'ค้นหาทะเบียนรับ',
            page: `register-receive?division_id=&sub_division_id=&doc_status=&doc_no=&item_no=&keyword=&start_date=${start_date}&end_date=${end_date}&book_cate_id=&doc_type_id=&form_type_id=&page=0`,
            permission: { main: 'search', sub: 'register_receive', action: 'view' },
          },
          {
            title: 'ค้นหาทะเบียนส่ง',
            page: `register-send?division_id=&sub_division_id=&doc_status=&doc_no=&item_no=&keyword=&start_date=${start_date}&end_date=${end_date}&book_cate_id=&doc_type_id=&form_type_id=&page=0`,
            permission: { main: 'search', sub: 'register_send', action: 'view' },
          },
          {
            title: 'ค้นหา Log',
            page: `user-log?type=error&start_date=${end_date}&end_date=${end_date}&page=1`,
            permission: { main: 'search', sub: 'user_log', action: 'view' },
          }
        ],
      },
      {
        title: 'e-CMS Data',
        root: true,
        custom_icon: '',
        toggle: 'click',
        translate: 'e-CMS Data',
        bullet: 'dot',
        submenu: [
          {
            title: 'หน่วยงาน e-CMS',
            page: 'division-dga',
          },
          {
            title: 'รหัสกระทรวง e-CMS',
            page: 'org-dga',
          },
          {
            title: 'รหัสชั้นความลับ e-CMS',
            page: 'secret-codes-dga',
          },
          {
            title: 'รหัสชั้นความเร็ว e-CMS',
            page: 'speed-codes-dga',
          },
          {
            title: 'ประเภทไฟล์เอกสาร e-CMS',
            page: 'mime-codes-dga',
          },
          {
            title: 'เช็กเวลา e-CMS', 
            page: 'time-check-dga',
          },
        ],
      },
    ],
  },
};