import React from 'react';
import RoleListPage from '../role-list/RoleListPage'
export default function RoleList() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RoleListPage />
        </div>
      </div>
    </>
  );
}
