const Const = {
    Table: {
        RowPerPages: 20,
    },
    DefaultOption: {
        SecretTypeId: '1',
        SpeedTypeId: '1',
        EFormDocStatusId: '0'
    },
    MasterData: {
        SpeedType: {
            Normal: '1'
        },
        EFormDocStatus: {
            Draft: 0,
            Receive: 6,
            SentBack: 27,
            WaitReview: 28,
            WaitEndorse: 29,
            WaitSendExecutive: 30,
            WaitSend: 31
        }
    },
    File: {
        ImageSizeLimit: 2097152,
        DocAttachExtension: ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'txt', 'jpg', 'bmp', 'tiff', 'png', 'zip', 'rtf'],
        DocAttachSizeLimit: 20971520,
    }
}

export default Const