import React from 'react';
import EFormReviewPage from '../e-form-review/EFormReviewPage';
export default function EFormReview() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <EFormReviewPage />
        </div>
      </div>
    </>
  );
}
