/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      check_edit: true,
      cate_stores_id: null,
      stores_docs_type: null,
      docs_age: null,
      sub_cate_stores_id: 0,
    };
  }
  displayAlert = (type = 'receive', data = null) => {
    console.log(data)
    switch (type) {
      case 'receive': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนรับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onConfirm();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        let html = (
          <>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เล่มทะเบียน
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.book_cate_name}
              </StyledButtonLeft>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เลขที่หนังสือ
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.receive_no}
              </StyledButtonLeft>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เรื่อง
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.topic}
              </StyledButtonLeft>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เรียน
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.to_name}
              </StyledButtonLeft>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                ลงวันที่
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {this.dateFormat(moment(data.str_date_at))}
              </StyledButtonLeft>
            </div>
          </>
        );
        MySwal.fire({
          title: 'สำเร็จ!',
          html,
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.props.history.goBack();
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };
  componentWillMount() { this.getData(); }
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    try {
      const stores_docs_id = this.props.location.state ? this.props.location.state.stores_docs_id : NaN;
      const _id = this.props.history.location.pathname.split('/register-receive-store/').pop();
      const id = !isNaN(stores_docs_id) ? stores_docs_id : _id;
      await API.get('/console/static/cate_stores_id/all').then((res) => {
        if (res && res.success) {
          let cate_stores_id = null;
          _.filter(res.data, (v, k) => {
            if (!cate_stores_id) cate_stores_id = k;
          });
          this.setState(
            {
              cate_stores_ids: res.data,
              cate_stores_id,
            },
            async () => {
              if (isNaN(id)) {
                await API.get(
                  `/console/static/sub_cate_stores_id/${cate_stores_id}`
                ).then((res) => {
                  if (res && res.success) {
                    this.setState({
                      sub_cate_stores_ids: _.merge(
                        {},
                        { 0: 'ไม่เลือก' },
                        res.data
                      ),
                      sub_cate_stores_id: 0,
                    });
                  }
                });
              }
            }
          );
        }
      });
      await API.get('/console/static/stores_docs_type/all').then((res) => {
        if (res && res.success) {
          let stores_docs_type = null;
          _.filter(res.data, (v, k) => {
            if (!stores_docs_type) stores_docs_type = k;
          });
          this.setState({
            stores_docs_types: res.data,
            stores_docs_type,
          });
        }
      });
      await API.get('/console/static/cate_destroy_stores_id/all').then(
        (res) => {
          if (res && res.success) {
            let cate_destroy_stores_id = null;
            _.filter(res.data, (v, k) => {
              if (!cate_destroy_stores_id) cate_destroy_stores_id = k;
            });
            this.setState({
              cate_destroy_stores_ids: res.data,
              cate_destroy_stores_id,
            });
          }
        }
      );
      await API.get('/console/static/docs_age/all').then((res) => {
        if (res && res.success) {
          let docs_age = null;
          _.filter(res.data, (v, k) => {
            if (!docs_age) docs_age = k;
          });
          this.setState({
            docs_ages: res.data,
            docs_age,
          });
        }
      });
      if (!isNaN(id)) {
        this.setState({ id });
        await API.get(`/console/stores/${id}/edit`).then((res) => {
          if (res && res.success) {
            API.get(
              `/console/static/sub_cate_stores_id/${res.data.cate_stores_id}`
            ).then((res1) => {
              if (res1 && res1.success) {
                this.setState({
                  sub_cate_stores_ids: _.merge(
                    {},
                    { 0: 'ไม่เลือก' },
                    res1.data
                  ),
                });
              }
            });
            this.setState({
              ...res.data,
            });
          }
        });
      }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  setData = (key, value) => {
    this.setState({ [key]: value }, () => {
      switch (key) {
        case 'cate_stores_id': {
          API.get(`/console/static/sub_cate_stores_id/${value}`).then((res) => {
            if (res && res.success) {
              this.setState({
                sub_cate_stores_ids: _.merge({}, { 0: 'ไม่เลือก' }, res.data),
                sub_cate_stores_id: 0,
              });
            }
          });
          break;
        }
        default:
          break;
      }
    });
  };
  onConfirm = async () => {
      this.displayAlert('loading');
    try {
      let api_path = '/console/stores/add';
      let req_data = {
        cate_stores_id: this.state.cate_stores_id,
        stores_docs_type: this.state.stores_docs_type,
      };
      req_data.sub_cate_stores_id = this.state.sub_cate_stores_id;
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.docs_trans_id
      ) {
        req_data.docs_trans_id = this.props.location.state.docs_trans_id;
      }
      if (typeof this.state.remark == 'string' && this.state.remark != '') {
        req_data.remark = this.state.remark;
      }
      if (this.state.docs_trans_id) {
        req_data.docs_trans_id = this.state.docs_trans_id;
      }
      if (this.state.cate_destroy_stores_id) {
        req_data.cate_destroy_stores_id = this.state.cate_destroy_stores_id;
      }
      if (this.state.docs_age) {
        req_data.docs_age = this.state.docs_age;
      }
      if (this.state.stores_docs_id) {
        api_path = `/console/stores/${this.state.stores_docs_id}/edit`;
      }
      await API.post(api_path, req_data).then((res) => {
        if (res && res.success) {
          this.displayAlert('success', res.data.item);
        } else {
          this.displayAlert('error', res.error);
        }
      });
    } catch (e) {}
  };
  handleSelectDivision = (selected_division) =>
    this.setState({ selected_division, selected_sub_division: null });
  handleSelectSubDivision = (selected_sub_division) =>
    this.setState({ selected_sub_division, selected_division: null });
  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    _.filter(this.state.division_data, (v) => {
      new_selected_divisions.push(v);
    });
    this.setState({
      selected_divisions: new_selected_divisions,
    });
  };
  handleSelectDivisions = (selected_divisions) =>
    this.setState({ selected_divisions });
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    _.filter(this.state.sub_division_data, (v) => {
      new_selected_sub_divisions.push(v);
    });
    this.setState({
      selected_sub_divisions: new_selected_sub_divisions,
    });
  };
  handleSelectSubDivisions = (selected_sub_divisions) =>
    this.setState({ selected_sub_divisions });
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) {
      formatArray[indexFullYear] = thaiYear;
    }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) {
      formatArray[indexShortYear] = thaiYear.toString().substr(2);
    }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>จัดเก็บหนังสือ</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ทะเบียนจัดเก็บ
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='book_cate_id'
                  value={this.state.cate_stores_id}
                  select
                  onChange={(e) =>
                    this.setData('cate_stores_id', String(e.target.value))
                  }
                  margin='normal'
                >
                  {_.map(this.state.cate_stores_ids, (label, value) => {
                    return (
                      <MenuItem key={`cate_stores_id_${value}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ทะเบียนจัดเก็บย่อย
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='book_cate_id'
                  value={this.state.sub_cate_stores_id}
                  select
                  onChange={(e) =>
                    this.setData('sub_cate_stores_id', String(e.target.value))
                  }
                  margin='normal'
                >
                  {_.map(this.state.sub_cate_stores_ids, (label, value) => {
                    return (
                      <MenuItem
                        key={`sub_cate_stores_id_${value}`}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </div>

            {/*
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ประเภทเอกสาร
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='book_cate_id'
                  value={this.state.stores_docs_type}
                  select
                  onChange={(e) =>
                    this.setData('stores_docs_type', String(e.target.value))
                  }
                  margin='normal'
                >
                  {_.map(this.state.stores_docs_types, (label, value) => {
                    return (
                      <MenuItem key={`stores_docs_type_${value}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </div>
            */}
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                อายุเอกสาร
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='book_cate_id'
                  value={this.state.docs_age}
                  select
                  onChange={(e) =>
                    this.setData('docs_age', String(e.target.value))
                  }
                  margin='normal'
                >
                  {_.map(this.state.docs_ages, (label, value) => {
                    return (
                      <MenuItem key={`docs_age_${value}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </div>
            {/* <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ทะเบียนเก็บหรือทำลาย
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='book_cate_id'
                  value={this.state.cate_destroy_stores_id}
                  select
                  onChange={(e) =>
                    this.setData(
                      'cate_destroy_stores_id',
                      String(e.target.value)
                    )
                  }
                  margin='normal'
                >
                  {_.map(this.state.cate_destroy_stores_ids, (label, value) => {
                    return (
                      <MenuItem key={`stores_docs_type_${value}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </div> */}
          </form>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              รายละเอียด
            </StyledLabelRight>
            <div className='col-md-8'>
              <StyledTextField
                id='note'
                value={this.state.remark}
                placeholder='รายละเอียด'
                multiline
                rows='5'
                margin='normal'
                variant='outlined'
                onChange={(e) =>
                  this.setState({
                    remark: String(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                style={{ marginLeft: '5px' }}
                onClick={() => this.onConfirm()}
                disabled={!this.state.check_edit}
              >
                ยืนยัน
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
export default withRouter(Class);
