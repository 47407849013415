import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem, Paper } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../api';
import moment from 'moment';
import _ from 'lodash';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
const head_rows = [
  {
    id: 'doc_download',
    sortable: false,
    disablePadding: false,
    label: 'เอกสาร',
    align: 'center',
  },
  {
    id: 'send_no',
    sortable: true,
    disablePadding: false,
    label: 'เลขหนังสือ',
    align: 'left',
  },
  { id: 'year', sortable: true, disablePadding: false, label: 'ปี', align: 'center' },
  { id: 'date_at', sortable: true, disablePadding: false, label: 'ลงวันที่', align: 'center' },
  { id: 'send_to', sortable: true, disablePadding: false, label: 'ส่งถึง', align: 'left' },
  { id: 'topic', sortable: true, disablePadding: false, label: 'เรื่อง', align: 'left' },
  {
    id: 'sended_by',
    sortable: true,
    disablePadding: false,
    label: 'ส่งโดย',
    align: 'left',
  },
  { id: 'doc_status', sortable: true, disablePadding: false, label: 'สถานะ', align: 'center' },
];
const moq_users = ['user_1', 'user_2', 'user_3', 'user_4', 'user_5'];
const moq_topics = ['topic_1', 'topic_2', 'topic_3', 'topic_4', 'topic_5'];
const moq_statuses = [
  'status_1',
  'status_2',
  'status_3',
  'status_4',
  'status_5',
];
const moq_histories = [
  'history_1',
  'history_2',
  'history_3',
  'history_4',
  'history_5',
];
var rows = [];
for (let i = 1; i <= 200; i++) {
  rows.push({
    docs_trans_id: i,
    check_edit: i == 2 || i == 5 ? false : true,
    send_no: `doc_no_${i}`,
    date_at: 1577158200,
    send_to: moq_users,
    topic: moq_topics[getRandomInt(0, 4)],
    sended_by: moq_users[getRandomInt(0, 4)],
    doc_status: moq_statuses[getRandomInt(0, 4)],
    view_history: moq_histories[getRandomInt(0, 4)],
  });
}
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const textRight = {
  textAlign: 'right',
};

class RegisterSendPaginatePage extends React.Component {
  table = React.createRef();
  constructor(props) {
    super(props);
    const current_year = new Date().getFullYear();
    const start_date_of_year = new Date('1/1/' + current_year);
    const start_date = moment(start_date_of_year.valueOf()).format(
      'YYYY-MM-DD'
    );
    const end_date = moment().format('YYYY-MM-DD');
    this.state = {
      display_loading: false,
      query_string: null,
      status: 'all',
      doc_status: null,
      doc_no: null,
      keyword: null,
      start_date,
      end_date,
      book_cate_id: null,
      doc_type_id: null,
      page: 1,
      display_filters: 'false',
      doc_status_text: '',
      per_page: null,
      selecteds: [],
      division_data: [],
      selected_division: null,
      master_secret: null,
      master_speed: null,
      form_type_id: null,
      item_no: null,
    };
  }
  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
          String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
        )
      : null;
  getQueryStringFromUrl = (callback = null) => {
    this.displayAlert('loading');
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const doc_status = this.unicodeToChar(url_params.get('doc_status'));
    const doc_no = this.unicodeToChar(url_params.get('doc_no'));
    const item_no = this.unicodeToChar(url_params.get('item_no'));
    const keyword = this.unicodeToChar(url_params.get('keyword'));
    const start_date = this.unicodeToChar(url_params.get('start_date'));
    const end_date = this.unicodeToChar(url_params.get('end_date'));
    const book_cate_id = this.unicodeToChar(url_params.get('book_cate_id'));
    const doc_type_id = this.unicodeToChar(url_params.get('doc_type_id'));
    const form_type_id = this.unicodeToChar(url_params.get('form_type_id'));
    const page = this.unicodeToChar(url_params.get('page'));
    const display_filters = this.unicodeToChar(url_params.get('display_filters'));
    let doc_status_text = '';
    switch (doc_status) {
      case '0':
        doc_status_text = 'ร่าง';
        break;
      case '1':
        doc_status_text = 'ทะเบียนส่ง';
        break;
      case '8':
        doc_status_text = 'ดึงคืนเรื่อง';
        break;
      default: {
        if (display_filters == 'true') doc_status_text = 'ค้นหา';
        else doc_status_text = 'ทั้งหมด';
        break;
      }
    }
    this.setState(
      {
        query_string,
        status: doc_status || 'all',
        doc_status,
        doc_no,
        item_no,
        keyword,
        start_date: moment(start_date, 'YYYY-MM-DD', true).isValid()
          ? start_date
          : moment()
              .add(-60, 'days')
              .format('YYYY-MM-DD'),
        end_date: moment(end_date, 'YYYY-MM-DD', true).isValid()
          ? end_date
          : moment().format('YYYY-MM-DD'),
        book_cate_id,
        doc_type_id,
        form_type_id,
        page: page || 1,
        display_filters,
        doc_status_text,
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      default:
        break;
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  /* cDF = date => {
    const Ymd = date.split('-');
    return `${Ymd[2]}/${Ymd[1]}/${Ymd[0]}`;
  }; */
  getData = () => {
    this.displayAlert('loading');
    this.setState({
      send_data: undefined,
    });
    const {
      start_date,
      end_date,
      doc_type_id,
      book_cate_id,
      doc_status,
      keyword,
      page,
      doc_no,
      item_no,
      secret_type_id,
      speed_type_id,
      selected_division,
      book_cate_data,
      form_type_id,
    } = this.state;
    const data = {
      start_date: start_date.replace('/', '-'),
      end_date: end_date.replace('/', '-'),
      doc_type_id,
      book_cate_id,
      doc_status,
      keyword,
      page,
      doc_no,
      item_no,
      secret_type_id,
      speed_type_id,
      send_to: {
        division_id: [],
        sub_division_id: [],
      },
      form_type_id,
    };
    if (selected_division) {
      const str = selected_division[0].value;
      if (str.includes('main_')) {
        var main = str.split('main_');
        data.send_to.division_id.push(main[1]);
      }
      if (str.includes('sub_')) {
        var sub = str.split('sub_');
        data.send_to.sub_division_id.push(sub[1]);
      }
    }
    API.post('/console/send/list', data).then((res) => {
      _.filter(res.data, (v, k) => {
        const book_cate = _.find(
          book_cate_data,
          (value) => value.book_cate_id == v.book_cate_id && value.name
        );
        const book_cate_name = book_cate ? book_cate.name : '-';
        v.send_no = book_cate_name + ' ' + v.send_no;
      });
      if (res && res.success) {
        this.setState(
          {
            send_data: res.data,
            current_page: res.current_page,
            last_page: res.last_page,
            per_page: res.count,
            display_loading: false,
          },
          () => MySwal.close()
        );
      }
    });
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };
  handleSelectDivision = (selected_division) => {
    this.setState({ selected_division, selected_sub_division: null });
  };
    getSearch = async () => {
        curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    const doc_type_req = await API.get('/console/doc_type/all');
    if (doc_type_req.success) {
      this.setState({
        doc_type_data: doc_type_req.data,
      });
    }
    const book_cate_req = await API.get('/console/book_cate/all?trans_type=2');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }
    const master_secret = await API.get(`/console/static/master_secret/all`);
    if (master_secret.success) {
      this.setState({
        master_secret_data: master_secret.data,
      });
    }
    const master_speed = await API.get(`/console/static/master_speed/all`);
    if (master_speed.success) {
      this.setState({
        master_speed_data: master_speed.data,
      });
    }
    const master_form_type = await API.get(`/console/static/master_form_type/all`);
      if (master_form_type.success) {
      this.setState({
        master_form_type_data: master_form_type.data,
      });
    }
    const master_doc_status = await API.get('/console/static/doc_status/all?trans_type=2');
    if (master_doc_status.success) {
      this.setState({
        master_doc_status_data: master_doc_status.data,
      });
    }
    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res.success) {
        const division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));
        const sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
        }));
        var division_data = division.concat(sub_division);
        this.setState({ division_data });
      }
    });
    this.getData();
  };
  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.getSearch())
      );
    }
  }
  onSubmit = (no_time = false) => {
    const doc_status = this.state.doc_status || '';
    const doc_no = this.state.doc_no || '';
    const item_no = this.state.item_no || '';
    const keyword = this.state.keyword || '';
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const book_cate_id = this.state.book_cate_id || '';
    const doc_type_id = this.state.doc_type_id || '';
    const display_filters = this.state.display_filters || '';
    const form_type_id = this.state.form_type_id || '';
    const path = `/register-send-paginate${curr_page}?doc_status=${doc_status}&doc_no=${doc_no}&item_no=${item_no}&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}&book_cate_id=${book_cate_id}&doc_type_id=${doc_type_id}&form_type_id=${form_type_id}&page=1&display_filters=${display_filters}`;
    console.log('path -->', path);
    this.props.history.push(path);
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string == query_string) {
      this.getData();
    }
    setTimeout(
      () => {
        const query_string = window.location.search;
        if (
          this.state.query_string &&
          this.state.query_string != query_string
        ) {
          this.getQueryStringFromUrl(() => this.getSearch());
        }
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: this.table.current === undefined || this.table.current.offsetTop === undefined ? 553 : this.table.current.offsetTop,
        });
      },
      no_time ? 0 : 250
    );
  };
  onEnter = _.debounce((e) => {
    if (this.state.display_filters == 'true' && e.keyCode === 13) {
      this.onSubmit(true);
    }
  }, 250);
  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
    this.getQueryStringFromUrl(() => this.getSearch());
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }
  onChangeValue = (key, value) => this.setState({ [key]: value });
  render() {
    const {
      secret_type_id,
      speed_type_id,
      master_secret_data,
      master_speed_data,
      master_form_type_data,
      master_doc_status_data,
    } = this.state;
    const list_master_secret = _.map(master_secret_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_master_speed = _.map(master_speed_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_form_type = _.map(master_form_type_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_doc_status = _.map(master_doc_status_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.doc_status_id + ''}>
          {value.name}
        </MenuItem>
      );
    });
    return (
      <>
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>
                รายการหนังสือส่ง
                {typeof this.state.doc_status_text == 'string' &&
                this.state.doc_status_text != ''
                  ? ` (${this.state.doc_status_text})`
                  : ''}
              </h3>
            </div>
          </div>
          {this.state.display_filters == 'true' && (
            <form
              className='searchForm'
              noValidate
              autoComplete='on'
              style={{ paddingBottom: 0 }}
            >
              <div className='form-group row' style={{ marginTop: '2rem' }}>
                <label style={textRight} className='col-md-2 col-form-label'>
                  เลขที่หนังสือ
                </label>
                <div className='col-md-2'>
                  <StyledTextField
                    id='standard-name'
                    value={this.state.doc_no}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('doc_no', value)
                    }
                  />
                </div>
                <label style={textRight} className='col-md-1 col-form-label'>
                  คำสำคัญ
                </label>
                <div className='col-md-2'>
                  <StyledTextField
                    id='standard-name'
                    value={this.state.keyword}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('keyword', value)
                    }
                  />
                </div>
                <label style={textRight} className='col-md-1 col-form-label'>
                  ทะเบียนส่ง
                </label>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    id='standard-select-currency'
                    select
                    margin='normal'
                    value={this.state.book_cate_id}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('book_cate_id', value)
                    }
                  >
                    {_.map(this.state.book_cate_data, (v, k) => (
                      <MenuItem key={k} value={v.book_cate_id}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </div>
              </div>
              <div className='form-group row'>
                <label style={textRight} className='col-md-2 col-form-label'>
                  ลงวันที่ ตั้งแต่
                </label>
                <div className='col-md-2'>
                  <DatePicker
                    dateFormat='d MMM yyyy'
                    selected={new Date(this.state.start_date)}
                    onChange={(date) => {
                      try {
                        this.setState({
                          start_date: moment(date).format('YYYY-MM-DD'),
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </div>
                <label style={textRight} className='col-md-1 col-form-label'>
                  ถึง
                </label>
                <div className='col-md-2'>
                  <DatePicker
                    dateFormat='d MMM yyyy'
                    selected={new Date(this.state.end_date)}
                    onChange={(date) => {
                      try {
                        this.setState({
                          end_date: moment(date).format('YYYY-MM-DD'),
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                </div>
                <label style={textRight} className='col-md-1 col-form-label'>
                  ประเภท
                </label>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    id='standard-select-currency'
                    select
                    margin='normal'
                    value={this.state.doc_type_id}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('doc_type_id', value)
                    }
                  >
                    {_.map(this.state.doc_type_data, (v, k) => (
                      <MenuItem key={k} value={v.doc_type_id}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ชั้นความลับ
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='secret_type_id'
                    value={secret_type_id}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('secret_type_id', value)
                    }
                    margin='normal'
                  >
                    {list_master_secret}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-1 col-form-label'>
                  ชั้นความเร็ว
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='speed_type_id'
                    value={speed_type_id}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('speed_type_id', value)
                    }
                    margin='normal'
                  >
                    {list_master_speed}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-1 col-form-label'>
                  หน่วยงาน
                </StyledLabelRight>
                <div className='col-md-2'>
                  <CustomAutocomplete
                    placeholder='หน่วยงาน/หน่วยงานย่อย'
                    items={this.state.division_data}
                    selected_item={this.state.selected_division}
                    handleSelectItem={this.handleSelectDivision}
                    isMultiLimit
                  ></CustomAutocomplete>
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  แบบฟอร์ม
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    id='form_type_id'
                    select
                    value={this.state.form_type_id}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('form_type_id', value)
                    }
                    margin='normal'
                  >
                    {list_form_type}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-1 col-form-label'>
                  สถานะ
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    id='doc_status'
                    select
                    value={this.state.doc_status || ''}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('doc_status', value)
                    }
                    margin='normal'>
                    {list_doc_status}
                  </StyledTextField>
                </div>
                <label style={textRight} className='col-md-1 col-form-label'>
                  เลขทะเบียนรับ
                </label>
                <div className='col-md-2'>
                  <StyledTextField
                    id='item_no'
                    value={this.state.item_no}
                    onChange={({ target: { value } }) =>
                       this.onChangeValue('item_no', value)
                    }
                  />
                </div>
              </div>
            </form>
          )}
          <div className='form-group row' style={{ marginBottom: 0, paddingTop: 0 }}>
            <StyledButtonLeft style={{ paddingLeft: '2.5%', zIndex: 11 }}>
              <Button
                variant='success'
                style={{ minWidth: 100 }}
                onClick={() => {
                  this.props.history.push('/register-send-add');
                }}
              >
                สร้าง +
              </Button>
            </StyledButtonLeft>
            {this.state.display_filters == 'true' && (
              <StyledButtonRight className='col-md-12' style={{ position: 'absolute' }}>
                <Button
                  variant='success'
                  onClick={() => {
                    this.onSubmit();
                  }}
                >
                  ค้นหา
                </Button>
              </StyledButtonRight>
            )}
          </div>
          <div ref={this.table}></div>
          <CustomTable
            title='รายการหนังสือส่ง'
            hideSelect={true}
            showManage={true}
            head_rows={head_rows}
            curr_page={curr_page}
            book_cate_data={this.state.book_cate_data}
            rows={this.state.send_data || []}
            selecteds={this.state.selecteds}
            setSelecteds={this.setSelecteds}
            history={this.props.history}
            linkManage='/register-send-edit/'
            btnManageName={
              <Icon className='flaticon-edit-1' style={{ fontSize: 18 }} />
            }
            btnManageAlign='left'
            isSend={true}
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            reloadData={this.getData}
            handleChangePage={this.handleChangePage}
            btnManageDisabled={false}
            btnDeleteDisabled={false}
          />
        </div>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps)(RegisterSendPaginatePage));
