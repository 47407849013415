import React from 'react';
import UserListAddFormPage from '../user-list-add-form/UserListAddFormPage';
export default function UserListnAddForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <UserListAddFormPage />
        </div>
      </div>
    </>
  );
}
