/* eslint-disable default-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Button } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.profileImageInputRef = React.createRef();
    this.signatureImageInputRef = React.createRef();
    this.state = {
      loading: true,
      image: null,
      signature: null,
      remove_profile_image: false,
      remove_signature_image: false,
    };
  }
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          text: '',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(({ value: result }) => {
          if (result) {
            window.location.reload(false);
          }
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
            console.log(data);
            console.log(v);
            console.log(v[0]);
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        });
        break;
      }
    }
  };
  getData = async () => {
    this.displayAlert('loading');
    try {
      const organizations_req = await API.get('/console/organizations/all');
      if (organizations_req.success) {
        this.setState({
          organizations_data: organizations_req.data,
        });
      }
      const departments_req = await API.get('/console/departments/all');
      if (departments_req.success) {
        this.setState({
          departments_data: departments_req.data,
        });
      }
      const divisions_req = await API.get('/console/divisions/all');
      if (divisions_req.success) {
        this.setState({
          divisions_data: divisions_req.data,
        });
      }
      const sub_divisions_req = await API.get('/console/sub_divisions/all');
      if (sub_divisions_req.success) {
        this.setState({
          sub_divisions_data: sub_divisions_req.data,
        });
      }
      const groups_req = await API.get('/console/groups/all');
      if (groups_req.success) {
        this.setState({
          groups_data: groups_req.data,
        });
      }
      const roles_req = await API.get('/console/roles/all');
        if (roles_req.success) {
        this.setState({
          roles_data: roles_req.data,
        });
      }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    const {
      email,
      username,
      mobile,
      prefix,
      fname,
      sname,
      organization_id,
      department_id,
      division_id,
      sub_division_id,
      group_id,
      role_id,
      image,
      position,
      signature
    } = this.props.user;
    this.setState({
      email,
      username,
      mobile,
      prefix,
      fname,
      sname,
      organization_id,
      department_id,
      division_id,
      sub_division_id,
      group_id,
      role_id,
      image_path: image,
      position,
      signature_path: signature
    });
    this.getData();
  }
  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
    if (key == 'image') {
      this.setState({ image_path: (value !== null ? URL.createObjectURL(value) : '') });
    }
    if (key == 'signature') {
      this.setState({ signature_path: (value !== null ? URL.createObjectURL(value) : '') });
    }
  };
  onSave = async () => {
    this.displayAlert('loading');
    const {
      email,
      username,
      password,
      password_confirmation,
      mobile,
      prefix,
      fname,
      sname,
      image,
      position,
      signature,
      remove_profile_image,
      remove_signature_image,
    } = this.state;
    const data = {
      email,
      username,
      password,
      password_confirmation,
      mobile,
      prefix,
      fname,
      sname,
      image,
      position,
      signature,
      remove_profile_image,
      remove_signature_image,
    };
    let form_data = new FormData();
    _.filter(data, (v, k) => {
      if (v && v != '') {
        if (k == 'image' || k == 'signature') {
          form_data.append(k, v);
        } else {
          form_data.set(k, v);
        }
      }
    });
    const update_profiles_req = await API.post(
      '/console/users/profile',
      form_data
    );
    if (update_profiles_req.success) {
      this.props.fulfillUser(update_profiles_req.data.item);
      this.displayAlert('success');
    } else {
      this.displayAlert('error', update_profiles_req.error);
    }
  };
  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>แก้ไขข้อมูลส่วนตัว</h3>
            </div>
          </div>
          <form className='saveForm' noValidate>
            <div className='form-group row' style={{ marginTop: '2rem' }}>
              <div className='col-md-1'></div>
              <FormControl
                className='col-md-2'
                style={{
                  marginTop: '-14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <InputLabel
                  htmlFor='organization_id'
                >
                  กระทรวง
                </InputLabel>
                <StyledSelectField
                  value={this.state.organization_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('organization_id', value)
                  }
                  inputProps={{
                    name: 'organization_id',
                    id: 'organization_id',
                  }}
                  disabled
                >
                  {_.map(this.state.organizations_data, (v, k) => (
                    <MenuItem key={k} value={v.organization_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </FormControl>
              <StyledLabelRight className='col-md-2 col-form-label'>
                คำนำหน้า
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='prefix'
                  placeholder=''
                  value={this.state.prefix}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('prefix', value)
                  }
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                อีเมล
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='email'
                  placeholder=''
                  value={this.state.email}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('email', value)
                  }
                  inputProps={{ maxLength: 255 }}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-1'></div>
              <FormControl
                className='col-md-2'
                style={{
                  marginTop: '-14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <InputLabel
                  htmlFor='department_id'
                >
                  กรม
                </InputLabel>
                <StyledSelectField
                  value={this.state.department_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('department_id', value)
                  }
                  inputProps={{
                    name: 'department_id',
                    id: 'department_id',
                  }}
                  disabled
                >
                  {_.map(this.state.departments_data, (v, k) => (
                    <MenuItem key={k} value={v.department_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </FormControl>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ชื่อ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='fname'
                  placeholder=''
                  value={this.state.fname}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('fname', value)
                  }
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ชื่อผู้ใช้
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                    id='username'
                    placeholder=''
                    value={this.state.username}
                    onChange={({ target: { value } }) =>
                        this.onChangeValue('username', value)
                    }
                    inputProps={{ maxLength: 20 }}
                    disabled={true}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-1'></div>
              <FormControl
                className='col-md-2'
                style={{
                  marginTop: '-14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <InputLabel
                  htmlFor='division_id'
                >
                  หน่วยงาน
                </InputLabel>
                <StyledSelectField
                  value={this.state.division_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('division_id', value)
                  }
                  inputProps={{
                    name: 'division_id',
                    id: 'division_id',
                  }}
                  disabled
                >
                  {_.map(this.state.divisions_data, (v, k) => (
                    <MenuItem key={k} value={v.division_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </FormControl>
              <StyledLabelRight className='col-md-2 col-form-label'>
                นามสกุล
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='sname'
                  placeholder=''
                  value={this.state.sname}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('sname', value)
                  }
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                รหัสผ่าน
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='password'
                  type='password'
                  placeholder=''
                  value={this.state.password}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('password', value)
                  }
                  inputProps={{maxLength: 16, autocomplete: "new-password"}}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-1'></div>
              <FormControl
                className='col-md-2'
                style={{
                  marginTop: '-14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <InputLabel
                  htmlFor='sub_division_id'
                >
                  หน่วยงานย่อย
                </InputLabel>
                <StyledSelectField
                  value={this.state.sub_division_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('sub_division_id', value)
                  }
                  inputProps={{
                    name: 'sub_division_id',
                    id: 'sub_division_id',
                  }}
                  disabled
                >
                  {_.map(this.state.sub_divisions_data, (v, k) => (
                    <MenuItem key={k} value={v.sub_division_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </FormControl>
              <StyledLabelRight className='col-md-2 col-form-label'>
                เบอร์โทรศัพท์มือถือ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='mobile'
                  placeholder=''
                  value={this.state.mobile}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('mobile', value)
                  }
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ยืนยันรหัสผ่าน
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='password_confirmation'
                  type='password'
                  placeholder=''
                  value={this.state.password_confirmation}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('password_confirmation', value)
                  }
                  inputProps={{maxLength: 16, autocomplete: "new-password"}}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-1'></div>
              <FormControl
                className='col-md-2'
                style={{
                  marginTop: '-14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <InputLabel
                  htmlFor='role_id'
                >
                  สิทธิ์การเข้าถึงข้อมูล
                </InputLabel>
                <StyledSelectField
                  value={this.state.role_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('role_id', value)
                  }
                  inputProps={{
                    name: 'role_id',
                    id: 'role_id',
                  }}
                  disabled
                >
                  {_.map(this.state.roles_data, (v, k) => (
                    <MenuItem key={k} value={v.role_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </FormControl>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ตำแหน่ง
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='position'
                  placeholder=''
                  value={this.state.position}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('position', value)
                  }
                  inputProps={{ maxLength: 255 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-md-1'></div>
              <div className='col-md-10' >
                <StyledLabelLeft className='col-form-label' style={{marginLeft: '-10px', float: 'left'}}>
                  รูปโพรไฟล์
                </StyledLabelLeft>
                <input
                  accept='image/*'
                  style={{ display: 'none' }}
                  id='contained-button-file'
                  type='file'
                  onChange={({ target: { files } }) =>
                    this.onChangeValue('image', files[0])
                  }
                  ref={this.profileImageInputRef}
                />
                <label htmlFor='contained-button-file' style={{marginLeft: '12px', marginTop: '8px', float: 'left'}}>
                  <Button variant='contained' component='span'>
                    เลือกไฟล์
                  </Button>
                </label>
                {this.state.image_path && (
                  <div style={{ marginLeft: '32px', marginTop: '8px', float: 'left' }}>
                    <div style={{ marginLeft: '-1rem', float: 'left' }}>
                      <StyledUploadedFile
                        alt='Profile'
                        style={{ width: 120 }}
                        src={this.state.image_path}
                      />
                    </div>
                    <a onClick={(e) => {
                      e.preventDefault();
                      this.onChangeValue('image', null);
                      this.onChangeValue('remove_profile_image', true);
                      this.profileImageInputRef.current.value = '';
                    }}
                      style={{marginLeft: '8px', float: 'left'}}
                    >
                      <i className='flaticon2-cancel-music icon-xs text-danger' />
                    </a>
                  </div>
                )}
                <div style={{ marginLeft: '24px', float: 'left' }}>
                  <div>
                    <StyledLabelLeft style={{ color: 'red', marginBottom: 0 }}>หมายเหตุ</StyledLabelLeft>
                    <ol className="m-0 pl-3" style={{ color: 'red' }}>
                      <li>นามสกุล .png เท่านั้น</li>
                      <li>พื้นหลังของภาพต้องเป็น transparent</li>
                      <li>ขนาดไฟล์ ต้องไม่เกิน 2 MB</li>
                      <li>อัตราส่วนภาพแนะนำ 1:1</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div className='form-group row'>
              <div className='col-md-1'></div>
              <div className='col-md-10' >
                <StyledLabelLeft className='col-form-label' style={{marginLeft: '-10px', float: 'left'}}>
                  รูปลายเซ็น
                </StyledLabelLeft>
                <input
                  accept='image/*'
                  style={{ display: 'none' }}
                  id='contained-button-signature'
                  type='file'
                  onChange={({ target: { files } }) =>
                    this.onChangeValue('signature', files[0])
                  }
                  ref={this.signatureImageInputRef}
                />
                <label htmlFor='contained-button-signature' style={{marginLeft: '12px', marginTop: '8px', float: 'left'}}>
                  <Button variant='contained' component='span'>
                    เลือกไฟล์
                  </Button>
                </label>
                {this.state.signature_path && (
                  <div style={{ marginLeft: '32px', marginTop: '8px', float: 'left' }}>
                    <div style={{ marginLeft: '-1rem', float: 'left' }}>
                      <StyledUploadedFile
                        alt='Signature'
                        style={{ width: 200 }}
                        src={this.state.signature_path}
                      />
                    </div>
                    <a onClick={(e) => {
                      e.preventDefault();
                      this.onChangeValue('signature', null);
                      this.onChangeValue('remove_signature_image', true);
                      this.signatureImageInputRef.current.value = '';
                    }}
                      style={{marginLeft: '8px', float: 'left'}}
                    >
                      <i className='flaticon2-cancel-music icon-xs text-danger' />
                    </a>
                  </div>
                )}
                <div style={{ marginLeft: '24px', float: 'left' }}>
                  <div>
                    <StyledLabelLeft style={{ color: 'red', marginBottom: 0 }}>หมายเหตุ</StyledLabelLeft>
                    <ol className="m-0 pl-3" style={{ color: 'red' }}>
                      <li>นามสกุล .png เท่านั้น</li>
                      <li>พื้นหลังของภาพต้องเป็น transparent</li>
                      <li>ขนาดไฟล์ ต้องไม่เกิน 2 MB</li>
                      <li>อัตราส่วนภาพแนะนำ 3:1</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonCenter className='col-md-12'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
            </StyledButtonCenter>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelLeft = styled.label`
  text-align: left;
`
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 7px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledButtonCenter = styled.div`
  text-align: center;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
`;
const StyledSelectField = styled(Select)`
  .MuiInputBase-input {
    margin-top: 8px;
  }
`;
const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
export default connect(mapStateToProps, auth.actions)(Class);
