import React from 'react';
import RegisterReceiveEditFormPage from '../register-receive-edit-form/RegisterReceiveEditFormPage';
export default function RegisterReceiveEditForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterReceiveEditFormPage />
        </div>
      </div>
    </>
  );
}
