/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { mockAxios, setupAxios } from './_metronic';
/* import { configureStore } from './app/core/store'; */
import store, { persistor } from './app/store/store';
/* import { persistor } from './app/store/store'; */
import App from './App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ mockAxios(axios);
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
/* var store = configureStore(); */
if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
} else {
    console.log('Notifications are supported');
}
/* const full_path = window.location.href.split('://');
console.log('full_path -->', full_path); */
/* if (full_path[0] != 'https') {
  window.location.replace(`https://${full_path[1]}`);
} else if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
} */
setupAxios(axios, store);
const Layout = React.lazy(() => import('./_metronic/layout/Layout.js'));
ReactDOM.render(
    <App
        store={store}
        Layout={Layout}
        persistor={persistor}
        basename={PUBLIC_URL}
    />,
    document.getElementById('root')
);