import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
    {
        id: '_id',
        sortable: true,
        disablePadding: false,
        label: 'id',
        align: 'left',
    },
    {
        id: 'name',
        sortable: true,
        disablePadding: false,
        label: 'name',
        align: 'left',
    },
];
var rows = [];
class Class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: '',
            selecteds: [],
            data: [],
            current_page: 1,
            last_page: 1,
            page: 1,
            errorMsg: null,
        };
    }
    setData = (key, value) => {
        this.setState({
            [key]: value,
        });
    };
    handleChangePage = (page) => {
        this.setState({ page }, () => this.getData());
    };
    displayAlert = (type = 'loading', data = null) => {
        switch (type) {
            case 'loading': {
                MySwal.fire({
                    title: 'กำลังโหลด...',
                    confirmButtonColor: '#5578eb',
                    onBeforeOpen: () => MySwal.showLoading(),
                    allowOutsideClick: () => !MySwal.isLoading(),
                });
                break;
            }
        }
    };
    setSelecteds = (selecteds) => this.setState({ selecteds });
    getData = async () => {
        this.displayAlert('loading');
        const { keyword, page } = this.state;
        const res = await API.get(
            `/dga/speed-codes?page=${page}`,
            {}
        );
        if (res.success) {
            let data = res.data;
            _.filter(data, (value, key) => {
                value._id = value.id;
            });
            this.setState(
                {
                    data,
                    current_page: res.current_page,
                    last_page: res.last_page,
                    per_page: res.count,
                },
                () => MySwal.close()
            );
        } else {
            if (res.error && res.error.not_connect && res.error.not_connect[0])
                this.setState({
                    errorMsg: res.error.not_connect[0],
                });
            else {
                this.setState({
                    errorMsg: 'ไม่พบข้อมูล',
                });
            }
            MySwal.close();
        }
    };
    componentDidMount() {
        this.getData();
    }
    render() {
        const { keyword } = this.state;
        const { ecs_permission } = this.props;
        return (
            <div className="kt-portlet kt-portlet--height-fluid">
                <CustomTable
                    hideSelect
                    showManage={false}
                    head_rows={head_rows}
                    rows={this.state.data ? this.state.data : rows}
                    history={this.props.history}
                    reloadData={this.getData}
                    current_page={this.state.current_page}
                    last_page={this.state.last_page}
                    rowsPerPage={this.state.per_page}
                    handleChangePage={this.handleChangePage}
                    headerStyles={{ textAlign: 'left' }}
                    errorMsg={this.state.errorMsg}
                />
            </div>
        );
    }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
    ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(Class));