/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Radio, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import { Editor } from '@tinymce/tinymce-react';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: null,
      description: null,
      status: 1,
      image: null,
      date_stamp: moment().format('YYYY-MM-DD'),
      date_stamp_to: null,
    };
  }
  setData = (key, value) => {
    this.setState({ [key]: value });
    if (key == 'image') { this.setState({ image_path: URL.createObjectURL(value) }); }
  };
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/news-management');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.props.history.push('/news-management');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };
  getData = async () => { this.setState({ loading: false }); };
  handleChangeStatus = (event) => { this.setData('status', event.target.value); };
  componentDidMount() { this.getData(); }
  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { title, description, status, image, date_stamp, date_stamp_to } = this.state;
      let data = {
        title,
        description,
        status,
        image,
        date_stamp,
        date_stamp_to,
      };
      let form_data = new FormData();
      _.filter(data, (v, k) => {
        if (v && v != '') {
          if (k == 'image') {
            form_data.append(k, v);
          } else {
            form_data.set(k, v);
          }
        }
      });
      const create_news = await API.post('/console/news/add', form_data);
      if (create_news.success) {
        this.displayAlert('success', create_news.data);
      } else {
        this.displayAlert('error', create_news.error);
      }
    } catch (e) {}
  };

  render() {
    const { loading, title, description, status } = this.state;
    if (loading) {
      return null;
    } else {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>เพิ่มข่าวประชาสัมพันธ์</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *หัวข้อ
              </StyledLabelRight>
              <div className='col-md-8'>
                <StyledTextField
                  id='title'
                  value={title}
                  placeholder='หัวข้อ'
                  onChange={(e) => {
                    this.setData('title', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *รายละเอียด
              </StyledLabelRight>
              <Editor
                value={description}
                init={{
                  width: '80%',
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount', 
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
                }}
                onEditorChange={(description) =>
                  this.setData('description', description)
                }
              />
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                รูปภาพ
              </StyledLabelRight>
              <div className='col-md-1'>
                <input
                  accept='image/*'
                  style={{ display: 'none' }}
                  id='contained-button-file'
                  type='file'
                  onChange={({ target: { files } }) =>
                    this.setData('image', files[0])
                  }
                />
                <label htmlFor='contained-button-file'>
                  <Button variant='contained' component='span'>
                    เลือกไฟล์
                  </Button>
                </label>
              </div>
              {this.state.image_path && (
                <div className='col-md-1' style={{ marginLeft: '-1rem' }}>
                  <StyledUploadedFile
                    alt='Profile'
                    style={{ width: 120, height: 120 }}
                    src={this.state.image_path}
                  />
                </div>
              )}
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-3'>
                <Radio
                  checked={status == 0}
                  onChange={this.handleChangeStatus}
                  value='0'
                  name='status'
                  inputProps={{ 'aria-label': 0 }}
                />
                ซ่อน
                <Radio
                  checked={status == 1}
                  onChange={this.handleChangeStatus}
                  value='1'
                  name='status'
                  inputProps={{ 'aria-label': 1 }}
                />
                แสดง
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ลงวันที่
              </StyledLabelRight>
              <div className='col-md-4'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={
                    this.state.date_stamp
                      ? new Date(this.state.date_stamp)
                      : null
                  }
                  onChange={(date) => {
                    try {
                      this.setState({
                        date_stamp: date ? moment(date).format('YYYY-MM-DD') : null,
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onKeyDown={e => e.preventDefault()}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ถึงวันที่
              </StyledLabelRight>
              <div className='col-md-4'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={this.state.date_stamp_to ? new Date(this.state.date_stamp_to) : null}
                  onChange={(date) => {
                    try {
                      this.setState({
                        date_stamp_to: date ? moment(date).format('YYYY-MM-DD') : null,
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  isClearable={true}
                  onKeyDown={e => e.preventDefault()}
                />
              </div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = (state) => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
