/* eslint-disable default-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doc_type_data: [],
      book_cate_data: [],
      master_secret_data: [],
      division_data: [],
      sub_division_data: [],
      selected_divisions: [],
      selected_sub_divisions: [],
      selected_internal_divisions: [],
      division_type: '1',
      date_at: moment().format('YYYY-MM-DD'),
      due_date: moment().format('YYYY-MM-DD'),
      expire_date: moment().format('YYYY-MM-DD'),
      active_tab: 'book_data',
      receive_users_data: [],
      selected_receive_users: [],
    };
  }
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('0');
          } else {
            return;
          }
        });
        break;
      }
      case 'keep': {
        MySwal.fire({
          title: 'ต้องการจัดเก็บ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('9');
          } else {
            return;
          }
        });
        break;
      }
      case 'register-send': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนส่ง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('1');
          } else {
            return;
          }
        });
        break;
      }
      case 'cancel': {
        MySwal.fire({
          title: 'ต้องการยกเลิก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('3');
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
            if (result) {
            this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          text: '',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.props.history.goBack();
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        });
        break;
      }
    }
  };
  getReceiveUsers = () => {
    const users_req_data = { division_id: [], sub_division_id: [] };
    _.filter(this.state.selected_divisions, (v) => {
      const str = v.value;
      if (str.includes('main_')) {
        var main = str.split('main_');
        users_req_data.division_id.push(main[1]);
      }
      if (str.includes('sub_')) {
        var sub = str.split('sub_');
        users_req_data.sub_division_id.push(sub[1]);
      }
    });
    API.post('/console/users/all', users_req_data).then((users_res) => {
      if (
        users_res &&
        users_res.success &&
        _.isArray(users_res.data) &&
        !_.isEmpty(users_res.data)
      ) {
        const receive_users_data = _.map(users_res.data, (v) => ({
          value: v.user_id,
          label: `${v.fname} ${v.sname}`,
        }));
        this.setState({ receive_users_data });
      }
    });
  };
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    try {
      await API.get('/console/doc_type/all').then(({ success, data }) => {
        if (success) {
          this.setState({
            doc_type_data: data,
          });
        }
      });
      await API.get('/console/book_cate/all?trans_type=2').then(
        ({ success, data }) => {
          if (success) {
            this.setState({
              book_cate_data: data,
            });
          }
        }
      );
      await API.get('/console/static/master_secret/all').then(
        ({ success, data }) => {
          if (success) {
            this.setState({
              master_secret_data: data,
            });
          }
        }
      );
      await API.get('/console/static/master_speed/all').then(
        ({ success, data }) => {
          if (success) {
            this.setState({
              master_speed_data: data,
            });
          }
        }
      );
      await API.get('/console/static/division_and_sub/all').then((res) => {
        if (res && res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
          }));
          var division_data = division.concat(sub_division);
          this.setState({ division, sub_division, division_data });
        }
      });
      await API.get('/console/static/division_and_sub/central').then((res) => {
        if (res && res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
          }));
          const internal_division = division.concat(sub_division);
          this.setState({ internal_division });
        }
      });
      await API.get('/console/users/all').then((res) => {
        if (res && res.success && _.isArray(res.data) && !_.isEmpty(res.data)) {
          const receive_users_data = _.map(res.data, (v) => ({
            value: v.user_id,
            label: `${v.fname} ${v.sname}`,
          }));
          this.setState({ receive_users_data });
        }
      });
      console.log('this.props.location.state -->', this.props.location.state);
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.docs_trans_id
      ) {
        const docs_trans_id = this.props.location.state.docs_trans_id;
        await API.get(`/console/receive/retire/${docs_trans_id}`).then(
          (res) => {
            if (res && res.success) {
              this.setState(
                {
                  ...res.data,
                },
                () => {
                  _.filter(res.data.send_to, (v, k) => {
                    switch (k) {
                      case 'division_id': {
                        const new_selected_divisions = [];
                        _.filter(v, (v1) => {
                          v1 = 'main_' + v1;
                          _.filter(this.state.division_data, (v2) => {
                            if (v1 == v2.value) {
                              new_selected_divisions.push(v2);
                            }
                          });
                        });
                        this.setState({
                          selected_divisions: new_selected_divisions,
                        });
                        break;
                      }
                      case 'sub_division_id': {
                        const new_selected_sub_divisions = [];
                        _.filter(v, (v1) => {
                          v1 = 'sub_' + v1;
                          _.filter(this.state.division_data, (v2) => {
                            if (v1 == v2.value) {
                              new_selected_sub_divisions.push(v2);
                            }
                          });
                        });
                        this.setState({
                          selected_sub_divisions: new_selected_sub_divisions,
                        });
                        break;
                      }
                      case 'receive_user_id': {
                        const new_selected_receive_users = [];
                        _.filter(v, (v1) => {
                          _.filter(this.state.receive_users_data, (v2) => {
                            if (v1 == v2.value) {
                              new_selected_receive_users.push(v2);
                            }
                          });
                        });
                        this.setState({
                          selected_receive_users: new_selected_receive_users,
                        });
                        break;
                      }
                      default:
                        break;
                    }
                  });
                }
              );
              const selected_divisions = this.state.selected_divisions;
              const selected_sub_divisions = this.state.selected_sub_divisions;
              let merge_divisions = selected_divisions.concat(
                selected_sub_divisions
              );
              this.setState({ selected_divisions: merge_divisions }, () =>
                this.getReceiveUsers()
              );
            }
          }
        );
      }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    this.getData();
  }
  onChooseFiles = (type, files) => {
    if (!files.length) return;
    switch (type) {
      case 'doc_file': {
        this.setState({
          doc_file: files[0],
        });
        break;
      }
      case 'attaches': {
        if (this.state.attaches) {
          this.setState({ attaches: [...this.state.attaches, ...files] });
        } else {
          this.setState({
            attaches: files,
          });
        }
        break;
      }
    }
  };
  onChangeValue = (key, value) => this.setState({ [key]: value });
  onSave = async (doc_status) => {
    this.displayAlert('loading');
    const {
      selected_divisions,
      selected_receive_users,
      send_remark,
    } = this.state;
    const data = {
      send_to: {
        division_id: [],
        sub_division_id: [],
        receive_user_id: [],
      },
      send_remark,
    };
    if (this.state.docs_trans_id) {
      data.docs_trans_id = this.state.docs_trans_id;
    }
    if (this.state.division_type == '1') {
      data.to_text = undefined;
      _.filter(selected_divisions, (v) => {
        const str = v.value;
        if (str.includes('main_')) {
          var main = str.split('main_');
          data.send_to.division_id.push(main[1]);
        }
        if (str.includes('sub_')) {
          var sub = str.split('sub_');
          data.send_to.sub_division_id.push(sub[1]);
        }
      });
      _.filter(selected_receive_users, (v) => {
        data.send_to.receive_user_id.push(v.value);
      });
    } else {
      data.send_to = undefined;
    }
    this.props.setCache(data);
    let form_data = new FormData();
    _.filter(data, (v, k) => {
      if (v && v != '') {
        if (k == 'date_at' || k == 'due_date' || k == 'expire_date') {
          v = v.replace('T', ' ');
          v = v.replace('/', '-');
        }
        if (k == 'send_to') {
          _.filter(v, (v1, k1) => {
            _.filter(v1, (v2, k2) => {
              form_data.append(`${k}[${k1}][]`, v2);
            });
          });
        } else {
          if (k == 'doc_file') {
            form_data.append(k, v);
          } else if (k == 'attaches') {
            _.filter(v, (v1, k1) => {
              form_data.append(`attach[]`, v1);
            });
          } else {
            form_data.set(k, v);
          }
        }
      }
    });
    for (var [k, v] of form_data.entries()) {
      console.log(k, v);
    }
    const docs_trans_id =
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.docs_trans_id) ||
      0;
    API.post(`/console/receive/retire/${docs_trans_id}/edit`, form_data).then(
      ({ success, data, error }) => {
        if (success) {
          this.displayAlert('success');
        } else {
          this.displayAlert('error', error);
        }
      }
    );
  };
  handleSelectDivisions = (selected_divisions) =>
    this.setState({ selected_divisions }, () => this.getReceiveUsers());
  handleSelectReceiveUsers = (selected_receive_users) =>
    this.setState({ selected_receive_users }, () =>
      console.log(
        'this.state.selected_receive_users -->',
        this.state.selected_receive_users
      )
    );
  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_divisions.push(v1);
      }
    });
    let merge_divisions = selected_divisions.concat(new_selected_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_divisions = selected_divisions.concat(new_selected_sub_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectAllInternalDivisions = () => {
    const new_selected_internal_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.internal_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_internal_divisions.push(v1);
      }
    });
    let merge_divisions = selected_divisions.concat(
      new_selected_internal_divisions
    );
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectSubDivisions = (selected_sub_divisions) =>
    this.setState({ selected_sub_divisions });
  dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) {
      formatArray[indexFullYear] = thaiYear;
    }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) {
      formatArray[indexShortYear] = thaiYear.toString().substr(2);
    }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  render() {
    const {
      loading,
      send_remark,
      doc_type_data,
      book_cate_data,
      master_secret_data,
      master_speed_data,
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_doc_type = _.map(doc_type_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.doc_type_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_secret = _.map(master_secret_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      const list_master_speed = _.map(master_speed_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      return (
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">เกษียนหนังสือ</h3>
            </div>
          </div>
          <br />
          <div className="form-group row">
            <div className="col-md-2"></div>
            <ButtonBootstrap
              variant="success"
              onClick={this.handleSelectAllDivisions}
              style={{ marginLeft: '10px' }}
            >
              ส่งทุกหน่วยงาน
            </ButtonBootstrap>
            <ButtonBootstrap
              variant="success"
              onClick={this.handleSelectAllSubDivisions}
              style={{ marginLeft: '10px' }}
            >
              ส่งทุกหน่วยงานย่อย
            </ButtonBootstrap>
            <ButtonBootstrap
              variant="success"
              onClick={this.handleSelectAllInternalDivisions}
              style={{ marginLeft: '10px' }}
            >
              ส่งหน่วยงานส่วนกลาง
            </ButtonBootstrap>
          </div>
          <div className="form-group row">
            <StyledLabelRight className="col-md-2 col-form-label" color="red">
              *ส่งถึง
            </StyledLabelRight>
            <div className="col-md-9">
              <CustomAutocomplete
                isMulti
                placeholder="หน่วยงาน/หน่วยงานย่อย"
                items={this.state.division_data}
                selected_items={this.state.selected_divisions}
                handleSelectItems={this.handleSelectDivisions}
              ></CustomAutocomplete>
            </div>
          </div>
          <div className="form-group row">
            <StyledLabelRight className="col-md-2 col-form-label" color="red">
              *Receive Users
            </StyledLabelRight>
            <div className="col-md-9">
              <CustomAutocomplete
                isMulti
                placeholder="Receive Users"
                items={this.state.receive_users_data}
                selected_items={this.state.selected_receive_users}
                handleSelectItems={this.handleSelectReceiveUsers}
              ></CustomAutocomplete>
            </div>
          </div>
          <div className="form-group row">
            <StyledLabelRight className="col-md-2 col-form-label">
              เกษียนหนังสือ
            </StyledLabelRight>
            <div className="col-md-9">
              <StyledTextField
                id="send_remark"
                value={send_remark}
                placeholder="เกษียนหนังสือ"
                multiline
                rows="4"
                margin="normal"
                variant="outlined"
                onChange={(e) => {
                  this.onChangeValue('send_remark', e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group row" style={{ marginBottom: 0 }}>
            <StyledButtonRight className="col-md-6">
              <ButtonBootstrap
                          variant="info"
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                          variant='secondary'
                          style={{ marginLeft: '5px' }}
                          onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .makeStyles-container-126 {
    margin-top: -15px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 7px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = (state) => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
