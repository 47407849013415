import React from 'react';
import {
  Button as ButtonBootstrap,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { Button, TextField, Radio, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import API from '../../api';
import moment from 'moment';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import { Editor } from '@tinymce/tinymce-react';
import Const from '../../variables/Const';
import { getFileExt } from '../../utils/utils';
import ReserveDocNoDialog from '../../widgets/ReserveDocNoDialog';
import EndorserDialog from '../../widgets/EndorserDialog';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const fileStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap',
};
const editor_plugins = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
];
const editor_toolbar = 'undo redo | bold italic underline | \
  alignleft aligncenter alignright alignjustify | \
  outdent indent | removeformat | help';

class Class extends React.Component {
  constructor(props) {
    super(props);
    this.qrCodeImageInputRef = React.createRef();
    this.state = {
      error: null,
      id: null,
      eform_id: null,

      form_type: null,
      active_tab: null,
      book_cate_data: [],
      master_secret_data: [],
      master_speed_data: [],
      doc_type_data: [],
      book_cate_id: null,
      book_cate_name: null,
      secret_type_id: null,
      speed_type_id: null,
      doc_type_id: null,
      date_at: null,
      date_at_year: null,
      division_type: null,
      division_data: [],
      selected_divisions: [],
      division: [], 
      sub_division: [], 
      department: [], 
      internal_division: [],
      send_to_external: null,
      docs_send_no_reserve_id: null,
      reserved_doc_no: null,
      doc_no_prefix: null,
      creator_header: null,
      creator_footer: null,
      government_division_text: null,
      command_text: null,
      announcement_text: null,
      topic: null,
      to_name: null,
      content: null,
      purpose: null,
      summary: null,
      closing_text: null,
      secretary_id: null,
      selected_secretary_name: null,
      endorser_id: null,
      selected_endorser_name: null,
      show_stamp: null,
      endorser_name: null,
      endorser_position: null,
      endorser_role_id: null,
      qr_code_image: null,
      qr_code_image_path: null,
      order_remark: null,
      remark: null,
      attaches: [],
      detail: null,
      reference_doc: [],
      doc_status_id: null,
      can_edit: null,
      show_doc_no_prefix: false,
      remove_qr_code_image: false,
      remove_attach_id: [],

      can_change_doc_no_prefix_book_cate_id: [],
      organization_name: null,
      department_name: null,
      division_name: null,
      sub_division_name: null,
    };
  }

  replaceEmptyWithNull = (txt) => (!!txt ? txt : null);

  setSelectedReserveDocNo = (docs_send_no_reserve_id, reserved_doc_no) => {
    this.setState({ docs_send_no_reserve_id, reserved_doc_no });
  }

  setSelectedEndorser = (id, name, position, role_id) => {
    let secretary_id = this.state.secretary_id;
    let selected_secretary_name = this.state.selected_secretary_name;
    const executive_role_id = this.state.executive_role_id;
    if (role_id != executive_role_id) {
      secretary_id = '';
      selected_secretary_name = '';
    }
    this.setState({ 
      endorser_id: id,
      selected_endorser_name: (!!name ? name : ''), 
      endorser_name: (!!name ? name : ''),
      endorser_position: (!!position ? position : ''),
      endorser_role_id: (!!role_id ? role_id : ''),
      secretary_id,
      selected_secretary_name
    });
  }

  setSelectedSecretary = (id, name, position) => {
    this.setState({ 
      secretary_id: id,
      selected_secretary_name: (!!name ? name : ''), 
    });
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
    if (key == 'qr_code_image') {
      this.setState({ qr_code_image_path: (value !== null ? URL.createObjectURL(value) : '') });
    }
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  onChooseFiles = (type, files) => {
    if (!files.length) return;
    let allowFiles = [];
    _.filter(files, (v) => {
      const ext = getFileExt(v.name);
      if (Const.File.DocAttachExtension.includes(ext) && v.size <= Const.File.DocAttachSizeLimit) {
        console.log(v.name.split('.').pop().toLowerCase());
        allowFiles.push(v);
      }
    });

    switch (type) {
      case 'attaches': {
        if (this.state.attaches) {
          this.setState({ attaches: [...this.state.attaches, ...allowFiles] });
        } else {
          this.setState({
            attaches: allowFiles,
          });
        }
        break;
      }
    }
  };

  onRemoveAttachFile = (e, idx) => {
    e.preventDefault();
    let attaches = this.state.attaches;
    let remove_attach_id = this.state.remove_attach_id;
    let attach = attaches[idx];
    if (attach.eform_attach_id) {
      remove_attach_id.push(attach.eform_attach_id);
    }
    attaches.splice(idx, 1);
    this.setState({
      attaches,
      remove_attach_id
    });
  }

  downloadAttachFile = async (event, data) => {
    try {
        const resp = await API.postDownloadFile(data.attach_download, null, 'header');
        const file = new Blob([resp.data], {
            type: resp.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = data.file_name;
        fileLink.click();
    } catch (err) {
        console.log('error::');
        console.log(err);
    }
  }

  displayAlert = (type = 'draft', data = null) => {
    switch (type) {
      case 'draft': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'preview': {
        MySwal.fire({
          title: 'ต้องการดูตัวอย่างเอกสาร?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onPreview(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'send_review': {
        MySwal.fire({
          title: 'ต้องการส่งกลั่นกรอง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          if (this.state.id == 0) {
            this.props.history.push('/e-form-build/' + this.state.form_type + '?form_type=' + this.state.form_type + '&id=' + data.id);
          } else {
            window.location.reload();
          }
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.unicodeToChar(url_params.get('id'));
    const form_type = this.unicodeToChar(url_params.get('form_type'));
    
    this.setState(
      {
        query_string,
        id,
        form_type
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async (caller) => {
    this.displayAlert('loading');
    const { id } = this.state;
    const is_external = (this.state.form_type == 'external');
    const is_internal = (this.state.form_type == 'internal');
    const is_stamp = (this.state.form_type == 'stamp');
    const is_command = (this.state.form_type == 'command');
    const is_announce = (this.state.form_type == 'announce');
    let organization_name = '';
    let department_name = '';
    let division_name = '';
    let sub_division_name = '';

    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      if (!!profile_req.data.organization) {
        organization_name = profile_req.data.organization.name;
      }

      if (!!profile_req.data.department) {
        department_name = profile_req.data.department.name;
      }

      if (!!profile_req.data.division) {
        division_name = profile_req.data.division.name;
      }

      if (!!profile_req.data.sub_division) {
        sub_division_name = profile_req.data.sub_division.name;
      }

      this.setState({
        organization_name,
        department_name,
        division_name,
        sub_division_name,
      });
    }

    await API.get('/console/book_cate/all?trans_type=2').then(({ success, data }) => {
      if (success) {
        this.setState({
          book_cate_data: data,
        });
      }
    });

    await API.get('/console/setting?code=CanChangeDocNoPrefix_BookCategoryID').then(({ success, data }) => {
      if (success && data != null) {
        let can_change_doc_no_prefix_book_cate_id = data.value.split(',');
        this.setState({
          can_change_doc_no_prefix_book_cate_id
        });
      }
    });

    await API.get('/console/setting?code=Role_ExecutiveID').then(({ success, data }) => {
      if (success && data != null) {
        let executive_role_id = data.value;
        this.setState({
          executive_role_id
        });
      }
    });

    await API.get('/console/static/master_secret/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_secret_data: data,
        });
      }
    });

    await API.get('/console/static/master_speed/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_speed_data: data,
        });
      }
    });

    await API.get('/console/doc_type/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          doc_type_data: data,
        });
      }
    });

    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res && res.success) {
        const division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));
        const sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
          division_value: 'main_' + v.division_id,
        }));
        const department = _.filter(
          res.data,
          (v, k) => k == 'department_id'
        )[0].map((v) => ({
          value: 'department_' + v.id,
          label: v.name,
        }));
        let division_data = [];
        _.forEach(division, (d) => {
          division_data.push(d);
          division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
        });
        this.setState({ division, sub_division, department, division_data });
      }
    });

    await API.get('/console/static/division_and_sub/central').then((res) => {
      if (res && res.success) {
        const division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));
        const sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
          division_value: 'main_' + v.division_id,
        }));
        let internal_division = [];
        _.forEach(division, (d) => {
          internal_division.push(d);
          internal_division = internal_division.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
        });
        this.setState({ internal_division });
      }
    });

    if (id == null) {
      let closing_text = '',
          creator_header = '',
          creator_footer = '',
          secretary_id = '',
          selected_secretary_name = '',
          endorser_id = '', 
          selected_endorser_name = '', 
          endorser_name = '', 
          endorser_position = '',
          endorser_role_id = '';

      if (is_external) {
        closing_text = 'ขอแสดงความนับถือ';
      } else if (is_command || is_announce) {
        closing_text = 'ทั้งนี้ ตั้งแต่บัดนี้เป็นต้นไป';
      }

      await API.get('/console/users/endorser?page=1&per_page=1').then(({ success, data }) => {
        if (success) {
          if (data != null && data.length > 0) {
            let endorserData = data[0];
            endorser_id = endorserData.user_id;
            selected_endorser_name = endorserData.fullname;
            endorser_name = endorserData.fullname;
            if (endorserData.position != null) {
              endorser_position = endorserData.position;
            }
            endorser_role_id = endorserData.role_id;
          }
        }
      });

      if (is_external || is_stamp) {
        await API.get('/console/eform-setting').then(({ success, data }) => {
          if (success && data != null) {
            creator_header = data.header_text;
            creator_footer = data.footer_text;

            if (creator_header == null) creator_header = '';
            if (creator_footer == null) creator_footer = '';
          }
        });
      }

      this.setState({
        id: 0,
        eform_id: 0,
        book_cate_id: null,
        book_cate_name: '',
        secret_type_id: null,
        speed_type_id: null,
        doc_type_id: null,
        date_at: moment().format('YYYY-MM-DD'),
        date_at_year: (moment().year() + 543).toString(),
        division_type: '1',
        secret_type_id: Const.DefaultOption.SecretTypeId,
        speed_type_id: Const.DefaultOption.SpeedTypeId,
        selected_divisions: [],
        send_to_external: '',
        docs_send_no_reserve_id: null,
        reserved_doc_no: '',
        doc_no_prefix: '',
        creator_header,
        creator_footer,
        government_division_text: '',
        command_text: '',
        announcement_text: '',
        topic: '',
        to_name: '',
        content: '',
        purpose: '',
        summary: '',
        closing_text,
        secretary_id,
        selected_secretary_name,
        endorser_id,
        selected_endorser_name,
        endorser_name,
        endorser_position,
        endorser_role_id,
        show_stamp: is_stamp,
        qr_code_image: '',
        qr_code_image_path: '',
        order_remark: '',
        remark: '',
        attaches: [],
        detail: '',
        reference_doc: [],
        doc_status_id: null,
        can_edit: true,
        show_doc_no_prefix: '',
        remove_qr_code_image: false,
        remove_attach_id: [],
      }, () => {
        MySwal.close();
      });
    } else {
      await API.get(`/console/eform/${id}`).then((res) => {
        if (res.success) {
          const data = res.data;
          let selected_book_cate_name = null;
          let show_doc_no_prefix = false;
          const book_cate = _.filter(this.state.book_cate_data, (v, k) => v.book_cate_id == data.book_cate_id.toString());
          if (book_cate) {
            selected_book_cate_name = book_cate.name;
            show_doc_no_prefix = (this.state.can_change_doc_no_prefix_book_cate_id.includes(data.book_cate_id.toString()));
          }

          const selected_division_temp = _.filter(
            data,
            (v, k) => k == 'selected_division_id'
          )[0].map((v) => ({
            value: 'main_' + v
          }));
          const selected_sub_division_temp = _.filter(
            data,
            (v, k) => k == 'selected_sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v
          }));

          let selected_divisions_temp = [];
          _.forEach(selected_division_temp, (d) => {  
            selected_divisions_temp = selected_divisions_temp.concat(_.filter(this.state.division_data, (v, k) => v.value == d.value));
          });
          _.forEach(selected_sub_division_temp, (d) => {  
            selected_divisions_temp = selected_divisions_temp.concat(_.filter(this.state.division_data, (v, k) => v.value == d.value));
          });

          this.setState({
            ...data,
            date_at_year: (parseInt(moment(data.date_at).format('YYYY'), 10) + 543).toString(),
            selected_endorser_name: data.selected_endorser_name,
            selected_book_cate_name,
            show_doc_no_prefix,
            selected_divisions: selected_divisions_temp,
          }, () => { MySwal.close(); });
        } else {
          this.setState({error: true}, () => {
            this.displayAlert('error', res.error);
          })
        }
      });
    }
    this.setState({ 
      active_tab: 'book_data',
    });
  }

  handleSelectDivisions = (selected_divisions) => {
    this.setState({ selected_divisions });
  }

  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_divisions.push(v1);
      }
    });

    let merge_divisions = (selected_divisions == null) ? new_selected_divisions : selected_divisions.concat(new_selected_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_sub_divisions : selected_divisions.concat(new_selected_sub_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };

  handleSelectAllInternalDivisions = () => {
    const new_selected_internal_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.internal_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_internal_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_internal_divisions : selected_divisions.concat(new_selected_internal_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      const { 
        id,
        eform_id,
        form_type,
        book_cate_id,
        date_at,
        division_type,
        selected_divisions,
        send_to_external,
        docs_send_no_reserve_id,
        reserved_doc_no,
        doc_no_prefix,
        secret_type_id,
        speed_type_id,
        creator_header,
        creator_footer,
        government_division_text,
        command_text,
        announcement_text,
        topic,
        to_name,
        content,
        purpose,
        summary,
        closing_text,
        secretary_id,
        endorser_id,
        show_stamp,
        endorser_name,
        endorser_position,
        order_remark,
        remark,
        qr_code_image,
        doc_type_id,
        attaches,
        detail,
        reference_doc,
        remove_qr_code_image,
        remove_attach_id,
      } = this.state;
      let data = {
        id: (id == null ? 0 : id),
        eform_id: (eform_id == null ? 0 : eform_id),
        action: action,
        form_type,
        book_cate_id,
        date_at,
        division_type,
        send_to_department_id: [],
        send_to_division_id: [],
        send_to_sub_division_id: [],
        send_to_external,
        docs_send_no_reserve_id,
        reserved_doc_no,
        doc_no_prefix,
        secret_type_id,
        speed_type_id,
        creator_header,
        creator_footer,
        government_division_text,
        command_text,
        announcement_text,
        topic,
        to_name,
        content,
        purpose,
        summary,
        closing_text,
        secretary_id,
        endorser_id,
        show_stamp,
        endorser_name,
        endorser_position,
        order_remark,
        remark,
        qr_code_image,
        doc_type_id,
        detail,
        attaches,
        reference_doc,
        remove_qr_code_image,
        remove_attach_id,
      };

      if (division_type == '1') {
        data.to_text = '';
      } else {
        data.send_to_department_id = [];
        data.send_to_division_id = [];
        data.send_to_sub_division_id = [];
      }

      _.filter(selected_divisions, (v) => {
          const str = v.value;
          if (str.includes('main_')) {
              var main = str.split('main_');
              data.send_to_division_id.push(main[1]);
          }
          if (str.includes('sub_')) {
              var sub = str.split('sub_');
              data.send_to_sub_division_id.push(sub[1]);
          }
          if (str.includes('department_')) {
              var department = str.split('department_');
              data.send_to_department_id.push(department[1]);
          }
      });

      if (_.isArray(reference_doc) && !_.isEmpty(reference_doc)) data.reference_doc = JSON.stringify(reference_doc);

      let form_data = new FormData();
      _.filter(data, (v, k) => {
        if (k == 'show_stamp') {
          form_data.set(k, v);
        } else {
          if (v && v != '') {
            if (k == 'date_at') {
              v = v.replace('T', ' ');
              v = v.replace('/', '-');
              form_data.set(k, v);
            } else if (k == 'qr_code_image') {
              form_data.append(k, v);
            } else if (k == 'send_to_department_id' || k == 'send_to_division_id' || k == 'send_to_sub_division_id' || k == 'attaches' || k == 'remove_attach_id') {
              _.filter(v, (v1, k1) => {
                form_data.append(`${k}[]`, v1);
              });
            } else {
              form_data.set(k, v);
            }
          }
        }
      });

      const resp = await API.post('/console/eform/save', form_data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      console.log('error::');
      console.log(e);
      this.displayAlert('error');
    }
  };

  onPreview = async (action) => {
    this.displayAlert('loading');
    try {
      const { 
        id,
        eform_id,
        form_type,
        book_cate_id,
        date_at,
        division_type,
        selected_divisions,
        send_to_external,
        docs_send_no_reserve_id,
        reserved_doc_no,
        doc_no_prefix,
        secret_type_id,
        speed_type_id,
        creator_header,
        creator_footer,
        government_division_text,
        command_text,
        announcement_text,
        topic,
        to_name,
        content,
        purpose,
        summary,
        closing_text,
        secretary_id,
        endorser_id,
        show_stamp,
        endorser_name,
        endorser_position,
        order_remark,
        remark,
        qr_code_image,
        doc_type_id,
        detail,
        reference_doc,
        remove_qr_code_image,
      } = this.state;
      let data = {
        id: (id == null ? 0 : id),
        eform_id: (eform_id == null ? 0 : eform_id),
        action: action,
        form_type,
        book_cate_id,
        date_at,
        docs_send_no_reserve_id,
        reserved_doc_no,
        doc_no_prefix,
        secret_type_id,
        speed_type_id,
        creator_header,
        creator_footer,
        government_division_text,
        command_text,
        announcement_text,
        topic,
        to_name,
        content,
        purpose,
        summary,
        closing_text,
        secretary_id,
        endorser_id,
        show_stamp,
        endorser_name,
        endorser_position,
        order_remark,
        remark,
        qr_code_image,
        doc_type_id,
        detail,
        reference_doc,
        remove_qr_code_image,
      };

      if (_.isArray(reference_doc) && !_.isEmpty(reference_doc)) data.reference_doc = JSON.stringify(reference_doc);

      let form_data = new FormData();
      _.filter(data, (v, k) => {
        if (k == 'show_stamp') {
          form_data.set(k, v);
        } else {
          if (v && v != '') {
            if (k == 'date_at') {
              v = v.replace('T', ' ');
              v = v.replace('/', '-');
              form_data.set(k, v);
            } else if (k == 'qr_code_image') {
              form_data.append(k, v);
            } else {
              form_data.set(k, v);
            }
          }
        }
      });

      const resp = await API.postDownloadFile('/console/eform/preview', form_data, 'header');
      const file = new Blob([resp.data], {
          type: resp.headers['content-type'],
      });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = moment().unix().toString() + '.docx';
      fileLink.click();

      MySwal.close();
    } catch (e) {
      console.log('error::');
      console.log(e);
      this.displayAlert('error');
    }
  };

  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData('update'))
      );
    }
  }

  componentDidMount() {
    this.getQueryStringFromUrl(() => this.setMetaData('mount'));
  }

  render() {
    const {
      error,
      form_type,
      active_tab,
      book_cate_data,
      master_secret_data,
      master_speed_data,
      doc_type_data,
      book_cate_id,
      speed_type_id,
      secret_type_id,
      doc_type_id,
      creator_header,
      creator_footer,
      government_division_text,
      command_text,
      announcement_text,
      topic,
      to_name,
      content,
      purpose,
      summary,
      closing_text,
      secretary_id,
      selected_secretary_name,
      endorser_id,
      selected_endorser_name,
      endorser_name,
      endorser_position,
      endorser_role_id,
      show_stamp,
      order_remark,
      remark,
      detail,
      reserved_doc_no,
      show_doc_no_prefix,
      qr_code_image,
      can_edit,
      executive_role_id,
      docs_trans_id,
    } = this.state;

    let show_creator_header = false;
    let show_creator_footer = false;
    let show_government_text = false;
    let show_command_text = false;
    let show_announce_text = false;
    let show_topic = false;
    let show_to_name = false;
    let show_closing_text = true;
    let show_purpose = false;
    let show_summary = false;
    let show_qr = false;
    const show_secretary = (endorser_role_id == executive_role_id);

    switch (form_type) {
      case 'external': 
        show_creator_header = true;
        show_creator_footer = true;
        show_topic = true;
        show_to_name = true;
        show_purpose = true;
        show_summary = true;
        show_qr = true;
        break;
      case 'internal': 
        show_government_text = true;
        show_topic = true;
        show_to_name = true;
        show_qr = true;
        show_purpose = true;
        show_summary = true;
        break;
      case 'stamp': 
        show_creator_footer = true;
        show_to_name = true;
        show_qr = true;
        show_purpose = true;
        show_summary = true;
        break;
      case 'command': 
        show_topic = true;
        show_command_text = true;
        show_purpose = true;
        break;
      case 'announce': 
        show_announce_text = true;
        show_purpose = true;
        break;
    }

    const list_book_cate = _.map(book_cate_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.book_cate_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_master_secret = _.map(master_secret_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_master_speed = _.map(master_speed_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_doc_type = _.map(doc_type_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.doc_type_id}>
          {value.name}
        </MenuItem>
      );
    });
    const can_edit_form = (can_edit && can_edit == true);
    const is_received = ((typeof docs_trans_id !== 'undefined') && docs_trans_id !== null);
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
          {error == null && (
            <>
              <Tabs id='main-tan' activeKey={active_tab} onSelect={(active_tab) => this.setState({ active_tab })}>
                <Tab eventKey='book_data' title='ข้อมูลหนังสือ'>
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ทะเบียนส่ง</StyledLabelRight>
                    <div className='col-md-3' style={{ marginTop: '-14px' }}>
                      <StyledTextField
                        id='book_cate_id'
                        select
                        margin='normal'
                        value={book_cate_id}
                        onChange={(e) => {
                          const selected_value = e.target.value;
                          const selected_book_cate_name = _.find(book_cate_data, ['book_cate_id', selected_value]).name;
                          const show_doc_no_prefix = (this.state.can_change_doc_no_prefix_book_cate_id.includes(selected_value.toString()));
                          this.setState({
                            book_cate_id: selected_value,
                            book_cate_name: selected_book_cate_name,
                            show_doc_no_prefix: show_doc_no_prefix,
                            docs_send_no_reserve_id: null,
                            reserved_doc_no: '',
                            doc_no_prefix: '',
                          });
                        }}
                        disabled={!can_edit_form || is_received}
                      >
                        {list_book_cate}
                      </StyledTextField>
                    </div>
                    <div className='col-md-1'></div>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ลงวันที่</StyledLabelRight>
                    <div className='col-md-2' style={{ marginTop: '10px' }}>
                      <DatePicker
                        dateFormat='d MMM yyyy'
                        selected={new Date(this.state.date_at)}
                        onChange={(date) => {
                          let date_at = moment(date).format('YYYY-MM-DD');
                          const selected_year = (parseInt(moment(date).format('YYYY'), 10) + 543).toString();

                          let date_at_year = this.state.date_at_year;
                          let reserved_doc_no = this.state.reserved_doc_no;
                          let doc_no_prefix = this.state.doc_no_prefix;
                          let docs_send_no_reserve_id = this.state.docs_send_no_reserve_id;
                          
                          if (date_at_year != selected_year) {
                            reserved_doc_no = '';
                            doc_no_prefix = '';
                            docs_send_no_reserve_id = null;
                          }
                          this.setState({
                            date_at,
                            date_at_year,
                            reserved_doc_no,
                            doc_no_prefix,
                            docs_send_no_reserve_id
                          });
                        }}
                        onKeyDown={e => e.preventDefault()}
                        disabled={!can_edit_form || is_received}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                      <div className='col-md-2'></div>
                      <div className='col-md-4 col-lg-2'>
                        <Radio
                          checked={this.state.division_type == '1'}
                          onChange={() => this.onChangeValue('division_type', '1')}
                          value='1'
                          name='radio-division-type'
                          inputProps={{ 'aria-label': '1' }}
                          disabled={!can_edit_form}
                        />
                        หน่วยงานภายใน
                      </div>
                      <div className='col-md-4 col-lg-2'>
                        <Radio
                          checked={this.state.division_type == '2'}
                          onChange={() => this.onChangeValue('division_type', '2')}
                          value='2'
                          name='radio-division-type'
                          inputProps={{ 'aria-label': '2' }}
                          disabled={!can_edit_form}
                        />
                        หน่วยงานภายนอก
                      </div>
                  </div>
                  {this.state.division_type == '1' ? (
                    <>
                      <div className='form-group row'>
                          <div className='col-md-2'></div>
                          <ButtonBootstrap
                            variant='success'
                            onClick={this.handleSelectAllDivisions}
                            style={{ marginLeft: '10px' }}
                            disabled={!can_edit_form}>
                              ส่งทุกหน่วยงาน
                          </ButtonBootstrap>
                          <ButtonBootstrap
                            variant='success'
                            onClick={this.handleSelectAllSubDivisions}
                            style={{ marginLeft: '10px' }}
                            disabled={!can_edit_form}>
                              ส่งทุกหน่วยงานย่อย
                          </ButtonBootstrap>
                          <ButtonBootstrap
                            variant='success'
                            onClick={this.handleSelectAllInternalDivisions}
                            style={{ marginLeft: '10px' }}
                            disabled={!can_edit_form}>
                              ส่งหน่วยงานส่วนกลาง
                          </ButtonBootstrap>
                      </div>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่งถึง</StyledLabelRight>
                        <div className='col-md-9'>
                          <CustomAutocomplete
                            isMulti
                            placeholder='หน่วยงาน/หน่วยงานย่อย'
                            items={this.state.division_data}
                            selected_items={this.state.selected_divisions}
                            handleSelectItems={this.handleSelectDivisions}
                            disabled={!can_edit_form}
                          ></CustomAutocomplete>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่งถึง</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                            id='send_to_external'
                            placeholder=''
                            value={this.state.send_to_external}
                            onChange={(e) => {
                              this.onChangeValue('send_to_external', e.target.value);
                            }}
                            inputProps={{maxLength: 255}}
                            disabled={!can_edit_form}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <hr />
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>เลขทะเบียนส่ง</StyledLabelRight>
                    <div className='col-md-4'>
                      <div style={{float: 'left', paddingTop: '6px'}}>
                        <ReserveDocNoDialog
                          year={this.state.date_at_year}
                          book_cate_id={this.state.book_cate_id}
                          book_cate_name={this.state.book_cate_name}
                          division_name={this.state.division_name}
                          sub_division_name={this.state.sub_division_name}
                          setSelected={this.setSelectedReserveDocNo}
                          disabled={!can_edit_form || is_received} />
                      </div>
                      {!_.isEmpty(reserved_doc_no) && (
                      <div style={{float: 'left'}}>
                        <StyledLabelLeft className='col-form-label' style={{marginLeft: '12px'}}>{reserved_doc_no}</StyledLabelLeft>
                        {can_edit_form && is_received === false && (
                          <a onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                reserved_doc_no: '',
                                doc_no_prefix:'',
                                docs_send_no_reserve_id: null
                              });
                            }}
                            style={{marginLeft: '8px'}}
                          >
                            <i className='flaticon2-cancel-music icon-xs text-danger' />
                          </a>
                        )}
                      </div>)}
                    </div>
                    
                    {(show_doc_no_prefix == true) &&
                      <>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*คำนำหน้าเลขหนังสือ</StyledLabelRight>
                        <div className='col-md-3'>
                          <StyledTextField
                            id='doc_no_prefix'
                            placeholder=''
                            value={this.state.doc_no_prefix}
                            onChange={({ target: { value } }) =>
                              this.onChangeValue('doc_no_prefix', value)
                            }
                            disabled={!can_edit_form || is_received}
                          />
                        </div>
                      </>
                    }
                  </div>
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความลับ</StyledLabelRight>
                    <div className='col-md-3' style={{ marginTop: '-14px' }}>
                      <StyledTextField
                        select
                        id='secret_type_id'
                        value={secret_type_id}
                        onChange={(e) => {
                          this.onChangeValue('secret_type_id', e.target.value);
                        }}
                        margin='normal'
                        disabled={!can_edit_form}
                      >
                        {list_master_secret}
                      </StyledTextField>
                    </div>
                    <div className='col-md-1'></div>
                    <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความเร็ว</StyledLabelRight>
                    <div className='col-md-3' style={{ marginTop: '-14px' }}>
                      <StyledTextField
                        select
                        id='speed_type_id'
                        value={speed_type_id}
                        onChange={(e) => {
                          this.onChangeValue('speed_type_id', e.target.value);
                        }}
                        margin='normal'
                        disabled={!can_edit_form}
                      >
                        {list_master_speed}
                      </StyledTextField>
                    </div>
                  </div>

                  {(show_creator_header == true && show_creator_footer == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เจ้าของเรื่อง (Header)</StyledLabelRight>
                        <div className='col-md-3'>
                          <StyledTextField
                              id='creator_header'
                              value={creator_header}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('creator_header', e.target.value);
                              }}
                              inputProps={{maxLength: 255}}
                              disabled={!can_edit_form}
                            />
                        </div>
                        <div className='col-md-1'></div>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เจ้าของเรื่อง (Footer)</StyledLabelRight>
                        <div className='col-md-3'>
                          <StyledTextField
                              id='creator_footer'
                              value={creator_footer}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('creator_footer', e.target.value);
                              }}
                              inputProps={{maxLength: 255}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_creator_header == false && show_creator_footer == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เจ้าของเรื่อง (Footer)</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='creator_footer'
                              value={creator_footer}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('creator_footer', e.target.value);
                              }}
                              inputProps={{maxLength: 255}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_government_text == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่วนราชการ</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='government_division_text'
                              value={government_division_text}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('government_division_text', e.target.value);
                              }}
                              inputProps={{maxLength: 255}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_command_text == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*คำสั่ง</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='command_text'
                              value={command_text}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('command_text', e.target.value);
                              }}
                              inputProps={{maxLength: 500}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_announce_text == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ประกาศ</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='announcement_text'
                              value={announcement_text}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('announcement_text', e.target.value);
                              }}
                              inputProps={{maxLength: 500}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_topic == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เรื่อง</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='topic'
                              value={topic}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('topic', e.target.value);
                              }}
                              inputProps={{maxLength: 500}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  {(show_to_name == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เรียน</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='to_name'
                              value={to_name}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('to_name', e.target.value);
                              }}
                              inputProps={{maxLength: 500}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  <div className="form-group row">
                    <StyledLabelRight className="col-md-2 col-form-label" color="red">*ภาคเหตุ</StyledLabelRight>
                    <Editor
                      value={content}
                      init={{
                        width: '75%',
                        height: 500,
                        menubar: false,
                        plugins: editor_plugins,
                        toolbar: editor_toolbar,
                      }}
                      onEditorChange={(value) =>
                        this.setData('content', value)
                      }
                      disabled={!can_edit_form}
                    />
                  </div>

                  {(show_purpose == true) && (
                    <div className="form-group row">
                      <StyledLabelRight className="col-md-2 col-form-label" color="red">*ภาคความประสงค์</StyledLabelRight>
                      <Editor
                        value={purpose}
                        init={{
                          width: '75%',
                          height: 500,
                          menubar: false,
                          plugins: editor_plugins,
                          toolbar: editor_toolbar,
                        }}
                        onEditorChange={(value) =>
                          this.setData('purpose', value)
                        }
                        disabled={!can_edit_form}
                      />
                    </div>
                  )}

                  {(show_summary == true) && (
                    <div className="form-group row">
                      <StyledLabelRight className="col-md-2 col-form-label" color="red">*ภาคสรุป</StyledLabelRight>
                      <Editor
                        value={summary}
                        init={{
                          width: '75%',
                          height: 500,
                          menubar: false,
                          plugins: editor_plugins,
                          toolbar: editor_toolbar,
                        }}
                        onEditorChange={(value) =>
                          this.setData('summary', value)
                        }
                        disabled={!can_edit_form}
                      />
                    </div>
                  )}

                  {(show_closing_text == true) &&
                    <>
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>ลงท้าย</StyledLabelRight>
                        <div className='col-md-9'>
                          <StyledTextField
                              id='closing_text'
                              value={closing_text}
                              placeholder=''
                              multiline
                              rows='3'
                              margin='normal'
                              variant='outlined'
                              onChange={(e) => {
                                this.onChangeValue('closing_text', e.target.value);
                              }}
                              inputProps={{maxLength: 500}}
                              disabled={!can_edit_form}
                            />
                        </div>
                      </div>
                    </>
                  }

                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ผู้ลงนาม</StyledLabelRight>
                    <div className='col-md-4'>
                        {!_.isEmpty(selected_endorser_name) &&
                          <div style={{float: 'left'}}>
                            <StyledLabelLeft className='col-form-label' style={{marginRight: '12px'}}>{selected_endorser_name}</StyledLabelLeft>
                          </div>
                        }
                      <div style={{float: 'left', paddingTop: '6px'}}>
                        <EndorserDialog
                          division_name={this.state.division_name}
                          sub_division_name={this.state.sub_division_name}
                          setSelected={this.setSelectedEndorser}
                          disabled={!can_edit_form} />
                      </div>
                    </div>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ตราประทับ</StyledLabelRight>
                    <div className='col-md-2 col-lg-1'>
                      <Radio
                        checked={show_stamp == true}
                        onChange={() => this.onChangeValue('show_stamp', true)}
                        value='1'
                        name='radio-show-stamp'
                        inputProps={{ 'aria-label': '1' }}
                        disabled={!can_edit_form}
                      />
                      แสดง
                    </div>
                    <div className='col-md-2 col-lg-1'>
                      <Radio
                        checked={show_stamp == false}
                        onChange={() => this.onChangeValue('show_stamp', false)}
                        value='0'
                        name='radio-show-stamp'
                        inputProps={{ 'aria-label': '0' }}
                        disabled={!can_edit_form}
                      />
                      ไม่แสดง
                    </div>
                  </div>

                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ชื่อ-นามสกุล</StyledLabelRight>
                    <div className='col-md-9'>
                      <StyledTextField
                        id='endorser_name'
                        placeholder=''
                        value={endorser_name}
                        onChange={(e) => {
                          this.onChangeValue('endorser_name', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                        disabled={!can_edit_form}
                      />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ตำแหน่ง</StyledLabelRight>
                    <div className='col-md-9'>
                      <StyledTextField
                          id='endorser_position'
                          value={endorser_position}
                          placeholder=''
                          multiline
                          rows='3'
                          margin='normal'
                          variant='outlined'
                          onChange={(e) => {
                            this.onChangeValue('endorser_position', e.target.value);
                          }}
                          inputProps={{maxLength: 255}}
                          disabled={!can_edit_form}
                        />
                    </div>
                  </div>

                  {show_secretary === true && (
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ผู้อนุมัติ ภายใต้หน่วยงาน</StyledLabelRight>
                      <div className='col-md-9'>
                          {!_.isEmpty(selected_secretary_name) &&
                            <div style={{float: 'left'}}>
                              <StyledLabelLeft className='col-form-label' style={{marginRight: '12px'}}>{selected_secretary_name}</StyledLabelLeft>
                            </div>
                          }
                        <div style={{float: 'left', paddingTop: '6px'}}>
                          <EndorserDialog
                            division_name={this.state.division_name}
                            sub_division_name={this.state.sub_division_name}
                            setSelected={this.setSelectedSecretary}
                            include_executive={false}
                            disabled={!can_edit_form} />
                        </div>
                      </div>
                    </div>
                  )}

                  {(show_qr == true) &&
                    <>
                      <div className="form-group row">
                        <StyledLabelRight className="col-md-2 col-form-label">รูป QR Code</StyledLabelRight>
                        <div className="col-md-8" style={{ marginTop: '8px' }}>
                          <input
                            accept="image/png"
                            style={{ display: 'none' }}
                            id="contained-button-qr-code"
                            type="file"
                            onChange={(e) =>
                              this.setData('qr_code_image', e.target.files[0])
                            }
                            ref={this.qrCodeImageInputRef}
                            disabled={!can_edit_form}
                          />
                          <label htmlFor="contained-button-qr-code" style={{float: 'left'}}>
                            <Button
                              variant="contained"
                              component="span"
                              disabled={!can_edit_form}
                              >
                              เลือกไฟล์
                            </Button>
                          </label>
                          {!_.isEmpty(this.state.qr_code_image_path) && (
                            <>
                              <div style={{ marginLeft: '12px', float: 'left' }}>
                                <StyledUploadedFile
                                  alt="QR Code"
                                  style={{ width: 120, height: 'auto' }}
                                  src={this.state.qr_code_image_path}
                                />
                              </div>
                              {can_edit_form && (
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.setData('qr_code_image', null);
                                    this.setData('remove_qr_code_image', true);
                                    this.qrCodeImageInputRef.current.value = '';
                                  }}
                                  style={{marginLeft: '8px'}}
                                >
                                  <i className='flaticon2-cancel-music icon-xs text-danger' />
                                </a>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }

                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>คำสั่งการ</StyledLabelRight>
                    <div className='col-md-9'>
                      <StyledTextField
                          id='order_remark'
                          value={order_remark}
                          placeholder=''
                          multiline
                          rows='3'
                          margin='normal'
                          variant='outlined'
                          onChange={(e) => {
                            this.onChangeValue('order_remark', e.target.value);
                          }}
                          disabled={!can_edit_form}
                        />
                    </div>
                  </div>

                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุ</StyledLabelRight>
                    <div className='col-md-9'>
                      <StyledTextField
                          id='remark'
                          value={remark}
                          placeholder=''
                          multiline
                          rows='3'
                          margin='normal'
                          variant='outlined'
                          onChange={(e) => {
                            this.onChangeValue('remark', e.target.value);
                          }}
                          disabled={!can_edit_form}
                        />
                    </div>
                  </div>

                  <br />
                </Tab>
                <Tab eventKey='book_detail' title='รายละเอียดหนังสือ'>
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ประเภทหนังสือ</StyledLabelRight>
                    <div className='col-md-3' style={{ marginTop: '-14px' }}>
                      <StyledTextField
                        select
                        id='doc_type_id'
                        value={doc_type_id}
                        onChange={(e) => {
                          this.onChangeValue('doc_type_id', e.target.value);
                        }}
                        margin='normal'
                        disabled={!can_edit_form}
                      >
                        {list_doc_type}
                      </StyledTextField>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>สิ่งที่ส่งมาด้วย</StyledLabelRight>
                    <div className='col-md-9' style={{ marginTop: '6px' }}>
                      <div style={{float: 'left', marginRight: '24px'}}>
                        <input
                          style={{ display: 'none' }}
                          accept='*'
                          id='contained-button-attach-files'
                          multiple
                          type='file'
                          onChange={({ target: { files } }) => {
                              this.onChooseFiles('attaches', files);
                            }
                          }
                          disabled={!can_edit_form}
                        />
                        <label htmlFor='contained-button-attach-files'>
                          <Button variant='contained'
                                  component='span'
                                  disabled={!can_edit_form}>
                            เลือกไฟล์
                          </Button>
                        </label>
                      </div>
                      <div style={{float: 'left', maxWidth: '800px'}}>
                        {this.state.attaches
                          ? _.map(
                            this.state.attaches,
                            (v, k) =>
                              v.name && (
                                <div key={k} style={fileStyle}>
                                  <StyledUploadedFile
                                    title={v.name}
                                    style={{ float: 'left', width: 36, paddingRight: '0.5rem', cursor: (v.attach_download ? 'pointer' : 'default') }}
                                    src={toAbsoluteUrl(
                                      `/media/files/${v.name.split('.').pop().toLowerCase()}.svg`
                                    )}
                                    onClick={(e) => {
                                      if (v.attach_download)
                                      {
                                        this.downloadAttachFile(e, v);
                                      }
                                    }}
                                  />
                                  <div style={{float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180, cursor: (v.attach_download ? 'pointer' : 'default')}}
                                    onClick={(e) => {
                                      if (v.attach_download)
                                      {
                                        this.downloadAttachFile(e, v);
                                      }
                                    }}
                                  >{v.name}</div>
                                  {can_edit_form && (
                                    <div style={{float: 'left', marginTop: '2px', marginLeft: '8px'}}>
                                      <a onClick={(e) => this.onRemoveAttachFile(e, k)}>
                                        <i className='flaticon2-cancel-music icon-xs text-danger' />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )
                          )
                        : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label'>รายละเอียด</StyledLabelRight>
                      <div className='col-md-9'>
                        <StyledTextField
                            id='detail'
                            placeholder=''
                            multiline
                            rows='3'
                            margin='normal'
                            variant='outlined'
                            value={detail}
                            onChange={(e) => {
                              this.onChangeValue('detail', e.target.value);
                            }}
                            disabled={!can_edit_form}
                        />
                      </div>
                  </div>
                  {
                    _.isArray(this.state.reference_doc) && !_.isEmpty(this.state.reference_doc) ?
                      _.map(this.state.reference_doc, (v, k) => {
                        const reference_doc = this.state.reference_doc;
                        return (
                          <div className='form-group row'>
                            <StyledLabelRight className='col-md-2 col-form-label'>{k == 0 ? 'หนังสืออ้างอิง' : ''}</StyledLabelRight>
                            <div className='col-md-2' style={{ marginTop: '-14px' }}>
                              <StyledTextField
                                id='ref-doc-no'
                                placeholder='เลขที่หนังสือ'
                                margin='normal'
                                value={reference_doc[k].doc_no}
                                onChange={(e) => {
                                  reference_doc[k].doc_no = e.target.value;
                                  this.setState({ reference_doc });
                                }}
                                inputProps={{maxLength: 50}}
                                disabled={!can_edit_form}
                              />
                            </div>
                            <div className='col-md-3' style={{ marginTop: '-14px' }}>
                              <StyledTextField
                                id='ref-doc-topic'
                                placeholder='เรื่อง'
                                margin='normal'
                                value={reference_doc[k].topic}
                                onChange={(e) => {
                                  reference_doc[k].topic = e.target.value;
                                  this.setState({ reference_doc });
                                }}
                                inputProps={{maxLength: 255}}
                                disabled={!can_edit_form}
                              />
                            </div>
                            <div className='col-md-2' style={{ marginTop: '14px' }}>
                              <DatePicker
                                placeholderText='วันที่'
                                dateFormat='d MMM yyyy'
                                selected={reference_doc[k].date_at_txt ? new Date(reference_doc[k].date_at_txt) : null}
                                onChange={(date) => {
                                  try {
                                    if (date != null) {
                                      reference_doc[k].date_at_txt = moment(date).format('YYYY-MM-DD');
                                    } else {
                                      reference_doc[k].date_at_txt = '';
                                    }
                                    this.setState({ reference_doc });
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }}
                                isClearable={can_edit_form}
                                onKeyDown={e => e.preventDefault()}
                                disabled={!can_edit_form}
                              />
                            </div>
                            <div className='col-md-3'>
                              <ButtonBootstrap
                                variant='danger'
                                style={{ marginLeft: 16 }}
                                onClick={() => {
                                  reference_doc.splice(k, 1);
                                  this.setState({ reference_doc });
                                }}
                                disabled={!can_edit_form}>
                                ลบ
                              </ButtonBootstrap>
                              {k == (_.size(reference_doc) - 1) && (
                                <ButtonBootstrap
                                  variant='success'
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    reference_doc.push({
                                      doc_no: '',
                                      topic: '',
                                      date_at_txt: moment().format('YYYY-MM-DD')
                                    });
                                    this.setState({ reference_doc });
                                  }}
                                  disabled={!can_edit_form}
                                >
                                  เพิ่ม
                                </ButtonBootstrap>
                              )}
                            </div>
                          </div>
                        );
                      }) : (
                        <div className='form-group row'>
                          <StyledLabelRight className='col-md-2 col-form-label'>หนังสืออ้างอิง</StyledLabelRight>
                          <div className='col-md-1'>
                            <ButtonBootstrap
                              variant='success'
                              onClick={() => {
                                this.setState({
                                  reference_doc: [
                                    {
                                      doc_no: '',
                                      topic: '',
                                      date_at_txt: moment().format('YYYY-MM-DD')
                                    }
                                  ],
                                });
                              }}
                              disabled={!can_edit_form}
                            >
                              เพิ่ม
                            </ButtonBootstrap>
                          </div>
                        </div>
                      )
                  }

                  <br />
                </Tab>
              </Tabs>
              
              <div
                  className='form-group row'
                  style={{
                      marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'
                  }}
              >
                {can_edit_form && (
                  <>
                    <ButtonBootstrap
                        variant='secondary'
                        onClick={() => this.displayAlert('draft')}
                    >
                        บันทึกร่าง
                    </ButtonBootstrap>
                    <ButtonBootstrap
                        variant='info'
                        style={{ marginLeft: '5px' }}
                        onClick={() => this.displayAlert('preview')}
                    >
                        ดูตัวอย่างเอกสาร
                    </ButtonBootstrap>
                    <ButtonBootstrap
                        variant='success'
                        style={{ marginLeft: '5px' }}
                        onClick={() => this.displayAlert('send_review')}
                    >
                        ส่งกลั่นกรอง
                    </ButtonBootstrap>
                  </>
                )}
              </div>
              <br />
            </>
          )}
      </div>
    );
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: left;
`;
const StyledButtonCenter = styled.div`
  text-align: center;
`;
const StyledUploadedFile = styled.img`
  
`;
const StyledLinkButton = styled.i`
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
