import React from 'react';
import RegisterReceivePage from '../register-receive/RegisterReceivePage';
export default function RegisterReceive() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterReceivePage />
        </div>
      </div>
    </>
  );
}
