/* eslint-disable default-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import 'react-datepicker/dist/react-datepicker.css';

import * as auth from '../../store/ducks/auth.duck';

import { Button, MenuItem, TextField } from '@material-ui/core';
import { Button as ButtonBootstrap, Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import API from '../../api';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import CustomDownshift from '../../widgets/CustomDownshift';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import ReserveDocNoDialog from '../../widgets/ReserveDocNoDialog';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import th from 'date-fns/locale/th';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import withReactContent from 'sweetalert2-react-content';
import { withRouter } from 'react-router-dom';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const current_year = new Date().getFullYear();
const start_date_of_year = new Date('1/1/' + current_year);
const start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
const end_date = moment().format('YYYY-MM-DD');
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
var param = `?doc_status=&doc_no=&item_no=&keyword=&start_date=${start_date}&end_date=${end_date}&book_cate_id=&doc_type_id=&form_type_id=&page=1&display_filters=true`;
const input = { display: 'none' };
const fileStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
};
const attachStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
  position: 'absolute', marginLeft: '1rem'
};
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doc_type_data: [],
      book_cate_data: [],
      master_secret_data: [],
      division_data: [],
      sub_division_data: [],
      selected_divisions: [],
      selected_sub_divisions: [],
      selected_internal_divisions: [],
      division_type: '1',
      date_at: moment().format('YYYY-MM-DD'),
      due_date: moment().format('YYYY-MM-DD'),
      expire_date: moment().format('YYYY-MM-DD'),
      active_tab: 'book_data',
      select_ecms: false,
      reference_doc: [],
      attach: [],

      reserved_doc_no: null,
      docs_send_no_reserve_id: null,
      show_doc_no_prefix: false,
      doc_no_prefix: null,

      date_at_year: null,
      book_cate_id: null,
      book_cate_name: null,
      selected_book_cate_name: null,

      can_change_doc_no_prefix_book_cate_id: [],
      organization_name: null,
      department_name: null,
      division_name: null,
      sub_division_name: null,
    };
  }
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('0');
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            if (curr_page == '/register-send-add')
              window.location.reload(false);
            else
              this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'keep': {
        MySwal.fire({
          title: 'ต้องการจัดเก็บ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('9');
          } else {
            return;
          }
        });
        break;
      }
      case 'register-send': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนส่ง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('1');
          } else {
            return;
          }
        });
        break;
      }
      case 'cancel': {
        MySwal.fire({
          title: 'ต้องการยกเลิก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onSave('3');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        let html = (
          <>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เลขทะเบียนส่ง
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.doc_no}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เรื่อง
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.topic}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เรียน
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.to_name}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                วันที่/เวลาส่ง
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {this.dateFormat(moment(data.date_at))}
              </StyledButtonLeft>
            </div>
          </>
        );
        MySwal.fire({
          title: 'สำเร็จ!',
          html,
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.props.history.push('/register-send-paginate/all' + param);
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        });
        break;
      }
    }
  };
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    try {
      let organization_name = '';
      let department_name = '';
      let division_name = '';
      let sub_division_name = '';
      const profile_req = await API.get('/console/users/profile');
      if (profile_req.success) {
        if (!!profile_req.data.organization) {
          organization_name = profile_req.data.organization.name;
        }
        if (!!profile_req.data.department) {
          department_name = profile_req.data.department.name;
        }
        if (!!profile_req.data.division) {
          division_name = profile_req.data.division.name;
        }
        if (!!profile_req.data.sub_division) {
          sub_division_name = profile_req.data.sub_division.name;
        }
        this.setState({
          organization_name,
          department_name,
          division_name,
          sub_division_name,
        });
      }
      await API.get('/console/doc_type/all').then(({ success, data }) => { if (success) { this.setState({ doc_type_data: data }); } });
      await API.get('/console/book_cate/all?trans_type=2').then(({ success, data }) => { if (success) { this.setState({ book_cate_data: data }); } });
      await API.get('/console/static/master_secret/all').then(({ success, data }) => { if (success) { this.setState({ master_secret_data: data }); } });
      await API.get('/console/static/master_speed/all').then(({ success, data }) => { if (success) { this.setState({ master_speed_data: data }); } });
      await API.get('/console/static/division_and_sub/all').then((res) => {
        if (res && res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
            division_value: 'main_' + v.division_id,
          }));
          const department = _.filter(
            res.data,
            (v, k) => k == 'department_id'
          )[0].map((v) => ({
            value: 'department_' + v.id,
            label: v.name,
          }));
          let division_data = [];
          _.forEach(division, (d) => {
            division_data.push(d);
            division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
          });
          this.setState({ division, sub_division, department, division_data });
        }
      });
      await API.get('/console/static/division_and_sub/central').then((res) => {
        if (res && res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
            division_value: 'main_' + v.division_id,
          }));
          let internal_division = [];
          _.forEach(division, (d) => {
            internal_division.push(d);
            internal_division = internal_division.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
          });
          this.setState({ internal_division });
        }
      });
      await API.get('/console/setting?code=CanChangeDocNoPrefix_BookCategoryID').then(({ success, data }) => {
        if (success && data != null) {
          let can_change_doc_no_prefix_book_cate_id = data.value.split(',');
          this.setState({
            can_change_doc_no_prefix_book_cate_id
          });
        }
      });
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.docs_trans_id
      ) {
        const docs_trans_id = this.props.location.state.docs_trans_id;
        await API.get(`/console/receive/${docs_trans_id}/edit`).then((res) => {
          if (res && res.success) {
            let selected_book_cate_name = null;
            let show_doc_no_prefix = false;
            const book_cate = _.filter(this.state.book_cate_data, (v, k) => v.book_cate_id == res.data.book_cate_id.toString());
            if (book_cate) {
              selected_book_cate_name = book_cate.name;
              show_doc_no_prefix = (this.state.can_change_doc_no_prefix_book_cate_id.includes(res.data.book_cate_id.toString()));
            }
            this.setState({
              docs_trans_id,
              //doc_download: res.data.doc_download,
              //doc_name: res.data.doc_name,
              //doc_type: res.data.doc_type,
              topic: res.data.topic,
              //doc_type_id: res.data.doc_type_id,
              //page: res.data.page,
              //storage_location: res.data.storage_location,
              //secret_type_id: res.data.secret_type_id,
              //speed_type_id: res.data.speed_type_id,
              //attach: res.data.attach,
              //detail: res.data.detail,
              //reference_doc: res.data.reference_doc,
              remark: 'เลขทะเบียนรับ: ' + res.data.receive_no + ' เลขที่หนังสือ: ' + res.data.doc_no,
              //doc_no_prefix: res.data.doc_no_prefix,
              //selected_book_cate_name: selected_book_cate_name,
              show_doc_no_prefix: show_doc_no_prefix,
            });
          }
        });
      }
      this.setState({ date_at_year: (moment().year() + 543).toString() });
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  setSelectedReserveDocNo = (docs_send_no_reserve_id, reserved_doc_no) => { this.setState({ docs_send_no_reserve_id, reserved_doc_no }); }
  componentDidMount() { this.getData(); }
  onInputClick = (event) => { event.target.value = ''; }
  downloadDocFile = async (event, id, file_name) => {
    await this.downloadFile(event, '/console/static/download/doc/' + id, file_name);
  }
  downloadAttachFile = async (event, id, file_name) => {
    console.log(1)
    await this.downloadFile(event, '/console/static/download/attach/' + id, file_name);
  }
  downloadFile = async (event, url, file_name) => {
    try {
      const resp = await API.postDownloadFile(url, null, 'header');
      const file = new Blob([resp.data], {
        type: resp.headers['content-type'],
      });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = file_name;
      fileLink.click();
    } catch (err) {
      console.log('error::');
      console.log(err);
    }
  }
  onChooseFiles = (type, files) => {
    if (!files.length) return;
    switch (type) {
      case 'doc_file': {
        this.setState({
          doc_file: files[0],
        });
        break;
      }
      case 'attach': {
        if (this.state.attach) {
          this.setState({ attach: [...this.state.attach, ...files] });
        } else {
          this.setState({
            attach: files,
          });
        }
        break;
      }
    }
  };
  onRemoveAttachFile = (e, idx) => {
    e.preventDefault();
    let attach = this.state.attach;
    attach.splice(idx, 1);
    this.setState({ attach });
  }
  onChangeValue = (key, value) => this.setState({ [key]: value });
  onSave = async (doc_status) => {
    this.displayAlert('loading');
    const {
      doc_trans,
      doc_type_id,
      book_cate_id,
      date_at,
      topic,
      to_name,
      storage_location,
      page,
      speed_type_id,
      secret_type_id,
      detail,
      remark,
      due_date,
      expire_date,
      to_text,
      doc_file,
      attach,
      reference_doc,
      docs_code,
      selected_divisions,
      doc_no_prefix,
      order_remark,
      docs_send_no_reserve_id,
      reserved_doc_no,
    } = this.state;
    let data = {
      doc_trans,
      doc_type_id,
      book_cate_id,
      date_at,
      topic,
      to_name,
      storage_location,
      page,
      speed_type_id,
      secret_type_id,
      detail,
      remark,
      due_date,
      expire_date,
      doc_status,
      send_to: {
        division_id: [],
        sub_division_id: [],
        department_id: [],
      },
      to_text,
      doc_file,
      attach,
      docs_code,
      doc_no_prefix,
      order_remark,
      docs_send_no_reserve_id,
      reserved_doc_no,
    };
    if (_.isArray(reference_doc) && !_.isEmpty(reference_doc)) {
      for (var i = 0; i < reference_doc.length; i++) {
        var _dr = reference_doc[i];
        if (!isNaN(_dr.date_at)) {
          console.log(new Date(_dr.date_at))
          _dr.date_at = _dr.date_at ? _dr.date_at.replace('T', ' ').replace('/', '-') : null;
        }
      }
      data.reference_doc = JSON.stringify(reference_doc);
    }
    if (this.state.docs_trans_id) { data.docs_trans_id = this.state.docs_trans_id; }
    _.filter(selected_divisions, (v) => {
      const str = v.value;
      console.log('str -->', str);
      if (str.includes('main_')) {
        let main = str.split('main_');
        data.send_to.division_id.push(main[1]);
      }
      if (str.includes('sub_')) {
        let sub = str.split('sub_');
        data.send_to.sub_division_id.push(sub[1]);
      }
      if (str.includes('department_')) {
        let department = str.split('department_');
        data.send_to.department_id.push(department[1]);
      }
    });
    if (this.state.division_type == '1') {
      data.to_text = undefined;
    } else {
      if (this.state.select_ecms) {
        data.to_text = undefined;
      } else {
        data.send_to = undefined;
      }
    }
    this.props.setCache(data);
    let form_data = new FormData();
    _.filter(data, (v, k) => {
      if (v && v != '') {
        if (k == 'date_at' || k == 'due_date' || k == 'expire_date') {
          v = v.replace('T', ' ');
          v = v.replace('/', '-');
        }
        if (k == 'send_to') {
          _.filter(v, (v1, k1) => {
            _.filter(v1, (v2, k2) => {
              form_data.append(`${k}[${k1}][]`, v2);
            });
          });
        } else {
          if (k == 'doc_file') {
            form_data.append(k, v);
          } else if (k == 'attach') {
            _.filter(v, (v1, k1) => {
              form_data.append(`attach[]`, v1);
            });
          } else {
            form_data.set(k, v);
          }
        }
      }
    });
    console.log('data -->', data);
    for (var [k, v] of form_data.entries()) {
      console.log(k, v);
    }
    API.post(`/console/send/add`, form_data).then(
      ({ success, data, error }) => {
        if (success) {
          if (data.item.doc_status == '9') {
            this.props.history.push({
              pathname: (`/register-send-store/register`),
              state: {
                docs_trans_id: data.item.docs_trans_id,
                stores_docs_id: data.item.stores_docs_id,
              },
            });
          } else {
            this.displayAlert('success', data.item);
          }
        } else {
          this.displayAlert('error', error);
        }
      }
    );
  };
  handleSelectDivisions = (selected_divisions) => this.setState({ selected_divisions });
  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_divisions.push(v1);
      }
    });

    let merge_divisions = (selected_divisions == null) ? new_selected_divisions : selected_divisions.concat(new_selected_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_sub_divisions : selected_divisions.concat(new_selected_sub_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectAllInternalDivisions = () => {
    const new_selected_internal_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.internal_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_internal_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_internal_divisions : selected_divisions.concat(new_selected_internal_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  handleSelectAllDepartments = () => {
    const new_selected_departments = [];
    _.filter(this.state.department, (v1) => { new_selected_departments.push(v1); });
    this.setState({
      select_ecms: true,
      to_text: null,
      selected_divisions: new_selected_departments,
    });
  };
  handleTypeToText = () => {
    this.setState({
      select_ecms: false,
      to_text: null,
      selected_divisions: [],
    });
  };
  handleSelectSubDivisions = (selected_sub_divisions) => this.setState({ selected_sub_divisions });
  dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) {
      formatArray[indexFullYear] = thaiYear;
    }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) {
      formatArray[indexShortYear] = thaiYear.toString().substr(2);
    }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  render() {
    const {
      loading,
      active_tab,
      book_cate_id,
      secret_type_id,
      speed_type_id,
      doc_type_id,
      page,
      doc_type_data,
      book_cate_data,
      master_secret_data,
      master_speed_data,
      reserved_doc_no,
      show_doc_no_prefix
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_doc_type = _.map(doc_type_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.doc_type_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_secret = _.map(master_secret_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      const list_master_speed = _.map(master_speed_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <Tabs
            id='controlled-tab-example'
            activeKey={active_tab}
            onSelect={(active_tab) => this.setState({ active_tab })}
          >
            <Tab eventKey='book_data' title='ข้อมูลหนังสือ'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>สร้างหนังสือส่งออก</h3>
                </div>
              </div>
              <br />
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  แนบไฟล์หนังสือ
                </StyledLabelRight>
                <div className='col-md-4'>
                  <input
                    accept='*'
                    style={input}
                    id='contained-button-file'
                    type='file'
                    onChange={({ target: { files } }) => this.onChooseFiles('doc_file', files)}
                    onClick={this.onInputClick}
                  />
                  <label htmlFor='contained-button-file'>
                    <Button variant='contained' component='span'>
                      เลือกไฟล์
                    </Button>
                  </label>
                  {this.state.doc_file && (
                    <div style={attachStyle}>
                      <StyledUploadedFile
                        title={this.state.doc_file.name}
                        style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                        src={toAbsoluteUrl(`/media/files/${this.state.doc_file.name.split('.').pop().toLowerCase()}.svg`)}
                      />
                      <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{this.state.doc_file.name}</div>
                      <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                        <a onClick={(e) => {
                          this.setState({ doc_file: null });
                          e.preventDefault();
                        }}>
                          <i className='flaticon2-cancel-music icon-xs text-danger' />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    รายละเอียดหนังสือส่งออก
                  </h3>
                </div>
              </div>
              <br />
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                  *ทะเบียนส่ง
                </StyledLabelRight>
                <div className='col-md-4' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    id='book_cate_id'
                    value={book_cate_id}
                    select
                    onChange={(e) => {
                      const selected_value = e.target.value;
                      const selected_book_cate_name = _.find(book_cate_data, ['book_cate_id', selected_value]).name;
                      const show_doc_no_prefix = (this.state.can_change_doc_no_prefix_book_cate_id.includes(selected_value.toString()));
                      this.setState({
                        book_cate_id: selected_value,
                        book_cate_name: selected_book_cate_name,
                        show_doc_no_prefix: show_doc_no_prefix,
                        docs_send_no_reserve_id: null,
                        reserved_doc_no: '',
                        doc_no_prefix: '',
                      });
                    }}
                    margin='normal'
                  >
                    {list_book_cate}
                  </StyledTextField>
                </div>
                <StyledLabelRight
                  className='col-md-2 col-form-label'
                  color='red'
                >
                  *ลงวันที่
                </StyledLabelRight>
                <div className='col-md-2'>
                  <DatePicker
                    dateFormat='d MMM yyyy'
                    selected={new Date(this.state.date_at)}
                    onChange={(date) => {
                      let date_at = moment(date).format('YYYY-MM-DD');
                      const selected_year = (parseInt(moment(date).format('YYYY'), 10) + 543).toString();

                      let date_at_year = this.state.date_at_year;
                      let reserved_doc_no = this.state.reserved_doc_no;
                      let doc_no_prefix = this.state.doc_no_prefix;
                      let docs_send_no_reserve_id = this.state.docs_send_no_reserve_id;

                      if (date_at_year != selected_year) {
                        reserved_doc_no = '';
                        doc_no_prefix = '';
                        docs_send_no_reserve_id = null;
                        date_at_year = selected_year;
                      }
                      this.setState({
                        date_at,
                        date_at_year,
                        reserved_doc_no,
                        doc_no_prefix,
                        docs_send_no_reserve_id
                      });
                    }}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>เลขทะเบียนส่ง</StyledLabelRight>
                <div className='col-md-4'>
                  <div style={{ float: 'left', paddingTop: '6px' }}>
                    <ReserveDocNoDialog
                      year={this.state.date_at_year}
                      book_cate_id={this.state.book_cate_id}
                      book_cate_name={this.state.book_cate_name}
                      division_name={this.state.division_name}
                      sub_division_name={this.state.sub_division_name}
                      setSelected={this.setSelectedReserveDocNo}
                      disabled={this.state.book_cate_id != null ? false : true} />
                  </div>
                  {!_.isEmpty(reserved_doc_no) && (
                    <div style={{ float: 'left' }}>
                      <StyledLabelLeft className='col-form-label' style={{ marginLeft: '12px' }}>{reserved_doc_no}</StyledLabelLeft>
                      {(
                        <a onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            reserved_doc_no: '',
                            doc_no_prefix: '',
                            docs_send_no_reserve_id: null
                          });
                        }}
                          style={{ marginLeft: '8px' }}
                        >
                          <i className='flaticon2-cancel-music icon-xs text-danger' />
                        </a>
                      )}
                    </div>)}
                </div>
                {(show_doc_no_prefix == true) &&
                  <>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*คำนำหน้าเลขหนังสือ</StyledLabelRight>
                    <div className='col-md-3'>
                      <StyledTextField
                        id='doc_no_prefix'
                        placeholder=''
                        value={this.state.doc_no_prefix}
                        onChange={({ target: { value } }) =>
                          this.onChangeValue('doc_no_prefix', value)
                        }
                        inputProps={{ maxLength: 255 }}
                      />
                    </div>
                  </>
                }
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ชั้นความลับ
                </StyledLabelRight>
                <div className='col-md-4' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='secret_type_id'
                    value={secret_type_id}
                    onChange={(e) => {
                      this.onChangeValue('secret_type_id', e.target.value);
                    }}
                    margin='normal'
                  >
                    {list_master_secret}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ชั้นความเร็ว
                </StyledLabelRight>
                <div className='col-md-3' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='speed_type_id'
                    value={speed_type_id}
                    onChange={(e) => {
                      this.onChangeValue('speed_type_id', e.target.value);
                    }}
                    margin='normal'
                  >
                    {list_master_speed}
                  </StyledTextField>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-md-2'></div>
                <div className='col-auto'>
                  <Radio
                    checked={this.state.division_type == '1'}
                    onChange={() => this.onChangeValue('division_type', '1')}
                    value='1'
                    name='radio-button-demo'
                    inputProps={{ 'aria-label': '1' }}
                  />
                  หน่วยงานภายใน
                </div>
                <div className='col-auto'>
                  <Radio
                    checked={this.state.division_type == '2'}
                    onChange={() => this.onChangeValue('division_type', '2')}
                    value='2'
                    name='radio-button-demo'
                    inputProps={{ 'aria-label': '2' }}
                  />
                  หน่วยงานภายนอก
                </div>
              </div>
              {this.state.division_type == '1' ? (
                <>
                  <div className='form-group row'>
                    <div className='col-md-2'></div>
                    <ButtonBootstrap
                      variant='success'
                      onClick={this.handleSelectAllDivisions}
                      style={{ marginLeft: '10px' }}
                    >
                      ส่งทุกหน่วยงาน
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='success'
                      onClick={this.handleSelectAllSubDivisions}
                      style={{ marginLeft: '10px' }}
                    >
                      ส่งทุกหน่วยงานย่อย
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='success'
                      onClick={this.handleSelectAllInternalDivisions}
                      style={{ marginLeft: '10px' }}
                    >
                      ส่งหน่วยงานส่วนกลาง
                    </ButtonBootstrap>
                  </div>
                  <div className='form-group row'>
                    <StyledLabelRight
                      className='col-md-2 col-form-label'
                      color='red'
                    >
                      *ส่งถึง (จัดเก็บไม่ต้องเลือก)
                    </StyledLabelRight>
                    <div className='col-md-9'>
                      <CustomAutocomplete
                        isMulti
                        placeholder='หน่วยงาน/หน่วยงานย่อย'
                        items={this.state.division_data}
                        selected_items={this.state.selected_divisions}
                        handleSelectItems={this.handleSelectDivisions}
                      ></CustomAutocomplete>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='form-group row' style={{ display: 'none' }}>
                    <div className='col-md-2'></div>
                    <ButtonBootstrap
                      variant='success'
                      onClick={this.handleTypeToText}
                      style={{ marginLeft: '10px' }}
                    >
                      พิมพ์ชื่อหน่วยงาน
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() =>
                        this.setState({
                          select_ecms: true,
                          to_text: null,
                        })
                      }
                      style={{ marginLeft: '10px' }}
                    >
                      ส่งหน่วยงาน e-CMS
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='success'
                      onClick={this.handleSelectAllDepartments}
                      style={{ marginLeft: '10px' }}
                    >
                      ส่งหน่วยงาน e-CMS ทั้งหมด
                    </ButtonBootstrap>
                  </div>
                  {this.state.select_ecms ? (
                    <div className='form-group row'>
                      <StyledLabelRight
                        className='col-md-2 col-form-label'
                        color='red'
                      >
                        *ส่งถึง (จัดเก็บไม่ต้องเลือก)
                      </StyledLabelRight>
                      <div className='col-md-9'>
                        <CustomAutocomplete
                          isMulti
                          placeholder='หน่วยงาน/หน่วยงานย่อย'
                          items={this.state.department}
                          selected_items={this.state.selected_divisions}
                          handleSelectItems={this.handleSelectDivisions}
                        ></CustomAutocomplete>
                      </div>
                    </div>
                  ) : (
                    <div className='form-group row'>
                      <StyledLabelRight
                        className='col-md-2 col-form-label'
                        color='red'
                      >
                        *ส่งถึง (จัดเก็บไม่ต้องกรอก)
                      </StyledLabelRight>
                      <div className='col-md-6'>
                        <StyledTextField
                          id='standard-name'
                          placeholder=''
                          value={this.state.to_text}
                          onChange={({ target: { value } }) =>
                            this.onChangeValue('to_text', value)
                          }
                          inputProps={{ maxLength: 255 }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className='form-group row'>
                <StyledLabelRight
                  className='col-md-2 col-form-label'
                  color='red'
                >
                  *เรื่อง
                </StyledLabelRight>
                <div className='col-md-9' style={{ marginTop: -12 }}>
                  <StyledTextField
                    id='note'
                    value={this.state.topic}
                    placeholder='เรื่อง'
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    onChange={e => {
                      this.onChangeValue('topic', e.target.value);
                    }}
                    inputProps={{ maxLength: 500 }}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight
                  className='col-md-2 col-form-label'
                  color='red'
                >
                  *เรียน
                </StyledLabelRight>
                <div className='col-md-9' style={{ marginTop: -12 }}>
                  <CustomDownshift
                    _id='to_name'
                    focused_id={this.state.focused_id}
                    suggestions={this.props.cache.to_name}
                    inputValue={this.state.to_name}
                    handleInputChange={(event) => {
                      this.onChangeValue('to_name', event.target.value);
                    }}
                    handleOnFocus={(focused_id) =>
                      this.onChangeValue('focused_id', focused_id)
                    }
                    maxLength={255}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  คำสั่งการ
                </StyledLabelRight>
                <div className='col-md-9'>
                  <StyledTextField
                    id='order_remark'
                    value={this.state.order_remark}
                    placeholder='คำสั่งการ'
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    onChange={(e) => {
                      this.onChangeValue('order_remark', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  หมายเหตุ
                </StyledLabelRight>
                <div className='col-md-9'>
                  <StyledTextField
                    id='outlined-multiline-static'
                    placeholder='หมายเหตุ'
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    value={this.state.remark}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('remark', value)
                    }
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey='book_detail' title='รายละเอียดหนังสือ'>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ประเภทหนังสือ
                </StyledLabelRight>
                <div className='col-md-4' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='doc_type_id'
                    value={doc_type_id}
                    onChange={(e) => {
                      this.onChangeValue('doc_type_id', e.target.value);
                    }}
                    margin='normal'
                  >
                    {list_doc_type}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  จำนวน
                </StyledLabelRight>
                <div className='col-md-2'>
                  <StyledTextField
                    id='page'
                    type='number'
                    value={page}
                    placeholder='หน้า'
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      this.onChangeValue('page', e.target.value);
                    }}
                  />
                </div>
                <StyledLabelLeft className='col-md-1 col-form-label'>
                  หน้า
                </StyledLabelLeft>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  เก็บไว้ที่หน่วยงาน
                </StyledLabelRight>
                <div className='col-md-9' style={{ marginTop: -12 }}>
                  <CustomDownshift
                    _id='storage_location'
                    focused_id={this.state.focused_id}
                    suggestions={this.props.cache.storage_location}
                    inputValue={this.state.storage_location}
                    handleInputChange={(event) =>
                      this.onChangeValue('storage_location', event.target.value)
                    }
                    handleOnFocus={(focused_id) =>
                      this.onChangeValue('focused_id', focused_id)
                    }
                    maxLength={255}
                  />
                </div>
              </div>
              <div className='form-group row' style={{ marginBottom: 0 }}>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  สิ่งที่ส่งมาด้วย
                </StyledLabelRight>
                <div className='col-auto'>
                  <input
                    accept='*'
                    style={input}
                    id='contained-button-files'
                    multiple
                    type='file'
                    onChange={({ target: { files } }) => this.onChooseFiles('attach', files)}
                    onClick={this.onInputClick}
                  />
                  <label htmlFor='contained-button-files'>
                    <Button variant='contained' component='span'>
                      เลือกไฟล์
                    </Button>
                  </label>
                </div>
                <div className='col-md-7'>
                  {this.state.attach
                    ? _.map(
                      this.state.attach,
                      (v, k) =>
                        v.name && (
                          <div key={k} style={fileStyle}>
                            <StyledUploadedFile
                              title={v.name}
                              style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                              src={toAbsoluteUrl(`/media/files/${v.name.split('.').pop().toLowerCase()}.svg`)}
                            />
                            <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{v.name}</div>
                            <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                              <a onClick={(e) => this.onRemoveAttachFile(e, k)}>
                                <i className='flaticon2-cancel-music icon-xs text-danger' />
                              </a>
                            </div>
                          </div>
                        )
                    )
                    : null}
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  รายละเอียด
                </StyledLabelRight>
                <div className='col-md-9'>
                  <StyledTextField
                    id='outlined-multiline-static'
                    placeholder=''
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    value={this.state.detail}
                    onChange={({ target: { value } }) =>
                      this.onChangeValue('detail', value)
                    }
                  />
                </div>
              </div>
              {
                _.isArray(this.state.reference_doc) && !_.isEmpty(this.state.reference_doc) ?
                  _.map(this.state.reference_doc, (v, k) => {
                    const reference_doc = this.state.reference_doc;
                    return (
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>
                          {k == 0 ? 'หนังสืออ้างอิง' : ''}
                        </StyledLabelRight>
                        <div className='col-md-2'>
                          <StyledTextField
                            id='outlined-multiline-static'
                            placeholder='เลขที่หนังสือ'
                            multiline
                            margin='normal'
                            value={reference_doc[k].doc_no}
                            onChange={({ target: { value } }) => {
                              reference_doc[k].doc_no = value;
                              this.setState({ reference_doc });
                            }}
                            inputProps={{ maxLength: 255 }}
                          />
                        </div>
                        <div className='col-md-3'>
                          <StyledTextField
                            id='outlined-multiline-static'
                            placeholder='เรื่อง'
                            multiline
                            margin='normal'
                            value={reference_doc[k].topic}
                            onChange={({ target: { value } }) => {
                              reference_doc[k].topic = value;
                              this.setState({ reference_doc });
                            }}
                            inputProps={{ maxLength: 500 }}
                          />
                        </div>
                        <div className='col-md-2'>
                          <DatePicker
                            wrapperClassName="mt-38"
                            placeholderText='วันที่'
                            dateFormat='d MMM yyyy'
                            selected={reference_doc[k].date_at ? new Date(reference_doc[k].date_at) : null}
                            onChange={(date) => {
                              try {
                                reference_doc[k].date_at = date ? moment(date).format('YYYY-MM-DD') : null;
                                this.setState({ reference_doc });
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        </div>
                        <div className='col-md-1'>
                          <ButtonBootstrap
                            variant='danger'
                            style={{ marginLeft: 16 }}
                            onClick={() => {
                              reference_doc.splice(k, 1);
                              this.setState({ reference_doc });
                            }}>
                            ลบ
                          </ButtonBootstrap>
                        </div>
                        {k == (_.size(reference_doc) - 1) && (<div className='col-md-1'>
                          <ButtonBootstrap
                            variant='success'
                            style={{ marginLeft: k == 0 ? 16 : 0 }}
                            onClick={() => {
                              reference_doc.push({
                                doc_no: '',
                                topic: '',
                                date_at: moment().format('YYYY-MM-DD')
                              });
                              this.setState({ reference_doc });
                            }}
                          >
                            เพิ่ม
                          </ButtonBootstrap>
                        </div>)}
                      </div>
                    );
                  }) : (
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label'>
                        หนังสืออ้างอิง
                      </StyledLabelRight>
                      <div className='col-md-1'>
                        <ButtonBootstrap
                          variant='success'
                          onClick={() => {
                            this.setState({
                              reference_doc: [
                                {
                                  doc_no: '',
                                  topic: '',
                                  date_at: moment().format('YYYY-MM-DD')
                                }
                              ],
                            });
                          }}
                        >
                          เพิ่ม
                        </ButtonBootstrap>
                      </div>
                    </div>
                  )
              }
            </Tab>
          </Tabs>
          <div
            className='form-group row'
            style={{
              marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'
            }}
          >
            <StyledButtonRight>
              <ButtonBootstrap variant='dark' onClick={() => this.displayAlert()}>
                บันทึกร่าง
              </ButtonBootstrap>
              <ButtonBootstrap variant='info' style={{ marginLeft: '5px' }} onClick={() => this.displayAlert('keep')}>
                จัดเก็บ
              </ButtonBootstrap>
              <ButtonBootstrap variant='success' style={{ marginLeft: '5px' }} onClick={() => this.displayAlert('register-send')}>
                ลงทะเบียนส่ง
              </ButtonBootstrap>
              <ButtonBootstrap variant='secondary' style={{ marginLeft: '5px' }} onClick={() => this.displayAlert('back')}>
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .makeStyles-container-126 {
    margin-top: -15px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 7px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = (state) => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));