import React from 'react';
import SubDivisionAddFormPage from '../sub-division-add-form/SubDivisionAddFormPage';
export default function DivisionAddForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <SubDivisionAddFormPage />
        </div>
      </div>
    </>
  );
}
