import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'title',
    sortable: true,
    disablePadding: false,
    label: 'หัวข้อ',
    align: 'center',
  },
  {
    id: 'date_stamp',
    sortable: true,
    disablePadding: false,
    label: 'ลงวันที่',
    align: 'center',
  },
  {
    id: 'date_stamp_to',
    sortable: true,
    disablePadding: false,
    label: 'ถึงวันที่',
    align: 'center',
  },
  {
    id: 'status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
];
var rows = [];
class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      status: null,
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: Const.Table.RowPerPages
    };
  }
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangePage = (page) => { this.setState({ page }, () => this.getData()); };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  getData = async () => {
    this.displayAlert('loading');
    const { keyword, page, per_page } = this.state;
    const news = await API.post(`/console/news/list?keyword=${keyword}&page=${page}`);
    if (news.success) {
      let news_data = news.data;
      _.filter(news_data, (news, key) => {
        news.id = news.news_id;
        news.status =
          news.status == 1 ? 'แสดง' : news.status == 2 ? 'ลบ' : 'ซ่อน';
      });
      this.setState(
        {
          news_data,
          current_page: news.current_page,
          last_page: news.last_page,
        },
        () => MySwal.close()
      );
    }
  };
  componentDidMount() { this.getData(); }

  render() {
    const { keyword } = this.state;
    const { ecs_permission } = this.props;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">บริหารข่าวประชาสัมพันธ์</h3>
          </div>
        </div>
        <br />
        <form className="searchForm" noValidate>
          <div className="form-group row">
            <StyledLabelRight className="col-md-2 col-form-label">
              คำสำคัญ
            </StyledLabelRight>
            <div className="col-md-2">
              <StyledTextField
                id="keyword"
                placeholder="คำสำคัญ"
                onChange={(e) => {
                  this.setData('keyword', e.target.value);
                }}
              />
            </div>
            <div className="col-md-2 offset-md-1">
              <Button
                variant="success"
                onClick={() => {
                  this.handleChangePage(1);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </form>
        <div
          className="form-group row"
          style={{ marginBottom: 0, paddingLeft: 10 }}
        >
          <StyledButtonLeft className="col-md-6">
            <Button
              variant="success"
              onClick={() => {
                this.props.history.push('/news-add');
              }}
              disabled={
                ecs_permission &&
                ecs_permission.news &&
                ecs_permission.news.news_management &&
                !ecs_permission.news.news_management.add
              }
            >
              เพิ่มข่าวประชาสัมพันธ์
            </Button>
          </StyledButtonLeft>
        </div>
        <CustomTable
          title="ข่าวประชาสัมพันธ์"
          hideSelect={true}
          showManage={true}
          head_rows={head_rows}
          rows={this.state.news_data ? this.state.news_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage="/news-edit/"
          btnManageName="แก้ไข"
          btnManageAlign="left"
          linkDelete="/console/news/"
          btnDeleteName="ลบ"
          btnDeleteAlign="left"
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={
            ecs_permission &&
            ecs_permission.news &&
            ecs_permission.news.news_management &&
            !ecs_permission.news.news_management.edit
          }
          btnDeleteDisabled={
            ecs_permission &&
            ecs_permission.news &&
            ecs_permission.news.news_management &&
            !ecs_permission.news.news_management.delete
          }
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(NewsPage));
