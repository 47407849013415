import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
    /* width: 200 */
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  items: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: '#eeeeee'
  },
  item: {
    cursor: 'pointer',
    '&:hover': {
      color: 'red'
    }
  }
}));
export default function TextFields(props) {
  const classes = useStyles();
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [partialMention, setPartialMention] = React.useState(null);
  const inputRef = React.useRef(null);
  function Suggestions(s_props) {
    let suggestionItems;
    try {
      function selectSuggestion(username) {
        // console.log('username -->', username);
        const regexp = /[a-zA-Z0-9]*$/;
        /* const newValue = props.inputValue.replace(regexp, username + ' '); */
        /* const newValue = s_props.inputValue.replace(regexp, username); */
        const newValue = username;
        s_props.applyMention({ target: { value: newValue } }); // THIS MIMICS AN ONCHANGE EVENT
        s_props.focusInput();
      }
      suggestionItems = s_props.suggestionList
        .filter(item => {
          return (
            item.includes(s_props.partialMention) &&
            item != s_props.partialMention
          );
        })
        .map((item, key) => {
          return (
            <div
              className={classes.item}
              onClick={() => selectSuggestion(item)}
              style={{
                paddingTop: key == 0 ? 5 : 0,
                paddingRight: 10,
                paddingBottom: key == s_props.suggestionList.length - 1 ? 5 : 0,
                paddingLeft: 10
              }}
            >
              {item}
            </div>
          );
        });
    } catch (e) {
      console.log(e);
    }
    return <div className={classes.items}>{suggestionItems}</div>;
  }
  function onChange(event) {
    console.log('event.target.value -->', event.target.value);
    const regexp = /[a-zA-Z0-9]*$/;
    if (regexp.test(event.target.value) && event.target.value != '') {
      setPartialMention(event.target.value);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
    props.handleInputChange(event);
  }
  function focusInput() {
    inputRef.current.focus();
  }
  return (
    <form className={classes.container} noValidate autoComplete='off'>
      <StyledTextField
        onFocus={() => {
          props.handleOnFocus(props._id);
          setShowSuggestions(true);
        }}
        onBlur={() => {
          setTimeout(() => setShowSuggestions(false), 250);
          /* if (props.focused_id != props._id) {
            setTimeout(() => props.handleOnFocus(''), 250);
          } */
        }}
        ref={inputRef}
        label=''
        multiline
        rowsMax='4'
        value={props.inputValue}
        onChange={onChange}
        className={classes.textField}
        margin='normal'
        disabled={props.disabled == true}
        inputProps={{ maxLength: props.maxLength }}
      />
      {showSuggestions &&
        props.focused_id == props._id &&
        props.inputValue != '' && (
          <Suggestions
            inputValue={props.inputValue}
            suggestionList={props.suggestions}
            applyMention={onChange}
            focusInput={focusInput}
            partialMention={partialMention}
          />
        )}
    </form>
  );
}
const StyledTextField = styled(TextField)`
  .MuiInputBase-inputMultiline {
    line-height: 1.6;
    margin-bottom: -2px;
    padding-left: 2px;
    padding-top: 10px;

  }
  .MuiInputBase-multiline {
    padding: 0px;
  }
  .MuiOutlinedInput-inputMultiline{
  }
`;
