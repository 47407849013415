import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem, Icon } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'doc_download',
    sortable: false,
    disablePadding: false,
    label: 'เอกสาร',
    align: 'center',
  },
  {
    id: 'doc_no_display',
    sortable: true,
    disablePadding: false,
    label: 'เลขที่หนังสือ',
    align: 'center',
  },
  { 
    id: 'year_th', 
    sortable: true, 
    disablePadding: false, 
    label: 'ปี',
    align: 'center',
  },
  { 
    id: 'date_at_txt', 
    sortable: true, 
    disablePadding: false, 
    label: 'ลงวันที่',
    align: 'center',
  },
  { 
    id: 'send_to', 
    sortable: true, 
    disablePadding: false, 
    label: 'ส่งถึง',
  },
  { 
    id: 'topic', 
    sortable: true, 
    disablePadding: false, 
    label: 'เรื่อง',
  },
  {
    id: 'sender',
    sortable: true,
    disablePadding: false,
    label: 'ส่งโดย',
    align: 'center',
  },
  { id: 
    'doc_status_name', 
    sortable: true, 
    disablePadding: false, 
    label: 'สถานะ',
    align: 'center',
  },
];

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display_loading: false,
      type: '',
      show_search: true,

      doc_no: '',
      topic: '',
      book_cate_id: null,
      start_date: null,
      end_date: null,
      doc_type_id: null,
      secret_type_id: null,
      speed_type_id: null,
      selected_division: '',
      division_id: null,
      doc_status_id: null,
      
      book_cate_data: [],
      master_doc_type_data: [],
      master_secret_data: [],
      master_speed_data: [],
      division_data: [],
      master_doc_status_data: [],

      link_manage: null,
      table_data: [],
      current_page: 1,
      last_page: 1,
      page: 1,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  handleSelectDivision = (selected_division) => {
    this.setState({ selected_division });
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  getData = async () => {
    this.displayAlert('loading');
    const {
      type,
      doc_no,
      topic,
      book_cate_id,
      start_date,
      end_date,
      doc_type_id,
      secret_type_id,
      speed_type_id,
      selected_division,
      doc_status_id,
      page,
    } = this.state;
    const data = {
      type,
      doc_no,
      topic,
      book_cate_id,
      start_date,
      end_date,
      doc_type_id,
      secret_type_id,
      speed_type_id,
      division_id: null,
      sub_division_id: null,
      doc_status_id,
      page,
      per_page: Const.Table.RowPerPages,
    };

    if (selected_division) {
      if (selected_division.includes('main_')) {
        var main = selected_division.split('main_');
        data.division_id = main[1];
      } else if (selected_division.includes('sub_')) {
        var sub = selected_division.split('sub_');
        data.sub_division_id = sub[1];
      }
    }

    API.post('/console/eform/list', data).then((res) => {
      if (res && res.success) {
        this.setState(
          {
            table_data: res.data,
            current_page: res.current_page,
            last_page: res.last_page,
            per_page: res.count,
            display_loading: false,
          },
          () => MySwal.close()
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const type = this.unicodeToChar(url_params.get('type'));
    let show_search = false;
    let start_date = null;
    let end_date = null;
    let link_manage = null;
    let doc_status_id = null;

    switch (type) {
      case 'search':
        const current_year = new Date().getFullYear();
        const start_date_of_year = new Date('1/1/' + current_year);
        start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        doc_status_id = Const.MasterData.EFormDocStatus.Draft;
        show_search = true;
        link_manage = '/e-form-build/';
        break;
      case 'sent-back':
        doc_status_id = Const.MasterData.EFormDocStatus.SentBack;
        link_manage = '/e-form-build/';
        break;
      case 'wait-review':
        doc_status_id = Const.MasterData.EFormDocStatus.WaitReview;
        link_manage = '/e-form-review/';
        break;
      case 'wait-endorse':
        doc_status_id = Const.MasterData.EFormDocStatus.WaitEndorse;
        link_manage = '/e-form-endorse/';
        break;
      case 'wait-send-executive-secretary':
          doc_status_id = Const.MasterData.EFormDocStatus.WaitSendExecutive;
          link_manage = '/e-form-send-executive-secretary/';
          break;
      case 'wait-send-executive':
        doc_status_id = Const.MasterData.EFormDocStatus.WaitSendExecutive;
        link_manage = '/e-form-send-executive/';
        break;
      case 'wait-send':
        doc_status_id = Const.MasterData.EFormDocStatus.WaitSend;
        link_manage = '/e-form-send/';
        break;
      default:
        break;
    }

    this.setState(
      {
        type,
        start_date,
        end_date,
        doc_status_id,
        show_search,
        link_manage
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    console.log('setMetaData');
    
    await API.get('/console/book_cate/all?trans_type=2').then(({ success, data }) => {
      if (success) {
        this.setState({
          book_cate_data: data,
        });
      }
    });

    await API.get('/console/static/master_secret/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_secret_data: data,
        });
      }
    });

    await API.get('/console/static/master_speed/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_speed_data: data,
        });
      }
    });

    await API.get('/console/doc_type/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_doc_type_data: data,
        });
      }
    });

    await API.get('/console/static/doc_status/eform').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_doc_status_data: data,
        });
      }
    });

    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res.success) {
        const division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));

        const sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
          division_value: 'main_' + v.division_id,
        }));

        let division_data = [];
        _.forEach(division, (d) => {
          division_data.push(d);
          division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
        });

        this.setState({ division_data });
      }
    });

    if (!this.state.show_search) {
      this.getData();
    }
  }

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      show_search,
      
      book_cate_data,
      master_doc_type_data,
      master_secret_data,
      master_speed_data,
      division_data,
      master_doc_status_data,
    } = this.state;

    const list_book_cate = _.map(book_cate_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.book_cate_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_doc_type = _.map(master_doc_type_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.doc_type_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_master_secret = _.map(master_secret_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_master_speed = _.map(master_speed_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_division = _.map(division_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.value}>
          {value.label}
        </MenuItem>
      );
    });
    const list_doc_status = _.map(master_doc_status_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    return (
      <>
        <div className='kt-portlet kt-portlet--height-fluid'>
          {show_search && (
            <>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    ค้นหา
                  </h3>
                </div>
              </div>
              <form
                className='searchForm'
                noValidate
                autoComplete='on'
                style={{ paddingBottom: 0 }}
              >
                <div className='form-group row' style={{ marginTop: '2rem' }}>
                  <StyledLabelRight className='col-md-2 col-form-label'>เลขที่หนังสือ</StyledLabelRight>
                  <div className='col-md-2'>
                    <StyledTextField
                      id='doc_no'
                      value={this.state.doc_no}
                      onChange={(e) => {
                        this.onChangeValue('doc_no', e.target.value);
                      }}
                    />
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>เรื่อง</StyledLabelRight>
                  <div className='col-md-2'>
                    <StyledTextField
                      id='topic'
                      value={this.state.topic}
                      onChange={(e) => {
                        this.onChangeValue('topic', e.target.value);
                      }}
                    />
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>ทะเบียนส่ง</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='book_cate_id'
                      select
                      margin='normal'
                      value={this.state.book_cate_id}
                      onChange={(e) => {
                        this.onChangeValue('book_cate_id', e.target.value);
                      }}
                    >
                      {list_book_cate}
                    </StyledTextField>
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>ตั้งแต่</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '14px' }}>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={this.state.start_date ? new Date(this.state.start_date) : null}
                      onChange={(date) => {
                        try {
                          if (date != null) {
                            this.setState({start_date: moment(date).format('YYYY-MM-DD')});
                          } else {
                            this.setState({start_date: ''});
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      isClearable={true}
                      onKeyDown={e => e.preventDefault()}
                    />
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>ถึง</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '14px' }}>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={this.state.end_date ? new Date(this.state.end_date) : null}
                      onChange={(date) => {
                        try {
                          if (date != null) {
                            this.setState({end_date: moment(date).format('YYYY-MM-DD')});
                          } else {
                            this.setState({end_date: ''});
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      isClearable={true}
                      onKeyDown={e => e.preventDefault()}
                    />
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>ประเภท</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='doc_type'
                      select
                      margin='normal'
                      value={this.state.doc_type_id}
                      onChange={(e) => {
                        this.onChangeValue('doc_type_id', e.target.value);
                      }}
                    >
                      {list_doc_type}
                    </StyledTextField>
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความลับ</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='secret_type_id'
                      select
                      margin='normal'
                      value={this.state.secret_type_id}
                      onChange={(e) => {
                        this.onChangeValue('secret_type_id', e.target.value)
                      }}                    
                    >
                      {list_master_secret}
                    </StyledTextField>
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>ชั้นความเร็ว</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='speed_type_id'
                      select
                      margin='normal'
                      value={this.state.speed_type_id}
                      onChange={(e) => {
                        this.onChangeValue('speed_type_id', e.target.value)
                      }}
                    >
                      {list_master_speed}
                    </StyledTextField>
                  </div>
                  <StyledLabelRight className='col-md-1 col-form-label'>หน่วยงาน</StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='division'
                      select
                      margin='normal'
                      value={this.state.selected_division}
                      onChange={(e) => {
                        this.onChangeValue('selected_division', e.target.value)
                      }}
                    >
                      {list_division}
                    </StyledTextField>
                    {/* <CustomAutocomplete
                      placeholder='หน่วยงาน/หน่วยงานย่อย'
                      items={this.state.division_data}
                      selected_item={this.state.selected_division}
                      handleSelectItem={this.handleSelectDivision}
                      isMultiLimit
                    ></CustomAutocomplete> */}
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    สถานะ
                  </StyledLabelRight>
                  <div className='col-md-2' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='doc_status_id'
                      select
                      margin='normal'
                      value={this.state.doc_status_id}
                      onChange={(e) => {
                        this.onChangeValue('doc_status_id', e.target.value)
                      }}
                    >
                      {list_doc_status}
                    </StyledTextField>
                  </div>
                  <div className='col-md-6'>
                    <StyledButtonRight>
                      <Button
                        variant='success'
                        onClick={(e) => {
                          this.handleChangePage(1);
                        }}
                      >
                        ค้นหา
                      </Button>
                    </StyledButtonRight>
                  </div>
                </div>
              </form>
            </>
          )}
          <CustomTable
            title='รายการหนังสือ e-form'
            hideSelect={true}
            showManage={true}
            head_rows={head_rows}
            isEForm={true}
            eform_type={this.state.type}
            rows={this.state.table_data || []}
            history={this.props.history}
            linkManage={this.state.link_manage}
            btnManageName={
              <Icon className='flaticon-edit-1' style={{ fontSize: 18 }} />
            }
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            reloadData={this.getData}
            handleChangePage={this.handleChangePage}
            btnManageDisabled={false}
            btnDeleteDisabled={false}
          />
        </div>
      </>
    );
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledLabelLeft = styled.label`
  text-align: left;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  
`;
const StyledLinkButton = styled.i`
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));