import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Builder from './Builder';
import Dashboard from './Dashboard';
import RegisterReceive from './RegisterReceive';
import RegisterReceivePaginate from './RegisterReceivePaginate';
import NewsList from './News';
import NewsViewForm from './NewsViewForm';
import NewsManagement from './NewsManagement';
import NewsAddForm from './NewsAddForm';
import NewsEditForm from './NewsEditForm';
import RoleList from './RoleList';
import RoleAddForm from './RoleAddForm';
import RoleEditForm from './RoleEditForm';
import UserList from './UserList';
import UserListAddForm from './UserListAddForm';
import UserListEditForm from './UserListEditForm';
import Organization from './Organization';
import OrganizationAddForm from './OrganizationAddForm';
import OrganizationEditForm from './OrganizationEditForm';
import Department from './Department';
import DepartmentAddForm from './DepartmentAddForm';
import DepartmentEditForm from './DepartmentEditForm';
import Division from './Division';
import DivisionAddForm from './DivisionAddForm';
import DivisionEditForm from './DivisionEditForm';
import SubDivision from './SubDivision';
import SubDivisionAddForm from './SubDivisionAddForm';
import SubDivisionEditForm from './SubDivisionEditForm';
import EFormBuildForm from './EFormBuildForm';
import EFormList from './EFormList';
import EFormReview from './EFormReview';
import EFormEndorse from './EFormEndorse';
import EFormSendExecutiveSecretary from './EFormSendExecutiveSecretary';
import EFormSendExecutive from './EFormSendExecutive';
import EFormSend from './EFormSend';
import EFormForward from './EFormForward';
import RegisterBook from './RegisterBook';
import RegisterBookEditForm from './RegisterBookEditForm';
import RegisterDocNo from './RegisterDocNo';
import RegisterReceiveAddForm from './RegisterReceiveAddForm';
import RegisterReceiveEditForm from './RegisterReceiveEditForm';
import RegisterReceiveViewForm from './RegisterReceiveViewForm';
import RegisterReceiveSendForwardForm from './RegisterReceiveSendForwardForm';
import RegisterReceiveStoreForm from './RegisterReceiveStoreForm';
import RegisterSend from './RegisterSend';
import RegisterSendPaginate from './RegisterSendPaginate';
import RegisterSendAddForm from './RegisterSendAddForm';
import RegisterSendEditForm from './RegisterSendEditForm';
import RegisterSendViewForm from './RegisterSendViewForm';
import RegisterSendStoreForm from './RegisterSendStoreForm';
import UserProfileEditForm from './UserProfileEditForm';
import ReceiveReport from './ReceiveReport';
import SendReport from './SendReport';
import OperateReport from './OperateReport';
import PersonWorkReport from './PersonWorkReport';
import PersonLogReport from './PersonLogReport';
import SummaryReceiveSendReport from './SummaryReceiveSendReport';
import StatisticsReceiveSendReport from './StatisticsReceiveSendReport';
import StaleReceiveSendReport from './StaleReceiveSendReport';
import CountReceiveSendReport from './CountReceiveSendReport';
import OutsideSendReport from './OutsideSendReport';
import StatisticsSendReport from './StatisticsSendReport';
import RejectSendReport from './RejectSendReport';
import CloseSendReport from './CloseSendReport';
import CancelSendReport from './CancelSendReport';
import OutsideReceiveReport from './OutsideReceiveReport';
import InsideReceiveReport from './InsideReceiveReport';
import Lending from './Lending';
import DocsPage from './docs/DocsPage';
import ReserveDocSendNo from './ReserveDocSendNo';
import ReserveDocSendNoAddForm from './ReserveDocSendNoAddForm';
import UserLog from './UserLog';
import EFormSetting from './EFormSetting';
import { LayoutSplashScreen } from '../../../_metronic';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import StorePaginate from './StorePaginate';
import RegisterReceiveRetire from './RegisterReceiveRetire';
import DivisionDga from './DivisionDga';
import OrgDga from './OrgDga';
import SecretCodesDga from './SecretCodesDga';
import SpeedCodesDga from './SpeedCodesDga';
import MimeCodesDga from './MimeCodesDga';
import TimeCheckDga from './TimeCheckDga';
const GoogleMaterialPage = lazy(() =>
  import('./google-material/GoogleMaterialPage')
);
const ReactBootstrapPage = lazy(() =>
  import('./react-bootstrap/ReactBootstrapPage')
);
function HomePage(props) {
  props.versionCheck();
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /register-receive. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/register-receive" component={RegisterReceive} />
        <Route path="/register-receive/:status" component={RegisterReceive} />
        <Route path="/register-receive-paginate" component={RegisterReceivePaginate} />
        <Route path="/register-receive-add" component={RegisterReceiveAddForm} />
        <Route path="/register-receive-edit/:id" component={RegisterReceiveEditForm} />
        <Route path="/register-receive-view/:id" component={RegisterReceiveViewForm} />
        <Route path="/register-receive-send-forward/:id" component={RegisterReceiveSendForwardForm} />
        <Route path="/register-receive-store/:id" component={RegisterReceiveStoreForm} />
        <Route path="/register-send" component={RegisterSend} />
        <Route path="/register-send/:status" component={RegisterSend} />
        <Route path="/register-send-paginate" component={RegisterSendPaginate} />
        <Route path="/register-send-add" component={RegisterSendAddForm} />
        <Route path="/register-send-edit/:id" component={RegisterSendEditForm} />
        <Route path="/register-send-view/:id" component={RegisterSendViewForm} />
        <Route path="/register-send-store/:id" component={RegisterSendStoreForm} />
        <Route path="/user-profile-edit" component={UserProfileEditForm} />
        {/* report */}
        <Route path="/receive-report" component={ReceiveReport} />
        <Route path="/send-report" component={SendReport} />
        <Route path="/operate-report" component={OperateReport} />
        <Route path="/person-work-report" component={PersonWorkReport} />
        <Route path="/person-log-report" component={PersonLogReport} />
        <Route path="/summary-receive-send-report" component={SummaryReceiveSendReport} />
        <Route path="/statistics-receive-send-report" component={StatisticsReceiveSendReport} />
        <Route path="/stale-receive-send-report" component={StaleReceiveSendReport} />
        <Route path="/count-receive-send-report" component={CountReceiveSendReport} />
        <Route path="/outside-send-report" component={OutsideSendReport} />
        <Route path="/statistics-send-report" component={StatisticsSendReport} />
        <Route path="/reject-send-report" component={RejectSendReport} />
        <Route path="/close-send-report" component={CloseSendReport} />
        <Route path="/cancel-send-report" component={CancelSendReport} />
        <Route path="/outside-receive-report" component={OutsideReceiveReport} />
        <Route path="/inside-receive-report" component={InsideReceiveReport} />
        <Route path="/lending" component={Lending} />
        <Route path="/register-receive-retire-form" component={RegisterReceiveRetire} />
        <Route path="/news" component={NewsList} />
        <Route path="/news-view/:id" component={NewsViewForm} />
        <Route path="/news-management" component={NewsManagement} />
        <Route path="/news-add" component={NewsAddForm} />
        <Route path="/news-edit/:id" component={NewsEditForm} />
        <Route path="/role" component={RoleList} />
        <Route path="/role-add" component={RoleAddForm} />
        <Route path="/role-edit/:id" component={RoleEditForm} />
        <Route path="/user" component={UserList} />
        <Route path="/user-add" component={UserListAddForm} />
        <Route path="/user-edit/:id" component={UserListEditForm} />
        <Route path="/organization" component={Organization} />
        <Route path="/organization-add" component={OrganizationAddForm} />
        <Route path="/organization-edit/:id" component={OrganizationEditForm} />
        <Route path="/department" component={Department} />
        <Route path="/department-add" component={DepartmentAddForm} />
        <Route path="/department-edit/:id" component={DepartmentEditForm} />
        <Route path="/division" component={Division} />
        <Route path="/division-add" component={DivisionAddForm} />
        <Route path="/division-edit/:id" component={DivisionEditForm} />
        <Route path="/sub-division" component={SubDivision} />
        <Route path="/sub-division-add" component={SubDivisionAddForm} />
        <Route path="/sub-division-edit/:id" component={SubDivisionEditForm} />
        {/* e-CMS */}
        <Route path="/division-dga" component={DivisionDga} />
        <Route path="/org-dga" component={OrgDga} />
        <Route path="/secret-codes-dga" component={SecretCodesDga} />
        <Route path="/speed-codes-dga" component={SpeedCodesDga} />
        <Route path="/mime-codes-dga" component={MimeCodesDga} />
        <Route path="/time-check-dga" component={TimeCheckDga} />
        {/* E Form */}
        <Route path="/e-form-build" component={EFormBuildForm} />
        <Route path="/e-form-list" component={EFormList} />
        <Route path="/e-form-review" component={EFormReview} />
        <Route path="/e-form-endorse" component={EFormEndorse} />
        <Route path="/e-form-send-executive-secretary" component={EFormSendExecutiveSecretary} />
        <Route path="/e-form-send-executive" component={EFormSendExecutive} />
        <Route path="/e-form-send" component={EFormSend} />
        <Route path="/e-form-forward" component={EFormForward} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/store-paginate/:page_id" component={StorePaginate} />
        {/* Settings */}
        <Route path="/register-book" component={RegisterBook} />
        <Route path="/register-book-edit/:id" component={RegisterBookEditForm} />
        <Route path="/register-doc-no" component={RegisterDocNo} />
        {/* Division Settings */}
        <Route path="/reserve-doc-send-no" component={ReserveDocSendNo} />
        <Route path="/reserve-doc-send-no-add" component={ReserveDocSendNoAddForm} />
        <Route path="/e-form-setting" component={EFormSetting} />

        <Route path="/user-log" component={UserLog} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
/* const mapStateToProps = state => state.auth; */
export default withRouter(connect(null, auth.actions)(HomePage));