import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'name',
    sortable: true,
    disablePadding: false,
    label: 'ชื่อสิทธิ์การเข้าถึงข้อมูล',
    align: 'center',
  },
  {
    id: 'status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
];
class RoleListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: Const.Table.RowPerPages
    };
  }
  notificationSystem = React.createRef();
  addNotification = (event, level, position) => {
    const { history } = this.props;
    const list_level = ['success', 'warning', 'error', 'info'];
    const list_position = ['tl', 'tr', 'tc', 'bl', 'br', 'bc'];
    var level = list_level[Math.floor(Math.random() * 4)];
    var position = list_position[Math.floor(Math.random() * 6)];
    var position = 'tl';
    event.preventDefault();
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: <span data-notify='icon' className='flaticon2-notification' />,
      message: <div>ทดสอบ แจ้งเตือน</div>,
      action: {
        label: 'คลิก',
        callback: function() {
          history.push('/role-add');
        },
      },
      level: level,
      position: position,
      autoDismiss: 15, // 86400
      onRemove: function(e) {
        console.log('e -->', e);
      },
    });
  };
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangePage = (page) => { this.setState({ page }, () => this.getData()); };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  getData = async () => {
    this.displayAlert('loading');
    const { keyword, page, per_page } = this.state;
    const roles = await API.get(`/console/roles?keyword=${keyword}&page=${page}&size=${per_page}`);
    if (roles.success) {
      let role_data = roles.data;
      _.filter(role_data, (role, key) => {
        role.id = role.role_id;
        role.status = role.status == 1 ? 'เปิดใช้งาน' : 'ปิดการใช้งาน';
      });
      this.setState(
        {
          role_data,
          current_page: roles.current_page,
          last_page: roles.last_page,
        },
        () => MySwal.close()
      );
    }
  };
  componentDidMount() { this.getData(); }

  render() {
    const { ecs_permission } = this.props;
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>สิทธิ์การเข้าถึงข้อมูล</h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              คำสำคัญ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='keyword'
                placeholder='คำสำคัญ'
                onChange={(e) => {
                  this.setData('keyword', e.target.value);
                }}
              />
            </div>
            <div className='col-md-2 offset-md-1'>
              <Button
                variant='success'
                onClick={() => {
                  this.handleChangePage(1);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </form>
        <div
          className='form-group row'
          style={{ marginBottom: 0, paddingLeft: 10 }}
        >
          <StyledButtonLeft className='col-md-6'>
            <Button
              variant='success'
              onClick={() => {
                this.props.history.push('/role-add');
              }}
              disabled={!ecs_permission.users.user_logs.add}
            >
              เพิ่มสิทธิ์การเข้าถึงข้อมูล
            </Button>
          </StyledButtonLeft>
        </div>
        <CustomTable
          title='สิทธิ์การเข้าถึงข้อมูล'
          hideSelect={true}
          showManage={true}
          head_rows={head_rows}
          rows={this.state.role_data ? this.state.role_data : []}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage='/role-edit/'
          btnManageName='แก้ไข'
          btnManageAlign='left'
          linkDelete='/console/roles/'
          btnDeleteName='ลบ'
          btnDeleteAlign='left'
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={!ecs_permission.users.user_logs.edit}
          btnDeleteDisabled={!ecs_permission.users.user_logs.delete}
        />
      </div>
    );
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(RoleListPage));
// export default withRouter(RoleListPage);
