import React from 'react';
import { useHistory } from 'react-router-dom';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import * as routerHelpers from '../../router/RouterHelpers';
import API from '../../api';
import _ from 'lodash';
export const actionTypes = {
  VersionCheck: '[Version Check] Action',
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  DevLogin: '[Dev Login] Action',
  Cache: '[Cache] Action',
};
const initial_state = {
  user: undefined,
  Authorization: undefined,
  cache: {
    topic: [],
    to_name: [],
    from_name: [],
    storage_location: [],
  },
  version: '1.0.3',
};
export const reducer = persistReducer(
  {
    storage,
    key: 'global',
    whitelist: ['user', 'Authorization', 'cache', 'version'],
  },
  (state = initial_state, action) => {
    switch (action.type) {
      case actionTypes.VersionCheck: {
        let new_cache = { ...state.cache };
        let displayed_version = false;
        let topic = [];
        _.filter(new_cache['topic'], (v, k) => {
          if (topic.indexOf(v) == -1) {
            topic.push(v);
          }
        });
        new_cache['topic'] = topic;

        let to_name = [];
        _.filter(new_cache['to_name'], (v, k) => {
          if (to_name.indexOf(v) == -1) {
            to_name.push(v);
          }
        });
        new_cache['to_name'] = to_name;

        let from_name = [];
        _.filter(new_cache['from_name'], (v, k) => {
          if (from_name.indexOf(v) == -1) {
            from_name.push(v);
          }
        });
        new_cache['from_name'] = from_name;

        let storage_location = [];
        _.filter(new_cache['storage_location'], (v, k) => {
          if (storage_location.indexOf(v) == -1) {
            storage_location.push(v);
          }
        });
        new_cache['storage_location'] = storage_location;
        if (!displayed_version) {
          displayed_version = true;
          console.log('Current version -->', state.version);
          console.log('Latest version -->', initial_state.version);
        }
        if (!state.version || state.version != initial_state.version) {
          console.log('Updated version -->', initial_state.version);
          let history = useHistory();
          history.push('/logout');
          return {
            ...initial_state,
            user: state.user,
            Authorization: state.Authorization,
            cache: new_cache,
          };
        } else {
          return { ...state, cache: new_cache };
        }
      }
      case actionTypes.Login: {
        const { Authorization } = action.payload;
        return { Authorization, user: undefined };
      }
      case actionTypes.Register: {
        const { Authorization } = action.payload;
        return { Authorization, user: undefined };
      }
      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return { ...initial_state, cache: state.cache };
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.Cache: {
        const { data } = action.payload;
        let new_cache = { ...state.cache };
        _.filter(data, (v, k) => {
          if (v) {
            if (
              k == 'topic' ||
              k == 'to_name' ||
              k == 'from_name' ||
              k == 'storage_location'
            ) {
              /* const found = _.find(new_cache[k], { label: v });
              if (!found) {
                new_cache[k].push({ label: v });
              } */
              const found = _.find(new_cache[k], v);
              if (!found) {
                /* check not duplicate*/
                if (new_cache[k].indexOf(v) == -1) {
                  new_cache[k].push(v);
                  if (new_cache[k].length > 200) {
                    new_cache[k] = new_cache[k].slice(1);
                  }
                }
              }
            }
          }
        });
        return { ...state, cache: new_cache };
      }
      default:
        return state;
    }
  }
);
export const actions = {
  versionCheck: () => ({ type: actionTypes.VersionCheck }),
  login: (Authorization) => ({
    type: actionTypes.Login,
    payload: { Authorization },
  }),
  register: (Authorization) => ({
    type: actionTypes.Register,
    payload: { Authorization },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  devLogin: () => ({ type: actionTypes.DevLogin }),
  setCache: (data) => ({
    type: actionTypes.Cache,
    payload: { data },
  }),
};
export function* saga() {
  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield API.get('/console/users/profile');
    yield put(actions.fulfillUser(user));
  });
  yield takeLatest(actionTypes.Logout, function* loggedOut() {
    yield localStorage.clear();
  });
  yield takeLatest(actionTypes.DevLogin, function* loggedOut() {
    yield put(
      actions.fulfillUser({
        prefix: 'นาย',
        fname: 'ซัน',
        sname: 'เดฟ',
      })
    );
  });
  /* yield put(actions.versionCheck()); */
}
