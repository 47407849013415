import React from 'react';
import RegisterReceiveRetireFormPage from '../register-receive-retire-form/RegisterReceiveRetireFormPage';
export default function Function() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterReceiveRetireFormPage />
        </div>
      </div>
    </>
  );
}
