import { Button, Modal } from 'react-bootstrap';

import CustomAutocomplete from './CustomAutocomplete';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import styled from 'styled-components';

class SendMoreDivisionModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,

      division: this.props.division,
      sub_division: this.props.sub_division,
      internal_division: this.props.internal_division,
      department: this.props.department,
      division_data: this.props.division_data,

      select_ecms: this.props.select_ecms,

      selected_items: [],
      to_text: null
    };
    this.handleShow = () => {
      this.setState({ show: true, selected_items: [], to_text: null });
    };
    this.handleHide = () => {
      this.setState({ show: false, selected_items: [], to_text: null });
    };
  }

  handleTypeToText = () => {
    this.setState({
      select_ecms: false,
      to_text: null,
      selected_items: [],
    });
  };
  handleSelectDivisions = (selected_items) => this.setState({ selected_items });
  handleSelectAllDivisions = () => {
    const new_selected = [];
    const { selected_items } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_items, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected.push(v1);
      }
    });

    let merge_divisions = (selected_items == null) ? new_selected : selected_items.concat(new_selected);
    this.setState({ selected_items: merge_divisions });
  };
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_items } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_items, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_items == null) ? new_selected_sub_divisions : selected_items.concat(new_selected_sub_divisions);
    this.setState({ selected_items: merge_divisions });
  };
  handleSelectAllInternalDivisions = () => {
    const new_selected_internal_divisions = [];
    const { selected_items } = this.state;
    _.filter(this.state.internal_division, (v1) => {
      let found = false;
      _.filter(selected_items, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_internal_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_items == null) ? new_selected_internal_divisions : selected_items.concat(new_selected_internal_divisions);
    this.setState({ selected_items: merge_divisions });
  };
  handleSelectAllDepartments = () => {
    const new_selected_departments = [];
    _.filter(this.state.department, (v1) => { new_selected_departments.push(v1); });
    this.setState({
      select_ecms: true,
      selected_items: new_selected_departments,
      to_text: null,
    });
  };

  onChangeValue = (key, value) => this.setState({ [key]: value });

  render() { 
    const { id, docs_id, type, displayAlert } = this.props;
    const { show } = this.state;
    
    return (
      <>
        <Button variant='success' style={{ marginLeft: '5px' }} onClick={this.handleShow}>
          เพิ่มหน่วยงาน
        </Button>
        <Modal show={show} onHide={this.handleHide} dialogClassName='modal-xl' aria-labelledby='example-custom-modal-styling-title'>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
            เพิ่มหน่วยงาน
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <div className='offset-2 col-md-2'>
                <Radio
                  checked={type == '1'}
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': '1' }}
                  disabled={true}
                />
                  หน่วยงานภายใน
              </div>
              <div className='col-md-3'>
                <Radio
                  checked={type == '2'}
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': '2' }}
                  disabled={true}
                />
                  หน่วยงานภายนอก
              </div>
            </div>
            {type == '1' ? (
              <>
                <div div className='form-group row'>
                  <div className='col-md-2'></div>
                  <Button variant='success' onClick={this.handleSelectAllDivisions} style={{ marginLeft: '10px' }}>
                    ส่งทุกหน่วยงาน
                  </Button>
                  <Button variant='success' onClick={this.handleSelectAllSubDivisions} style={{ marginLeft: '10px' }}>
                    ส่งทุกหน่วยงานย่อย
                  </Button>
                  <Button variant='success' onClick={this.handleSelectAllInternalDivisions} style={{ marginLeft: '10px' }}>
                    ส่งหน่วยงานส่วนกลาง
                  </Button>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เพิ่มหน่วยงานส่งถึง
                  </StyledLabelRight>
                  <div className='col-md-9'>
                    <CustomAutocomplete
                      isMulti
                      placeholder='หน่วยงาน/หน่วยงานย่อย'
                      items={this.state.division_data}
                      selected_items={this.state.selected_items}
                      handleSelectItems={this.handleSelectDivisions}
                    ></CustomAutocomplete>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='form-group row' style={{ display: 'none' }}>
                  <div className='col-md-2'></div>
                  <Button variant='success' onClick={this.handleTypeToText} style={{ marginLeft: '10px' }}>
                    พิมพ์ชื่อหน่วยงาน
                  </Button>
                  <Button
                    variant='success'
                    onClick={() =>
                      this.setState({
                        select_ecms: true,
                        selected_items: [],
                        to_text: null,
                      })
                    }
                    style={{ marginLeft: '10px' }}
                  >
                    ส่งหน่วยงาน e-CMS
                  </Button>
                  <Button variant='success' onClick={this.handleSelectAllDepartments} style={{ marginLeft: '10px' }}>
                    ส่งหน่วยงาน e-CMS ทั้งหมด
                  </Button>
                </div>
                {this.state.select_ecms ? (
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                      *เพิ่มหน่วยงานส่งถึง
                    </StyledLabelRight>
                    <div className='col-md-9'>
                      <CustomAutocomplete
                        isMulti
                        placeholder='หน่วยงาน/หน่วยงานย่อย'
                        items={this.state.department}
                        selected_items={this.state.selected_items}
                        handleSelectItems={this.handleSelectDivisions}
                      ></CustomAutocomplete>
                    </div>
                  </div>
                ) : (
                  <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                      *เพิ่มหน่วยงานส่งถึง
                    </StyledLabelRight>
                    <div className='col-md-9'>
                      <StyledTextField
                        id='standard-name'
                        placeholder=''
                        value={this.state.to_text}
                        onChange={({ target: { value } }) => this.onChangeValue('to_text', value)}
                        inputProps={{ maxLength: 255 }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <div className='form-group row'>
            <StyledButtonRight className='col-md-6'>
              <Button
                style={{ margin: 5 }}
                variant='success'
                onClick={() => {
                  this.handleHide();
                  displayAlert('send-more-division', {
                    id: id,
                    docs_id: docs_id,
                    type: type,
                    select_ecms: this.state.select_ecms,
                    selected_items: this.state.selected_items,
                    to_text: this.state.to_text
                  });
                }}
              >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button style={{ margin: 5 }} variant='danger' onClick={this.handleHide}>
                ยกเลิก
              </Button>
            </StyledButtonLeft>
          </div>
        </Modal>
      </>
    )
  }
}
const StyledTextField = styled(TextField)`
    margin-left: 8;
    margin-right: 8;
    width: 100%;
    .MuiOutlinedInput-multiline {
        padding: 10px 7px;
        line-height: 1.6;
    }
    .MuiOutlinedInput-inputMultiline {
        padding-top: 10px;
    }
    .makeStyles-container-126 {
        margin-top: -15px;
    }
    .MuiInputBase-input {
        padding-top: 10px;
    }
`;
const StyledLabelRight = styled.label`
    text-align: right;
    color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default SendMoreDivisionModal;
