import React from 'react';
import RegisterSendViewFormPage from '../register-send-view-form/RegisterSendViewFormPage';
export default function Function() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterSendViewFormPage />
        </div>
      </div>
    </>
  );
}
