/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import API from '../api';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import moment from 'moment';
import 'moment/locale/th';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import Const from '../variables/Const';
moment.locale('th');

class Class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'doc',
        };
    }
    handleOpenDialog = async () => {
        await API.get(`/console/eform/${this.props.eform_trans_id}?type=${this.props.eform_type}`).then(({ success, data }) => {
            if (success) {
                this.setState({
                    open: true,
                    ...data,
                });
            }
        });
    };
    
    replaceEmpty = (txt, suffix) => (!!txt ? txt + (suffix ? ' ' + suffix : '') : '-');

    downloadDocFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/eform/doc/' + id, file_name);
    }

    downloadAttachFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/eform/attach/' + id, file_name);
    }

    downloadFile = async (event, url, file_name) => {
        try {
            const resp = await API.postDownloadFile(url, null, 'header');
            const file = new Blob([resp.data], {
                type: resp.headers['content-type'],
            });
            const fileURL = URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = file_name;
            fileLink.click();
        } catch (err) {
            console.log('error::');
            console.log(err);
        }
    }

    render() {
        const {
            attaches,
            reference_doc,
          } = this.state;
      
        const has_attach = (attaches && attaches.length > 0);
        const has_ref_doc = (reference_doc && reference_doc.length > 0);

        return (
            <>
                <a onClick={this.handleOpenDialog}>{this.props.children}</a>
                <Dialog maxWidth='lg' fullWidth onEntering={() => null} aria-labelledby='confirmation-dialog-title' open={this.state.open}>
                    <DialogTitle id='confirmation-dialog-title'>
                        หนังสือ
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'doc' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'doc' });
                            }}
                        >
                            Doc
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'file' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'file' });
                            }}
                        >
                            File
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'transaction' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'transaction' });
                            }}
                        >
                            Transaction
                        </Button>
                    </DialogTitle>
                    {this.state.type == 'doc' && (
                        <DialogContent dividers>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>ทะเบียนรับ/ส่ง:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.book_cate_name)}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>เลขที่หนังสือ:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.doc_no)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>เรื่อง:</StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{(_.isEmpty(this.state.topic) ? this.state.announcement_text : this.state.topic)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>เรียน:</StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.replaceEmpty(this.state.to_name)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>ปี พ.ศ.:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.year_th)}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>ลงวันที่:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.date_at_txt)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>ชั้นความเร็ว:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.speed_type_name)}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>ชั้นความลับ:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.secret_type_name)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>ประเภทหนังสือ:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.doc_type_name)}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>เลขทะเบียนรับ:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.receive_no)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>จำนวน:</StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.replaceEmpty(this.state.page, 'แผ่น')}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>สิ่งที่ส่งมาด้วย:</StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.replaceEmpty(this.state.detail)}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>หนังสืออ้างอิง:</StyledLabel>
                                <div className='col-md-6'>
                                    {has_ref_doc === true ? (
                                    <ul style={{listStyleType: 'number', marginTop: '12px', paddingLeft: '18px'}}>
                                        {_.map(reference_doc, (item) => (
                                        <li>{(!!item.topic ? item.topic + ' ' : '') + 'ที่ ' + (!!item.doc_no ? item.doc_no + ' ' : '') + 'ลงวันที่ ' + item.date_at_txt_th_long}</li>
                                        ))}
                                    </ul>
                                    ) : (
                                        <StyledLabel className='col-form-label'>-</StyledLabel>
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>หมายเหตุ:</StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.replaceEmpty(this.state.remark)}</StyledLabel>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'file' && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 col-form-label'>ไฟล์หนังสือ:</StyledLabel>
                                    <div class="col-md-10">
                                        {this.state.file_name ? (
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                this.downloadDocFile(e, this.state.id, this.state.file_name);
                                            }}>
                                                <StyledUploadedFile title={this.state.file_name} src={toAbsoluteUrl(`/media/files/${this.state.file_name.split('.').pop().toLowerCase()}.svg`)} style={{marginTop: '10px'}}/>
                                            </a>
                                        ) : (
                                            <StyledLabel className='col-form-label'>-</StyledLabel>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 col-form-label'>สิ่งที่ส่งมาด้วย:</StyledLabel>
                                    <div class="col-md-10">
                                        {has_attach === true ? (
                                            <ul className="m-0 p-0" style={{ listStyle:'none' }}>
                                                {_.map(attaches, (v, k) => {
                                                    return (
                                                        <li>
                                                            <a onClick={(e) => {
                                                                e.preventDefault();
                                                                this.downloadAttachFile(e, v.eform_attach_id, v.file_name);
                                                            }}>
                                                                <StyledUploadedFile title={v.file_name + '.' + v.file_type} src={toAbsoluteUrl(`/media/files/${v.file_type.toLowerCase()}.svg`)} />
                                                            </a>
                                                            <StyledLabel className='col-10 ml-2 col-form-label'>
                                                                {v.created_at_txt}
                                                                {v.uploader_division ? ` หน่วยงาน: ${v.uploader_division}` : null}
                                                                {v.uploader_by ? `, ${v.uploader_by}` : null}
                                                            </StyledLabel>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <StyledLabel className='col-form-label'>-</StyledLabel>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'transaction' && this.state.log && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <PerfectScrollbar
                                    options={{
                                        wheelSpeed: 2,
                                        wheelPropagation: false,
                                    }}
                                    style={{
                                        maxHeight: '50vh',
                                        position: 'relative',
                                    }}
                                >
                                    <div className='kt-timeline-v2 ps ps--active-y'>
                                        <div className='kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30'>
                                            {_.map(this.state.log, (v) => {
                                                const is_send_trans = (v.doc_status_id != Const.MasterData.EFormDocStatus.Receive);
                                                let scrollbar_color = '';
                                                let log_created = '';
                                                let log_status = '';
                                                let order_remark = null;
                                                let remark = null;
                                                let action = null;
                                                if (is_send_trans === true) {
                                                    scrollbar_color = 'kt-font-info';
                                                    log_created = v.created_at_txt;
                                                    log_status = v.doc_status_name;
                                                    if (!!v.receive_no) {
                                                        log_status += ', เลขทะเบียนรับ: ' + v.receive_no;
                                                    }
                                                    if (!!v.send_no) {
                                                        log_status += ', เลขทะเบียนส่ง: ' + v.send_no;
                                                    }
                                                    log_status += ', ปี พ.ศ. ' + v.year_th;
                                                    log_status += ', ' + v.user;
                                                    order_remark = v.order_remark;
                                                    remark = v.remark;

                                                    if (!!v.action) {
                                                        if (v.action === 'approve') {
                                                            action = 'อนุมัติ';
                                                        } else if (v.action === 'reject') {
                                                            action = 'ไม่อนุมัติ';
                                                        } 
                                                    }
                                                } else {
                                                    scrollbar_color = 'kt-font-success';
                                                }

                                                return (
                                                    <div className='kt-timeline-v2__item'>
                                                        <span
                                                            className='kt-timeline-v2__item-time'
                                                            style={{
                                                                width: '10rem',
                                                            }}
                                                        >
                                                            {log_created}
                                                        </span>
                                                        <div
                                                            className='kt-timeline-v2__item-cricle'
                                                            style={{
                                                                borderRadius: '50%',
                                                                border: '0.89rem solid white',
                                                                zIndex: 1,
                                                                top: '0.2rem',
                                                                left: '10.12rem',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <i
                                                                className={'fa fa-genderless ' + scrollbar_color}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'table-cell',
                                                                verticalAlign: 'top',
                                                                fontSize: '1rem',
                                                                padding: '0.35rem 0 0 12rem',
                                                                color: '#74788d',
                                                            }}
                                                        >
                                                            {log_status}
                                                            {v.division && is_send_trans === true && <br />}
                                                            {v.division ? `หน่วยงาน: ${v.division}` : null}
                                                            {order_remark && <br />}
                                                            {order_remark ? `คำสั่งการ: ${order_remark}` : null}
                                                            {remark && <br />}
                                                            {remark ? `หมายเหตุ: ${remark}` : null}
                                                            {v.action && <br />}
                                                            {v.action ? `${action}, ${v.action_user} ${v.action_at_txt}` : null}
                                                        </div>
                                                        <div className='kt-list-pics kt-list-pics--sm kt-padding-l-20' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <ButtonBootstrap
                            variant='success'
                            onClick={() => {
                                this.setState({ open: false });
                            }}
                        >
                            ตกลง
                        </ButtonBootstrap>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const StyledLabel = styled.label`
    text-align: ${(props) => (props.center ? 'center' : props.right ? 'right' : 'left')};
    white-space: pre-wrap;
    ${'' /* color: ${props => props.color || '#646c9a'}; */}
`;
const StyledUploadedFile = styled.img`
    width: 36px;
    padding-right: 0.5rem;
`;
export default Class;