import React from 'react';
import UserListEditFormPage from '../user-list-edit-form/UserListEditFormPage';
export default function UserListEditForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <UserListEditFormPage />
        </div>
      </div>
    </>
  );
}
