import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';

const MySwal = withReactContent(Swal);

class EFormSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.stampImageInputRef = React.createRef();
    this.state = {
      remove_stamp_image: false,
      stamp_color: 'BLUE',
    };
  }

  setData = (key, value) => {
    this.setState({[key]: value});
    if (key == 'stamp_image') {
      this.setState({ stamp_image_path: (value !== null ? URL.createObjectURL(value) : '') });
    }
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(({ value: result }) => {
          if (result) {
            window.location.reload(false);
          }
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  setMetaData = async () => {
    let can_save = false;
    let organization_name = '-';
    let department_name = '-';
    let division_name = '-';
    let sub_division_name = '-';

    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      if (!!profile_req.data.organization) {
        organization_name = this.replaceEmpty(profile_req.data.organization.name);
      }

      if (!!profile_req.data.department) {
        department_name = this.replaceEmpty(profile_req.data.department.name);
      }

      if (!!profile_req.data.division) {
        can_save = true;
        division_name = this.replaceEmpty(profile_req.data.division.name);
      }

      if (!!profile_req.data.sub_division) {
        can_save = true;
        sub_division_name = this.replaceEmpty(profile_req.data.sub_division.name);
      }

      this.setState({
        organization_name,
        department_name,
        division_name,
        sub_division_name,
        can_save,
      });
    }

    const stamp_color_req = await API.get('/console/static/eform-stamp-color/all');
    if (stamp_color_req.success) {
      this.setState({
        stamp_color_data: stamp_color_req.data,
      });
    }

    if (can_save) {
      this.getData();
    }
  }

  getData = async () => {
    this.displayAlert('loading');
    try {
      const request = await API.get('/console/eform-setting');
      if (request.success) {
        if (!!request.data) {
          this.setState({
            header_text: request.data.header_text,
            footer_text: request.data.footer_text,
            stamp_division_name: request.data.stamp_division_name,
            stamp_color: request.data.stamp_color,
            stamp_image: request.data.stamp_image,
            stamp_image_path: request.data.stamp_image,
          }, () => { MySwal.close(); });
        } else {
          MySwal.close();
        }
      } else {
        this.displayAlert('error', request.error);
      }
    } catch (e) { 
      this.displayAlert('error');
    }
  };

  onSave = async () => {
    this.displayAlert('loading');

    const {
      header_text,
      footer_text,
      stamp_division_name,
      stamp_color,
      stamp_image,
      remove_stamp_image,
    } = this.state;
    const data = {
      header_text,
      footer_text,
      stamp_division_name,
      stamp_color,
      stamp_image,
      remove_stamp_image,
    };
    let form_data = new FormData();
    _.filter(data, (v, k) => {
      if (v && v != '') {
        if (k == 'stamp_image') {
          form_data.append(k, v);
        } else {
          form_data.set(k, v);
        }
      }
    });
    try {
      const request = await API.post(
        '/console/eform-setting',
        form_data
      );

      if (request.success) {
        this.displayAlert('success');
      } else {
        this.displayAlert('error', request.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
  };

  componentDidUpdate(prev_props, prev_state) {
  }

  componentDidMount() {
    this.setMetaData();
  }

  render() {
    const {
      organization_name,
      department_name,
      division_name,
      sub_division_name,
      header_text,
      footer_text,
      stamp_division_name,
      stamp_color,
      stamp_color_data,
      can_save,
    } = this.state;

    let list_stamp_color = _.map(stamp_color_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });

    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>ตั้งค่า e-Form</h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              กระทรวง
            </StyledLabelRight>
            <StyledLabelLeft className='col-md-3 col-form-label'>
              {organization_name}
            </StyledLabelLeft>
            
            <StyledLabelRight className='col-md-2 col-form-label'>
              กรม
            </StyledLabelRight>
            <StyledLabelLeft className='col-md-3 col-form-label'>
              {department_name}
            </StyledLabelLeft>
          </div>

          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              หน่วยงาน
            </StyledLabelRight>
            <StyledLabelLeft className='col-md-3 col-form-label'>
              {division_name}
            </StyledLabelLeft>

            <StyledLabelRight className='col-md-2 col-form-label'>
              หน่วยงานย่อย
            </StyledLabelRight>
            <StyledLabelLeft className='col-md-3 col-form-label'>
              {sub_division_name}
            </StyledLabelLeft>
          </div>

          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
                ข้อความ Header เจ้าของเรื่อง
            </StyledLabelRight>
            <div className='col-md-3'>
              <StyledTextField
                  id='header_text'
                  value={header_text}
                  placeholder=''
                  multiline
                  rows='3'
                  margin='normal'
                  variant='outlined'
                  disabled={!can_save}
                  onChange={(e) => {
                      this.onChangeValue('header_text', e.target.value);
                  }}
                  inputProps={{maxLength: 255}}
                />
            </div>

            <StyledLabelRight className='col-md-2 col-form-label'>
              ข้อความ Footer เจ้าของเรื่อง
            </StyledLabelRight>
            <div className='col-md-3'>
              <StyledTextField
                  id='footer_text'
                  value={footer_text}
                  placeholder=''
                  multiline
                  rows='3'
                  margin='normal'
                  variant='outlined'
                  disabled={!can_save}
                  onChange={(e) => {
                      this.onChangeValue('footer_text', e.target.value);
                  }}
                  inputProps={{maxLength: 255}}
                />
            </div>
          </div>

          <div className='form-group row'>
              <StyledLabelRight
                  className='col-md-2 col-form-label' color={(can_save ? 'red' : 'gray')}>
                  *ชื่อหน่วยงานลงเลขรับ
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='stamp_division_name'
                  value={stamp_division_name}
                  placeholder=''
                  multiline
                  rows='3'
                  margin='normal'
                  variant='outlined'
                  disabled={!can_save}
                  onChange={(e) => {
                      this.onChangeValue('stamp_division_name', e.target.value);
                  }}
                  inputProps={{maxLength: 255}}
                />
              </div>

              <StyledLabelRight className='col-md-2 col-form-label' color={(can_save ? 'red' : 'gray')}>
                *สีลงเลขรับ
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='stamp_color'
                  select
                  value={stamp_color}
                  disabled={!can_save}
                  onChange={(e) => {
                    this.setData('stamp_color', e.target.value);
                  }}
                >
                  {list_stamp_color}
                </StyledTextField>
              </div>
          </div>

          <div className="form-group row">
            <StyledLabelRight className="col-md-2 col-form-label">
              รูปภาพตราประทับ
            </StyledLabelRight>
            <div className="col-md-8" style={{ marginTop: '8px' }}>
              <input
                accept="image/png"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                disabled={!can_save}
                onChange={({ target: { files } }) =>
                  this.setData('stamp_image', files[0])
                }
                ref={this.stampImageInputRef}
              />
              <label htmlFor="contained-button-file" style={{float: 'left'}}>
                <Button variant="contained" component="span" disabled={!can_save}>
                  เลือกไฟล์
                </Button>
              </label>
              {this.state.stamp_image_path && (
                <div style={{ marginLeft: '12px', float: 'left' }}>
                  <div style={{ float: 'left' }}>
                    <StyledUploadedFile
                      alt="Stamp"
                      style={{ width: 120, height: 'auto' }}
                      src={this.state.stamp_image_path}
                    />
                  </div>
                  <a onClick={(e) => {
                      e.preventDefault();
                      this.setData('stamp_image', null);
                      this.setData('remove_stamp_image', true);
                      this.stampImageInputRef.current.value = '';
                    }}
                    style={{marginLeft: '8px', float: 'left'}}
                  >
                    <i className='flaticon2-cancel-music icon-xs text-danger' />
                  </a>
                </div>
              )}
              <div style={{ marginLeft: '24px', float: 'left' }}>
                <div>
                  <StyledLabelLeft style={{ color: 'red', marginBottom: 0 }}>หมายเหตุ</StyledLabelLeft>
                  <ol className="m-0 pl-3" style={{ color: 'red' }}>
                    <li>นามสกุล .png เท่านั้น</li>
                    <li>พื้นหลังของภาพต้องเป็น Transparent</li>
                    <li>ขนาดไฟล์ต้องไม่เกิน 2 MB</li>
                    <li>อัตราส่วนภาพแนะนำ 1:1</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='form-group row' style={{ marginBottom: 0 }}>
          <StyledButtonRight className='col-md-7'>
            <ButtonBootstrap
              variant='info'
              disabled={!can_save}
              onClick={() => this.displayAlert('save')}
            >
              บันทึก
            </ButtonBootstrap>
          </StyledButtonRight>
        </div>
        <br />
      </div>
    );
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledButtonCenter = styled.div`
  text-align: center;
  margin-top: 6px;
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledUploadedFile = styled.img`
  width: 36px;
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(EFormSettingPage));