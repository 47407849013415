import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import EndorserDialog from '../../widgets/EndorserDialog';
import EFormInfo from '../../widgets/EFormInfo';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
      is_init_flow: true,
    };
  }

  setSelectedEndorser = (endorser_id, endorser_name, endorser_position) => {
    this.setState({ 
      endorser_id: endorser_id,
      selected_endorser_name: (!!endorser_name ? endorser_name : ''), 
      endorser_name: (!!endorser_name ? endorser_name : ''),
      endorser_position: (!!endorser_position ? endorser_position : '') 
    });
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeDivision = (value) => {
    let endorser_division_id = null;
    let endorser_sub_division_id = null;
    let endorser_division_name = '';
    let endorser_sub_division_name = '';
    if (value.includes('main_')) {
      const mains = value.split('_');
      endorser_division_id = mains[1];
    } else if (value.includes('sub_')) {
        const subs = value.split('_');
        endorser_division_id = subs[2];
        endorser_sub_division_id = subs[1];
    }
    
    const selected_division = _.filter(this.state.division_data, (v, k) => v.value == 'main_' + endorser_division_id.toString());
    endorser_division_name = selected_division[0].name;
    if (endorser_sub_division_id != null) {
      const selected_sub_division = _.filter(this.state.division_data, (v, k) => v.value == 'sub_' + endorser_sub_division_id.toString() + '_' + endorser_division_id.toString());
      endorser_sub_division_name = selected_sub_division[0].name;
    }

    this.setState({
      selected_division_id: value,
      endorser_division_id,
      endorser_sub_division_id,
      endorser_division_name,
      endorser_sub_division_name,
      selected_endorser_name: '',
      endorser_id: null,
      endorser_name: '',
      endorser_position: '',
    });
  }

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการส่งผู้บริหารลงนาม?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/e-form-list/t5?type=wait-send-executive-secretary');
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t5?type=wait-send-executive-secretary');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;
      
  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-send-executive-secretary/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res.success) {
        const division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
          name: v.raw_name,
        }));

        const sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id + '_' + v.division_id,
          label: v.name,
          name: v.raw_name,
          division_value: 'main_' + v.division_id,
        }));

        let division_data = [];
        _.forEach(division, (d) => {
          division_data.push(d);
          division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
        });

        this.setState({ division_data }, 
        async () => {
          await this.getData();
        });
      }
    });

    this.setState({
      finished_init_page: true,
      display_loading: false,
    });
  }

  getData = async () => {
    this.displayAlert('loading');
    const {
      id
    } = this.state;

    let is_init_flow = true;
    let eform_data = null;
    let selected_division_id = '';
    let selected_endorser_name = '';
    let endorser_division_id = null;
    let endorser_division_name = '';
    let endorser_sub_division_id = null;
    let endorser_sub_division_name = '';
    let endorser_id = null;
    let endorser_name = '';
    let endorser_position = '';

    await API.get('/console/eform/' + id + '/send_executive_secretary').then((res) => {
      if (res && res.success) {
        eform_data = res.data;
        is_init_flow = (eform_data.receive_no === null);
        if (is_init_flow === true)
        {
          endorser_division_id = eform_data.endorser_division_id;
          endorser_sub_division_id = eform_data.endorser_sub_division_id;
          endorser_id = eform_data.endorser_id;
          endorser_name = eform_data.endorser_name;
          endorser_position = eform_data.endorser_position;

          if (eform_data.endorser_sub_division_id != null) {
            selected_division_id = 'sub_' + eform_data.endorser_sub_division_id + '_' + eform_data.endorser_division_id;
          } else {
            selected_division_id = 'main_' + eform_data.endorser_division_id;
          }
  
          const selected_division = _.filter(this.state.division_data, (v, k) => v.value == 'main_' + eform_data.endorser_division_id.toString());
          endorser_division_name = selected_division[0].name;
          if (eform_data.endorser_sub_division_id != null) {
            const selected_sub_division = _.filter(this.state.division_data, (v, k) => v.value == 'sub_' + eform_data.endorser_sub_division_id.toString() + '_' + eform_data.endorser_division_id.toString());
            endorser_sub_division_name = selected_sub_division[0].name;
          }
        }
      } else {
        this.displayAlert('error', res.error);
      }
    });

    if (is_init_flow === true && eform_data !== null && eform_data.endorser_id !== undefined && eform_data.endorser_id != null) {
      await API.get(`/console/users/profile?id=${eform_data.endorser_id}`).then((res) => {
        if (res.success) {
          selected_endorser_name = res.data.fullname;
        }
      });
    }

    this.setState(
      {
        is_init_flow,
        eform_data,
        selected_division_id,
        endorser_division_id,
        endorser_division_name,
        endorser_sub_division_id,
        endorser_sub_division_name,
        endorser_id,
        endorser_name,
        endorser_position,
        selected_endorser_name,
      }, MySwal.close()
    );
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        action: action,
        endorser_id: this.state.endorser_id,
        endorser_name: this.state.endorser_name,
        endorser_position: this.state.endorser_position,
        additional_remark: this.state.additional_remark
      }
      
      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/send_executive_secretary', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
      division_data,
      is_init_flow,
    } = this.state;

    if (finished_init_page === true) {
      const list_division = _.map(division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.value}>
            {value.label}
          </MenuItem>
        );
      });

      return (
        <div>
          {this.state.eform_data && (<EFormInfo eform_data={this.state.eform_data}></EFormInfo>)}
  
          {this.state.eform_data && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    ส่งผู้บริหารลงนาม
                  </h3>
                </div>
              </div>
              <br />
              <form className='approveForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*หน่วยงาน</StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      id='division'
                      select
                      margin='normal'
                      value={this.state.selected_division_id}
                      onChange={(e) => {
                        this.onChangeDivision(e.target.value);
                      }}
                      disabled={is_init_flow}
                    >
                      {list_division}
                    </StyledTextField>
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ผู้ลงนาม</StyledLabelRight>
                  <div className='col-md-9'>
                      {!_.isEmpty(this.state.selected_endorser_name) &&
                        <div style={{float: 'left'}}>
                          <StyledLabelLeft className='col-form-label' style={{marginRight: '12px'}}>{this.state.selected_endorser_name}</StyledLabelLeft>
                        </div>
                      }
                    <div style={{float: 'left', paddingTop: '6px'}}>
                      {/* { this.state.selected_division_id && (
                      <EndorserDialog
                        division_name={this.state.endorser_division_name}
                        sub_division_name={this.state.endorser_sub_division_name}
                        division_id={this.state.endorser_division_id}
                        sub_division_id={this.state.endorser_sub_division_id}
                        setSelected={this.setSelectedEndorser}
                        role='executive'
                        disabled={is_init_flow}/>)} */}
                      <EndorserDialog
                        division_name={this.state.endorser_division_name}
                        sub_division_name={this.state.endorser_sub_division_name}
                        division_id={this.state.endorser_division_id}
                        sub_division_id={this.state.endorser_sub_division_id}
                        setSelected={this.setSelectedEndorser}
                        role='executive'
                        disabled={is_init_flow}/>
                    </div>
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ชื่อ-นามสกุล</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='endorser_name'
                      placeholder=''
                      value={this.state.endorser_name}
                      onChange={(e) => {
                        this.onChangeValue('endorser_name', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                      disabled={is_init_flow}
                    />
                  </div>
                </div>  
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ตำแหน่ง</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='endorser_position'
                        value={this.state.endorser_position}
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('endorser_position', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                        disabled={is_init_flow}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุเพิ่มเติม<br/>(ไม่แสดงในหนังสือ)</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='additional_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('additional_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() => this.displayAlert('approve')}
                    >
                      ส่งผู้บริหารลงนาม
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='danger'
                      style={{ marginLeft: '5px' }}
                      onClick={() => this.displayAlert('back')}
                    >
                      กลับ
                    </ButtonBootstrap>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelLeft = styled.label`
  text-align: left;
  white-space: pre-wrap;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));