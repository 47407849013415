import { Button, Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

class ChangeSendDateModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      
      date_at: this.props.date_at,

      change_to: null,
    };
    this.handleShow = () => {
      this.setState({ show: true, change_to: null });
    };
    this.handleHide = () => {
      this.setState({ show: false, change_to: null });
    };
  }
  
  render() { 
    const { id, docs_id, date_at, displayAlert } = this.props;
    const { show } = this.state;
    
    return (
      <>
        <Button variant='info' style={{ marginLeft: '5px' }} onClick={this.handleShow}>
          แก้ไขวันที่
        </Button>
        <Modal show={show} onHide={this.handleHide} dialogClassName='modal-90w' aria-labelledby='example-custom-modal-styling-title'>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              แก้ไข ลงวันที่
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label'>
                ลงวันที่
              </StyledLabelRight>
              <div className='col-md-6' style={{  }}>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={date_at ? new Date(date_at) : null}
                  onKeyDown={e => e.preventDefault()}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-5 col-form-label' color='red'>
                *แก้ไขเป็น
              </StyledLabelRight>
              <div className='col-md-6'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={this.state.change_to ? new Date(this.state.change_to) : null}
                  onChange={date => {
                    try {
                      this.setState({ change_to: date ? moment(date).format('YYYY-MM-DD') : null });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onKeyDown={e => e.preventDefault()}
                />
              </div>
            </div>
            </Modal.Body>
            <div className='form-group row'>
              <StyledButtonRight className='col-md-6'>
                <Button
                style={{ margin: 5 }}
                variant='success'
                onClick={() => {
                  this.handleHide();
                  displayAlert('change-send-date', {
                    id: id,
                    docs_id: docs_id,
                    date_at: date_at,
                    change_to: this.state.change_to,
                  });
                }}
                >
                บันทึก
              </Button>
            </StyledButtonRight>
            <StyledButtonLeft className='col-md-6'>
              <Button style={{ margin: 5 }} variant='danger' onClick={this.handleHide}>
                ยกเลิก
              </Button>
            </StyledButtonLeft>
            </div>
        </Modal>
      </>
    )
  }
}
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 1% !important;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 1%; !important;
  margin-top: 0px;
`;
export default ChangeSendDateModal;
