import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import 'moment/locale/th';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';

const MySwal = withReactContent(Swal);
class RegisterDocNoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query_string: null,
      trans_type: 0,
      trans_type_text: '',
      show_update_section: false,

      book_cate_data: [],
      division_data: [],
      sub_division_data: [],

      year: moment().year() + 543,
      book_cate_id: '',
      division_id: '',
      sub_division_id: '',

      running: '',
      running_data: {}
    };
  }

  setData = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');
  onNumerInputKeyDown = (event) => {
    if(event.key === "e" || event.key === "+" || event.key === "-" || event.key === ".") event.preventDefault();
  }

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const trans_type = this.unicodeToChar(url_params.get('trans_type'));

    this.setState(
      {
        query_string,
        trans_type
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'save': {
        MySwal.fire({
          title: 'ต้องการแก้ไข?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  setMetaData = async () => {
    let trans_type_text = '';
    switch (this.state.trans_type) {
      case '1':
        trans_type_text = 'รับ'
        break;
      case '2':
        trans_type_text = 'ส่ง'
        break;
      default: {
        break;
      }
    }

    this.setState({
      show_update_section: false,
      trans_type_text,
      year: moment().year() + 543,
      book_cate_data: [],
      division_data: [],
      sub_division_data: [],
      book_cate_id: '',
      division_id: '',
      sub_division_id: '',
      running: '',
      running_data: {}
    });

    const book_cate_req = await API.get('/console/book_cate/all?trans_type=' + this.state.trans_type + '&all_division=1');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }

    const division_req = await API.get('/console/divisions/all');
    if (division_req.success) {
      this.setState({
        division_data: division_req.data,
      });
    }

    this.setState({ loading: false });
  }

  onChangeDivision = async division_id => {
    this.displayAlert('loading');
    const sub_division_req = await API.get(
      `/console/sub_divisions/all?division_id=${division_id}`
    );
    if (sub_division_req.success) {
      this.setData('sub_division_data', sub_division_req.data);
      this.setData('sub_division_id', '');
    }
    MySwal.close();
  };

  getData = async () => {
    this.displayAlert('loading');
    const { year, trans_type, book_cate_id, division_id, sub_division_id } = this.state;
    const responce = await API.get(
      `/console/register_doc_no?trans_type=${trans_type}&book_cate_id=${book_cate_id}&division_id=${division_id}&sub_division_id=${sub_division_id}&year=${year}`
    ).then(
      ({ success, data, error }) => {
        if (success) {
          this.setState({
            loading: false,
            show_update_section: true,
            running: '',
            running_data: data
          }, () => MySwal.close());
        } else {
          this.setState({ loading: false }, () => MySwal.close());
          this.displayAlert('error', error);
        }
      }
    );
  };

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { trans_type, running_data, running } = this.state;
      let data = {
        trans_type: trans_type,
        gen_running_id: running_data.gen_running_id,
        gen_pattern_id: running_data.gen_pattern_id,
        year: running_data.year,
        pattern_running: running_data.pattern_running,
        running: (running ? running : null),
      };
      const response = await API.post(
        `/console/register_doc_no/edit`,
        data
      );
      if (response.success) {
        running_data.last_generated_date = response.data.item.last_generated_date;
        running_data.last_generated_date_txt = response.data.item.last_generated_date_txt;
        running_data.last_running_txt = response.data.item.last_running_txt;
        running_data.last_updated_running_date = response.data.item.last_updated_running_date;
        running_data.last_updated_running_date_txt = response.data.item.last_updated_running_date_txt;
        running_data.last_updated_running_by = response.data.item.last_updated_running_by;

        this.setState({
          running_data,
          running: ''
        }, () => this.displayAlert('success', response.data));
      } else {
        this.displayAlert('error', response.error);
      }
    } catch (e) {
      alert(e.message);
    }
    
  };

  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const { ecs_permission } = this.props;
    const {
      loading,
      show_update_section,
      trans_type_text,
      year,
      book_cate_data,
      division_data,
      sub_division_data,
      running,

      running_data,
    } = this.state;

    if (loading) {
      return null;
    } else {
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_division = _.map(division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.division_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_sub_division = _.map(sub_division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.sub_division_id}>
            {value.name}
          </MenuItem>
        );
      });

      return (
        <div>
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>ค้นหา</h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                  *ปี
              </StyledLabelRight>
              <div className='col-md-2'>
                  <StyledTextField
                      id='year'
                      type='number'
                      value={year}
                      placeholder='ปี'
                      InputProps={{ inputProps: { min: 2500, max: 9999 } }}
                      onKeyDown={e => {this.onNumerInputKeyDown(e);}}
                      onChange={e => {
                          this.setData('year', e.target.value);
                      }}
                  />
              </div>

              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ทะเบียน{trans_type_text}
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  margin='normal'
                  value={this.state.book_cate_id}
                  onChange={(e) => {
                    this.setData('book_cate_id', e.target.value);
                  }}
                >
                  {list_book_cate}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *หน่วยงาน
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='division_id'
                  select
                  margin='normal'
                  value={this.state.division_id}
                  onChange={(e) => {
                    this.onChangeDivision(e.target.value);
                    this.setData('division_id', e.target.value);
                  }}
                >
                  {list_division}
                </StyledTextField>
              </div>

              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='sub_division_id'
                  select
                  margin='normal'
                  value={this.state.sub_division_id}
                  onChange={(e) => {
                    this.setData('sub_division_id', e.target.value);
                  }}
                >
                  {list_sub_division}
                </StyledTextField>
              </div>

              <div className='col-md-2 offset-md-1'>
                <Button
                  variant='success'
                  onClick={() => {
                    this.getData();
                  }}
                >
                  ค้นหา
              </Button>
              </div>
            </div>
          </form>
        </div>
        {show_update_section && (<>
        <div className='kt-portlet kt-portlet--height-fluid hidden'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>ข้อมูลเลขทะเบียน{trans_type_text}</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ปี
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.year)}
              </StyledLabelLeft>
              
              <StyledLabelRight className='col-md-2 col-form-label'>
                วันที่{trans_type_text}ล่าสุด
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.last_generated_date_txt)}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ทะเบียน{trans_type_text}
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-8 col-form-label'>
                {running_data.book_cate_name}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงาน
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {running_data.division_name}
              </StyledLabelLeft>
              
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.sub_division_name)}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                เลขทะเบียนล่าสุด
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.last_running_txt)}
              </StyledLabelLeft>
              
              <StyledLabelRight className='col-md-2 col-form-label'>
                วันที่แก้ไขข้อมูลล่าสุด
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.last_updated_running_date_txt)}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *แก้ไขเลขทะเบียน{trans_type_text}ล่าสุดเป็น
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                    id='running'
                    type='number'
                    value={running}
                    InputProps={{ inputProps: { min: 1 } }}
                    onKeyDown={e => {this.onNumerInputKeyDown(e);}}
                    onChange={e => {
                      this.setData('running',e.target.value);
                    }}
                />
              </div>

              <StyledLabelRight className='col-md-3 col-form-label'>
                ผู้ที่แก้ไขข้อมูลล่าสุด
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-3 col-form-label'>
                {this.replaceEmpty(running_data.last_updated_running_by)}
              </StyledLabelLeft>
            </div>

            <div className='form-group row' style={{ marginBottom: 0 }}>
              <StyledButtonRight className='col-md-6'>
                <Button
                  variant='info'
                  onClick={() => this.displayAlert('save')}
                >
                  บันทึก
                </Button>
              </StyledButtonRight>
            </div>
            <br />
          </form>

        </div>
        </>)}
        </div>
      );
    }

  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(RegisterDocNoPage));