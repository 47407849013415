import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'code',
    sortable: true,
    disablePadding: false,
    label: 'รหัส',
    align: 'center'
  },
  {
    id: 'name',
    sortable: true,
    disablePadding: false,
    label: 'ชื่อหน่วยงาน',
    align: 'center'
  },
  {
    id: 'organization_name',
    sortable: false,
    disablePadding: false,
    label: 'กระทรวง',
    align: 'center'
  },
  {
    id: 'department_name',
    sortable: true,
    disablePadding: false,
    label: 'กรม',
    align: 'center'
  },
  {
    id: 'status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center'
  }
];
var rows = [];
class DivisionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: Const.Table.RowPerPages
    };
  }
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangePage = (page) => { this.setState({ page }, () => this.getData()); };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
    }
  };
  setSelecteds = selecteds => this.setState({ selecteds });
  getData = async () => {
    this.displayAlert('loading');
    const { keyword, page, per_page } = this.state;
    const divisions = await API.get(`/console/divisions?keyword=${keyword}&page=${page}&size=${per_page}`);
    if (divisions.success) {
      let division_data = divisions.data;
      _.filter(division_data, (division, key) => {
        division.id = division.division_id;
        division.organization_name = division.organization[0].name;
        division.department_name = division.department[0].name;
        division.status = division.status == 1 ? 'เปิดใช้งาน' : 'ปิดการใช้งาน';
      });
      this.setState(
        {
          division_data,
          current_page: divisions.current_page,
          last_page: divisions.last_page,
        },
        () => MySwal.close()
      );
    }
  };
  componentDidMount() { this.getData(); }

  render() {
    const { keyword } = this.state;
    const { ecs_permission } = this.props;
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>หน่วยงาน</h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              คำสำคัญ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='keyword'
                placeholder='คำสำคัญ'
                onChange={e => {
                  this.setData('keyword', e.target.value);
                }}
              />
            </div>
            <div className='col-md-2 offset-md-1'>
              <Button
                variant='success'
                onClick={() => {
                  this.handleChangePage(1);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </form>
        <div
          className='form-group row'
          style={{ marginBottom: 0, paddingLeft: 10 }}
        >
          <StyledButtonLeft className='col-md-6'>
            <Button
              variant='success'
              onClick={() => {
                this.props.history.push('/division-add');
              }}
              disabled={!ecs_permission.organizations.divisions_management.add}
            >
              เพิ่มหน่วยงาน
            </Button>
          </StyledButtonLeft>
        </div>
        <CustomTable
          title='หน่วยงาน'
          hideSelect={true}
          showManage={true}
          head_rows={head_rows}
          rows={this.state.division_data ? this.state.division_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage='/division-edit/'
          btnManageName='แก้ไข'
          btnManageAlign='left'
          linkDelete='/console/divisions/'
          btnDeleteName='ลบ'
          btnDeleteAlign='left'
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={!ecs_permission.organizations.divisions_management.edit}
          btnDeleteDisabled={!ecs_permission.organizations.divisions_management.delete}
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(DivisionPage));
