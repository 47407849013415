/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      check_edit: true,
      send_remark: null,
      division_data: [],
      sub_division_data: [],
      selected_divisions: [],
      selected_sub_divisions: [],
      selected_departments: [],
    };
  }
  displayAlert = (type = 'receive', data = null) => {
    switch (type) {
      case 'receive': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนรับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.onConfirm();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
            if (result) {
              this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          text: '',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          this.props.history.goBack();
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };
  componentWillMount() { this.getData(); }
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    const { doc_trans } = this.state;
    try {
      await API.get('/console/static/division_and_sub/all').then((res) => {
        if (res.success) {
          this.setState({
            division_data: _.filter(
              res.data,
              (v, k) => k == 'division_id'
            )[0].map((v) => ({
              value: v.id,
              label: v.name,
            })),
            sub_division_data: _.filter(
              res.data,
              (v, k) => k == 'sub_division_id'
            )[0].map((v) => ({
              value: v.id,
              label: v.name,
            })),
            department_data: _.filter(
              res.data,
              (v, k) => k == 'department_id'
            )[0].map((v) => ({
              value: v.id,
              label: v.name,
            })),
          });
        }
      });
      const id = this.props.history.location.pathname.split('/register-receive-send-forward/').pop().replace(curr_page, '');
      this.setState({ id });
      await API.get(`/console/receive/forward/${id}`).then(
        ({ success, data }) => {
          if (success) {
            console.log('data -->', data);
            this.setState(
              {
                ...data,
              },
              () => {
                _.filter(data.send_to, (v, k) => {
                  switch (k) {
                    case 'division_id': {
                      const new_selected_divisions = [];
                      _.filter(v, (v1) => {
                        _.filter(this.state.division_data, (v2) => {
                          if (v1 == v2.value) {
                            new_selected_divisions.push(v2);
                          }
                        });
                      });
                      this.setState({
                        selected_divisions: new_selected_divisions,
                      });
                      break;
                    }
                    case 'sub_division_id': {
                      const new_selected_sub_divisions = [];
                      _.filter(v, (v1) => {
                        _.filter(this.state.sub_division_data, (v2) => {
                          if (v1 == v2.value) {
                            new_selected_sub_divisions.push(v2);
                          }
                        });
                      });
                      this.setState({
                        selected_sub_divisions: new_selected_sub_divisions,
                      });
                      break;
                    }
                    case 'department_id': {
                      const new_selected_departments = [];
                      _.filter(v, (v1) => {
                        _.filter(this.state.department_data, (v2) => {
                          if (v1 == v2.value) {
                            new_selected_departments.push(v2);
                          }
                        });
                      });
                      this.setState({
                        selected_departments: new_selected_departments,
                      });
                      break;
                    }
                    default:
                      break;
                  }
                });
              }
            );
          }
        }
      );
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  setData = (key, value) => { this.setState({ [key]: value }); };
  onConfirm = async () => {
    this.displayAlert('loading');
    try {
      const { send_remark } = this.state;
      let data = {
        send_remark,
        send_to: {
          division_id: [],
          sub_division_id: [],
          department_id: [],
        },
      };
      console.log('this.state.selected_divisions -->', this.state.selected_divisions);
      _.filter(this.state.selected_divisions, (v) => data.send_to.division_id.push(v.value));
      _.filter(this.state.selected_sub_divisions, (v) => data.send_to.sub_division_id.push(v.value));
      _.filter(this.state.selected_departments, (v) => data.send_to.department_id.push(v.value));
      let form_data = new FormData();
      _.filter(data, (value, key) => {
        if (value && value != '') {
          if (key == 'date_at' || key == 'due_date' || key == 'expire_date') {
            value = value.replace('T', ' ');
            value = value.replace('/', '-');
          }
          if (key == 'send_to') {
            _.filter(value, (v1, k1) => {
              _.filter(v1, (v2, k2) => {
                form_data.append(`${key}[${k1}][]`, v2);
              });
            });
          } else if (key == 'doc_file') {
            form_data.append(key, value);
          } else if (key == 'attach') {
            _.filter(value, (v, k) => {
              form_data.append(`attach[]`, v);
            });
          } else {
            form_data.set(key, value);
          }
        }
      });
      for (var [key, value] of form_data.entries()) {
        console.log(key + ' : ' + value);
      }
      const edit_receive = await API.post(`/console/receive/forward/${this.state.id}/edit`, form_data);
      if (edit_receive.success) {
        this.displayAlert('success');
      } else {
        this.displayAlert('error', edit_receive.error);
      }
    } catch (e) {}
  };
  handleSelectDivision = (selected_division) =>
    this.setState({ selected_division, selected_sub_division: null });
  handleSelectSubDivision = (selected_sub_division) =>
    this.setState({ selected_sub_division, selected_division: null });
  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    _.filter(this.state.division_data, (v) => {
      new_selected_divisions.push(v);
    });
    this.setState({
      selected_divisions: new_selected_divisions,
    });
  };
  handleSelectDivisions = (selected_divisions) =>
    this.setState({ selected_divisions });
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    _.filter(this.state.sub_division_data, (v) => {
      new_selected_sub_divisions.push(v);
    });
    this.setState({
      selected_sub_divisions: new_selected_sub_divisions,
    });
  };
  handleSelectSubDivisions = (selected_sub_divisions) =>
    this.setState({ selected_sub_divisions });
  handleSelectAllDepartments = () => {
    const new_selected_departments = [];
    _.filter(this.state.department_data, (v) => {
      new_selected_departments.push(v);
    });
    this.setState({
      selected_departments: new_selected_departments,
    });
  };
  handleSelectDepartments = (selected_departments) =>
    this.setState({ selected_departments });
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  render() {
    if (this.state.loading) {
      return null;
    } else {
      const { send_remark } = this.state;
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>ส่งเรื่อง</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate>
            <div className='form-group row'>
              <div className='col-md-2'></div>
              <ButtonBootstrap
                variant='success'
                onClick={this.handleSelectAllDivisions}
                style={{ marginLeft: '10px' }}
              >
                ส่งทุกหน่วยงาน
              </ButtonBootstrap>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ส่งถึง
              </StyledLabelRight>
              <div className='col-md-8'>
                <CustomAutocomplete
                  isMulti
                  placeholder='หน่วยงาน'
                  items={this.state.division_data}
                  selected_items={this.state.selected_divisions}
                  handleSelectItems={this.handleSelectDivisions}
                ></CustomAutocomplete>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-2'></div>
              <ButtonBootstrap
                variant='success'
                onClick={this.handleSelectAllSubDivisions}
                style={{ marginLeft: '10px' }}
              >
                ส่งทุกหน่วยงานย่อย
              </ButtonBootstrap>
            </div>
            <div className='form-group row'>
              <div className='col-md-2'></div>
              <div className='col-md-8'>
                <CustomAutocomplete
                  isMulti
                  placeholder='หน่วยงานย่อย'
                  items={this.state.sub_division_data}
                  selected_items={this.state.selected_sub_divisions}
                  handleSelectItems={this.handleSelectSubDivisions}
                ></CustomAutocomplete>
              </div>
            </div>
            <div className='form-group row' style={{ display: 'none' }}>
              <div className='col-md-2'></div>
              <ButtonBootstrap
                variant='success'
                onClick={this.handleSelectAllDepartments}
                style={{ marginLeft: '10px' }}
              >
                ส่งทุกหน่วยงาน e-CMS
              </ButtonBootstrap>
            </div>
            <div className='form-group row' style={{ display: 'none' }}>
              <div className='col-md-2'></div>
              <div className='col-md-8'>
                <CustomAutocomplete
                  isMulti
                  placeholder='หน่วยงาน e-CMS'
                  items={this.state.department_data}
                  selected_items={this.state.selected_departments}
                  handleSelectItems={this.handleSelectDepartments}
                ></CustomAutocomplete>
              </div>
            </div>
          </form>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              คำสั่งการ
            </StyledLabelRight>
            <div className='col-md-8'>
              <StyledTextField
                id='note'
                value={send_remark}
                placeholder='คำสั่งการ'
                multiline
                rows='5'
                margin='normal'
                variant='outlined'
                onChange={(e) => {
                  this.setData('send_remark', e.target.value);
                }}
              />
            </div>
          </div>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                style={{ marginLeft: '5px' }}
                onClick={() => this.onConfirm()}
                disabled={!this.state.check_edit}
              >
                ยืนยัน
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='secondary'
                style={{ marginLeft: '5px' }}

                onClick={() =>
                  this.displayAlert('back')
                }
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
export default withRouter(Class);
