import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../api';
import moment from 'moment';
import _ from 'lodash';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import MasterData from '../../variables/MasterData';
import CustomTable from '../../widgets/CustomTable';
registerLocale('th', th);
setDefaultLocale('th');
const textRight = {
  textAlign: 'right',
};
const head_rows = [
  {
    id: 'no',
    label: 'ลำดับ',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'department',
    label: 'หน่วยงาน',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'begin_date',
    label: 'ตั้งแต่วันที่',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'end_date',
    label: 'ถึงวันที่',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'count',
    label: 'จำนวนเรื่องที่ส่ง',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
];
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'view',
      page: '1',
    };
  }
  getData = async () => {
    this.setState({
      list_type_data: MasterData.type_data,
    });
    const doc_type_req = await API.get('/console/doc_type/all');
    if (doc_type_req.success) {
      this.setState({
        doc_type_data: doc_type_req.data,
      });
    }
    const book_cate_req = await API.get('/console/book_cate/all?trans_type=2');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }
    await API.get('/console/doc_type/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          doc_type_data: data,
        });
      }
    });
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getReport());
  };
  componentDidMount() {
    this.getData();
  }
  onChangeValue = async (key, value) => {
    if (key == 'end_limit') {
      if (
        this.state.start_limit &&
        Number(value.value) < Number(this.state.start_limit.value)
      ) {
        this.setState({ end_limit: this.state.start_limit });
        return;
      }
    }
    if (key == 'start_limit') {
      if (
        this.state.end_limit &&
        Number(value.value) > Number(this.state.end_limit.value)
      ) {
        this.setState({ end_limit: value });
      }
    }
    this.setState({ [key]: value }, () => {
      if (key == 'start_limit' || key == 'end_limit') {
        if (this.state.start_limit && this.state.end_limit) {
          let limit2 = [];
          for (
            let i = Number(this.state.start_limit.value);
            i <= Number(this.state.end_limit.value);
            i++
          ) {
            limit2.push({
              value: String(i),
              label: String(i),
            });
          }
          this.setState({
            limit2,
            selected_limit: [],
          });
        }
      }
    });
    if (key == 'book_cate_id' && value != this.state.book_cate_id) {
      this.setState({
        count: undefined,
        limit: undefined,
        limit2: undefined,
        start_limit: undefined,
        end_limit: undefined,
        selected_limit: [],
      });
      await API.post('/console/reports/send/count', {
        book_cate_id: value,
      }).then(({ success, count }) => {
        if (success) {
          let limit = [];
          for (let i = Number(count) > 0 ? 1 : 0; i <= Number(count); i++) {
            limit.push({
              value: String(i),
              label: String(i),
            });
          }
          this.setState({
            count: String(count),
            limit,
          });
        }
      });
    }
  };
  getReport = async () => {
    const {
      start_date,
      end_date,
      type,
      book_cate_id,
      start_limit,
      end_limit,
      selected_limit,
      limit_text,
      page,
    } = this.state;
    let data = {};
    if (book_cate_id) {
      data.book_cate_id = book_cate_id;
    }
    if (start_date) {
      data.start_date = start_date.replace('/', '-');
    }
    if (end_date) {
      data.end_date = end_date.replace('/', '-');
    }
    if (type) {
      data.type = type;
    }
    // if (start_limit && start_limit.value) {
    //   data.start_limit = Number(start_limit.value);
    // }
    // if (end_limit && end_limit.value) {
    //   data.end_limit = Number(end_limit.value);
    // }
    // if (selected_limit && selected_limit.length > 0) {
    //   data.select_limit = [];
    //   _.filter(selected_limit, v => data.select_limit.push(Number(v.value)));
    // }
    // if (limit_text) {
    //   data.limit = limit_text;
    // }
    if (page) {
      data.page = page;
    }
    if (type == 'view') {
      await API.post('/console/reports/send/outside', data).then((res) => {
        if (res && res.success) {
          this.setState(
            {
              receive_data: res.data,
              current_page: res.current_page,
              last_page: res.last_page,
              per_page: res.count,
            }
            // () => MySwal.close()
          );
        }
      });
    } else {
      const send_report = await API.postDownloadFile(
        '/console/reports/send/outside',
        data,
        'header'
      );
      const file = new Blob([send_report.data], {
        type: send_report.headers['content-type'],
      });
      const content_disposition = send_report.headers['content-disposition'];
      let fileName = 'unknown';
      if (content_disposition) {
        fileName = content_disposition.replace('attachment; filename=', '');
      }
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = fileName;
      fileLink.click();
    }
  };
  render() {
    return (
      <>
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>
                สรุปจำนวนหนังสือส่งออกจำแนกตามหน่วยงานภายนอก
              </h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate>
            <div className='form-group row'>
              {/* <label style={textRight} className='col-md-2 col-form-label'>
                ทะเบียนส่ง
              </label>
              <div className='col-md-2' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='standard-select-currency'
                  select
                  margin='normal'
                  value={this.state.book_cate_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('book_cate_id', value)
                  }
                >
                  {_.map(this.state.book_cate_data, (v, k) => (
                    <MenuItem key={k} value={v.book_cate_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </div> */}
              {/* <StyledLabelRight className='col-md-2 col-form-label'>
                กำหนดลำดับหนังสือ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='limit_text'
                  placeholder='1-4,5,6,10-12'
                  onChange={(e) => {
                    this.onChangeValue('limit_text', e.target.value);
                  }}
                />
              </div> */}
            </div>
            {/* {this.state.limit && (
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ลำดับเริ่มต้น
                </StyledLabelRight>
                <div className='col-md-2'>
                  <CustomAutocomplete
                    items={this.state.limit}
                    selected_item={this.state.start_limit}
                    handleSelectItem={(start_limit) =>
                      this.onChangeValue('start_limit', start_limit)
                    }
                  />
                </div>
                <StyledLabelRight className='col-md-1 col-form-label'>
                  ลำดับสิ้นสุด
                </StyledLabelRight>
                <div className='col-md-2'>
                  <CustomAutocomplete
                    items={this.state.limit}
                    selected_item={this.state.end_limit}
                    handleSelectItem={(end_limit) =>
                      this.onChangeValue('end_limit', end_limit)
                    }
                  />
                </div>
                {this.state.limit2 && (
                  <>
                    <StyledLabelRight className='col-md-1 col-form-label'>
                      เลือกลำดับ
                    </StyledLabelRight>
                    <div className='col-md-2'>
                      <CustomAutocomplete
                        isMulti
                        items={this.state.limit2}
                        selected_items={this.state.selected_limit}
                        handleSelectItems={(selected_limit) =>
                          this.onChangeValue('selected_limit', selected_limit)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            )} */}
            <div className='form-group row'>
              <label style={textRight} className='col-md-2 col-form-label'>
                ตั้งแต่
              </label>
              <div className='col-md-2'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={
                    this.state.start_date
                      ? new Date(this.state.start_date)
                      : null
                  }
                  onChange={(date) => {
                    try {
                      this.setState({
                        start_date: moment(date).format('YYYY-MM-DD'),
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </div>
              <label style={textRight} className='col-md-1 col-form-label'>
                ถึง
              </label>
              <div className='col-md-2'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={
                    this.state.end_date ? new Date(this.state.end_date) : null
                  }
                  onChange={(date) => {
                    try {
                      this.setState({
                        end_date: moment(date).format('YYYY-MM-DD'),
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </div>
              <StyledLabelRight className='col-md-1 col-form-label'>
                ชนิดข้อมูล
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='type'
                  select
                  margin='normal'
                  value={this.state.type}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('type', value)
                  }
                >
                  {_.map(this.state.list_type_data, (v, k) => (
                    <MenuItem key={k} value={k}>
                      {v}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-12' style={{ textAlign: 'center' }}>
                <Button variant='success' onClick={() => this.getReport()}>
                  ส่งออกรายงาน
                </Button>
              </div>
            </div>
          </form>
          <CustomTable
            title='สรุปจำนวนหนังสือส่งออกจำแนกตามหน่วยงานภายนอก'
            showManage={false}
            head_rows={head_rows}
            rows={this.state.receive_data ? this.state.receive_data : []}
            history={this.props.history}
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            handleChangePage={this.handleChangePage}
          />
        </div>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
export default withRouter(Class);
