import React from 'react';
import DivisionEditFormPage from '../division-edit-form/DivisionEditFormPage';
export default function DivisionEditForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <DivisionEditFormPage />
        </div>
      </div>
    </>
  );
}
