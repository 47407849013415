import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'username',
    sortable: true,
    disablePadding: false,
    label: 'ชื่อผู้ใช้',
    align: 'center',
  },
  {
    id: 'name',
    sortable: true,
    disablePadding: false,
    label: 'ชื่อ-นามสกุล',
    align: 'center',
  },
  {
    id: 'email',
    sortable: true,
    disablePadding: false,
    label: 'อีเมล',
    align: 'center',
  },
  {
    id: 'role_name',
    sortable: true,
    disablePadding: false,
    label: 'สิทธิ์การเข้าถึงข้อมูล',
    align: 'center',
  },
  {
    id: 'organization_name',
    sortable: true,
    disablePadding: false,
    label: 'กระทรวง',
    align: 'center',
  },
  {
    id: 'department_name',
    sortable: true,
    disablePadding: false,
    label: 'กรม',
    align: 'center',
  },
  {
    id: 'division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงาน',
    align: 'center',
  },
  {
    id: 'sub_division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงานย่อย',
    align: 'center',
  },
  {
    id: 'status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
];
var rows = [];
class UserListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: Const.Table.RowPerPages
    };
  }
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangePage = (page) => { this.setState({ page }, () => this.getData());};
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  getData = async () => {
    this.displayAlert('loading');
    const { keyword, page, per_page } = this.state;
    const users = await API.get(`/console/users?keyword=${keyword}&page=${page}&size=${per_page}`);
    if (users.success) {
      let user_data = users.data;
      _.filter(user_data, (user, key) => {
        user.id = user.user_id;
        user.name =
          (user.prefix ? user.prefix : '') +
          (user.fname ? user.fname + ' ' : '') +
          (user.sname ? user.sname : '');
        user.name = user.name ? user.name : '-';
        user.status = user.status == 1 ? 'เปิดใช้งาน' : 'ปิดการใช้งาน';
      });
      this.setState(
        {
          user_data,
          current_page: users.current_page,
          last_page: users.last_page,
        },
        () => MySwal.close()
      );
    }
  };
  componentDidMount() { this.getData(); }

  render() {
    const { ecs_permission } = this.props;
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>สมาชิก</h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              คำสำคัญ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='keyword'
                placeholder='คำสำคัญ'
                onChange={(e) => {
                  this.setData('keyword', e.target.value);
                }}
              />
            </div>
            <div className='col-md-2 offset-md-1'>
              <Button
                variant='success'
                onClick={() => {
                  this.handleChangePage(1);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </form>
        <div
          className='form-group row'
          style={{ marginBottom: 0, paddingLeft: 10 }}
        >
          <StyledButtonLeft className='col-md-6'>
            <Button
              variant='success'
              onClick={() => {
                this.props.history.push('/user-add');
              }}
              disabled={!ecs_permission.users.user_management.add}
            >
              เพิ่มสมาชิก
            </Button>
          </StyledButtonLeft>
        </div>
        <CustomTable
          title='สมาชิก'
          hideSelect={true}
          showManage={true}
          head_rows={head_rows}
          rows={this.state.user_data ? this.state.user_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage='/user-edit/'
          btnManageName='แก้ไข'
          btnManageAlign='left'
          linkDelete='/console/users/'
          btnDeleteName='ลบ'
          btnDeleteAlign='left'
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={!ecs_permission.users.user_management.edit}
          btnDeleteDisabled={!ecs_permission.users.user_management.delete}
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2% !important;
  margin-top: 6px;
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(UserListPage));
// export default withRouter(RegisterReceivePage);
