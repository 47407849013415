import axios from 'axios';
/* const prefix_domain = process.env.REACT_APP_PREFIX_DOMAIN;
const domain = process.env.REACT_APP_DOMAIN; */
/* const prefix_domain = 'http';
const domain = '122.155.223.189'; */
const prefix_domain = 'https';
//const domain = 'ecs-services.dms.go.th'; // 'ecs.services.dms.moph.go.th'
//const domain = 'ecs-services.netconsult.co.th'
var domain = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    domain = 'localhost:44377';
    //domain = 'localhost:5001'; // Kresrel port
}
else {
    domain = 'ecs-services.netconsult.co.th';
}
const instance = axios.create();
export default class Class {
  static async get(path, headers = { Authorization: null }) {
    const Authorization = await localStorage.getItem('Authorization');
    if (Authorization) {
      headers = {
        Authorization,
      };
    }
    const option = {
      headers: {
        ...headers,
        'content-type': 'application/json;multipart/form-data',
      },
    };
    return await instance
      .get(`${prefix_domain}://${domain}/api${path}`, option)
      .then((res) => res && res.data)
      .catch(function (err) {
        if (err.response.status == 401) window.location.href = '/logout';
      });
  }
  static async post(path, data = null, headers = { Authorization: null }) {
    const Authorization = await localStorage.getItem('Authorization');
    if (Authorization) {
      headers = {
        Authorization,
      };
    }
    const option = {
      headers: {
        ...headers,
        'content-type': 'application/json;multipart/form-data',
      },
    };
    return await instance
        .post(`${prefix_domain}://${domain}/api${path}`, data, option)
        .then((res) => res && res.data)
        .catch(function (err) {
            console.log(err);
            if (err.response.status == 401) window.location.href = '/logout';
        });
  }
  static async postDownloadFile(
    path,
    data = null,
    headers = { Authorization: null }
  ) {
    const Authorization = await localStorage.getItem('Authorization');
    if (Authorization) {
      headers = {
        Authorization,
      };
    }
    const option = {
      headers: {
        ...headers,
        'content-type': 'application/json;multipart/form-data',
      },
      responseType: 'blob',
    };
    return await instance
      .post(`${prefix_domain}://${domain}/api${path}`, data, option)
      .then((res) => res)
      .catch(function (err) {
          console.log(err);
          if (err.response.status == 401) window.location.href = '/logout';
      });
  }
}
