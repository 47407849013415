import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
      selected_divisions: [],
      selected_sub_divisions: [],
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการยืนยัน?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/e-form-list/t7?type=wait-send');
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t7?type=wait-send');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  handleSelectDivisions = (selected_divisions) => {
    this.setState({ selected_divisions });
  }

  handleSelectSubDivisions = (selected_sub_divisions) => {
    this.setState({ selected_sub_divisions });
  }

  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_divisions.push(v1);
      }
    });

    let merge_divisions = (selected_divisions == null) ? new_selected_divisions : selected_divisions.concat(new_selected_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_sub_divisions } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_sub_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_sub_divisions = (selected_sub_divisions == null) ? new_selected_sub_divisions : selected_sub_divisions.concat(new_selected_sub_divisions);
    this.setState({
      selected_sub_divisions: merge_sub_divisions,
    });
  };

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-forward/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    this.displayAlert('loading');

    let division = [],
        sub_division = [];

    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res && res.success) {
        division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: v.id.toString(),
          label: v.name,
        }));

        sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: v.division_id.toString() + '_' + v.id.toString(),
          label: v.name,
        }));
      }
    });

    await this.getData();

    this.setState({
      division, 
      sub_division, 
      finished_init_page: true,
      display_loading: false,
    },
    () => { MySwal.close(); });
  }

  getData = async () => {
    const {
      id
    } = this.state;
    await API.get('/console/eform/' + id + '/forward').then((res) => {
      if (res && res.success) {
        const eform_data = res.data;        
        this.setState(
          {
            eform_data,
          },
          () => { MySwal.close(); }
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      let send_to_division_id = [];
      let send_to_sub_division_id = [];
      _.forEach(this.state.selected_divisions, (v) => {
        send_to_division_id.push(v.value);
      });

      _.forEach(this.state.selected_sub_divisions, (v) => {
        send_to_sub_division_id.push(v.value);
      });

      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        send_to_division_id: send_to_division_id,
        send_to_sub_division_id: send_to_sub_division_id,
        order_remark: this.state.order_remark
      };

      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/forward', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      alert(e.message);
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
    } = this.state;

    if (finished_init_page === true) {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
              <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                  ส่งต่อ
                  </h3>
              </div>
          </div>
          <br />
          <form className='forwardForm' noValidate autoComplete='off'>
            <div className='form-group row'>
                <div className='col-md-2 col-form-label'></div>
                <div className='col-md-9'>
                  <ButtonBootstrap
                    variant='success'
                    onClick={this.handleSelectAllDivisions}>
                      ส่งทุกหน่วยงาน
                  </ButtonBootstrap>
                </div>
            </div>
            <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่งถึง</StyledLabelRight>
                <div className='col-md-9'>
                    <CustomAutocomplete
                      isMulti
                      placeholder='หน่วยงาน'
                      items={this.state.division}
                      selected_items={this.state.selected_divisions}
                      handleSelectItems={this.handleSelectDivisions}
                    ></CustomAutocomplete>
                  </div>
            </div>
            <div className='form-group row'>
                <div className='col-md-2 col-form-label'></div>
                <div className='col-md-9'>
                  <ButtonBootstrap
                    variant='success'
                    onClick={this.handleSelectAllSubDivisions}>
                      ส่งทุกหน่วยงานย่อย
                  </ButtonBootstrap>
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-md-2 col-form-label'></div>
                <div className='col-md-9'>
                    <CustomAutocomplete
                      isMulti
                      placeholder='หน่วยงานย่อย'
                      items={this.state.sub_division}
                      selected_items={this.state.selected_sub_divisions}
                      handleSelectItems={this.handleSelectSubDivisions}
                    ></CustomAutocomplete>
                  </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>คำสั่งการ</StyledLabelRight>
              <div className='col-md-9'>
                <StyledTextField
                    id='order_remark'
                    placeholder=''
                    multiline
                    rows='3'
                    margin='normal'
                    variant='outlined'
                    onChange={(e) => {
                      this.onChangeValue('order_remark', e.target.value);
                    }}
                    inputProps={{maxLength: 255}}
                  />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                <ButtonBootstrap
                  variant='success'
                  onClick={() => this.displayAlert('approve')}
                >
                  ยืนยัน
                </ButtonBootstrap>
                <ButtonBootstrap
                  variant='danger'
                  style={{ marginLeft: '5px' }}
                  onClick={() => this.displayAlert('back')}
                >
                  กลับ
                </ButtonBootstrap>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));