import React from 'react';
import EFormEndorsePage from '../e-form-endorse/EFormEndorsePage';
export default function EFormEndorse() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <EFormEndorsePage />
        </div>
      </div>
    </>
  );
}
