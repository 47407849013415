export const type_data = {
  view: 'view',
  word: 'word',
  excel: 'excel',
  pdf: 'pdf',
};


const MasterData = {
  type_data,
}

export default MasterData

