/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Radio, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.signatureImageInputRef = React.createRef();
    this.state = {
      loading: true,
      email: null,
      username: null,
      password: null,
      password_confirmation: null,
      status: 1,
      role_id: null,
      mobile: null,
      prefix: null,
      fname: null,
      sname: null,
      organization_id: null,
      department_id: null,
      division_id: null,
      sub_division_id: null,
      
      master_roles_data: [],
      master_organizations_data: [],
      master_departments_data: [],
      master_divisions_data: [],
      master_sub_divisions_data: [],
      position: null,
      signature: null,
      remove_signature_image: false,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
    if (key == 'signature') {
      this.setState({ signature_path: (value !== null ? URL.createObjectURL(value) : '') });
    }
  };

  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/user');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/user');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  getData = async () => {
    this.displayAlert('loading');
    try {
      const master_doc_roles = await API.get(`/console/roles/all`);
      if (master_doc_roles.success) {
        this.setData('master_roles_data', master_doc_roles.data);
      }
      const master_doc_organizations = await API.get(
        `/console/organizations/all`
      );
      if (master_doc_organizations.success) {
        this.setData(
          'master_organizations_data',
          master_doc_organizations.data
        );
      }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };

  onChangeDepartment = async organization_id => {
    this.displayAlert('loading');
    if (this.state.organization_id != organization_id) {
      this.setData('department_id', null);
      this.setData('division_id', null);
      this.setData('sub_division_id', null);
      this.setData('master_divisions_data', null);
      this.setData('master_sub_divisions_data', null);
    }
    const master_doc_departments = await API.get(
      `/console/departments/all?organization_id=${organization_id}`
    );
    if (master_doc_departments.success) {
      this.setData('master_departments_data', master_doc_departments.data);
    }
    MySwal.close();
  };

  onChangeDivision = async department_id => {
    this.displayAlert('loading');
    if (this.state.department_id != department_id) {
      this.setData('division_id', null);
      this.setData('sub_division_id', null);
      this.setData('master_sub_divisions_data', null);
    }
    const master_doc_divisions = await API.get(
      `/console/divisions/all?department_id=${department_id}`
    );
    if (master_doc_divisions.success) {
      this.setData('master_divisions_data', master_doc_divisions.data);
    }
    MySwal.close();
  };

  onChangeSubDivision = async division_id => {
    this.displayAlert('loading');
    if (this.state.division_id != division_id) {
      this.setData('sub_division_id', null);

      this.setData('master_sub_divisions_data', null);
    }
    const master_doc_sub_divisions = await API.get(
      `/console/sub_divisions/all?division_id=${division_id}`
    );
    if (master_doc_sub_divisions.success) {
      this.setData('master_sub_divisions_data', master_doc_sub_divisions.data);
    }
    MySwal.close();
  };

  handleChangeStatus = event => {
    this.setData('status', event.target.value);
  };

  componentDidMount() {
    this.getData();
  }

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const {
        email,
        username,
        password,
        password_confirmation,
        status,
        role_id,
        mobile,
        prefix,
        fname,
        sname,
        department_id,
        organization_id,
        division_id,
        sub_division_id,
        position,
        signature,
        remove_signature_image
      } = this.state;
      let data = {
        email,
        username,
        password,
        password_confirmation,
        status,
        role_id,
        mobile,
        prefix,
        fname,
        sname,
        department_id,
        organization_id,
        division_id,
        sub_division_id,
        position,
        signature,
        remove_signature_image
      };
      let form_data = new FormData();
      _.filter(data, (v, k) => {
        if (v && v != '') {
          if (k == 'signature') {
            form_data.append(k, v);
          } else {
            form_data.set(k, v);
          }
        }
      });
      const create_user = await API.post('/console/users/add', form_data);
      if (create_user.success) {
        this.displayAlert('success', create_user.data);
      } else {
        this.displayAlert('error', create_user.error);
      }
    } catch (e) {}
  };

  render() {
    const {
      loading,
      email,
      username,
      status,
      role_id,
      mobile,
      prefix,
      fname,
      sname,
      department_id,
      organization_id,
      division_id,
      sub_division_id,
      master_roles_data,
      master_organizations_data,
      master_departments_data,
      master_divisions_data,
      master_sub_divisions_data,
      position
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_master_doc_role = _.map(master_roles_data, (value, key) => {
        return (
          <MenuItem key={value.role_id} value={value.role_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_doc_organization = _.map(
        master_organizations_data,
        (value, key) => {
          return (
            <MenuItem key={value.organization_id} value={value.organization_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      const list_master_doc_department = _.map(
        master_departments_data,
        (value, key) => {
          return (
            <MenuItem key={value.department_id} value={value.department_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      const list_master_doc_division = _.map(
        master_divisions_data,
        (value, key) => {
          return (
            <MenuItem key={value.division_id} value={value.division_id}>
              {value.name}
            </MenuItem>
          );
        }
      );

      const list_master_doc_sub_division = _.map(
        master_sub_divisions_data,
        (value, key) => {
          return (
            <MenuItem key={value.sub_division_id} value={value.sub_division_id}>
              {value.name}
            </MenuItem>
          );
        }
      );

      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>เพิ่มสมาชิก</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *อีเมล
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='email'
                  value={email}
                  onChange={e => {
                    this.setData('email', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ชื่อผู้ใช้งาน
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='username'
                  value={username}
                  onChange={e => {
                    this.setData(
                      'username',
                      e.target.value.replace(/[^a-zA-Z0-9.]/g, '')
                    );
                  }}
                  inputProps={{ maxLength: 20 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *รหัสผ่าน
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='password'
                  type='password'
                  onChange={e => {
                    this.setData('password', e.target.value);
                  }}
                  inputProps={{ maxLength: 16, autocomplete: "new-password" }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ยืนยันรหัสผ่าน
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='password_confirmation'
                  type='password'
                  onChange={e => {
                    this.setData('password_confirmation', e.target.value);
                  }}
                  inputProps={{ maxLength: 16, autocomplete: "new-password" }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-3'>
                <Radio
                  checked={status == 1}
                  onChange={this.handleChangeStatus}
                  value='1'
                  name='status'
                  inputProps={{ 'aria-label': 1 }}
                />
                เปิดใช้งาน
                <Radio
                  checked={status == 0}
                  onChange={this.handleChangeStatus}
                  value='0'
                  name='status'
                  inputProps={{ 'aria-label': 0 }}
                />
                ปิดการใช้งาน
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *สิทธิ์การเข้าถึงข้อมูล
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='role_id'
                  value={role_id}
                  select
                  onChange={e => {
                    this.setData('role_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_role}
                </StyledTextField>
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                เบอร์โทรศัพท์
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='mobile'
                  value={mobile}
                  onChange={e => {
                    //this.setData(
                    //  'mobile',
                    //  e.target.value.replace(/[^0-9]/g, '')
                    //);
                    this.setData('mobile', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                คำนำหน้า
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='prefix'
                  value={prefix}
                  onChange={e => {
                    this.setData('prefix', e.target.value);
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ชื่อ
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='fname'
                  value={fname}
                  onChange={e => {
                    this.setData('fname', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *นามสกุล
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='sname'
                  value={sname}
                  onChange={e => {
                    this.setData('sname', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                ตำแหน่ง
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='position'
                  value={position}
                  onChange={e => {
                      this.setData('position', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *กระทรวง
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='organization_id'
                  value={organization_id}
                  select
                  onChange={e => {
                    this.onChangeDepartment(e.target.value);
                    this.setData('organization_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_organization}
                </StyledTextField>
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *กรม
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='department_id'
                  value={department_id}
                  select
                  onChange={e => {
                    this.onChangeDivision(e.target.value);
                    this.setData('department_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_department}
                </StyledTextField>
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *หน่วยงาน
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='division_id'
                  value={division_id}
                  select
                  onChange={e => {
                    this.onChangeSubDivision(e.target.value);
                    this.setData('division_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_division}
                </StyledTextField>
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='sub_division_id'
                  value={sub_division_id}
                  select
                  onChange={e => {
                    this.setData('sub_division_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_sub_division}
                </StyledTextField>
              </div>
            </div>

            <div className="form-group row">
              <StyledLabelRight className="col-md-2 col-form-label">
                รูปลายเซ็น
              </StyledLabelRight>
              <div className="col-md-8" style={{ marginTop: '8px' }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={({ target: { files } }) =>
                    this.setData('signature', files[0])
                  }
                  ref={this.signatureImageInputRef}
                />
                <label htmlFor="contained-button-file" style={{float: 'left'}}>
                  <Button variant="contained" component="span">
                    เลือกไฟล์
                  </Button>
                </label>
                {this.state.signature_path && (
                  <div style={{ marginLeft: '12px', float: 'left' }}>
                    <div style={{ float: 'left' }}>
                      <StyledUploadedFile
                        alt="Signature"
                        style={{ width: 200 }}
                        src={this.state.signature_path}
                      />
                    </div>
                    <a onClick={(e) => {
                        e.preventDefault();
                        this.setData('signature', null);
                        this.setData('remove_signature_image', true);
                        this.signatureImageInputRef.current.value = '';
                      }}
                      style={{marginLeft: '8px', float: 'left'}}
                    >
                      <i className='flaticon2-cancel-music icon-xs text-danger' />
                    </a>
                  </div>
                )}
                <div style={{ marginLeft: '24px', float: 'left' }}>
                  <div>
                    <StyledLabelLeft style={{ color: 'red', marginBottom: 0 }}>หมายเหตุ</StyledLabelLeft>
                    <ol className="m-0 pl-3" style={{ color: 'red' }}>
                      <li>นามสกุล .png เท่านั้น</li>
                      <li>พื้นหลังของภาพต้องเป็น transparent</li>
                      <li>ขนาดไฟล์ ต้องไม่เกิน 2 MB</li>
                      <li>อัตราส่วนภาพแนะนำ 3:1</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
