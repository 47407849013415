/* eslint-disable default-case */

import 'react-datepicker/dist/react-datepicker.css';

import * as auth from '../../store/ducks/auth.duck';

import { Button, MenuItem, Radio, TextField } from '@material-ui/core';
import { Button as ButtonBootstrap, Tab, Tabs } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import API from '../../api';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import CustomDownshift from '../../widgets/CustomDownshift';
import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import th from 'date-fns/locale/th';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import withReactContent from 'sweetalert2-react-content';
import { withRouter } from 'react-router-dom';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const current_year = new Date().getFullYear();
const start_date_of_year = new Date('1/1/' + current_year);
const start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
const end_date = moment().format('YYYY-MM-DD');
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
var param = `?doc_status=&doc_no=&item_no=&keyword=&start_date=${start_date}&end_date=${end_date}&book_cate_id=&doc_type_id=&form_type_id=&page=1&display_filters=true`;
const input = { display: 'none' };
const fileStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
};
const attachStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
  position: 'absolute', marginLeft: '1rem'
};
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      division_data: [],
      sub_division_data: [],
      selected_division: null,
      selected_sub_division: null,
      id: null,
      confirm_register: false,
      confirm_back: false,
      confirm_cancel: false,
      doc_file: null,
      doc_trans: 1,
      doc_type_id: null,
      book_cate_id: null,
      fdoc_no: null,
      ldoc_no: null,
      date_at: moment().format('YYYY-MM-DD'),
      from: {
        sub_division_id: [],
        division_id: [],
        department_id: []
      },
      topic: null,
      to_name: null,
      storage_location: null,
      page: null,
      secret_type_id: null,
      speed_type_id: null,
      detail: null,
      remark: null,
      due_date: moment().format('YYYY-MM-DD'),
      expire_date: moment().format('YYYY-MM-DD'),
      doc_status: '6',
      master_doc_type_data: null,
      master_book_cate_data: null,
      master_secret_data: null,
      master_speed_data: null,
      from_text: null,
      reference: null,
      relate: null,
      department_type: 1,
      active_tab: 'book_data',
      reference_doc: [],
      received_at: moment().format('YYYY-MM-DD'),
      attach: [],
    };
  }
  displayAlert = (type = 'receive', data = null) => {
    switch (type) {
      case 'receive': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนรับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onRegister();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            if (curr_page == '/register-receive-add')
              window.location.reload(false);
            else
              this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        let html = (
          <>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เลขทะเบียนรับ
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.receive_no}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เลขที่หนังสือ
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.doc_no}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เรื่อง
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.topic}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                จาก
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.from_name ? data.from_name : "-"}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                เรียน
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {data.to_name}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                ลงวันที่
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {this.dateFormat(moment(data.date_at))}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight
                className='col-md-4 offset-1 col-form-label'
                style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
              >
                วันที่ลงทะเบียนรับ
              </StyledLabelRight>
              <StyledButtonLeft
                className='col-md-7 col-form-label'
                style={{ margin: 0, paddingRight: 0 }}
              >
                {this.datetimeFormat(moment(data.received_at_tmp))}
              </StyledButtonLeft>
            </div>
          </>
        );
        MySwal.fire({
          title: 'สำเร็จ!',
          html,
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/register-receive-paginate/all' + param);
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };
  componentWillMount() { this.getData(); }
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    const { doc_trans } = this.state;
    try {
      const master_doc_type = await API.get(`/console/doc_type/all`);
      if (master_doc_type.success) { this.setData('master_doc_type_data', master_doc_type.data); }
      const master_book_cate = await API.get(`/console/book_cate/all?trans_type=1&doc_trans=${doc_trans}`);
      if (master_book_cate.success) { this.setData('master_book_cate_data', master_book_cate.data); }
      const master_secret = await API.get(`/console/static/master_secret/all`);
      if (master_secret.success) { this.setData('master_secret_data', master_secret.data); }
      const master_speed = await API.get(`/console/static/master_speed/all`);
      if (master_speed.success) { this.setData('master_speed_data', master_speed.data); }
      await API.get('/console/static/division_and_sub/all?permission=1').then((res) => {
        if (res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
            division_value: 'main_' + v.division_id,
          }));
          let division_data = [];
          _.forEach(division, (d) => {
            division_data.push(d);
            division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
          });
          this.setState({ division_data });
        }
      });
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  handleClearFrom = () => {
    this.setState({
      from: {
        sub_division_id: [],
        division_id: [],
        department_id: []
      },
      from_text: null
    });
  };
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangeDoctrans = async event => {
    await this.setData('doc_trans', event.target.value);
    await this.setData('book_cate_id', null);
    this.getData();
  };
  handleChangeDepartmentType = event => {
    this.setData('department_type', event.target.value);
    this.handleClearFrom();
  };
  onInputClick = (event) => { event.target.value = ''; }
  onChooseFiles = (type, files) => {
    switch (type) {
      case 'doc_file': {
        this.setState({
          doc_file: files[0]
        });
        break;
      }
      case 'attach': {
        if (this.state.attach) {
          const attach = [...this.state.attach, ...files];
          this.setState({ attach });
        } else {
          this.setState({ attach: files });
        }
        break;
      }
    }
  };
  onRemoveAttachFile = (e, idx) => {
    e.preventDefault();
    let attach = this.state.attach;
    attach.splice(idx, 1);
    this.setState({ attach });
  }
  onRegister = async () => {
    this.displayAlert('loading');
    try {
      const {
        doc_trans,
        doc_type_id,
        book_cate_id,
        fdoc_no,
        ldoc_no,
        date_at,
        selected_division,
        from_text,
        topic,
        from_name,
        to_name,
        storage_location,
        page,
        secret_type_id,
        speed_type_id,
        detail,
        remark,
        due_date,
        expire_date,
        doc_status,
        doc_file,
        attach,
        department_type,
        reference_doc,
        received_at
      } = this.state;
      let data = {
        doc_trans,
        doc_type_id,
        book_cate_id,
        fdoc_no,
        ldoc_no,
        date_at,
        from: {
          division_id: [],
          sub_division_id: [],
          department_id: [],
        },
        from_text,
        topic,
        from_name,
        to_name,
        storage_location,
        page,
        secret_type_id,
        speed_type_id,
        detail,
        remark,
        due_date,
        expire_date,
        doc_status,
        doc_file,
        attach,
        received_at
      };
      if (_.isArray(reference_doc) && !_.isEmpty(reference_doc)) {
        for (var i = 0; i < reference_doc.length; i++) {
          var _dr = reference_doc[i];
          if (!isNaN(_dr.date_at)) {
            console.log(new Date(_dr.date_at))
            _dr.date_at = _dr.date_at ? _dr.date_at.replace('T', ' ').replace('/', '-') : null;
          }
        }
        data.reference_doc = JSON.stringify(reference_doc);
      }
      if (department_type == 1) {
        if (selected_division) {
          const str = selected_division.value;
          if (str.includes('main_')) {
            let main = selected_division.value.split('main_');
            data.from.division_id.push(main[1]);
          }
          if (str.includes('sub_')) {
            let sub = selected_division.value.split('sub_');
            data.from.sub_division_id.push(sub[1]);
          }
        }
        data.from_text = undefined;
      } else if (department_type == 2 && this.state.check_ecms) {
        if (selected_division) {
          const str = selected_division.value;
          if (str.includes('department_')) {
            let department = selected_division.value.split('department_');
            data.from.department_id.push(department[1]);
          }
        }
        data.from_text = undefined;
      } else {
        data.from = undefined;
      }
      this.props.setCache(data);
      let form_data = new FormData();
      _.filter(data, (value, key) => {
        if (value && value != '') {
          if (key == 'date_at' || key == 'due_date' || key == 'expire_date' || key == 'received_at') {
            value = value.replace('T', ' ');
            value = value.replace('/', '-');
          }
          if (key == 'from') {
            _.filter(value, (v1, k1) => {
              _.filter(v1, (v2, k2) => {
                form_data.append(`${key}[${k1}][]`, v2);
              });
            });
          } else if (key == 'doc_file') {
            form_data.append(key, value);
          } else if (key == 'attach') {
            _.filter(value, (v, k) => {
              form_data.append(`attach[]`, v);
            });
          } else {
            form_data.set(key, value);
          }
        }
      });
      for (var [key, value] of form_data.entries()) {
        console.log(key + ' : ' + value);
      }
      const create_receive = await API.post('/console/receive/add', form_data);
      if (create_receive.success) {
        this.displayAlert('success', create_receive.data.item);
      } else {
        this.displayAlert('error', create_receive.error);
      }
    } catch (e) { }
  };
  handleSelectDivision = selected_division => this.setState({ selected_division, selected_sub_division: null });
  handleSelectSubDivision = selected_sub_division => this.setState({ selected_sub_division, selected_division: null });
  dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) { formatArray[indexFullYear] = thaiYear; }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) { formatArray[indexShortYear] = thaiYear.toString().substr(2); }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  datetimeFormat = (momentObject, format = 'D MMM YY HH:mm', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) { formatArray[indexFullYear] = thaiYear; }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) { formatArray[indexShortYear] = thaiYear.toString().substr(2); }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  render() {
    const {
      loading,
      doc_type_id,
      book_cate_id,
      fdoc_no,
      topic,
      page,
      secret_type_id,
      speed_type_id,
      detail,
      remark,
      master_doc_type_data,
      master_book_cate_data,
      master_secret_data,
      master_speed_data,
      from_text,
      department_type,
      active_tab
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_master_doc_type = _.map(master_doc_type_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.doc_type_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_book_cate = _.map(
        master_book_cate_data,
        (value, key) => {
          return (
            <MenuItem key={key} value={value.book_cate_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      const list_master_secret = _.map(master_secret_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      const list_master_speed = _.map(master_speed_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <Tabs
            id='controlled-tab-example'
            activeKey={active_tab}
            onSelect={active_tab => this.setState({ active_tab })}
          >
            <Tab eventKey='book_data' title='ข้อมูลหนังสือ'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    ลงทะเบียนรับหนังสือ
                  </h3>
                </div>
              </div>
              <br />
              <form className='saveForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    แนบไฟล์หนังสือ
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <input
                      accept='*'
                      style={input}
                      id='contained-button-file'
                      type='file'
                      onChange={({ target: { files } }) => this.onChooseFiles('doc_file', files)}
                      onClick={this.onInputClick}
                    />
                    <label htmlFor='contained-button-file'>
                      <Button variant='contained' component='span'>
                        เลือกไฟล์
                      </Button>
                    </label>
                    {this.state.doc_file && (
                      <div style={attachStyle}>
                        <StyledUploadedFile
                          title={this.state.doc_file.name}
                          style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                          src={toAbsoluteUrl(`/media/files/${this.state.doc_file.name.split('.').pop().toLowerCase()}.svg`)}
                        />
                        <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{this.state.doc_file.name}</div>
                        <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                          <a onClick={(e) => {
                            this.setState({ doc_file: null });
                            e.preventDefault();
                          }}>
                            <i className='flaticon2-cancel-music icon-xs text-danger' />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *วันที่ลงทะเบียนรับ
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={this.state.received_at ? new Date(this.state.received_at) : null}
                      onChange={date => {
                        try {
                          this.setState({ received_at: date ? moment(date).format('YYYY-MM-DD') : null });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      onKeyDown={e => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className='kt-portlet__head'>
                  <div className='kt-portlet__head-label'>
                    <h3 className='kt-portlet__head-title'>
                      รายละเอียดหนังสือรับ
                    </h3>
                  </div>
                </div>
                <br />
                <div className='form-group row'>
                  <StyledLabelRight
                    className='col-md-2 col-form-label'
                    color='red'
                  >
                    *เลือกเล่มทะเบียนรับ
                  </StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      id='book_cate_id'
                      value={book_cate_id}
                      select
                      onChange={e => {
                        this.setData('book_cate_id', e.target.value);
                      }}
                      margin='normal'
                    >
                      {list_master_book_cate}
                    </StyledTextField>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-4'>
                    <Radio
                      checked={department_type == 1}
                      onChange={this.handleChangeDepartmentType}
                      value='1'
                      name='department_type'
                      inputProps={{ 'aria-label': 1 }}
                    />
                    หน่วยงานภายใน
                    <Radio
                      checked={department_type == 2}
                      onChange={this.handleChangeDepartmentType}
                      value='2'
                      name='department_type'
                      inputProps={{ 'aria-label': 2 }}
                    />
                    หน่วยงานภายนอก
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เลขที่หนังสือ
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <StyledTextField
                      required
                      id='fdoc_no'
                      value={fdoc_no}
                      placeholder='XX 0001/0001'
                      onChange={e => {
                        this.setData('fdoc_no', e.target.value);
                      }}
                      inputProps={{ maxLength: 255 }}
                    />
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *ลงวันที่
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={this.state.date_at ? new Date(this.state.date_at) : null}
                      onChange={date => {
                        try {
                          this.setState({ date_at: date ? moment(date).format('YYYY-MM-DD') : null });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      onKeyDown={e => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    ชั้นความลับ
                  </StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      select
                      id='secret_type_id'
                      value={secret_type_id}
                      onChange={e => {
                        this.setData('secret_type_id', e.target.value);
                      }}
                      margin='normal'
                    >
                      {list_master_secret}
                    </StyledTextField>
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    ชั้นความเร็ว
                  </StyledLabelRight>
                  <div className='col-md-3' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      select
                      id='speed_type_id'
                      value={speed_type_id}
                      onChange={e => {
                        this.setData('speed_type_id', e.target.value);
                      }}
                      margin='normal'
                    >
                      {list_master_speed}
                    </StyledTextField>
                  </div>
                </div>
                {this.state.department_type == 2 && (
                  <div className='form-group row' style={{ display: 'none' }}>
                    <div className='col-md-2'></div>
                    {this.state.check_ecms ? (
                      <ButtonBootstrap
                        variant='success'
                        onClick={() =>
                          this.setState({
                            check_ecms: false,
                            from_text: null,
                            selected_division: [],
                          })
                        }
                        style={{ marginLeft: '10px' }}
                      >
                        พิมพ์ชื่อหน่วยงาน
                      </ButtonBootstrap>
                    ) : (
                      <ButtonBootstrap
                        variant='success'
                        onClick={() =>
                          this.setState({
                            check_ecms: true,
                            from_text: null,
                          })
                        }
                        style={{ marginLeft: '10px' }}
                        disabled={_.isEmpty(this.state.department)}
                      >
                        เลือกหน่วยงาน e-CMS
                      </ButtonBootstrap>
                    )
                    }
                  </div>
                )}
                <div className='form-group row'>
                  <StyledLabelRight
                    className='col-md-2 col-form-label'
                    color='red'
                  >
                    *จาก
                  </StyledLabelRight>
                  {department_type == 1 ? (
                    <div className='col-md-9'>
                      <CustomAutocomplete
                        placeholder='หน่วยงาน/หน่วยงานย่อย'
                        items={this.state.division_data}
                        selected_item={this.state.selected_division}
                        handleSelectItem={this.handleSelectDivision}
                      ></CustomAutocomplete>
                    </div>
                  ) : (
                    <>
                      <div className='col-md-9'>
                        {this.state.check_ecms ? (
                          <CustomAutocomplete
                            placeholder='หน่วยงาน e-CMS'
                            items={this.state.department}
                            selected_item={this.state.selected_division}
                            handleSelectItem={this.handleSelectDivision}
                          ></CustomAutocomplete>
                        ) : (
                          <StyledTextField
                            id='from_text'
                            value={from_text}
                            placeholder='หน่วยงาน'
                            onChange={e => {
                              this.setData('from_text', e.target.value);
                            }}
                            inputProps={{ maxLength: 255 }}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className='form-group row'>
                  <StyledLabelRight
                    className='col-md-2 col-form-label'
                    color='red'
                  >
                    *เรื่อง
                  </StyledLabelRight>
                  <div className='col-md-9' style={{ marginTop: -12 }}>
                    <StyledTextField
                      id='note'
                      value={topic}
                      placeholder='เรื่อง'
                      multiline
                      rows='4'
                      margin='normal'
                      variant='outlined'
                      onChange={e => {
                        this.setData('topic', e.target.value);
                      }}
                      inputProps={{ maxLength: 500 }}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight
                    className='col-md-2 col-form-label'
                    color='red'
                  >
                    *เรียน
                  </StyledLabelRight>
                  <div className='col-md-9' style={{ marginTop: -12 }}>
                    <CustomDownshift
                      _id='to_name'
                      focused_id={this.state.focused_id}
                      suggestions={this.props.cache.to_name}
                      inputValue={this.state.to_name}
                      handleInputChange={event =>
                        this.setData('to_name', event.target.value)
                      }
                      handleOnFocus={focused_id =>
                        this.setData('focused_id', focused_id)
                      }
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    จาก
                  </StyledLabelRight>
                  <div className='col-md-9' style={{ marginTop: -12 }}>
                    <CustomDownshift
                      _id='from_name'
                      focused_id={this.state.focused_id}
                      suggestions={this.props.cache.from_name}
                      inputValue={this.state.from_name}
                      handleInputChange={event =>
                        this.setData('from_name', event.target.value)
                      }
                      handleOnFocus={focused_id =>
                        this.setData('focused_id', focused_id)
                      }
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>
                    หมายเหตุ
                  </StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='note'
                      value={remark}
                      placeholder='หมายเหตุ'
                      multiline
                      rows='4'
                      margin='normal'
                      variant='outlined'
                      onChange={e => {
                        this.setData('remark', e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </Tab>
            <Tab eventKey='book_detail' title='รายละเอียดหนังสือ'>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ประเภทเอกสาร
                </StyledLabelRight>
                <div className='col-md-4' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='doc_type_id'
                    value={doc_type_id}
                    onChange={e => {
                      this.setData('doc_type_id', e.target.value);
                    }}
                    margin='normal'
                  >
                    {list_master_doc_type}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  จำนวน
                </StyledLabelRight>
                <div className='col-md-2'>
                  <StyledTextField
                    id='page'
                    type='number'
                    value={page}
                    placeholder='หน้า'
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={e => {
                      this.setData('page', e.target.value);
                    }}
                  />
                </div>
                <StyledLabelLeft className='col-md-1 col-form-label'>
                  หน้า
                </StyledLabelLeft>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  สถานที่เก็บเอกสาร
                </StyledLabelRight>
                <div className='col-md-9' style={{ marginTop: '-12px' }}>
                  <CustomDownshift
                    _id='storage_location'
                    focused_id={this.state.focused_id}
                    suggestions={this.props.cache.storage_location}
                    inputValue={this.state.storage_location}
                    handleInputChange={event =>
                      this.setData('storage_location', event.target.value)
                    }
                    handleOnFocus={focused_id =>
                      this.setData('focused_id', focused_id)
                    }
                    maxLength={255}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  สิ่งที่แนบมาด้วย
                </StyledLabelRight>
                <div className='col-auto'>
                  <input
                    accept='*'
                    style={input}
                    id='attach'
                    type='file'
                    multiple
                    onChange={({ target: { files } }) =>
                      this.onChooseFiles('attach', files)
                    }
                    onClick={this.onInputClick}
                  />
                  <label htmlFor='attach'>
                    <Button variant='contained' component='span'>
                      เลือกไฟล์
                    </Button>
                  </label>
                </div>
                <div className='col-md-7'>
                  {this.state.attach
                    ? _.map(
                      this.state.attach,
                      (v, k) =>
                        v.name && (
                          <div key={k} style={fileStyle}>
                            <StyledUploadedFile
                              title={v.name}
                              style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                              src={toAbsoluteUrl(`/media/files/${v.name.split('.').pop().toLowerCase()}.svg`)}
                            />
                            <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{v.name}</div>
                            <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                              <a onClick={(e) => this.onRemoveAttachFile(e, k)}>
                                <i className='flaticon2-cancel-music icon-xs text-danger' />
                              </a>
                            </div>
                          </div>
                        )
                    )
                    : null}
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  รายละเอียด
                </StyledLabelRight>
                <div className='col-md-9'>
                  <StyledTextField
                    id='detail'
                    value={detail}
                    placeholder='รายละเอียด'
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    onChange={e => {
                      this.setData('detail', e.target.value);
                    }}
                  />
                </div>
              </div>
              {
                _.isArray(this.state.reference_doc) && !_.isEmpty(this.state.reference_doc) ?
                  _.map(this.state.reference_doc, (v, k) => {
                    const reference_doc = this.state.reference_doc;
                    return (
                      <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>
                          {k == 0 ? 'หนังสืออ้างอิง' : ''}
                        </StyledLabelRight>
                        <div className='col-md-2'>
                          <StyledTextField
                            id='outlined-multiline-static'
                            placeholder='เลขที่หนังสือ'
                            multiline
                            margin='normal'
                            value={reference_doc[k].doc_no}
                            onChange={({ target: { value } }) => {
                              reference_doc[k].doc_no = value;
                              this.setState({ reference_doc });
                            }}
                            inputProps={{ maxLength: 255 }}
                          />
                        </div>
                        <div className='col-md-3'>
                          <StyledTextField
                            id='outlined-multiline-static'
                            placeholder='เรื่อง'
                            multiline
                            margin='normal'
                            value={reference_doc[k].topic}
                            onChange={({ target: { value } }) => {
                              reference_doc[k].topic = value;
                              this.setState({ reference_doc });
                            }}
                            inputProps={{ maxLength: 500 }}
                          />
                        </div>
                        <div className='col-md-2'>
                          <DatePicker
                            wrapperClassName="mt-38"
                            placeholderText='วันที่'
                            dateFormat='d MMM yyyy'
                            selected={reference_doc[k].date_at ? new Date(reference_doc[k].date_at) : null}
                            onChange={(date) => {
                              try {
                                reference_doc[k].date_at = date ? moment(date).format('YYYY-MM-DD') : null;
                                this.setState({ reference_doc });
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            onKeyDown={e => e.preventDefault()}
                          />
                        </div>
                        <div className='col-md-3'>
                          <ButtonBootstrap
                            variant='danger'
                            style={{ marginLeft: 16 }}
                            onClick={() => {
                              reference_doc.splice(k, 1);
                              this.setState({ reference_doc });
                            }}>
                            ลบ
                          </ButtonBootstrap>
                          {k == (_.size(reference_doc) - 1) && (
                            <ButtonBootstrap
                              variant='success'
                              style={{ marginLeft: 8 }}
                              onClick={() => {
                                reference_doc.push({
                                  doc_no: '',
                                  topic: '',
                                  date_at: moment().format('YYYY-MM-DD')
                                });
                                this.setState({ reference_doc });
                              }}
                            >
                              เพิ่ม
                            </ButtonBootstrap>
                          )}
                        </div>
                      </div>
                    );
                  }) : (
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label'>
                        หนังสืออ้างอิง
                      </StyledLabelRight>
                      <div className='col-md-1'>
                        <ButtonBootstrap
                          variant='success'
                          onClick={() => {
                            this.setState({
                              reference_doc: [
                                {
                                  doc_no: '',
                                  topic: '',
                                  date_at: moment().format('YYYY-MM-DD')
                                }
                              ],
                            });
                          }}
                        >
                          เพิ่ม
                        </ButtonBootstrap>
                      </div>
                    </div>
                  )
              }
            </Tab>
          </Tabs>
          <div
            className='form-group row'
            style={{
              marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'
            }}
          >
            <StyledButtonRight>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                ลงทะเบียนรับ
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='secondary'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiOutlinedInput-multiline {
    padding: 10px 7px;
    line-height: 1.6;
  }
  .MuiOutlinedInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));