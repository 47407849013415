/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import API from '../api';
import styled from 'styled-components';
import _ from 'lodash';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import moment from 'moment';
import 'moment/locale/th';
moment.locale('th');

class Class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.eform_data,
        };
    }

    replaceEmpty = (txt, suffix) => (!!txt ? txt + (suffix ? ' ' + suffix : '') : '-');

    downloadDocFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/eform/doc/' + id, file_name);
    }

    downloadAttachFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/eform/attach/' + id, file_name);
    }

    downloadFile = async (event, url, file_name) => {
        try {
            const resp = await API.postDownloadFile(url, null, 'header');
            const file = new Blob([resp.data], {
                type: resp.headers['content-type'],
            });
            const fileURL = URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = file_name;
            fileLink.click();
        } catch (err) {
            console.log('error::');
            console.log(err);
        }
    }

    render() {
        const {
            id,
            book_cate_name,
            receive_no,
            doc_no,
            date_at_txt,
            speed_type_name,
            secret_type_name,
            file_name,
            topic,
            to_name,
            announcement_text,
            detail,
            attaches,
            reference_doc,
        } = this.state;
      
        const has_attach = (attaches && attaches.length > 0);
        const has_ref_doc = (reference_doc && reference_doc.length > 0);

        return (
            <div className='kt-portlet kt-portlet--height-fluid'>
                <div className='kt-portlet__head'>
                    <div className='kt-portlet__head-label'>
                        <h3 className='kt-portlet__head-title'>
                        ข้อมูลหนังสือ
                        </h3>
                    </div>
                </div>
                <br />
                
                <form className='infoForm' noValidate autoComplete='off'>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>ทะเบียนส่ง</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(book_cate_name)}</StyledLabelLeft>
                        
                        <StyledLabelRight className='col-md-2 col-form-label'>เลขทะเบียนรับ</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(receive_no)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>เลขที่หนังสือ</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(doc_no)}</StyledLabelLeft>
                        
                        <StyledLabelRight className='col-md-2 col-form-label'>ลงวันที่</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(date_at_txt)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความเร็ว</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(speed_type_name)}</StyledLabelLeft>
                        
                        <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความลับ</StyledLabelRight>
                        <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(secret_type_name)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>ไฟล์หนังสือ</StyledLabelRight>
                        {file_name && (
                        <div className='col-md-3'>
                            <a onClick={(e) => {
                            e.preventDefault();
                            this.downloadDocFile(e, id, file_name);
                            }}>
                            <StyledUploadedFile
                                title={file_name}
                                style={{ width: 36 }}
                                src={toAbsoluteUrl(
                                  `/media/files/${file_name.split('.').pop().toLowerCase()}.svg`
                                )}
                            />
                            </a>
                        </div>
                        )}
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>เรื่อง</StyledLabelRight>
                        <StyledLabelLeft className='col-md-9 col-form-label'>{(_.isEmpty(topic) ? announcement_text : topic)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>เรียน</StyledLabelRight>
                        <StyledLabelLeft className='col-md-9 col-form-label'>{this.replaceEmpty(to_name)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>สิ่งที่ส่งมาด้วย</StyledLabelRight>
                        <StyledLabelLeft className='col-md-9 col-form-label'>{this.replaceEmpty(detail)}</StyledLabelLeft>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>หนังสืออ้างอิง</StyledLabelRight>
                        <div className='col-md-9'>
                        {has_ref_doc === true ? (
                            <ul style={{listStyleType: 'number', marginTop: '12px', paddingLeft: '18px'}}>
                            {_.map(reference_doc, (item) => (
                                <li>{(!!item.topic ? item.topic + ' ' : '') + 'ที่ ' + (!!item.doc_no ? item.doc_no + ' ' : '') + 'ลงวันที่ ' + item.date_at_txt_th_long}</li>
                            ))}
                            </ul>
                        ) : (
                            <StyledLabelLeft className='col-form-label'>-</StyledLabelLeft>
                        )}
                        </div>
                    </div>
                    <div className='form-group row'>
                        <StyledLabelRight className='col-md-2 col-form-label'>ไฟล์สิ่งที่ส่งมาด้วย</StyledLabelRight>
                        <div className='col-md-9'>
                        {has_attach === true ? (
                            <div>
                            {_.map(attaches, (item) => (
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.downloadAttachFile(e, item.eform_attach_id, item.file_name);
                                }}>
                                <StyledUploadedFile
                                    title={item.file_name}
                                    style={{ width: 36, marginRight: '8px' }}
                                    src={toAbsoluteUrl(
                                      `/media/files/${item.file_type.toLowerCase()}.svg`
                                    )}
                                />
                                </a>
                            ))}
                            </div>
                        ) : (
                            <StyledLabelLeft className='col-form-label'>-</StyledLabelLeft>
                        )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: left;
  white-space: pre-wrap;
`;
const StyledUploadedFile = styled.img`
`;

export default Class;