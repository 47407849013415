import React from 'react';
import ReceivingList from '../../widgets/ReceivingList';
import SendingList from '../../widgets/SendingList';
import WaitingList from '../../widgets/WaitingList';
import Stats from '../../widgets/Stats';
import NewsUpdateList from '../../widgets/NewsUpdateList';
import Announcement from '../../widgets/Announcement';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import API from '../../api';
import _ from 'lodash';
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_loading: false,
      data: {
        receive: [],
        send: [],
        wait_receive: [],
      },
    };
  }
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      default:
        break;
    }
  };
  getData = () => {
    this.displayAlert('loading');
    API.get('/console/dashboard/home').then((res) => {
      if (res && res.success) {
        this.setState({
          data: res.data,
        });
      }
      this.setState({ display_loading: false });
      MySwal.close();
    });
  };
  onNotificationClick = _.debounce((e) => {
    console.log('onNotificationClick e -->', e);
  }, 250);
  componentDidMount() {
    this.getData();
    document.addEventListener(
      'notificationclick',
      this.onNotificationClick,
      false
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      'notificationclick',
      this.onNotificationClick,
      false
    );
  }
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-xl-6'>
            <div className='row'>
              <ReceivingList
                history={this.props.history}
                data={
                  this.state.data && _.isArray(this.state.data.receive)
                    ? this.state.data.receive
                    : []
                }
              />
            </div>
            <div className='row'>
              <SendingList
                history={this.props.history}
                data={
                  this.state.data && _.isArray(this.state.data.send)
                    ? this.state.data.send
                    : []
                }
              />
            </div>
          </div>
          <div className='col-xl-6'>
            <Stats
              data={
                this.state.data && _.isArray(this.state.data.chart)
                  ? this.state.data.chart
                  : []
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-6'>
            <div className='row'>
              <WaitingList
                history={this.props.history}
                data={
                  this.state.data && _.isArray(this.state.data.wait_receive)
                    ? this.state.data.wait_receive
                    : []
                }
              />
            </div>
          </div>
          <div className='col-xl-6'>
            <NewsUpdateList
              history={this.props.history}
              data={
                this.state.data && _.isArray(this.state.data.news_update)
                  ? this.state.data.news_update
                  : []
              }
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps)(Class));
