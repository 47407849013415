import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

const head_rows = [
  {
    id: 'book_cate_name',
    sortable: true,
    disablePadding: false,
    label: 'ทะเบียนส่ง',
    align: 'center',
  }, 
  {
    id: 'department_name',
    sortable: true,
    disablePadding: false,
    label: 'กรม',
    align: 'center',
  },
  {
    id: 'division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงาน',
    align: 'center',
  },
  {
    id: 'sub_division_name',
    sortable: true,
    disablePadding: false,
    label: 'หน่วยงานย่อย',
    align: 'center',
  },
  {
    id: 'reserved_send_no',
    sortable: true,
    disablePadding: false,
    label: 'เลขที่จอง',
    align: 'center',
  },
  {
    id: 'reserved_date_txt',
    sortable: true,
    disablePadding: false,
    label: 'วันที่จอง',
    align: 'center',
  },
  {
    id: 'reserved_by',
    sortable: true,
    disablePadding: false,
    label: 'ผู้จอง',
    align: 'center',
  },
  {
    id: 'used_status_name',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
];

class ReserveDocSendNoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      book_cate_data: [],
      division_data: [],
      sub_division_data: [],
      used_status_data: [],
      year: moment().year() + 543,
      book_cate_id: '',
      division_id: '',
      sub_division_id: '',
      used_status: "''",
      table_data: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      disabled_search_division: false
    };
  }

  setData = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');
  onNumerInputKeyDown = (event) => {
    if(event.key === "e" || event.key === "+" || event.key === "-" || event.key === ".") event.preventDefault();
  }

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };

  setMetaData = async () => {
    const book_cate_req = await API.get('/console/book_cate/all?trans_type=2&all_division=0');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }

    const division_req = await API.get('/console/divisions/all');
    if (division_req.success) {
      this.setState({
        division_data: division_req.data,
      });
    }

    const used_status_req = await API.get('/console/static/used_status/all');
    if (used_status_req.success) {
      this.setState({
        used_status_data: used_status_req.data,
      });
    }

    let division_id = '';
    let sub_division_id = '';
    let disabled_search_division = false;

    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      if (!!profile_req.data.division) {
        disabled_search_division = true;
        division_id = profile_req.data.division.division_id;
      }

      if (!!profile_req.data.sub_division) {
        disabled_search_division = true;
        sub_division_id = profile_req.data.sub_division.sub_division_id;
      }

      this.setState({
        division_id,
        sub_division_id,
        disabled_search_division
      });
    }

    if (sub_division_id != '') {
      const sub_division_req = await API.get(
        `/console/sub_divisions/all?division_id=${division_id}`
      );
      if (sub_division_req.success) {
        this.setData('sub_division_data', sub_division_req.data);
      }
    }

    this.getData();
  }

  getData = async () => {
    this.displayAlert('loading');
    const { year, book_cate_id, division_id, sub_division_id, used_status, page } = this.state;
    const response = await API.get(
      `/console/reserve_doc_no?year=${year}&book_cate_id=${book_cate_id}&division_id=${division_id}&sub_division_id=${sub_division_id}&used_status=${used_status}&page=${page}`
    );

    if (response.success) {
      if (response.data != null) {
        for (var idx = 0; idx < response.data.length; idx++) {
          response.data[idx].sub_division_name = this.replaceEmpty(response.data[idx].sub_division_name);
        }
      }
      this.setState(
        {
          table_data: response.data,
          current_page: response.current_page,
          last_page: response.last_page,
          per_page: response.count,
        }
      );
    }

    this.setState({ loading: false }, () => MySwal.close());
  };

  componentDidUpdate(prev_props, prev_state) {
  }

  componentDidMount() {
    this.setMetaData();
  }

  onChangeDivision = async division_id => {
    this.displayAlert('loading');
    const sub_division_req = await API.get(
      `/console/sub_divisions/all?division_id=${division_id}`
    );
    if (sub_division_req.success) {
      this.setData('sub_division_data', sub_division_req.data);
      this.setData('sub_division_id', '');
    }
    MySwal.close();
  };

  render() {
    const { ecs_permission } = this.props;
    const {
      loading,
      year,
      book_cate_data,
      division_data,
      sub_division_data,
      used_status_data,
      disabled_search_division,
    } = this.state;

    if (loading) {
      return null;
    } else {
      const list_book_cate = _.map(book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_division = _.map(division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.division_id}>
            {value.name}
          </MenuItem>
        );
      });

      const list_sub_division = _.map(sub_division_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.sub_division_id}>
            {value.name}
          </MenuItem>
        );
      });

      let list_used_status = _.map(used_status_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      list_used_status.splice(0, 0, <MenuItem key='-1' value="''">ทั้งหมด</MenuItem>);

      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>ค้นหา</h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                  ปี
              </StyledLabelRight>
              <div className='col-md-2'>
                  <StyledTextField
                      id='year'
                      type='number'
                      value={year}
                      placeholder='ปี'
                      onKeyDown={e => {this.onNumerInputKeyDown(e);}}
                      onChange={e => {
                          this.setData('year', e.target.value);
                      }}
                      inputProps={{min: 1, max: 9999}}
                  />
              </div>

              <StyledLabelRight className='col-md-2 col-form-label'>
                ทะเบียนส่ง
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='book_cate_id'
                  select
                  margin='normal'
                  value={this.state.book_cate_id}
                  onChange={(e) => {
                    this.setData('book_cate_id', e.target.value);
                  }}
                >
                  {list_book_cate}
                </StyledTextField>
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงาน
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='division_id'
                  select
                  margin='normal'
                  value={this.state.division_id}
                  disabled={disabled_search_division}
                  onChange={(e) => {
                    this.onChangeDivision(e.target.value);
                    this.setData('division_id', e.target.value);
                  }}
                >
                  {list_division}
                </StyledTextField>
              </div>

              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='sub_division_id'
                  select
                  margin='normal'
                  value={this.state.sub_division_id}
                  disabled={disabled_search_division}
                  onChange={(e) => {
                    this.setData('sub_division_id', e.target.value);
                  }}
                >
                  {list_sub_division}
                </StyledTextField>
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='used_status_id'
                  select
                  margin='normal'
                  value={this.state.used_status}
                  onChange={(e) => {
                    this.setData('used_status', e.target.value);
                  }}
                >
                  {list_used_status}
                </StyledTextField>
              </div>
              <div className='col-md-2 offset-md-1'>
                <Button
                  variant='success'
                  onClick={() => {
                    this.handleChangePage(1);
                  }}
                >
                  ค้นหา
                </Button>
              </div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0, paddingLeft: 10 }}>
            <StyledButtonLeft className='col-md-6'>
              <Button
                variant='success'
                onClick={() => {
                  this.props.history.push('/reserve-doc-send-no-add');
                }}
                disabled={!ecs_permission.division_settings.docs_send_no_reserve.add}
              >
                จองเลข
              </Button>
            </StyledButtonLeft>
          </div>
          <CustomTable
            title='รายการเลขทะเบียนส่ง'
            hideSelect={true}
            showManage={false}
            head_rows={head_rows}
            rows={this.state.table_data ? this.state.table_data : []}
            history={this.props.history}
            reloadData={this.getData}
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            handleChangePage={this.handleChangePage}
          />
        </div>
      );
    }

  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;

const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(ReserveDocSendNoPage));