import React from 'react';
import RegisterDocNoPage from '../register-doc-no/RegisterDocNoPage'
export default function RegisterDocNo() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterDocNoPage />
        </div>
      </div>
    </>
  );
}
