import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import EFormInfo from '../../widgets/EFormInfo';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการส่งผู้บริหารลงนาม?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'reject': {
        MySwal.fire({
          title: 'ต้องการปฏิเสธ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t6?type=wait-send-executive');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;
      
  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-send-executive/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    await this.getData();
    this.setState({
      finished_init_page: true,
      display_loading: false,
    });
  }

  getData = async () => {
    this.displayAlert('loading');
    const {
      id
    } = this.state;
    
    API.get('/console/eform/' + id + '/send_executive').then((res) => {
      if (res && res.success) {
        const eform_data = res.data;
        this.setState(
          {
            eform_data,
          },
          () => MySwal.close()
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        action: action,
        additional_remark: this.state.additional_remark
      }
      
      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/send_executive', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
    } = this.state;

    if (finished_init_page === true) {
      return (
        <div>
          {this.state.eform_data && (<EFormInfo eform_data={this.state.eform_data}></EFormInfo>)}
  
          {this.state.eform_data && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    ส่งผู้บริหารลงนาม
                  </h3>
                </div>
              </div>
              <br />
              <form className='approveForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุเพิ่มเติม<br/>(ไม่แสดงในหนังสือ)</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='additional_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('additional_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() => this.displayAlert('approve')}
                    >
                      ส่งผู้บริหารลงนาม
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='danger'
                      style={{ marginLeft: '5px' }}
                      onClick={() => this.displayAlert('reject')}
                    >
                      ปฏิเสธ
                    </ButtonBootstrap>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));