/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Radio } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: 1,
      name: null,
      code: null,
      organization_id: null,
      department_id: null,
      division_id: null,
      master_doc_organizations_data: [],
      master_doc_departments_data: []
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/division');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/sub-division');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  getData = async () => {
    this.displayAlert('loading');
    try {
      const master_doc_organizations = await API.get(
        `/console/organizations/all`
      );
      if (master_doc_organizations.success) {
        this.setData(
          'master_doc_organizations_data',
          master_doc_organizations.data
        );
      }
      // const master_doc_departments = await API.get(`/console/departments/all`);
      // if (master_doc_departments.success) {
      //   this.setData(
      //     'master_doc_departments_data',
      //     master_doc_departments.data
      //   );
      // }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };

  onChangeDepartment = async organization_id => {
    this.displayAlert('loading');
    const master_doc_departments = await API.get(
      `/console/departments/all?organization_id=${organization_id}`
    );
    if (master_doc_departments.success) {
      this.setData('master_doc_departments_data', master_doc_departments.data);
    }
    MySwal.close();
  };

  onChangeDivision = async department_id => {
    this.displayAlert('loading');
    const master_doc_divisions = await API.get(
      `/console/divisions/all?organization_id=${department_id}`
    );
    if (master_doc_divisions.success) {
      this.setData('master_doc_division_data', master_doc_divisions.data);
    }
    MySwal.close();
  };

  handleChangeStatus = event => {
    this.setData('status', event.target.value);
  };

  componentDidMount() {
    this.getData();
  }

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const {
        code,
        name,
        status,
        department_id,
        organization_id,
        division_id
      } = this.state;
      let data = {
        code,
        name,
        status,
        department_id,
        organization_id,
        division_id
      };
      const create_sub_division = await API.post(
        '/console/sub_divisions/add',
        data
      );
      if (create_sub_division.success) {
        this.displayAlert('success', create_sub_division.data);
      } else {
        this.displayAlert('error', create_sub_division.error);
      }
    } catch (e) {}
  };

  render() {
    const {
      loading,
      code,
      name,
      status,
      department_id,
      organization_id,
      division_id,
      master_doc_organizations_data,
      master_doc_departments_data,
      master_doc_division_data
    } = this.state;
    if (loading) {
      return null;
    } else {
      const list_master_doc_organization = _.map(
        master_doc_organizations_data,
        (value, key) => {
          return (
            <MenuItem key={value.organization_id} value={value.organization_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      const list_master_doc_department = _.map(
        master_doc_departments_data,
        (value, key) => {
          return (
            <MenuItem key={value.department_id} value={value.department_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      const list_master_doc_division = _.map(
        master_doc_division_data,
        (value, key) => {
          return (
            <MenuItem key={value.division_id} value={value.division_id}>
              {value.name}
            </MenuItem>
          );
        }
      );
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>เพิ่มหน่วยงานย่อย</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *กระทรวง
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='organization_id'
                  value={organization_id}
                  select
                  onChange={e => {
                    this.onChangeDepartment(e.target.value);
                    this.setData('organization_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_organization}
                </StyledTextField>
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *กรม
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='department_id'
                  value={department_id}
                  select
                  onChange={e => {
                    this.onChangeDivision(e.target.value);
                    this.setData('department_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_department}
                </StyledTextField>
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *หน่วยงาน
              </StyledLabelRight>
              <div className='col-md-3' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  id='division_id'
                  value={division_id}
                  select
                  onChange={e => {
                    this.setData('division_id', e.target.value);
                  }}
                  margin='normal'
                >
                  {list_master_doc_division}
                </StyledTextField>
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *รหัส
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='code'
                  value={code}
                  onChange={e => {
                    this.setData('code', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ชื่อ
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='name'
                  value={name}
                  onChange={e => {
                    this.setData('name', e.target.value);
                  }}
                  inputProps={{ maxLength: 255 }}
                />
              </div>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-3'>
                <Radio
                  checked={status == 1}
                  onChange={this.handleChangeStatus}
                  value='1'
                  name='status'
                  inputProps={{ 'aria-label': 1 }}
                />
                เปิดใช้งาน
                <Radio
                  checked={status == 0}
                  onChange={this.handleChangeStatus}
                  value='0'
                  name='status'
                  inputProps={{ 'aria-label': 0 }}
                />
                ปิดการใช้งาน
              </div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
