/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import PortletHeaderDropdown from '../partials/content/CustomDropdowns/PortletHeaderDropdown';
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};
const chart_colors = [
  'hsl(0, 70%, 50%)',
  'hsl(25, 70%, 50%)',
  'hsl(50, 70%, 50%)',
  'hsl(75, 70%, 50%)',
  'hsl(100, 70%, 50%)',
  'hsl(125, 70%, 50%)',
  'hsl(150, 70%, 50%)',
  'hsl(175, 70%, 50%)',
  'hsl(200, 70%, 50%)',
  'hsl(225, 70%, 50%)',
  'hsl(250, 70%, 50%)',
  'hsl(275, 70%, 50%)',
  'hsl(300, 70%, 50%)',
  'hsl(325, 70%, 50%)',
  'hsl(350, 70%, 50%)',
  'hsl(375, 70%, 50%)',
  'hsl(400, 70%, 50%)',
];
export default function Function(props) {
  return (
    <>
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>
              สถิติการรับ-ส่งตามประเภทหนังสือประจำเดือน
            </h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className='kt-portlet__body'>
          <ResponsivePie
            theme={{
              fontSize: '1rem',
            }}
            data={
              /* [
              {
                id: 'lisp',
                label: 'lisp',
                value: 314,
                color: 'hsl(322, 70%, 50%)',
              },
              {
                id: 'sass',
                label: 'sass',
                value: 224,
                color: 'hsl(42, 70%, 50%)',
              },
              {
                id: 'ruby',
                label: 'ruby',
                value: 10,
                color: 'hsl(353, 70%, 50%)',
              },
              {
                id: 'javascript',
                label: 'javascript',
                value: 271,
                color: 'hsl(326, 70%, 50%)',
              },
              {
                id: 'make',
                label: 'make',
                value: 101,
                color: 'hsl(62, 70%, 50%)',
              },
            ] */
              !_.isEmpty(props.data)
                ? _.map(
                    _.filter(props.data, (v) => v.value > 0),
                    (v, k) => ({
                      id: v.label,
                      label: v.label,
                      value: v.value,
                      color: v.color || chart_colors[k],
                    })
                  )
                : []
            }
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor='#333333'
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor='#333333'
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={
              [
                /* {
                match: {
                  id: 'ruby',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'c',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'go',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'python',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'scala',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'lisp',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'elixir',
                },
                id: 'lines',
              },
              {
                match: {
                  id: 'javascript',
                },
                id: 'lines',
              }, */
              ]
            }
            // legends={[
            //   {
            //     anchor: 'bottom',
            //     direction: 'row',
            //     translateY: 56,
            //     itemWidth: 100,
            //     itemHeight: 18,
            //     itemTextColor: '#999',
            //     symbolSize: 18,
            //     symbolShape: 'circle',
            //     effects: [
            //       {
            //         on: 'hover',
            //         style: {
            //           itemTextColor: '#000',
            //           symbolSize: 36,
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </div>
      </div>
    </>
  );
}
