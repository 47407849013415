/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
moment.locale('th');
function dateFormat(momentObject, format = 'D MMM YY', splitText = ' ') {
  var formatArray = format.split(splitText);
  var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
  var indexFullYear = formatArray.indexOf('YYYY');
  if (indexFullYear != -1) {
    formatArray[indexFullYear] = thaiYear;
  }
  var indexShortYear = formatArray.indexOf('YY');
  if (indexShortYear != -1) {
    formatArray[indexShortYear] = thaiYear.toString().substr(2);
  }
  format = formatArray.join(' ');
  return momentObject.format(format);
}
//const current_year = new Date().getFullYear();
//const start_date_of_year = new Date('1/1/' + current_year);
//const start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
//const end_date = moment().format('YYYY-MM-DD');
export default function Function(props) {
  return (
    <>
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>ข่าวสาร</h3>
          </div>
          <Button
            variant='info'
            style={{
              height: 48,
              padding: 10,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            onClick={() => {
              props.history.push(`news`);
            }}
          >
            ดูทั้งหมด
          </Button>
        </div>
        <div className='kt-portlet__body'>
          <div className='kt-widget5'>
            {_.map(props.data, (v, k) => {
              return (
                <div key={k} className='kt-widget5__item '>
                  <div className='kt-widget5__content'>
                    <div style={{ paddingRight: '1.25rem' }}>
                      <img
                        alt={v.title}
                        src={toAbsoluteUrl(
                          v.image ? v.image : '/media/files/no_document.svg'
                        )}
                        style={{ width: 64 }}
                      />
                    </div>
                    <div className='kt-widget5__section'>
                      <p style={{ margin: 0, color: '#5578eb' }}>{v.title}</p>
                      {/* <p className='kt-widget5__desc'>{v.description}</p> */}
                      {/* <div className='kt-widget5__info'>
                        <span
                          style={{
                            color: 'gray',
                          }}
                        >
                          ลงวันที่:
                        </span>
                        <span className='kt-font-info'>
                          {dateFormat(moment(v.date_at))}
                        </span>
                      </div> */}
                    </div>
                  </div>
                  <div className='kt-widget5__content'>
                    {/* <div className='kt-widget5__stats'>
                      <span className='kt-widget5__number'>{v.type}</span>
                      <span>Type</span>
                    </div> */}
                    <div className='kt-widget5__stats'>
                      <span
                        style={{
                          color: 'gray',
                        }}
                      >
                        ลงวันที่:{' '}
                        <span className='kt-font-info'>
                          {dateFormat(moment.unix(v.date_stamp))}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
