import React from 'react';
import RegisterBookEditFormPage from '../register-book-edit-form/RegisterBookEditFormPage';
export default function RegisterBookEditForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterBookEditFormPage />
        </div>
      </div>
    </>
  );
}
