import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, Radio } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import ReserveDocNoDialog from '../../widgets/ReserveDocNoDialog';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
      show_doc_no_prefix: false,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนส่ง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/e-form-list/t7?type=wait-send');
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t7?type=wait-send');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  replaceEmpty = (txt, suffix) => (!!txt ? txt + (suffix ? ' ' + suffix : '') : '-');

  downloadDocFile = async (event, id, file_name) => {
      await this.downloadFile(event, '/console/static/download/eform/doc/' + id, file_name);
  }

  downloadAttachFile = async (event, id, file_name) => {
      await this.downloadFile(event, '/console/static/download/eform/attach/' + id, file_name);
  }

  downloadFile = async (event, url, file_name) => {
      try {
          const resp = await API.postDownloadFile(url, null, 'header');
          const file = new Blob([resp.data], {
              type: resp.headers['content-type'],
          });
          const fileURL = URL.createObjectURL(file);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.download = file_name;
          fileLink.click();
      } catch (err) {
          console.log('error::');
          console.log(err);
      }
  }

  onChangeDocDate = (date) => {
    let date_at = moment(date).format('YYYY-MM-DD');
    const selected_year = (parseInt(moment(date).format('YYYY'), 10) + 543).toString();

    let date_at_year = this.state.date_at_year;
    let reserved_doc_no = this.state.reserved_doc_no;
    let doc_no_prefix = this.state.doc_no_prefix;
    let docs_send_no_reserve_id = this.state.docs_send_no_reserve_id;
    
    if (date_at_year != selected_year) {
      reserved_doc_no = '';
      doc_no_prefix = '';
      docs_send_no_reserve_id = null;
    }
    this.setState({
      date_at,
      date_at_year,
      reserved_doc_no,
      doc_no_prefix,
      docs_send_no_reserve_id
    });
  };

  setSelectedReserveDocNo = (docs_send_no_reserve_id, reserved_doc_no) => {
    this.setState({ docs_send_no_reserve_id, reserved_doc_no });
  }

  handleSelectDivisions = (selected_divisions) => {
    this.setState({ selected_divisions });
  }

  handleSelectAllDivisions = () => {
    const new_selected_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_divisions.push(v1);
      }
    });

    let merge_divisions = (selected_divisions == null) ? new_selected_divisions : selected_divisions.concat(new_selected_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
  
  handleSelectAllSubDivisions = () => {
    const new_selected_sub_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.sub_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_sub_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_sub_divisions : selected_divisions.concat(new_selected_sub_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };

  handleSelectAllInternalDivisions = () => {
    const new_selected_internal_divisions = [];
    const { selected_divisions } = this.state;
    _.filter(this.state.internal_division, (v1) => {
      let found = false;
      _.filter(selected_divisions, (v2) => {
        if (v1.value == v2.value) {
          found = true;
        }
      });
      if (!found) {
        new_selected_internal_divisions.push(v1);
      }
    });
    let merge_divisions = (selected_divisions == null) ? new_selected_internal_divisions : selected_divisions.concat(new_selected_internal_divisions);
    this.setState({
      selected_divisions: merge_divisions,
    });
  };
      
  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-send/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    this.displayAlert('loading');

    let division_data = [],
        division = [],
        sub_division = [], 
        department = [], 
        internal_division = [],
        can_change_doc_no_prefix_book_cate_id = [];

    await API.get('/console/static/division_and_sub/all').then((res) => {
      if (res && res.success) {
        division = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));

        sub_division = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
          division_value: 'main_' + v.division_id,
        }));

        department = _.filter(
          res.data,
          (v, k) => k == 'department_id'
        )[0].map((v) => ({
          value: 'department_' + v.id,
          label: v.name,
        }));

        _.forEach(division, (d) => {
          division_data.push(d);
          division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
        });
      }
    });

    await API.get('/console/static/division_and_sub/central').then((res) => {
      if (res && res.success) {
        const division_temp = _.filter(
          res.data,
          (v, k) => k == 'division_id'
        )[0].map((v) => ({
          value: 'main_' + v.id,
          label: v.name,
        }));
        const sub_division_temp = _.filter(
          res.data,
          (v, k) => k == 'sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v.id,
          label: v.name,
          division_value: 'main_' + v.division_id,
        }));
        _.forEach(division_temp, (d) => {
          internal_division.push(d);
          internal_division = internal_division.concat(_.filter(sub_division_temp, (v, k) => v.division_value == d.value));
        });
      }
    });

    await API.get('/console/setting?code=CanChangeDocNoPrefix_BookCategoryID').then(({ success, data }) => {
      if (success && data != null) {
        can_change_doc_no_prefix_book_cate_id = data.value.split(',');
      }
    });

    await this.getData(can_change_doc_no_prefix_book_cate_id, division_data);

    this.setState({
      division_data, 
      division, 
      sub_division, 
      department, 
      internal_division, 
      can_change_doc_no_prefix_book_cate_id,
      finished_init_page: true,
      display_loading: false,
    });
  }

  getData = async (can_change_doc_no_prefix_book_cate_id, division_data) => {
    const {
      id
    } = this.state;
    await API.get('/console/eform/' + id + '/send').then((res) => {
      if (res && res.success) {
        const eform_data = res.data;
        let show_doc_no_prefix = (can_change_doc_no_prefix_book_cate_id.includes(eform_data.book_cate_id.toString()));
        const selected_division_temp = _.filter(
          eform_data,
          (v, k) => k == 'selected_division_id'
        )[0].map((v) => ({
          value: 'main_' + v
        }));
        const selected_sub_division_temp = _.filter(
          eform_data,
          (v, k) => k == 'selected_sub_division_id'
        )[0].map((v) => ({
          value: 'sub_' + v
        }));

        let selected_divisions_temp = [];
        _.forEach(selected_division_temp, (d) => {  
          selected_divisions_temp = selected_divisions_temp.concat(_.filter(division_data, (v, k) => v.value == d.value));
        });
        _.forEach(selected_sub_division_temp, (d) => {  
          selected_divisions_temp = selected_divisions_temp.concat(_.filter(division_data, (v, k) => v.value == d.value));
        });

        this.setState(
          {
            eform_data,
            date_at: eform_data.date_at,
            date_at_year: eform_data.year_th.toString(),
            reserved_doc_no: eform_data.reserved_doc_no,
            doc_no_prefix: eform_data.doc_no_prefix,
            docs_send_no_reserve_id: eform_data.docs_send_no_reserve_id,
            show_doc_no_prefix,
            selected_divisions: selected_divisions_temp,
            division_type: eform_data.division_type, 
            send_to_external: eform_data.send_to_external, 
          },
          () => { MySwal.close(); }
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      let selected_division_id = [];
      let selected_sub_division_id = [];

      _.filter(this.state.selected_divisions, (v) => {
        const str = v.value;
        if (str.includes('main_')) {
            var main = str.split('main_');
            selected_division_id.push(main[1]);
        }
        if (str.includes('sub_')) {
            var sub = str.split('sub_');
            selected_sub_division_id.push(sub[1]);
        }
      });

      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        book_cate_id: this.state.eform_data.book_cate_id,
        action: action,
        date_at_txt: this.state.date_at.replace('T', ' ').replace('/', '-'),
        docs_send_no_reserve_id: this.state.docs_send_no_reserve_id,
        reserved_doc_no: this.state.reserved_doc_no,
        division_type: this.state.division_type,
        doc_no_prefix: this.state.doc_no_prefix,
        send_to_division_id: selected_division_id,
        send_to_sub_division_id: selected_sub_division_id,
        send_to_external: this.state.send_to_external,
        additional_remark: this.state.additional_remark
      }
      
      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/send', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
    } = this.state;
    
    if (finished_init_page === true) {
      const is_received = ((typeof this.state.eform_data.docs_trans_id !== 'undefined') && this.state.eform_data.docs_trans_id !== null);
      return (
        <div>
          {this.state.eform_data && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                  <div className='kt-portlet__head-label'>
                      <h3 className='kt-portlet__head-title'>
                      ข้อมูลหนังสือ
                      </h3>
                  </div>
              </div>
              <br />
              
              <form className='sendForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ทะเบียนส่ง</StyledLabelRight>
                    <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.state.eform_data.book_cate_name)}</StyledLabelLeft>
                    
                    <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ลงวันที่</StyledLabelRight>
                    <div className='col-md-2' style={{ marginTop: '10px' }}>
                      <DatePicker
                        dateFormat='d MMM yyyy'
                        selected={new Date(this.state.date_at)}
                        onChange={(date) => {
                          this.onChangeDocDate(date);
                        }}
                        onKeyDown={e => e.preventDefault()}
                        disabled={is_received}
                      />
                    </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>เลขทะเบียนส่ง</StyledLabelRight>
                  <div className='col-md-4'>
                    <div style={{float: 'left', paddingTop: '6px'}}>
                      <ReserveDocNoDialog
                        year={this.state.date_at_year}
                        book_cate_id={this.state.eform_data.book_cate_id}
                        book_cate_name={this.state.eform_data.book_cate_name}
                        division_name={this.state.eform_data.sender_division_name}
                        sub_division_name={this.state.eform_data.sender_sub_division_name}
                        setSelected={this.setSelectedReserveDocNo}
                        disabled={is_received} />
                    </div>
                    {!_.isEmpty(this.state.reserved_doc_no) && is_received === false && (
                    <div style={{float: 'left'}}>
                      <StyledLabelLeft className='col-form-label' style={{marginLeft: '12px'}}>{this.state.reserved_doc_no}</StyledLabelLeft>
                      <a onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            reserved_doc_no: '',
                            doc_no_prefix:'',
                            docs_send_no_reserve_id: null
                          });
                        }}
                        style={{marginLeft: '8px'}}
                      >
                        <i className='flaticon2-cancel-music icon-xs text-danger' />
                      </a>
                    </div>)}
                  </div>
                  {(this.state.show_doc_no_prefix == true) &&
                    <>
                      <StyledLabelRight className='col-md-2 col-form-label' color='red'>*คำนำหน้าเลขหนังสือ</StyledLabelRight>
                      <div className='col-md-3'>
                        <StyledTextField
                          id='doc_no_prefix'
                          placeholder=''
                          value={this.state.doc_no_prefix}
                          onChange={({ target: { value } }) =>
                            this.onChangeValue('doc_no_prefix', value)
                          }
                          disabled={is_received}
                        />
                      </div>
                    </>
                  }
                </div>
                <div className='form-group row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-lg-2'>
                    <Radio
                      checked={this.state.division_type == '1'}
                      onChange={() => this.onChangeValue('division_type', '1')}
                      value='1'
                      name='radio-division-type'
                      inputProps={{ 'aria-label': '1' }}
                    />
                    หน่วยงานภายใน
                  </div>
                  <div className='col-md-4 col-lg-2'>
                    <Radio
                      checked={this.state.division_type == '2'}
                      onChange={() => this.onChangeValue('division_type', '2')}
                      value='2'
                      name='radio-division-type'
                      inputProps={{ 'aria-label': '2' }}
                    />
                    หน่วยงานภายนอก
                  </div>
                </div>
                {this.state.division_type == '1' ? (
                  <>
                    <div className='form-group row'>
                        <div className='col-md-2'></div>
                        <ButtonBootstrap
                          variant='success'
                          onClick={this.handleSelectAllDivisions}
                          style={{ marginLeft: '10px' }}>
                            ส่งทุกหน่วยงาน
                        </ButtonBootstrap>
                        <ButtonBootstrap
                          variant='success'
                          onClick={this.handleSelectAllSubDivisions}
                          style={{ marginLeft: '10px' }}>
                            ส่งทุกหน่วยงานย่อย
                        </ButtonBootstrap>
                        <ButtonBootstrap
                          variant='success'
                          onClick={this.handleSelectAllInternalDivisions}
                          style={{ marginLeft: '10px' }}>
                            ส่งหน่วยงานส่วนกลาง
                        </ButtonBootstrap>
                    </div>
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่งถึง</StyledLabelRight>
                      <div className='col-md-9'>
                        <CustomAutocomplete
                          isMulti
                          placeholder='หน่วยงาน/หน่วยงานย่อย'
                          items={this.state.division_data}
                          selected_items={this.state.selected_divisions}
                          handleSelectItems={this.handleSelectDivisions}
                          disabled={false}
                        ></CustomAutocomplete>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ส่งถึง</StyledLabelRight>
                      <div className='col-md-9'>
                        <StyledTextField
                          id='send_to_external'
                          placeholder=''
                          value={this.state.send_to_external}
                          onChange={(e) => {
                            this.onChangeValue('send_to_external', e.target.value);
                          }}
                          inputProps={{maxLength: 255}}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความเร็ว</StyledLabelRight>
                    <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.state.eform_data.speed_type_name)}</StyledLabelLeft>
                    
                    <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความลับ</StyledLabelRight>
                    <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.state.eform_data.secret_type_name)}</StyledLabelLeft>
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ไฟล์หนังสือ</StyledLabelRight>
                    {this.state.eform_data.file_name && (
                    <div className='col-md-3'>
                        <a onClick={(e) => {
                        e.preventDefault();
                        this.downloadDocFile(e, this.state.id, this.state.eform_data.file_name);
                        }}>
                        <StyledUploadedFile
                            title={this.state.eform_data.file_name}
                            style={{ width: 36 }}
                            src={toAbsoluteUrl(
                              `/media/files/${this.state.eform_data.file_name.split('.').pop().toLowerCase().toLowerCase()}.svg`
                            )}
                        />
                        </a>
                    </div>
                    )}
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>เรื่อง</StyledLabelRight>
                    <StyledLabelLeft className='col-md-9 col-form-label'>{(_.isEmpty(this.state.eform_data.topic) ? this.state.eform_data.announcement_text : this.state.eform_data.topic)}</StyledLabelLeft>
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>เรียน</StyledLabelRight>
                    <StyledLabelLeft className='col-md-9 col-form-label'>{this.replaceEmpty(this.state.eform_data.to_name)}</StyledLabelLeft>
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>สิ่งที่ส่งมาด้วย</StyledLabelRight>
                    <StyledLabelLeft className='col-md-9 col-form-label'>{this.replaceEmpty(this.state.eform_data.detail)}</StyledLabelLeft>
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>หนังสืออ้างอิง</StyledLabelRight>
                    <div className='col-md-9'>
                    {this.state.eform_data.reference_doc.length > 0 ? (
                        <ul style={{listStyleType: 'number', marginTop: '12px', paddingLeft: '18px'}}>
                        {_.map(this.state.eform_data.reference_doc, (item) => (
                            <li>{(!!item.topic ? item.topic + ' ' : '') + 'ที่ ' + (!!item.doc_no ? item.doc_no + ' ' : '') + 'ลงวันที่ ' + item.date_at_txt_th_long}</li>
                        ))}
                        </ul>
                    ) : (
                      <StyledLabelLeft className='col-form-label'>-</StyledLabelLeft>
                    )}
                    </div>
                </div>
                <div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ไฟล์สิ่งที่ส่งมาด้วย</StyledLabelRight>
                    <div className='col-md-9'>
                    {this.state.eform_data.attaches.length > 0 ? (
                        <div>
                        {_.map(this.state.eform_data.attaches, (item) => (
                            <a onClick={(e) => {
                                e.preventDefault();
                                this.downloadAttachFile(e, item.eform_attach_id, item.file_name);
                            }}>
                            <StyledUploadedFile
                                title={item.file_name}
                                style={{ width: 36, marginRight: '8px' }}
                                src={toAbsoluteUrl(
                                  `/media/files/${item.file_type.toLowerCase()}.svg`
                                )}
                            />
                            </a>
                        ))}
                        </div>
                    ) : (
                        <StyledLabelLeft className='col-form-label'>-</StyledLabelLeft>
                    )}
                    </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุเพิ่มเติม</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='additional_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('additional_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() => this.displayAlert('approve')}
                    >
                      ลงทะเบียนส่ง
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='danger'
                      style={{ marginLeft: '5px' }}
                      onClick={() => this.displayAlert('back')}
                    >
                      กลับ
                    </ButtonBootstrap>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: left;
  white-space: pre-wrap;
`;
const StyledUploadedFile = styled.img`
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));