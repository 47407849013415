import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../api';
import moment from 'moment';
import _ from 'lodash';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import MasterData from '../../variables/MasterData';
import CustomTable from '../../widgets/CustomTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Const from '../../variables/Const';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const textRight = {
  textAlign: 'right',
};
const head_rows = [
  {
    id: 'running',
    label: 'ทะเบียน',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'sended_at',
    label: 'วันที่สร้าง',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'send_no',
    label: 'ที่',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'date_at',
    label: 'ลงวันที่',
    align: 'center',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'send_to',
    label: 'จาก',
    align: 'left',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'to_name',
    label: 'ถึง',
    align: 'left',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'topic',
    label: 'เรือง',
    align: 'left',
    sortable: false,
    disablePadding: false,
  },
  {
    id: 'order_remark',
    label: 'ผู้ปฎิบัติ',
    align: 'left',
    sortable: false,
    disablePadding: false,
  },
];
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'view',
      page: '1',
      book_cate_id: '',
      per_page: Const.Table.RowPerPages,
      start_date: moment(new Date(new Date().getFullYear(),0,1)).format('YYYY-MM-DD'),
      end_date: moment(new Date()).format('YYYY-MM-DD'),
    };
  }
  getData = async () => {
    this.setState({
      list_type_data: MasterData.type_data,
    });
    const book_cate_req = await API.get('/console/book_cate/all?trans_type=2');
    if (book_cate_req.success) {
      this.setState({
        book_cate_data: book_cate_req.data,
      });
    }
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getReport());
  };
  componentDidMount() {
    this.getData();
  }
  onChangeValue = async (key, value) => {
      if (key == 'book_cate_id' && value != this.state.book_cate_id) {
          this.setState({ book_cate_id: value });
      }
      else if (key == "limit_text") {
          if (value.length == 0 || this.validLimitText(value)) {
              this.setState({ limit_text: value });
          }
          else {
              MySwal.fire({
                  title: 'กำหนดลำดับหนังสือไม่ถูกต้อง',
                  icon: 'error',
                  focusConfirm: false,
                  confirmButtonColor: '#5578eb',
                  confirmButtonText: 'ตกลง',
              }).then(() => {
              });
          }
      }
      else if (key == 'doc_no' && value != this.state.doc_no) {
          this.setState({ doc_no: value });
      }
      else if (key == 'title' && value != this.state.title) {
          this.setState({ title: value });
      }
      else if (key == 'transfer_receive_no' && value != this.state.transfer_receive_no) {
          this.setState({ transfer_receive_no: value });
      }
      else if (key == 'type' && value != this.state.type) {
          this.setState({ type: value });
      }
    };
    validLimitText = (value) => {
        let testReg = value.match(/^\d+(?:-\d+)?(?:,\d+(?:-\d+)?)*/);
        return (testReg && testReg[0].length == testReg.input.length);
    }
  getReport = async () => {
    const {
      start_date,
      end_date,
      type,
      book_cate_id,
      start_limit,
      end_limit,
      selected_limit,
      limit_text,
      page,
      title,
      doc_no,
      transfer_receive_no,
    } = this.state;
    let data = {item_per_page: Const.Table.RowPerPages };
    if (book_cate_id) {
      data.book_cate_id = book_cate_id;
    }
    if (start_date) {
      data.start_date = start_date.replace('/', '-');
    }
    if (end_date) {
      data.end_date = end_date.replace('/', '-');
    }
    if (type) {
      data.type = type;
    }
    if (start_limit && start_limit.value) {
      data.start_limit = Number(start_limit.value);
    }
    if (end_limit && end_limit.value) {
      data.end_limit = Number(end_limit.value);
    }
    if (selected_limit && selected_limit.length > 0) {
      data.select_limit = [];
      _.filter(selected_limit, (v) => data.select_limit.push(Number(v.value)));
    }
    if (limit_text) {
      data.limit = limit_text;
    }
    if (page) {
      data.page = page;
    }
    if (title) {
        data.title = title;
    }
    if (doc_no) {
        data.doc_no = doc_no;
    }
    if (transfer_receive_no) {
        data.transfer_receive_no = transfer_receive_no;
    }

    if (type == 'view') {
      await API.post('/console/reports/send/all', data).then((res) => {
        if (res && res.success) {
          this.setState(
            {
              receive_data: res.data,
              current_page: res.current_page,
              last_page: res.last_page,
              per_page: res.count,
            }
            // () => MySwal.close()
          );
        }
      });
    } else {
      const send_report = await API.postDownloadFile(
        '/console/reports/send/all',
        data,
        'header'
      );
        if (!send_report) window.location.href = '/logout';
      const file = new Blob([send_report.data], {
        type: send_report.headers['content-type'],
      });
      const content_disposition = send_report.headers['content-disposition'];
      let fileName = 'Send';
      if (content_disposition) {
        fileName = content_disposition.replace('attachment; filename=', '');
      }
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = fileName;
      fileLink.click();
    }
  };
  render() {
    return (
      <>
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>ทะเบียนส่ง</h3>
            </div>
          </div>
          <br />
          <form className='searchForm' noValidate>
            <div className='form-group row'>
              <label style={textRight} className='col-md-2 col-form-label'>
                ทะเบียนส่ง
              </label>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='standard-select-currency'
                  select
                  margin='normal'
                  value={this.state.book_cate_id}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('book_cate_id', value)
                  }
                >
                  {_.map(this.state.book_cate_data, (v, k) => (
                    <MenuItem key={k} value={v.book_cate_id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                กำหนดลำดับหนังสือ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='limit_text'
                  placeholder='1-4,5,6,10-12'
                  onBlur={(e) => {
                    this.onChangeValue('limit_text', e.target.value);
                  }}
                />
              </div>
              <StyledLabelRight className='col-md-1 col-form-label'>
                ชนิดข้อมูล
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                  id='type'
                  select
                  margin='normal'
                  value={this.state.type}
                  onChange={({ target: { value } }) =>
                    this.onChangeValue('type', value)
                  }
                >
                  {_.map(this.state.list_type_data, (v, k) => (
                    <MenuItem key={k} value={k}>
                      {v}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </div>
            </div>
            
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                เลขที่หนังสือ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='doc_no'
                  value={this.state.doc_no}
                  onChange={({ target: { value } }) =>
                      this.onChangeValue('doc_no', value)
                  }
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                เลขทะเบียนรับ
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='transfer_receive_no'
                  value={this.state.transfer_receive_no}
                  onChange={({ target: { value } }) =>
                      this.onChangeValue('transfer_receive_no', value)
                  }
                />
              </div>
              <StyledLabelRight className='col-md-1 col-form-label'>
                ชื่อเรื่อง
              </StyledLabelRight>
              <div className='col-md-2'>
                <StyledTextField
                  id='title'
                  value={this.state.title}
                  onChange={({ target: { value } }) =>
                      this.onChangeValue('title', value)
                  }
                />
              </div>
            </div>
            <div className='form-group row'>
              <label style={textRight} className='col-md-2 col-form-label'>
                วันที่สร้าง ตั้งแต่
              </label>
              <div className='col-md-2'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={
                    this.state.start_date
                      ? new Date(this.state.start_date)
                      : null
                  }
                  onKeyDown={e => e.preventDefault()}
                  onChange={(date) => {
                    try {
                      this.setState({
                        start_date: moment(date).format('YYYY-MM-DD'),
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </div>
              <label style={textRight} className='col-md-2 col-form-label'>
                ถึง
              </label>
              <div className='col-md-2'>
                <DatePicker
                  dateFormat='d MMM yyyy'
                  selected={
                    this.state.end_date ? new Date(this.state.end_date) : null
                  }
                  onKeyDown={e => e.preventDefault()}
                  onChange={(date) => {
                    try {
                      this.setState({
                        end_date: moment(date).format('YYYY-MM-DD'),
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
              </div>
              <div className='col-md-2' style={textRight}>
                <Button variant='success' onClick={() => { this.setState({ page: '1' }, () => this.getReport())}}>
                  ส่งออกรายงาน
                </Button>
              </div>
            </div>
          </form>
          <CustomTable
            title='ทะเบียนส่ง'
            showManage={false}
            head_rows={head_rows}
            rows={this.state.receive_data ? this.state.receive_data : []}
            history={this.props.history}
            current_page={this.state.current_page}
            last_page={this.state.last_page}
            rowsPerPage={this.state.per_page}
            handleChangePage={this.handleChangePage}
            selecteds={[]}
            hideSelect={true}
          />
        </div>
      </>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
export default withRouter(Class);
