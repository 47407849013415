import React from 'react';
import ReserveDocSendNoAddFormPage from '../reserve-doc-send-no-add-form/ReserveDocSendNoAddFormPage'
export default function ReserveDocSendNoAddForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <ReserveDocSendNoAddFormPage />
        </div>
      </div>
    </>
  );
}
