/* eslint-disable default-case */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';
import * as auth from '../../store/ducks/auth.duck';
import API from '../../api';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'error_with_title': {
        MySwal.fire({
          title: data,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //this.props.devLogin();
            MySwal.close();
          }
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        });
        break;
      }
    }
  };
  handleLogin = async ({ username, password }) => {
    if (!username || username == '' || !password || password == '') {
      this.displayAlert('error_with_title', 'กรุณากรอกข้อมูลให้ครบถ้วน!');
      return;
    }
    this.displayAlert('loading');
    const login_req = await API.post('/login', {
      grant_type: 'password',
      client_id: '2',
      client_secret: 'okEYjy4XOFpMvveAbKzHpoMXoBaMlxQabFrqin5I',
      username,
      password,
      scope: '',
    });
    //console.log('login_req -->', login_req);

    //var login_req = { "success": true, "data": { "token_type": "Bearer", "expires_in": 1667367700, "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiOGUzZmZmOTA1YzEzNmE0OTZkNWFkMmVlYjIxM2YyMWQwZDI0OTM3NDNlMWY1M2Y3OTM3ODY4MmVlZTQzODM1ODg3NzAwZDU3YzNhYjk1NTAiLCJpYXQiOjE2MzU4MzE3MDEsIm5iZiI6MTYzNTgzMTcwMSwiZXhwIjoxNjY3MzY3NzAxLCJzdWIiOiI3MDEiLCJzY29wZXMiOlsiKiJdfQ.LfBJ_r4fYGYbRG52A7o_C9cKXJT4V60f41sq7ECkVMMiGRDe3b8yUSnXiN8v-cS_pSscjIQG1WzGLBqSnfoQUeC14J0GLIyhRKcpQ_dlSzfaPutDb7t0XzvriuXrfxFzin8pkv2dYhvFOHIipu_V7nDbkW_DYC_oECBgj0eh0nXM_G2Q1Tb5Lp3LjyLiP11hybY2qnT91GuXD_M_cMzOnIS4sbwdNaZPxbjhisEwl1ZG01kMxzmUC0mgKjDjOAHK4uYKypjFueiHMGPYhpeiE6Jb9-kXM2giTkJRnQKaa2Z_gk5R3nixYAnfsMPL_BHTHtBFMGVOCu5AVEThpqsuKME-qsTMNiT_QO6L_3cuoXyhSZgG1Y1RQeT2Ah7VwrNWFJ6jBIxhrVrwRuGLGCoU2XqP69QVqi_ljJvCyPsy0wa1fNJPo5qELiDaTyogCfxPcnH_IR0GedQCafycAxWNJulSSkG-kQKOpPxmbChdbGZ-4HzJxJsPUefrHYzyIp4Nrp4kBNfwBrwdNxsHe-WsqRvjQkjVv6HPRunx4NB4wWfDnOng63apjrDVkRFA5dCIXTMYA1QY-68EnpqzYpPN8FQfHpy5fTX-4AQKSUE39_ECLLhxMw4Gst4hC7A6sML7KBEQsrCyg3qCy5inbAbhYfG7bLI-wUviNx2eYNFyHHg", "refresh_token": "def50200d4aabd1deea5219245b3f83907f6b97acfa0711aa35933e5ca4bc457b9eb3b095539a4eb98dc2ec99891a2b43ac2110ec44e71044ee812c7ed8a68cacfa62cc15a3b6a6cc97174675c613d381c2374355e89837ee3df6252b4e2ebfd30b0498afd4cc13afc3166f32fe17f4742685b849c91621b403ad44da369fc72caf4e82579d61d878bb123ed5d78f5020d5ad5b86ba801ca245c9958c484093b096b0699b790a2307bb824b6d9e11a2cc42ad54541c38383b74bd3dbafd776eee9220edd4a7db6bcdbd02d849df8f4595146b5b28162a880cdea344494e088bf7294bfdc4f8608d95bc5a7cd6cd732101e651d4e6a6f2e3c1c7aa2f27b7c0b925e180c545037b855ee1ba2c9aab58ce591682110ce53f5a2abcc0deeeff463a3f9cb4461a0e2d15f4858dd1acfd76f80bd6d1d3fc88a7362e7d8f7898dede6ea7ef7c8604f3581e06aa082c2149bb3f0ace9359f0abac069c59386882905106e51408daa964b" }, "error": null, "count": 4, "status": 200 };
    if (login_req.success) {
      const Authorization = `${login_req.data.token_type} ${login_req.data.access_token}`;
      await localStorage.setItem('Authorization', Authorization);
      this.props.requestUser();
      MySwal.close();
    } else {
      this.displayAlert(
        'error_with_title',
        login_req.error
      );
    }
    /* messaging
      .requestPermission()
      .then(function() {
        console.log('Notification permission granted.');
        return messaging.getToken();
      })
      .then(async (token) => {
        const login_req = await API.post('/login', {
          grant_type: 'password',
          client_id: '2',
          client_secret: 'okEYjy4XOFpMvveAbKzHpoMXoBaMlxQabFrqin5I',
          username,
          password,
          scope: '',
          firebase_token_web: token,
        });
        console.log('login_req -->', login_req);
        if (login_req.success) {
          const Authorization = `${login_req.data.token_type} ${login_req.data.access_token}`;
          await localStorage.setItem('Authorization', Authorization);
          this.props.requestUser();
          MySwal.close();
        } else if (login_req.status_code == 500) {
          this.displayAlert(
            'error_with_title',
            'ชื่อผู้ใช้/อีเมลหรือรหัสผ่านไม่ถูกต้อง!'
          );
        } else {
          this.displayAlert('error', login_req.error);
        }
      })
      .catch(function(err) {
        console.log('Unable to get permission to notify.', err);
      }); */
  };
  render() {
    return (
      <>
        <div className='kt-login__body'>
          <div className='kt-login__form'>
            <div className='kt-login__title'>
              <h3 style={{ textAlign: 'right' }}>ECS</h3>
              <h4 style={{ textAlign: 'right' }}>
                Electronic Correspondent Systems
              </h4>
            </div>
            <Formik
              initialValues={{
                username:
                  !process.env.NODE_ENV ||
                  process.env.NODE_ENV === 'development'
                    ? 'luettgen.ansel@hotmail.com'
                    : '',
                password:
                  !process.env.NODE_ENV ||
                  process.env.NODE_ENV === 'development'
                    ? 'Yotta!'
                    : '',
              }}
              validate={(values) => {
                const errors = {};
                /* if (!values.username) {
                  errors.username = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.username
                  )
                ) {
                  errors.username = intl.formatMessage({
                    id: 'AUTH.VALIDATION.INVALID_FIELD'
                  });
                }
                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                  });
                } */
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                this.handleLogin(values);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  noValidate={true}
                  autoComplete='off'
                  className='kt-form'
                  onSubmit={handleSubmit}
                >
                  {/* {status ? (
                    <div role='alert' className='alert alert-danger'>
                      <div className='alert-text'>{status}</div>
                    </div>
                  ) : (
                    <div role='alert' className='alert alert-info'>
                      <div className='alert-text'>
                        Use account <strong>admin@demo.com</strong> and password{' '}
                        <strong>demo</strong> to continue.
                      </div>
                    </div>
                  )} */}
                  <div className='form-group'>
                    <TextField
                      type='username'
                      label='ชื่อผู้ใช้/อีเมล'
                      margin='normal'
                      className='kt-width-full'
                      name='username'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      helperText={touched.username && errors.username}
                      error={Boolean(touched.username && errors.username)}
                    />
                  </div>
                  <div className='form-group'>
                    <TextField
                      type='password'
                      margin='normal'
                      label='รหัสผ่าน'
                      className='kt-width-full'
                      name='password'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>
                  <div className='kt-login__actions' style={{ float: 'right' }}>
                    {/*<Link*/}
                    {/*  to='/auth/forgot-password'*/}
                    {/*  className='kt-link kt-login__link-forgot'*/}
                    {/*>*/}
                    {/*  ลืมรหัสผ่าน*/}
                    {/*</Link>*/}
                    <button
                      id='kt_login_signin_submit'
                      class='btn btn-primary btn-elevate kt-login__btn-primary '
                      type='submit'
                    >
                      เข้าสู่ระบบ
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
