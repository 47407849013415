import 'react-datepicker/dist/react-datepicker.css';

import { MenuItem, TextField } from '@material-ui/core';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import API from '../../api';
import { Button } from 'react-bootstrap';
import CustomTable from '../../widgets/CustomTable';
import LendingModal from '../../widgets/LendingModal';
import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import th from 'date-fns/locale/th';
import withReactContent from 'sweetalert2-react-content';
import { withRouter } from 'react-router-dom';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'created_at',
    sortable: true,
    disablePadding: false,
    label: 'วันที่เวลา',
    align: 'center',
  },
  {
    id: 'docs_lending_status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
  {
    id: 'name',
    sortable: true,
    disablePadding: false,
    label: 'ชื่อ-นามสกุล',
    align: 'center',
  },
  {
    id: 'remark',
    sortable: true,
    disablePadding: false,
    label: 'รายละเอียด',
    align: 'center',
  },
];
class UserListPage extends React.Component {
  constructor(props) {
    super(props);
    let stores_docs_id = 3;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.stores_docs_id
    ) {
      stores_docs_id = this.props.location.state.stores_docs_id;
    }
    this.state = {
      name: '',
      docs_lending_status: null,
      stores_docs_id,
      docs_lending_status_data: [],
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: 20
    };
  }
  setData = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });

  getSearch = async () => {
    const docs_lending_status = await API.get(
      '/console/static/docs_lending_status/all'
    );
    if (docs_lending_status.success) {
      this.setData('docs_lending_status_data', docs_lending_status.data);
    }

    this.getData();
  };
  getData = async () => {
    const { name, docs_lending_status, stores_docs_id, page, per_page } = this.state;
    let data = {};
    if (name) {
      data.name = name;
    }
    if (docs_lending_status != null) {
      data.docs_lending_status = docs_lending_status;
    }
    if (stores_docs_id) {
      data.stores_docs_id = stores_docs_id;
    }
    if (page) {
      data.page = page;
    }
    data.per_page = per_page;
    this.displayAlert('loading');
    await API.post('/console/lending/list', data).then((res) => {
      if (res && res.success) {
        _.each(res.data, (value, key) => {
          value.docs_lending_status = this.state.docs_lending_status_data[
            value.docs_lending_status
          ];
        });
        this.setState(
          {
            receive_data: res.data,
            current_page: res.current_page,
            last_page: res.last_page,
            per_page: data.per_page,
          },
          () => MySwal.close()
        );
      }
    });
  };

  componentDidMount() {
    this.getSearch();
  }

  render() {
    const { docs_lending_status_data } = this.state;
    const list_docs_lending_status = _.map(
      docs_lending_status_data,
      (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      }
    );
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>ยืมคืนหนังสือ</h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              ชื่อ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='name'
                placeholder='ชื่อ'
                onChange={(e) => {
                  this.setData('name', e.target.value);
                }}
              />
            </div>
            <StyledLabelRight className='col-md-2 col-form-label'>
              สถานะ
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='docs_lending_status'
                select
                value={
                  this.state.docs_lending_status != null
                    ? this.state.docs_lending_status
                    : ''
                }
                onChange={(e) => {
                  this.setData('docs_lending_status', e.target.value);
                }}
                margin='normal'
              >
                {list_docs_lending_status}
              </StyledTextField>
            </div>
            <div className='col-md-2 offset-md-1'>
              <Button
                variant='success'
                onClick={() => {
                  this.setData('page', 1);
                  this.getData();
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </form>
        <div
          className='form-group row'
          style={{ marginBottom: 0, paddingLeft: 10 }}
        >
          <StyledButtonLeft className='col-md-6'>
            <LendingModal
              btnName='เพิ่ม'
              btnColor='success'
              reloadData={this.getData}
              stores_docs_id={this.state.stores_docs_id}
            />
          </StyledButtonLeft>
        </div>
        <CustomTable
          title='ยืมคืนหนังสือ'
          showManage={false}
          isLending={true}
          head_rows={head_rows}
          rows={this.state.receive_data ? this.state.receive_data : []}
          stores_docs_id={this.state.stores_docs_id}
          history={this.props.history}
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          hideSelect={true}
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps, null)(UserListPage));
// export default withRouter(RegisterReceivePage);
