import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import EFormInfo from '../../widgets/EFormInfo';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
      show_krasean: false,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการอนุมัติ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'reject': {
        MySwal.fire({
          title: 'ต้องการไม่อนุมัติ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t4?type=wait-endorse');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;

  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-endorse/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    let user_organization_name = '';
    let user_department_name = '';
    let user_division_id = null;
    let user_division_name = '';
    let user_sub_division_id = null;
    let user_sub_division_name = '';
    
    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      if (!!profile_req.data.organization) {
        user_organization_name = profile_req.data.organization.name;
      }

      if (!!profile_req.data.department) {
        user_department_name = profile_req.data.department.name;
      }

      if (!!profile_req.data.division) {
        user_division_id = profile_req.data.division.division_id;
        user_division_name = profile_req.data.division.name;
      }

      if (!!profile_req.data.sub_division) {
        user_sub_division_id = profile_req.data.sub_division.sub_division_id;
        user_sub_division_name = profile_req.data.sub_division.name;
      }

      this.setState({
        user_organization_name,
        user_department_name,
        user_division_id,
        user_division_name,
        user_sub_division_id,
        user_sub_division_name,
      });
    }

    await this.getData();
    this.setState({
      finished_init_page: true,
      display_loading: false,
    });
  }

  getData = async () => {
    this.displayAlert('loading');
    const {
      id
    } = this.state;
    
    API.get('/console/eform/' + id + '/endorse').then((res) => {
      if (res && res.success) {
        const eform_data = res.data;
        const show_krasean = (eform_data.receive_no !== null && eform_data.sent_executive_at === null);
        this.setState(
          {
            eform_data,
            krasean_to: eform_data.krasean_to,
            krasean_remark: eform_data.krasean_remark,
            show_krasean,
          },
          () => MySwal.close()
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        action: action,
        order_remark: this.state.order_remark,
        additional_remark: this.state.additional_remark,
        reruired_krasean: this.state.show_krasean,
        krasean_to: this.state.krasean_to,
        krasean_remark: this.state.krasean_remark,
      }
      
      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/endorse', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
      show_krasean,
    } = this.state;

    if (finished_init_page === true) {
      return (
        <div>
          {this.state.eform_data && (<EFormInfo eform_data={this.state.eform_data}></EFormInfo>)}
  
          {this.state.eform_data && show_krasean && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    เกษียณหนังสือ
                  </h3>
                </div>
              </div>
              <br />
              <form className='kraseanForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เรียน</StyledLabelRight>
                  <div className='col-md-4'>
                    <StyledTextField
                      id='krasean_to'
                      value={this.state.krasean_to}
                      placeholder=''
                      onChange={(e) => {
                        this.onChangeValue('krasean_to', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='krasean_remark'
                      value={this.state.krasean_remark}
                      placeholder=''
                      multiline
                      rows='3'
                      margin='normal'
                      variant='outlined'
                      onChange={(e) => {
                        this.onChangeValue('krasean_remark', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
  
          {this.state.eform_data && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    พิจารณาอนุมัติ
                  </h3>
                </div>
              </div>
              <br />
              <form className='approveForm' noValidate autoComplete='off'>
                {this.state.eform_data && (<div className='form-group row'>
                    <StyledLabelRight className='col-md-2 col-form-label'>ผู้ลงนาม</StyledLabelRight>
                    <div className='col-md-3'>
                      <StyledLabelLeft className='col-form-label'>{this.replaceEmpty(this.state.eform_data.endorser_name)}</StyledLabelLeft>
                      {this.state.eform_data.endorser_signature_image_path && (
                        <>
                          <br/>
                          <div>
                            <StyledUploadedFile
                              alt="Signature image"
                              style={{ width: 120, height: 'auto' }}
                              src={this.state.eform_data.endorser_signature_image_path}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <StyledLabelRight className='col-md-2 col-form-label'>ตำแหน่ง</StyledLabelRight>
                    <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.state.eform_data.endorser_position)}</StyledLabelLeft>
                </div>)}
                <div className='form-group row'>
                  <div className='col-md-2' style={{textAlign: 'right'}}>
                    <StyledLabelRight className='col-form-label'><span>คำสั่งการ</span><br/><StyledSpan color='red'>(แสดงในหนังสือ)</StyledSpan></StyledLabelRight>
                  </div>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='order_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('order_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุเพิ่มเติม<br/>(ไม่แสดงในหนังสือ)</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='additional_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('additional_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() => this.displayAlert('approve')}
                    >
                      อนุมัติ
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='danger'
                      style={{ marginLeft: '5px' }}
                      onClick={() => this.displayAlert('reject')}
                    >
                      ไม่อนุมัติ
                    </ButtonBootstrap>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: left;
  white-space: pre-wrap;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  
`;
const StyledLinkButton = styled.i`
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledSpan = styled.span`
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));