/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import API from '../api';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import CustomTable from './CustomTable';
import Const from '../variables/Const';
moment.locale('th');

const head_rows = [
    {
        id: 'fullname',
        sortable: true,
        disablePadding: false,
        label: 'ผู้ลงนาม',
        align: 'center',
    },
    {
    id: 'position',
        sortable: true,
        disablePadding: false,
        label: 'ตำแหน่ง',
        align: 'center',
    }
  ];

class Class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            current_page: 1,
            last_page: 1,
            page: 1,
            per_page: 1
        };
    }
    handleOpenDialog = async () => {
        await this.getData();
        this.setState({open: true});
    };
    handleSelectedEndorser = (data) => {
        this.props.setSelected(data.user_id, data.fullname, data.position, data.role_id);
        this.closeDialog();
    }
    closeDialog = () => { this.setState({open: false}); }
    replaceEmpty = (txt) => (!!txt ? txt : '-');
    checkCanChoose = () => { 
        return (this.props.disabled == false) && (this.props.division_name != '' || this.props.sub_division_name != '');
    }
    handleChangePage = (page) => {
        this.setState({ page }, () => this.getData());
    };
    getData = async () => {
        const { page } = this.state;
        let include_executive = true;
        let division_id = '';
        let sub_division_id = '';

        if (this.props.division_id !== undefined && this.props.division_id != null) {
            division_id = this.props.division_id;   
        }
        if (this.props.sub_division_id !== undefined && this.props.sub_division_id != null) {
            sub_division_id = this.props.sub_division_id;
        }
        if (this.props.include_executive !== undefined) {
            include_executive = this.props.include_executive;
        }

        let url = ``;
        if (this.props.role !== undefined && this.props.role === 'executive') {
            url = `/console/users/executive?division_id=${division_id}&sub_division_id=${sub_division_id}&page=${page}&per_page=${Const.Table.RowPerPages}`;
        } else {
            url = `/console/users/endorser?division_id=${division_id}&sub_division_id=${sub_division_id}&include_executive=${include_executive}&page=${page}&per_page=${Const.Table.RowPerPages}`;
        }
        console.log('url: ' + url);
        const response = await API.get(url);    
        if (response.success) {
          this.setState(
            {
              table_data: response.data,
              current_page: response.current_page,
              last_page: response.last_page,
              per_page: response.count,
            }
          );
        }
    }
    componentDidUpdate(prev_props, prev_state) {
    }
    render() {
        return (
            <>
                <Button variant='contained'
                    onClick={(e) => {
                        e.preventDefault();
                        this.handleOpenDialog();
                    }}
                    disabled={!this.checkCanChoose()}
                >
                    เลือก
                </Button>
                <Dialog maxWidth='lg' fullWidth onEntering={() => null} aria-labelledby='confirmation-dialog-title' open={this.state.open}>
                    <DialogTitle id='confirmation-dialog-title'>
                        เลือกผู้ลงนาม
                    </DialogTitle>
                    <DialogContent>
                        <div className='form-group row'>
                            <StyledLabelRight className='col-md-2 col-form-label'>หน่วยงาน</StyledLabelRight>
                            <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.props.division_name)}</StyledLabelLeft>
                            <StyledLabelRight className='col-md-2 col-form-label'>หน่วยงานย่อย</StyledLabelRight>
                            <StyledLabelLeft className='col-md-3 col-form-label'>{this.replaceEmpty(this.props.sub_division_name)}</StyledLabelLeft>
                        </div>
                        <CustomTable
                            title=''
                            showManage={false}
                            singleSelect={true}
                            head_rows={head_rows}
                            rows={this.state.table_data ? this.state.table_data : []}
                            history={this.props.history}
                            reloadData={this.getData}
                            current_page={this.state.current_page}
                            last_page={this.state.last_page}
                            rowsPerPage={this.state.per_page}
                            handleChangePage={this.handleChangePage}
                            setSelected={this.handleSelectedEndorser}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ButtonBootstrap
                            variant='success'
                            onClick={() => {
                                this.closeDialog();
                            }}
                        >
                            ตกลง
                        </ButtonBootstrap>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledFileLink = styled.a`
    margin: auto 0;
`;
const StyledUploadedFile = styled.img`
    width: 36px;
    padding-right: 0.5rem;
`;
export default Class;