/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, Radio, Switch, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

const name_menus = {
  view: 'เห็นเมนู',
  add: 'เพิ่มข้อมูล',
  edit: 'แก้ไขข้อมูล',
  delete: 'ลบข้อมูล'
};
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: 1,
      name: null,
      ecs_permission: []
    };
  }
  setData = (key, value) => {
    this.setState({ [key]: value });
  };
  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการบันทึก?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/role');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/role');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };
  getData = async () => {
    this.displayAlert('loading');
    try {
      const id = this.props.history.location.pathname
        .split('/role-edit/')
        .pop();
      await API.get(`/console/roles/${id}/edit`).then(({ success, data }) => {
        if (success) {
          this.setState(
            {
              id,
              ...data
            },
            () => console.log('this.state -->', this.state)
          );
        }
      });

      const master_roles = await API.get(`/console/roles/permission/all`);
      if (master_roles.success) {
        this.setState({ master_role_data: master_roles.data });
      }
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  handleChangeStatus = event => {
    this.setData('status', event.target.value);
  };
  handleChangeSwitch = (main, sub, action) => () => {
    this.setState(
      {
        ecs_permission: {
          ...this.state.ecs_permission,
          [main]: {
            ...this.state.ecs_permission[main],
            [sub]: {
              ...this.state.ecs_permission[main][sub],
              [action]: !this.state.ecs_permission[main][sub][action],
            }
          }
        }
      },
      () => {
        console.log(
          'ecs_permission.' + [main] + '.' + [sub] + ' -->',
          this.state.ecs_permission[main][sub]
        );
      }
    );
  };

  componentDidMount() {
    this.getData();
  }
  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { id, name, status, ecs_permission } = this.state;
      let data = { name, status, ecs_permission };
      const responce = await API.post(`/console/roles/${id}/edit`, data);
      if (responce.success) {
        this.displayAlert('success', responce.data);
      } else {
        this.displayAlert('error', responce.error);
      }
    } catch (e) {}
  };
  render() {
    const { loading, name, status, master_role_data } = this.state;
    if (loading) {
      return null;
    } else {
      const list_role = [];
      _.each(master_role_data, (v1, main) => {
        let l2 = [];
        _.each(v1, (v2, sub) => {
          let l3 = [];
          _.each(v2, (v3, action) => {
            l3.push(
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.handleChangeSwitch(main, sub, action)}
                    checked={this.state.ecs_permission[main][sub][action]}
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={name_menus[action] ? name_menus[action] : action}
              />
            );
          });
          l2.push(
            <ul className='col-form-label' key={sub}>
              {sub}
              <br />
              {l3}
            </ul>
          );
        });
        list_role.push(
          <ul className='col-form-label' key={main}>
            {main}
            {l2}
          </ul>
        );
      });
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>
                แก้ไขสิทธิ์การเข้าถึงข้อมูล
              </h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *ชื่อ
              </StyledLabelRight>
              <div className='col-md-3'>
                <StyledTextField
                  id='name'
                  value={name}
                  onChange={e => {
                    this.setData('name', e.target.value);
                  }}
                  inputProps={{maxLength: 255,}}
                />
              </div>
              <StyledLabelRight className='col-md-2 col-form-label'>
                สถานะ
              </StyledLabelRight>
              <div className='col-md-3'>
                <Radio
                  checked={status == 1}
                  onChange={this.handleChangeStatus}
                  value='1'
                  name='status'
                  inputProps={{ 'aria-label': 1 }}
                />
                เปิดใช้งาน
                <Radio
                  checked={status == 0}
                  onChange={this.handleChangeStatus}
                  value='0'
                  name='status'
                  inputProps={{ 'aria-label': 0 }}
                />
                ปิดการใช้งาน
              </div>
            </div>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                *สิทธิ์การเข้าถึงข้อมูล
              </StyledLabelRight>
              <div className='col-md-7'>{list_role}</div>
            </div>
          </form>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
  margin-left: 8;
  margin-right: 8;
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
