/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Radio, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: null,
      description: null,
      status: 1,
      image: null,
      date_stamp: moment().format('YYYY-MM-DD'),
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
    if (key == 'image') {
      this.setState({ image_path: URL.createObjectURL(value) });
    }
  };

  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/news');
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };

  getData = async () => {
    this.displayAlert('loading');
    try {
      const id = this.props.history.location.pathname
        .split('/news-view/')
        .pop();
      await API.get(`/console/news/${id}/edit`).then(({ success, data }) => {
        if (success) {
          this.setState(
            {
              id,
              ...data,
              image: null,
              image_path: data.image,
              date_stamp: moment.unix(data.date_stamp).format('YYYY-MM-DD'),
            },
            () => console.log('this.state -->', this.state)
          );
        }
      });
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.getData();
  }

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { id, title, description, status, image, date_stamp } = this.state;
      let data = {
        title,
        description,
        status,
        image,
        date_stamp,
      };
      let form_data = new FormData();
      _.filter(data, (v, k) => {
        if (v && v != '') {
          if (k == 'image') {
            form_data.append(k, v);
          } else {
            form_data.set(k, v);
          }
        }
      });
      const edit_news = await API.post(`/console/news/${id}/edit`, form_data);
      if (edit_news.success) {
        this.displayAlert('success', edit_news.data);
      } else {
        this.displayAlert('error', edit_news.error);
      }
    } catch (e) {}
  };

  render() {
    const { loading, title, description } = this.state;
    if (loading) {
      return null;
    } else {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>{title}</h3>
            </div>
          </div>
          <br />
          <div className='form-group row'>
            {this.state.image_path && (
              <div
                className='col-md-12 col-form-label'
                style={{ marginLeft: '2rem', textAlign: 'center' }}
              >
                <StyledImage alt='image' src={this.state.image_path} />
              </div>
            )}
          </div>
          <div className='form-group row'>
            <StyledTopic className='col-md-12'>{title}</StyledTopic>
          </div>

          <div className='form-group row'>
            <StyledDescription className='offset-md-1 col-md-10'>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </StyledDescription>
          </div>

          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTopic = styled.span`
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 32px !important;
`;
const StyledDescription = styled.span`
  text-indent: 48pt !important;
  text-align: left !important;
  font-size: 24px !important;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledImage = styled.img`
  text-align: 'center';
  width: 40%;
  height: auto;
`;
const mapStateToProps = (state) => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));
