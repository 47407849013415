import React from 'react';
import EFormSendExecutivePage from '../e-form-send-executive/EFormSendExecutivePage';
export default function EFormSendExecutive() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <EFormSendExecutivePage />
        </div>
      </div>
    </>
  );
}
