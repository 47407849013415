/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class RegisterBookEditFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query_string: null,
      trans_type: null,
      page_title_text: '',
      book_cate_text: '',
      organization_name: null,
      department_name: null,
      division_name: null,
      sub_division_name: null,
      division_id: null,
      sub_division_id: null,
      selecteds: [],
      current_page: 1,
      last_page: 1,
      page: 1,
      per_page: 20,
    };
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  displayAlert = (type = 'save', data = null) => {
    switch (type) {
      case 'save': {
        MySwal.fire({
          title: 'ต้องการแก้ไข?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave();
          } else {
            return;
          }
        });
        break;
      }
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.push('/register-book?trans_type=' + this.state.trans_type);
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading()
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/register-book?trans_type=' + this.state.trans_type);
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
    }
  };

  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };

  getData = async () => {
    this.displayAlert('loading');
    try {

      this.setState({
        page_title_text: '',
        book_cate_text: '',
        organization_name: '-',
        department_name: '-',
        division_name: '-',
        sub_division_name: '-',
        division_id: null,
        sub_division_id: null,
        book_cate_data: [],
        selecteds: [],
      });

      const id = this.props.history.location.pathname.split('/register-book-edit/').pop();
      var idPart = id.split('-');
      let trans_type = idPart[0];
      let division_id = idPart[1];
      let sub_division_id = (idPart[2] !== '0' ? idPart[2] : '');

      await API.get(`/console/register_book/edit?trans_type=${trans_type}&division_id=${division_id}&sub_division_id=${sub_division_id}`).then(
        ({ success, data }) => {
          if (success) {
            let book_cate_text = '';
            switch (trans_type) {
              case '1':
                book_cate_text = 'ทะเบียนรับ';
                break;
              case '2':
                book_cate_text = 'ทะเบียนส่ง';
                break;
              default: {
                break;
              }
            }
            let page_title_text = 'กำหนด' + book_cate_text;

            let selectedTemp = [];
            if (data.book_cate_divisions != null) {
              for (var idx = 0; idx < data.book_cate_divisions.length; idx++) {
                if (data.book_cate_divisions[idx].check_edit === true) {
                  selectedTemp.push(data.book_cate_divisions[idx].book_cate_id);
                }
              }
            }

            this.setState({
              trans_type,
              page_title_text,
              book_cate_text,
              organization_name: data.organization_name,
              department_name: data.department_name,
              division_name: data.division_name,
              sub_division_name: data.sub_division_name,
              division_id,
              sub_division_id,
              book_cate_data: data.book_cate_divisions,
              per_page: data.book_cate_divisions.length,
              selecteds: selectedTemp
            });
          }
        }
      );

      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };

  setSelecteds = (selecteds) => {
    this.setState({ selecteds });
  }

  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () => this.getData());
    }
  }
  
  componentDidMount() {
    this.getData();
  }

  onSave = async () => {
    this.displayAlert('loading');
    try {
      const { trans_type, division_id, sub_division_id, selecteds } = this.state;
      let book_cate_ids = selecteds;
      let data = {};
      if (sub_division_id) {
        data = { trans_type, division_id, sub_division_id, book_cate_ids };
      } else {
        data = { trans_type, division_id, book_cate_ids };
      }
      const update_book_cate_div = await API.post(
        `/console/register_book/edit`,
        data
      );
      if (update_book_cate_div.success) {
        this.displayAlert('success', update_book_cate_div.data);
      } else {
        this.displayAlert('error', update_book_cate_div.error);
      }
    } catch (e) {}
  };

  render() {
    const {
      loading,
      page_title_text,
      organization_name,
      department_name,
      division_name,
      sub_division_name,
      book_cate_data
    } = this.state;

    if (loading) {
      return null;
    } else {
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <div className='kt-portlet__head'>
            <div className='kt-portlet__head-label'>
              <h3 className='kt-portlet__head-title'>{page_title_text}</h3>
            </div>
          </div>
          <br />
          <form className='saveForm' noValidate autoComplete='off'>
            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                กระทรวง
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {organization_name}
              </StyledLabelLeft>
              
              <StyledLabelRight className='col-md-2 col-form-label'>
                กรม
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {department_name}
              </StyledLabelLeft>
            </div>

            <div className='form-group row'>
              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงาน
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {division_name}
              </StyledLabelLeft>

              <StyledLabelRight className='col-md-2 col-form-label'>
                หน่วยงานย่อย
              </StyledLabelRight>
              <StyledLabelLeft className='col-md-2 col-form-label'>
                {sub_division_name}
              </StyledLabelLeft>
            </div>
          </form>
          <div className='form-group row'>
            <CustomTable
              title=''
              allowSelect={true}
              showManage={false}
              head_rows={[{ id: 'name', sortable: false, disablePadding: false, label: this.state.book_cate_text, align: 'center', }]}
              rows={book_cate_data ? book_cate_data : []}
              history={this.props.history}
              selecteds={this.state.selecteds}
              setSelecteds={this.setSelecteds}
              isRegisterBook={true}
              current_page={this.state.current_page}
              last_page={this.state.last_page}
              rowsPerPage={this.state.per_page}
              handleChangePage={this.handleChangePage}
              />
          </div>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <StyledButtonRight className='col-md-7'>
              <ButtonBootstrap
                variant='info'
                onClick={() => this.displayAlert()}
              >
                บันทึก
              </ButtonBootstrap>
              <ButtonBootstrap
                variant='danger'
                style={{ marginLeft: '5px' }}
                onClick={() => this.displayAlert('back')}
              >
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: 'left';
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(RegisterBookEditFormPage));