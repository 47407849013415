import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'log_id',
    sortable: true,
    disablePadding: false,
    label: 'log_id',
    align: 'center',
  },
  {
    id: 'user_id',
    sortable: true,
    disablePadding: false,
    label: 'user_id',
    align: 'center',
  },
  {
    id: 'xid',
    sortable: true,
    disablePadding: false,
    label: 'xid',
    align: 'center',
  },
  {
    id: 'table',
    sortable: true,
    disablePadding: false,
    label: 'table',
    align: 'center',
  },
  {
    id: 'type',
    sortable: true,
    disablePadding: false,
    label: 'type',
    align: 'center',
  },
  {
    id: 'comment',
    sortable: true,
    disablePadding: false,
    label: 'comment',
    align: 'left',
  },
  {
    id: 'ip',
    sortable: true,
    disablePadding: false,
    label: 'ip',
    align: 'center',
  },
  {
    id: 'str_created',
    sortable: true,
    disablePadding: false,
    label: 'created',
    align: 'left',
  },
];
var rows = [];
class UserLogPage extends React.Component {
  table = React.createRef();
  constructor(props) {
    super(props);
    const start_date = moment().format('YYYY-MM-DD');
    const end_date = moment().format('YYYY-MM-DD');
    this.state = {
      display_loading: false,
      query_string: null,
      type: null,
      start_date,
      end_date,
      page: 1,
      per_page: 10,
      selecteds: [],

      type_data: [],
    };
  }
  unicodeToChar = (text) =>
    typeof text === 'string' && text != '' ? text.replace(/\\u[\dA-F]{4}/gi, (match) => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))) : null;
  getQueryStringFromUrl = (callback = null) => {
    this.displayAlert('loading');
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const type = this.unicodeToChar(url_params.get('type'));
    const start_date = this.unicodeToChar(url_params.get('start_date'));
    const end_date = this.unicodeToChar(url_params.get('end_date'));
    const page = this.unicodeToChar(url_params.get('page'));
    this.setState(
      {
        query_string,
        type,
        start_date: moment(start_date, 'YYYY-MM-DD', true).isValid()
          ? start_date
          : moment().format('YYYY-MM-DD'),
        end_date: moment(end_date, 'YYYY-MM-DD', true).isValid()
          ? end_date
          : moment().format('YYYY-MM-DD'),
        page: page || '1'
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };
  setData = (key, value) => { this.setState({ [key]: value, }); };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      default:
        break;
    }
  };
  getData = async () => {
    this.setState({ selecteds: [] });
    this.displayAlert('loading');
    const {
      type,
      start_date,
      end_date,
      page,
    } = this.state;
    const data = {
      type,
      start_date,
      end_date,
      page,
    };
    API.post('/console/user_log/list', data).then((res) => {
      if (res && res.success) {
        this.setState(
          {
            log_data: res.data,
            current_page: res.current_page,
            last_page: res.last_page,
            per_page: res.count,
            display_loading: false,
          },
          () => MySwal.close()
        );
      }
    });
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };
  getSearch = async () => {
    const user_log_type = await API.get('/console/static/user_log_type/all');
    if (user_log_type.success) {
      this.setData('type_data', user_log_type.data);
    }
    this.getData();
  };
  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.getSearch())
      );
    }
  }
  onSubmit = (no_time = false) => {
    const type = this.state.type || '';
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const path = `/user-log?type=${type}&start_date=${start_date}&end_date=${end_date}&page=1`;
    console.log('path -->', path);
    this.props.history.push(path);
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string == query_string) {
      this.getData();
    }
    setTimeout(
      () => {
        const query_string = window.location.search;
        if (
          this.state.query_string &&
          this.state.query_string != query_string
        ) {
          this.getQueryStringFromUrl(() => this.getSearch());
        }
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: this.table.current === undefined || this.table.current.offsetTop === undefined ? 553 : this.table.current.offsetTop,
        });
      },
      no_time ? 0 : 250
    );
  };
  onEnter = _.debounce((e) => {
    if (e.keyCode === 13) {
      this.onSubmit(true);
    }
  }, 250);
  componentDidMount() {
    console.log('process.env.REACT_APP_MODE -->', process.env.REACT_APP_MODE);
    document.addEventListener('keydown', this.onEnter, false);
    this.getQueryStringFromUrl(() => this.getSearch());
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }
  render() {
    const {
      type,
      type_data
    } = this.state;
    const list_type = _.map(type_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>
              ค้นหา
            </h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              Type
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='type'
                select
                value={type || ''}
                onChange={(e) => {
                  this.setData('type', e.target.value);
                }}
                margin='normal'>
                {list_type}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              Created
            </StyledLabelRight>
            <div className='col-md-2'>
              <DatePicker
                dateFormat='d MMM yyyy'
                selected={new Date(this.state.start_date)}
                onChange={(date) => {
                  try {
                    this.setState({
                      start_date: date ? moment(date).format('YYYY-MM-DD') : null,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              to
            </StyledLabelRight>
            <div className='col-md-2'>
              <DatePicker
                dateFormat='d MMM yyyy'
                selected={new Date(this.state.end_date)}
                onChange={(date) => {
                  try {
                    this.setState({
                      end_date: date ? moment(date).format('YYYY-MM-DD') : null,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
          </div>
        </form>
        <div className='form-group row' style={{ marginBottom: 0, paddingTop: 0 }}>
          <StyledButtonRight className='col-md-12'>
            <Button
              variant='success'
              onClick={() => {
                this.onSubmit();
              }}
            >
              ค้นหา
            </Button>
          </StyledButtonRight>
        </div>
        <div ref={this.table}></div>
        <CustomTable
          title='ข้อมูล'
          hideSelect={true}
          showManage={false}
          head_rows={head_rows}
          rows={this.state.log_data ? this.state.log_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={false}
          btnDeleteDisabled={false}
        />
      </div>
    ) 
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(
  connect(mapStateToProps)(UserLogPage)
);