import React from 'react';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TextField, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import EndorserDialog from '../../widgets/EndorserDialog';
import EFormInfo from '../../widgets/EFormInfo';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished_init_page: false,
      display_loading: false,
      show_krasean: false,
      is_secretary_office: false,
      krasean_send_executive: false,
    };
  }

  setSelectedEndorser = (endorser_id, endorser_name, endorser_position) => {
    this.setState({ 
      endorser_id: endorser_id,
      selected_endorser_name: (!!endorser_name ? endorser_name : ''), 
      endorser_name: (!!endorser_name ? endorser_name : ''),
      endorser_position: (!!endorser_position ? endorser_position : '') 
    });
  }

  setData = (key, value) => {
    this.setState({ [key]: value });
  };

  onChangeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  replaceEmpty = (txt) => (!!txt ? txt : '-');

  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'approve': {
        MySwal.fire({
          title: 'ต้องการอนุมัติ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'reject': {
        MySwal.fire({
          title: 'ต้องการไม่อนุมัติ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก'
        }).then(({ value: result }) => {
          if (result) {
            this.onSave(type);
          } else {
            return;
          }
        });
        break;
      }
      case 'success': {
        MySwal.fire({
          title: 'สำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          this.props.history.push('/e-form-list/t3?type=wait-review');
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง'
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      default:
        break;
    }
  };

  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
      )
      : null;
      
  getQueryStringFromUrl = (callback = null) => {
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const id = this.props.history.location.pathname.split('/e-form-review/').pop();

    this.setState(
      {
        query_string,
        id
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };

  setMetaData = async () => {
    let user_organization_name = '';
    let user_department_name = '';
    let user_division_id = null;
    let user_division_name = '';
    let user_sub_division_id = null;
    let user_sub_division_name = '';
    
    const profile_req = await API.get('/console/users/profile');
    if (profile_req.success) {
      if (!!profile_req.data.organization) {
        user_organization_name = profile_req.data.organization.name;
      }

      if (!!profile_req.data.department) {
        user_department_name = profile_req.data.department.name;
      }

      if (!!profile_req.data.division) {
        user_division_id = profile_req.data.division.division_id;
        user_division_name = profile_req.data.division.name;
      }

      if (!!profile_req.data.sub_division) {
        user_sub_division_id = profile_req.data.sub_division.sub_division_id;
        user_sub_division_name = profile_req.data.sub_division.name;
      }

      this.setState({
        user_organization_name,
        user_department_name,
        user_division_id,
        user_division_name,
        user_sub_division_id,
        user_sub_division_name,
      });
    }

    await API.get('/console/setting?code=Division_OfficeSecretaryCode').then(({ success, data }) => {
      if (success && data != null) {
        const office_secretary_code = data.value;
        this.setState({
          office_secretary_code
        });
      }
    });

    await API.get('/console/static/doc_krasean_purpose/all').then(({ success, data }) => {
      if (success) {
        this.setState({
          master_krasean_purpose_data: data,
        });
      }
    });

    await this.getData();
    this.setState({
      finished_init_page: true,
      display_loading: false,
    });
  }

  getData = async () => {
    this.displayAlert('loading');
    const {
      id
    } = this.state;
    
    API.get('/console/eform/' + id + '/review').then((res) => {
      if (res && res.success) {
        const eform_data = res.data;
        const show_krasean = (eform_data.receive_no !== null);
        const is_secretary_office = (this.state.office_secretary_code === eform_data.sender_division_code && eform_data.sender_sub_division_id == null);
        this.setState(
          {
            eform_data,
            show_krasean,
            is_secretary_office,
          },
          () => MySwal.close()
        );
      } else {
        this.displayAlert('error', res.error);
      }
    });
  };

  onSave = async (action) => {
    this.displayAlert('loading');
    try {
      const data = {
        eform_trans_id: this.state.eform_data.id,
        eform_id: this.state.eform_data.eform_id,
        action: action,
        additional_remark: this.state.additional_remark,
        reruired_krasean: this.state.show_krasean,
        krasean_send_executive: this.state.krasean_send_executive,
        krasean_to: this.state.krasean_to,
        krasean_remark: this.state.krasean_remark,
        endorser_id: this.state.endorser_id,
        endorser_name: this.state.endorser_name,
        endorser_position: this.state.endorser_position,
      }
      
      const resp = await API.post('/console/eform/' + this.state.eform_data.id + '/review', data);
      if (resp.success) {
        this.displayAlert('success', resp.data);
      } else {
        this.displayAlert('error', resp.error);
      }
    } catch (e) {
      this.displayAlert('error');
    }
    this.setState({
      display_loading: false,
    })
  };

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate()');
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.setMetaData())
      );
    }
  }

  componentDidMount() {
    console.log('componentDidMount()');
    this.getQueryStringFromUrl(() => this.setMetaData());
  }

  render() {
    const {
      finished_init_page,
      master_krasean_purpose_data,
      show_krasean,
      is_secretary_office,
    } = this.state;

    if (finished_init_page === true) {
      const list_master_krasean_purpose = _.map(master_krasean_purpose_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
  
      return (
        <div>
          {this.state.eform_data && (<EFormInfo eform_data={this.state.eform_data}></EFormInfo>)}
  
          {this.state.eform_data && show_krasean && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    เกษียณหนังสือ
                  </h3>
                </div>
              </div>
              <br />
              <form className='kraseanForm' noValidate autoComplete='off'>
                {is_secretary_office && (
                  <div className='form-group row'>
                    <div className='col-md-2' />
                    <div className='col-md-9'>
                      <Checkbox onChange={(e) => this.onChangeValue('krasean_send_executive', e.target.checked)}></Checkbox><label>ผู้บริหาร ลงนามสูงสุด</label>
                    </div>
                  </div>
                )}
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*เรียน</StyledLabelRight>
                  <div className='col-md-4'>
                    <StyledTextField
                      id='krasean_to'
                      placeholder=''
                      onChange={(e) => {
                        this.onChangeValue('krasean_to', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                    />
                  </div>
                </div>
                <div className='row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*รายละเอียด</StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-14px' }}>
                    <StyledTextField
                      select
                      id='krasean_purpose_id'
                      onChange={(e) => {
                        const selected_value = e.target.value;
                        const selected_name = master_krasean_purpose_data[selected_value];
                        this.setData('krasean_remark', selected_name);
                        this.onChangeValue('krasean_purpose_id', selected_value);
                      }}
                      margin='normal'
                    >
                      {list_master_krasean_purpose}
                    </StyledTextField>
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='krasean_remark'
                      value={this.state.krasean_remark}
                      placeholder=''
                      multiline
                      rows='3'
                      margin='normal'
                      variant='outlined'
                      onChange={(e) => {
                        this.onChangeValue('krasean_remark', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ผู้ลงนาม</StyledLabelRight>
                  <div className='col-md-3'>
                      {!_.isEmpty(this.state.selected_endorser_name) &&
                        <div style={{float: 'left'}}>
                          <StyledLabelLeft className='col-form-label' style={{marginRight: '12px'}}>{this.state.selected_endorser_name}</StyledLabelLeft>
                        </div>
                      }
                    <div style={{float: 'left', paddingTop: '6px'}}>
                      <EndorserDialog
                        division_name={this.state.user_division_name}
                        sub_division_name={this.state.user_sub_division_name}
                        setSelected={this.setSelectedEndorser}
                        include_executive={false}
                        disabled={false}/>
                    </div>
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ชื่อ-นามสกุล</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='endorser_name'
                      placeholder=''
                      value={this.state.endorser_name}
                      onChange={(e) => {
                        this.onChangeValue('endorser_name', e.target.value);
                      }}
                      inputProps={{maxLength: 255}}
                    />
                  </div>
                </div>  
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>*ตำแหน่ง</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='endorser_position'
                        value={this.state.endorser_position}
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('endorser_position', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
              </form>
            </div>
          )}
  
          {this.state.eform_data && (
            <div className='kt-portlet kt-portlet--height-fluid'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>
                    พิจารณาอนุมัติ
                  </h3>
                </div>
              </div>
              <br />
              <form className='approveForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุเพิ่มเติม<br/>(ไม่แสดงในหนังสือ)</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                        id='additional_remark'
                        placeholder=''
                        multiline
                        rows='3'
                        margin='normal'
                        variant='outlined'
                        onChange={(e) => {
                          this.onChangeValue('additional_remark', e.target.value);
                        }}
                        inputProps={{maxLength: 255}}
                      />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-md-12' style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'}}>
                    <ButtonBootstrap
                      variant='success'
                      onClick={() => this.displayAlert('approve')}
                    >
                      อนุมัติ
                    </ButtonBootstrap>
                    <ButtonBootstrap
                      variant='danger'
                      style={{ marginLeft: '5px' }}
                      onClick={() => this.displayAlert('reject')}
                    >
                      ไม่อนุมัติ
                    </ButtonBootstrap>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
  text-align: left;
  white-space: pre-wrap;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 10px !important;
  margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
  
`;
const StyledLinkButton = styled.i`
  color: ${(props) => props.color || '#646c9a'};
`;

const mapStateToProps = state => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));