/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import { makeStyles, lighten, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Typography,
  TableSortLabel,
  TablePagination
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import styled from 'styled-components';
import Pagination from 'react-bootstrap/Pagination';
moment.locale('th');
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const custom_style = {
    head_rows_label: {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 'Bold',
    },
  };
  return (
    <TableHead>
      <TableRow>
        {_.map(props.head_rows, (head_row) => (
          <TableCell
            key={head_row.id}
            style={custom_style.head_rows_label}
            align={head_row.align ? head_row.align : 'left'}
            padding={head_row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === head_row.id ? order : false}
          >
            {head_row.sortable ? (
              <TableSortLabel
                active={orderBy === head_row.id}
                direction={order}
                onClick={createSortHandler(head_row.id)}
              >
                {head_row.label}
              </TableSortLabel>
            ) : (
              head_row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));
//const styleButton = {
//  marginLeft: 5,
//  padding: 2,
//  width: 28,
//  height: 28,
//};
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            จำนวนที่เลือก {numSelected}
          </Typography>
        ) : (
          <Typography variant='h6' id='tableTitle'>
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      {/* <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton aria-label='Delete'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Filter list'>
            <IconButton aria-label='Filter list'>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));
function dateFormat(momentObject, format = 'D MMM YY', splitText = ' ') {
  var formatArray = format.split(splitText);
  var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
  var indexFullYear = formatArray.indexOf('YYYY');
  if (indexFullYear != -1) {
    formatArray[indexFullYear] = thaiYear;
  }
  var indexShortYear = formatArray.indexOf('YY');
  if (indexShortYear != -1) {
    formatArray[indexShortYear] = thaiYear.toString().substr(2);
  }
  format = formatArray.join(' ');
  return momentObject.format(format);
}
export default function Function(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage ? props.rowsPerPage : 10
  );
  const [check_all, setCheckAll] = useState(false);
  useEffect(() => {
    setRowsPerPage(props.rowsPerPage);
  });
  function handleRequestSort(event, property) {
    if (property == 'id') {
      return;
    }
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }
  function handleSelectAllClick(event) {
    console.log('event.target -->', event.target);
    /* if (event.target.checked) { */
    if (!check_all) {
      /* const new_selecteds = []; */
      const new_selecteds = _.map(
        props.rows,
        (row) => row.check_edit && row.docs_trans_id
      );
      // console.log('new_selecteds -->', new_selecteds);
      props.setSelecteds(_.compact(new_selecteds));
      setCheckAll(true);
      return;
    }
    props.setSelecteds([]);
    setCheckAll(false);
  }
  function handleClick(event, name) {
    const selectedIndex = props.selecteds.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selecteds, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selecteds.slice(1));
    } else if (selectedIndex === props.selecteds.length - 1) {
      newSelected = newSelected.concat(props.selecteds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selecteds.slice(0, selectedIndex),
        props.selecteds.slice(selectedIndex + 1)
      );
    }
    props.setSelecteds(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);
  //let _numrow = 1;
  return (
    <>
      <div className='kt-portlet__body' style={{ paddingTop: 0 }}>
        <div className='kt-section__content'>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              {props.title && (
                <EnhancedTableToolbar
                  title={props.title || 'Unknown'}
                  numSelected={props.selecteds && props.selecteds.length}
                />
              )}
              <StyledCustomTable>
                <Table
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size={'medium'}
                  style={{ color: 0 }}
                >
                  <EnhancedTableHead
                    numSelected={props.selecteds && props.selecteds.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={props.rows.length}
                    head_rows={props.head_rows}
                    showManage={props.showManage}
                  />
                  <TableBody>
                    {stableSort(props.rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        row = props.isStore ? row.docs_data : row;
                        const key = `custom-table-${index + 1}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              props.isReceive &&
                              row.check_receive &&
                              !props.isStore &&
                              handleClick(event, row.docs_trans_id)
                            }
                            role='checkbox'
                            tabIndex={-1}
                            key={key}
                          >
                            {_.map(props.head_rows, (head_row) => {
                              let row_v = row[head_row.id];
                              if (head_row.id == 'title') {
                                row_v = (
                                  <a href={/news-view/ + row.news_id}>
                                    {row_v}
                                  </a>
                                );
                              }
                              if (
                                (head_row.id == 'date_stamp' || head_row.id == 'date_stamp_to') &&
                                row[head_row.id]
                              ) {
                                row_v = dateFormat(
                                  moment.unix(Number(row[head_row.id])),
                                  'D MMM YY'
                                );
                              }
                              if (
                                row_v === undefined ||
                                (row_v && row_v.length == 0)
                              )
                                row_v = '-';
                              return (
                                head_row.id != 'docs_trans_id' &&
                                (head_row.id != 'id' &&
                                (row_v || row_v == 0) ? (
                                  <TableCell
                                    style={{
                                      minWidth:
                                        head_row.id == 'received_at'
                                          ? '6rem'
                                          : '5rem',
                                      width:
                                        props.isReceive &&
                                        head_row.id == 'topic'
                                          ? '10rem'
                                          : 'auto',
                                    }}
                                    align={head_row.align || 'left'}
                                  >
                                    {head_row.id == 'send_to' ||
                                    head_row.id == 'from'
                                      ? row_v
                                          .split('<br>')
                                          .map((place) => <p> {place} </p>)
                                      : row_v}
                                  </TableCell>
                                ) : (
                                  <TableCell>-</TableCell>
                                ))
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    {props.rows.length == 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={props.head_rows.length}
                          style={{
                            minWidth: '5rem',
                            width: 'auto',
                            height: '2rem',
                          }}
                          align={'center'}
                        >
                          ไม่พบข้อมูล
                        </TableCell>
                      </TableRow>
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={props.head_rows.length + 2} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </StyledCustomTable>
              <TablePagination
                /* rowsPerPageOptions={[5, 10, 25, 50, 100]} */
                rowsPerPageOptions={[20]}
                component='div'
                count={props.rows.length}
                // count={123}
                /* rowsPerPage={rowsPerPage} */
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              {props.current_page && props.rows.length > 0 && (
                <Pagination style={{ justifyContent: 'center', padding: 10 }}>
                  {props.current_page > 2 && (
                    <Pagination.First
                      onClick={() => {
                        console.log('GO First');
                        props.handleChangePage(1);
                      }}
                    />
                  )}
                  {props.current_page > 2 && (
                    <Pagination.Prev
                      onClick={() => {
                        console.log('GO Prev');
                        props.handleChangePage(props.current_page - 1);
                      }}
                    />
                  )}
                  {props.current_page > 1 && (
                    <Pagination.Item
                      onClick={() => {
                        console.log('GO', props.current_page - 1);
                        props.handleChangePage(props.current_page - 1);
                      }}
                    >
                      {props.current_page - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{props.current_page}</Pagination.Item>
                  {props.current_page + 1 <= props.last_page && (
                    <Pagination.Item
                      onClick={() => {
                        console.log('GO', props.current_page + 1);
                        props.handleChangePage(props.current_page + 1);
                      }}
                    >
                      {props.current_page + 1}
                    </Pagination.Item>
                  )}
                  {props.current_page + 2 <= props.last_page && (
                    <Pagination.Next
                      onClick={() => {
                        console.log('GO NEXT');
                        props.handleChangePage(props.current_page + 1);
                      }}
                    />
                  )}
                  {props.current_page + 2 <= props.last_page && (
                    <Pagination.Last
                      onClick={() => {
                        console.log('GO LAST');
                        props.handleChangePage(props.last_page);
                      }}
                    />
                  )}
                </Pagination>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
}
const StyledCustomTable = styled.div`
  .MuiButtonBase-root {
    padding: 2px !important;
  }
  .MuiTableCell-head {
    padding: 5px !important;
    font-weight: bold;
  }
  .MuiTableCell-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    vertical-align: top;
  }
`;
const StyledUploadedFile = styled.img`
  width: 36px;
  padding-right: 0.5rem;
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2%;
  margin-top: 0px;
`;
