import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
const head_rows = [
  {
    id: 'doc_download',
    sortable: false,
    disablePadding: false,
    label: 'เอกสาร',
    align: 'center',
  },
  {
    id: 'receive_no',
    sortable: true,
    disablePadding: false,
    label: 'เลขที่',
    align: 'center',
  },
  {
    id: 'year',
    sortable: true,
    disablePadding: false,
    label: 'ปี',
    align: 'center',
  },
  {
    id: 'received_at',
    sortable: true,
    disablePadding: false,
    label: 'วันที่รับ',
    align: 'center',
  },
  {
    id: 'send_no',
    sortable: true,
    disablePadding: false,
    label: 'เลขหนังสือ',
    align: 'left',
  },
  {
    id: 'date_at',
    sortable: true,
    disablePadding: false,
    label: 'ลงวันที่',
    align: 'left',
  },
  {
    id: 'from',
    sortable: true,
    disablePadding: false,
    label: 'จาก',
    align: 'left',
  },
  {
    id: 'topic',
    sortable: true,
    disablePadding: false,
    label: 'เรื่อง',
    align: 'left',
  },
  {
    id: 'received_by',
    sortable: true,
    disablePadding: false,
    label: 'โดย',
    align: 'left',
  },
  {
    id: 'doc_status',
    sortable: true,
    disablePadding: false,
    label: 'สถานะ',
    align: 'center',
  },
];
var rows = [];
class RegisterReceivePage extends React.Component {
  table = React.createRef();
  constructor(props) {
    super(props);
    const current_year = new Date().getFullYear();
    const start_date_of_year = new Date('1/1/' + current_year);
    const start_date = moment(start_date_of_year.valueOf()).format('YYYY-MM-DD');
    const end_date = moment().format('YYYY-MM-DD');
    this.state = {
      display_loading: false,
      query_string: null,
      status: 'all',
      doc_status: null,
      doc_no: null,
      item_no: null,
      keyword: null,
      start_date,
      end_date,
      book_cate_id: null,
      doc_type_id: null,
      secret_type_id: null,
      speed_type_id: null,
      page: 1,
      display_filters: 'false',
      doc_status_text: '',
      per_page: 10,
      selecteds: [],
      master_doc_type_data: null,
      master_book_cate_data: null,
      master_doc_status_data: null,
      master_secret: null,
      master_speed: null,
      form_type_id: null,
      from_txt: null,

      division_id: null,
      sub_division_id: null,
      division_req: [],
      sub_division_req: [],
    };
  }
  unicodeToChar = (text) =>
    typeof text === 'string' && text != '' ? text.replace(/\\u[\dA-F]{4}/gi, (match) => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))) : null;
  getQueryStringFromUrl = (callback = null) => {
    this.displayAlert('loading');
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const division_id = this.unicodeToChar(url_params.get('division_id'));
    const sub_division_id = this.unicodeToChar(url_params.get('sub_division_id'));
    const doc_status = this.unicodeToChar(url_params.get('doc_status'));
    const doc_no = this.unicodeToChar(url_params.get('doc_no'));
    const item_no = this.unicodeToChar(url_params.get('item_no'));
    const keyword = this.unicodeToChar(url_params.get('keyword'));
    const start_date = this.unicodeToChar(url_params.get('start_date'));
    const end_date = this.unicodeToChar(url_params.get('end_date'));
    const book_cate_id = this.unicodeToChar(url_params.get('book_cate_id'));
    const doc_type_id = this.unicodeToChar(url_params.get('doc_type_id'));
    const form_type_id = this.unicodeToChar(url_params.get('form_type_id'));
    const from_txt = this.unicodeToChar(url_params.get('from_txt'));
    const page = this.unicodeToChar(url_params.get('page'));
    this.setState(
      {
        query_string,
        status: doc_status || 'all',
        division_id,
        sub_division_id,
        doc_status,
        doc_no,
        item_no,
        keyword,
        start_date: moment(start_date, 'YYYY-MM-DD', true).isValid()
          ? start_date
          : moment()
            .add(-60, 'days')
            .format('YYYY-MM-DD'),
        end_date: moment(end_date, 'YYYY-MM-DD', true).isValid()
          ? end_date
          : moment().format('YYYY-MM-DD'),
        book_cate_id,
        doc_type_id,
        form_type_id,
        from_txt,
        page: page || '1'
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };
  setData = (key, value) => { this.setState({ [key]: value, }); };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        if (this.state.display_loading) return;
        this.setState(
          {
            display_loading: true,
          },
          () =>
            MySwal.fire({
              title: 'กำลังโหลด...',
              confirmButtonColor: '#5578eb',
              onBeforeOpen: () => MySwal.showLoading(),
              allowOutsideClick: () => !MySwal.isLoading(),
            })
        );
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        });
        break;
      }
      default:
        break;
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  renderInput = (inputProps) => {
    const { InputProps, ref, ...other } = inputProps;
    return (
      <StyledTextField
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    );
  };
  onChangeDivision = async division_id => {
    const sub_division = await API.get(
      `/console/sub_divisions/all?division_id=${division_id}`
    );
    if (sub_division.success) {
      this.setData('sub_division_req', sub_division.data);
      this.setData('sub_division_id', '');
    }
  };
  getData = async () => {
    this.setState({ selecteds: [] });
    this.displayAlert('loading');
    const {
      start_date,
      end_date,
      doc_type_id,
      book_cate_id,
      doc_status,
      keyword,
      page,
      doc_no,
      item_no,
      secret_type_id,
      speed_type_id,
      master_book_cate_data,
      form_type_id,
      from_txt,

      division_id,
      sub_division_id,
    } = this.state;
    const data = {
      start_date,
      end_date,
      doc_type_id,
      book_cate_id,
      doc_status,
      keyword,
      page,
      doc_no,
      item_no,
      secret_type_id,
      speed_type_id,
      form_type_id,
      from_txt,

      division_id,
      sub_division_id,
    };
    API.post('/console/receive/search', data).then((res) => {
      _.filter(res.data, (v, k) => {
        const book_cate = _.find(
          master_book_cate_data,
          (value) => value.book_cate_id == v.book_cate_id && value.name
        );
        const book_cate_name = book_cate ? book_cate.name : '-';
        v.send_no = book_cate_name + ' ' + v.send_no;
      });
      if (res) {
        if (res.success) {
          this.setState(
            {
              receive_data: res.data,
              current_page: res.current_page,
              last_page: res.last_page,
              per_page: res.count,
              display_loading: false,
            },
            () => MySwal.close()
          );
        } else {
          this.displayAlert('error', res.error);
        }
      }
    });
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };
  getSearch = async () => {
    const division = await API.get('/console/divisions/all');
    if (division.success) {
      this.setState({
        division_req: division.data,
      });
    }
    if (this.state.division_id != null) {
      const sub_division = await API.get(`/console/sub_divisions/all?division_id=${this.state.division_id}`);
      if (sub_division.success) {
        this.setState({
          sub_division_req: sub_division.data,
        });
      }
		}
    const master_doc_type = await API.get('/console/doc_type/all');
    if (master_doc_type.success) {
      this.setData('master_doc_type_data', master_doc_type.data);
    }
    const master_book_cate = await API.get(
      '/console/book_cate/all?trans_type=1'
    );
    if (master_book_cate.success) {
      this.setData('master_book_cate_data', master_book_cate.data);
    }
    const master_doc_status = await API.get(
      '/console/static/doc_status/all?trans_type=1'
    );
    if (master_doc_status.success) {
      this.setData('master_doc_status_data', master_doc_status.data);
    }
    const master_secret = await API.get(`/console/static/master_secret/all`);
    if (master_secret.success) {
      this.setData('master_secret_data', master_secret.data);
    }
    const master_form_type = await API.get(`/console/static/master_form_type/all`);
    if (master_form_type.success) {
      this.setData('master_form_type_data', master_form_type.data);
    }
    const master_speed = await API.get(`/console/static/master_speed/all`);
    if (master_speed.success) {
      this.setData('master_speed_data', master_speed.data);
    }
    this.getData();
  };
  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.setState({ query_string }, () =>
        this.getQueryStringFromUrl(() => this.getSearch())
      );
    }
  }
  onSubmit = (no_time = false) => {
    const division_id = this.state.division_id || '';
    const sub_division_id = this.state.sub_division_id || '';
    const doc_status = this.state.doc_status || '';
    const doc_no = this.state.doc_no || '';
    const item_no = this.state.item_no || '';
    const keyword = this.state.keyword || '';
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const book_cate_id = this.state.book_cate_id || '';
    const doc_type_id = this.state.doc_type_id || '';
    const form_type_id = this.state.form_type_id || '';
    const from_txt = this.state.from_txt || '';
    const path = `/register-receive?division_id=${division_id}&sub_division_id=${sub_division_id}&doc_status=${doc_status}&doc_no=${doc_no}&item_no=${item_no}&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}&book_cate_id=${book_cate_id}&doc_type_id=${doc_type_id}&form_type_id=${form_type_id}&from_txt=${from_txt}&page=1`;
    console.log('path -->', path);
    this.props.history.push(path);
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string == query_string) {
      this.getData();
    }
    setTimeout(
      () => {
        const query_string = window.location.search;
        if (
          this.state.query_string &&
          this.state.query_string != query_string
        ) {
          this.getQueryStringFromUrl(() => this.getSearch());
        }
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: this.table.current === undefined || this.table.current.offsetTop === undefined ? 553 : this.table.current.offsetTop,
        });
      },
      no_time ? 0 : 250
    );
  };
  onEnter = _.debounce((e) => {
    if (e.keyCode === 13) {
      this.onSubmit(true);
    }
  }, 250);
  componentDidMount() {
    console.log('process.env.REACT_APP_MODE -->', process.env.REACT_APP_MODE);
    document.addEventListener('keydown', this.onEnter, false);
    this.getQueryStringFromUrl(() => this.getSearch());
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }
  render() {
    const {
      doc_type_id,
      book_cate_id,
      doc_status,
      secret_type_id,
      speed_type_id,
      division_req,
      sub_division_req,
      master_doc_type_data,
      master_book_cate_data,
      master_doc_status_data,
      master_secret_data,
      master_speed_data,
      form_type_id,
      master_form_type_data,
    } = this.state;
    const list_division = _.map(division_req, (value, key) => {
      return (
        <MenuItem key={key} value={value.division_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_sub_division = _.map(sub_division_req, (value, key) => {
      return (
        <MenuItem key={key} value={value.sub_division_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_master_doc_type = _.map(master_doc_type_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.doc_type_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_master_book_cate = _.map(master_book_cate_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.book_cate_id}>
          {value.name}
        </MenuItem>
      );
    });
    const list_master_doc_status = _.map(master_doc_status_data, (value, key) => {
      return (
        <MenuItem key={key} value={value.doc_status_id}>
          {value.name}
        </MenuItem>
      );
    }
    );
    const list_master_secret = _.map(master_secret_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_master_speed = _.map(master_speed_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    const list_master_form_type = _.map(master_form_type_data, (value, key) => {
      return (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      );
    });
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>
              รายการหนังสือรับ (ค้นหา)
            </h3>
          </div>
        </div>
        <br />
        <form className='searchForm' noValidate>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label' color='red'>
              *หน่วยงาน
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='division_id'
                select
                margin='normal'
                value={this.state.division_id}
                onChange={(e) => {
                  this.onChangeDivision(e.target.value);
                  this.setData('division_id', e.target.value);
                }}
              >
                {list_division}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              หน่วยงานย่อย
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='sub_division_id'
                select
                margin='normal'
                value={this.state.sub_division_id}
                onChange={(e) => {
                  this.setData('sub_division_id', e.target.value);
                }}
              >
                {list_sub_division}
              </StyledTextField>
            </div>
          </div>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              เลขที่หนังสือ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='doc_no'
                placeholder='เลขที่หนังสือ'
                value={this.state.doc_no}
                onChange={(e) => {
                  this.setData('doc_no', e.target.value);
                }}
              />
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              เลขทะเบียนรับ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='item_no'
                placeholder='เลขทะเบียนรับ'
                value={this.state.item_no}
                onChange={(e) => {
                  this.setData('item_no', e.target.value);
                }}
              />
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              คำสำคัญ
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='keyword'
                placeholder='คำสำคัญ'
                value={this.state.keyword}
                onChange={(e) => {
                  this.setData('keyword', e.target.value);
                }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              วันที่รับ ตั้งแต่
            </StyledLabelRight>
            <div className='col-md-2'>
              <DatePicker
                dateFormat='d MMM yyyy'
                selected={new Date(this.state.start_date)}
                onChange={(date) => {
                  try {
                    this.setState({
                      start_date: date ? moment(date).format('YYYY-MM-DD') : null,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              ถึง
            </StyledLabelRight>
            <div className='col-md-2'>
              <DatePicker
                dateFormat='d MMM yyyy'
                selected={new Date(this.state.end_date)}
                onChange={(date) => {
                  try {
                    this.setState({
                      end_date: date ? moment(date).format('YYYY-MM-DD') : null,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              ทะเบียนรับ
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='book_cate_id'
                value={book_cate_id}
                select
                onChange={(e) => {
                  this.setData('book_cate_id', e.target.value);
                }}
                margin='normal'
              >
                {list_master_book_cate}
              </StyledTextField>
            </div>
          </div>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              สถานะ
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='doc_status'
                select
                value={doc_status || ''}
                onChange={(e) => {
                  this.setData('doc_status', e.target.value);
                }}
                margin='normal'>
                {list_master_doc_status}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              ประเภท
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                id='doc_type_id'
                select
                value={doc_type_id || ''}
                onChange={(e) => {
                  this.setData('doc_type_id', e.target.value);
                }}
                margin='normal'
              >
                {list_master_doc_type}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              แบบฟอร์ม
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                select
                id='form_type_id'
                value={form_type_id}
                onChange={(e) => {
                  this.setData('form_type_id', e.target.value);
                }}
                margin='normal'
              >
                {list_master_form_type}
              </StyledTextField>
            </div>
          </div>
          <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
              ชั้นความลับ
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                select
                id='secret_type_id'
                value={secret_type_id}
                onChange={(e) => {
                  this.setData('secret_type_id', e.target.value);
                }}
                margin='normal'
              >
                {list_master_secret}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              ชั้นความเร็ว
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
              <StyledTextField
                select
                id='speed_type_id'
                value={speed_type_id}
                onChange={(e) => {
                  this.setData('speed_type_id', e.target.value);
                }}
                margin='normal'
              >
                {list_master_speed}
              </StyledTextField>
            </div>
            <StyledLabelRight className='col-md-1 col-form-label'>
              จาก
            </StyledLabelRight>
            <div className='col-md-2'>
              <StyledTextField
                id='from_txt'
                placeholder='จาก'
                value={this.state.from_txt}
                onChange={(e) => {
                  this.setData('from_txt', e.target.value);
                }}
              />
            </div>
          </div>
        </form>
        <div className='form-group row' style={{ marginBottom: 0, paddingTop: 0 }}>
            <StyledButtonRight className='col-md-12'>
              <Button
                variant='success'
                onClick={() => {
                  this.onSubmit();
                }}
              >
                ค้นหา
              </Button>
            </StyledButtonRight>
        </div>
        <div ref={this.table}></div>
        <CustomTable
          title='รายการหนังสือรับ'
          hideSelect={true}
          showManage={true}
          head_rows={head_rows}
          book_cate_data={master_book_cate_data}
          rows={this.state.receive_data ? this.state.receive_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage={'/register-receive-view/'}
          btnManageName={
            <Icon className='flaticon-edit-1' style={{ fontSize: 18 }} />
          }
          btnManageAlign='left'
          isViewReceive={true}
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={false}
          btnDeleteDisabled={false}
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
  color: ${props => props.color || '#646c9a'};
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(
  connect(mapStateToProps)(RegisterReceivePage)
);
