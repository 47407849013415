import React from 'react';
import RegisterSendEditFormPage from '../register-send-edit-form/RegisterSendEditFormPage';
export default function Function() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <RegisterSendEditFormPage />
        </div>
      </div>
    </>
  );
}
