/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */

import 'moment/locale/th';

import {
  Button,
  Checkbox,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Button as ButtonBootstrap, OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { blueGrey, brown, cyan, green, lightGreen, orange, purple, red, yellow } from '@material-ui/core/colors';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

import API from '../api';
import Const from '../variables/Const';
import CustomDialog from '../widgets/CustomDialog';
import CustomEFormDialog from '../widgets/CustomEFormDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';
import LendingModal from './LendingModal';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';
import ReceiveModal from './ReceiveModal';
import ConfirmRejectReceiveModal from './ConfirmRejectReceiveModal';
import Swal from 'sweetalert2';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import styled from 'styled-components';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import withReactContent from 'sweetalert2-react-content';

/* eslint-disable-next-line no-restricted-imports */























const MySwal = withReactContent(Swal);
moment.locale('th');
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const PurpleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);
const OrangeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);
const CyanButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(cyan[500]),
    backgroundColor: cyan[500],
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
}))(Button);
const BlueGreyButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    '&:hover': {
      backgroundColor: blueGrey[700],
    },
  },
}))(Button);
const LightGreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
}))(Button);
const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);
const LightBrownButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(brown[300]),
    backgroundColor: brown[300],
    '&:hover': {
      backgroundColor: brown[500],
    },
  },
}))(Button);
const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[700],
    '&:hover': {
      backgroundColor: yellow[800],
    },
  },
}))(Button);
const LightYellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[300]),
    backgroundColor: yellow[300],
    '&:hover': {
      backgroundColor: yellow[500],
    },
  },
}))(Button);
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let custom_style = {
    head_rows_label: {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 'Bold',
      ...props.headerStyles
    },
  };
  return (
    <TableHead>
      <TableRow>
        {!props.hideSelect && <TableCell style={custom_style.head_rows_label}>เลือก</TableCell>}
        {props.showManage && <TableCell style={custom_style.head_rows_label}>จัดการ</TableCell>}
        {_.map(props.head_rows, (head_row) => (
          <TableCell
            key={head_row.id}
            style={custom_style.head_rows_label}
            align={head_row.align ? head_row.align : 'left'}
            padding={head_row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === head_row.id ? order : false}
          >
            {head_row.sortable ? (
              <TableSortLabel active={orderBy === head_row.id} direction={order} onClick={createSortHandler(head_row.id)}>
                {head_row.label}
              </TableSortLabel>
            ) : (
              head_row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));
const styleButton = {
  marginLeft: 5,
  padding: 2,
  width: 28,
  height: 28,
  marginBottom: 5,
};
const styleButtonManage = {
  marginLeft: 5,
  padding: 2,
  minWidth: 28,
  height: 28,
  marginBottom: 5,
};
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            จำนวนที่เลือก {numSelected}
          </Typography>
        ) : (
          <Typography variant='h6' id='tableTitle'>
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));
function dateFormat(momentObject, format = 'D MMM YY', splitText = ' ') {
  var formatArray = format.split(splitText);
  var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
  var indexFullYear = formatArray.indexOf('YYYY');
  if (indexFullYear != -1) {
    formatArray[indexFullYear] = thaiYear;
  }
  var indexShortYear = formatArray.indexOf('YY');
  if (indexShortYear != -1) {
    formatArray[indexShortYear] = thaiYear.toString().substr(2);
  }
  format = formatArray.join(' ');
  return momentObject.format(format);
}
function datetimeFormat(momentObject, format = 'D MMM YY HH:mm', splitText = ' ') {
  var formatArray = format.split(splitText);
  var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
  var indexFullYear = formatArray.indexOf('YYYY');
  if (indexFullYear != -1) {
    formatArray[indexFullYear] = thaiYear;
  }
  var indexShortYear = formatArray.indexOf('YY');
  if (indexShortYear != -1) {
    formatArray[indexShortYear] = thaiYear.toString().substr(2);
  }
  format = formatArray.join(' ');
  return momentObject.format(format);
}
export default function Function(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage ? props.rowsPerPage : 10);
  const [check_all, setCheckAll] = useState(false);
  useEffect(() => {
    setRowsPerPage(props.rowsPerPage);
  });
  function handleRequestSort(event, property) {
    if (property == 'id') {
      return;
    }
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }
  function handleSelectAllClick(event) {
    console.log('event.target -->', event.target);
    if (!check_all) {
      const new_selecteds = _.map(props.rows, (row) => row.check_edit && row.docs_trans_id);
      props.setSelecteds(_.compact(new_selecteds));
      setCheckAll(true);
      return;
    }
    props.setSelecteds([]);
    setCheckAll(false);
  }
  function handleClick(event, name) {
    const selectedIndex = props.selecteds.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selecteds, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selecteds.slice(1));
    } else if (selectedIndex === props.selecteds.length - 1) {
      newSelected = newSelected.concat(props.selecteds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(props.selecteds.slice(0, selectedIndex), props.selecteds.slice(selectedIndex + 1));
    }
    props.setSelecteds(newSelected);
  }
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }
  function RejectReceive(data, doc_status = 4) {
    let form_data = new FormData();
    form_data.set('doc_status', doc_status);
    if (doc_status === 4) {
      form_data.set('remark', data.remark);
		}
    API.post('/console/receive/' + data.id + '/edit', form_data).then((res) => {
      if (res.success) {
        switch (doc_status) {
          case 28: displayAlert('review_success', data.title); break;
          case 29: displayAlert('endorse_success', data.title); break;
          default: displayAlert('success', res.data.item); break;
        }
      } else {
        displayAlert('error', res.error);
      }
    });
  }
  function RegisterReceive(data) {
    let form_data = new FormData();
    form_data.set('doc_status', 6);
    form_data.set('received_at', data.received_at ? data.received_at.replace('T', ' ').replace('/', '-') : null);
    if (data.book_cate_id != null) { form_data.set('book_cate_id', data.book_cate_id); }

    API.post('/console/receive/' + data.id + '/edit', form_data).then((res) => {
      if (res.success) {
        displayAlert('success', res.data.item);
      } else {
        displayAlert('error', res.error);
      }
    });
  }
  function RetakingSend(data) {
    let form_data = new FormData();
    form_data.set('doc_status', 8);
    API.post('/console/send/' + data.id + '/edit', form_data).then((res) => {
      if (res.success) {
        displayAlert('retaking_success', res.data.item);
      } else {
        displayAlert('error', res.error);
      }
    });
  }
  function SentBack(data) {
    let form_data = new FormData();
    form_data.set('doc_status', 27);
    API.post('/console/send/' + data.id + '/edit', form_data).then((res) => {
      if (res.success) {
        displayAlert('sentback_success', res.data.item);
      } else {
        displayAlert('error', res.error);
      }
    });
  }
  function displayAlert(type = 'receive', data = null) {
    switch (type) {
      case 'receive': {
        MySwal.fire({
          title: 'ต้องการลงทะเบียนรับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RegisterReceive(data);
          } else {
            return;
          }
        });
        break;
      }
      case 'reject': {
        MySwal.fire({
          title: 'ต้องการปฏิเสธ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RejectReceive(data);
          } else {
            return;
          }
        });
        break;
      }
      case 'retaking': {
        MySwal.fire({
          title: 'ต้องการดึงเรื่องคืน?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RetakingSend(data);
          } else {
            return;
          }
        });
        break;
      }
      case 'sentback': {
        MySwal.fire({
          title: 'ต้องการคืนต้นเรื่อง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            SentBack(data);
          } else {
            return;
          }
        });
        break;
      }
      case 'review': {
        MySwal.fire({
          title: 'ต้องการส่งกลั่นกรอง?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RejectReceive(data, 28);
          } else {
            return;
          }
        });
        break;
      }
      case 'endorse': {
        MySwal.fire({
          title: 'ต้องการส่งลงนาม?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RejectReceive(data, 29);
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      case 'success': {
        let html = (
          <>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เลขทะเบียนรับ
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.receive_no}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เรื่อง
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.topic}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                จาก
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.from_name}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                เรียน
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.to_name}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                ลงวันที่
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {dateFormat(moment(data.date_at))}
              </StyledButtonLeft>
            </div>
            <div className='row'>
              <StyledLabelRight className='col-md-4 offset-1 col-form-label' style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                วันที่ลงทะเบียนรับ
              </StyledLabelRight>
              <StyledButtonLeft className='col-md-7 col-form-label' style={{ margin: 0, paddingRight: 0 }}>
                {data.received_at_tmp ? datetimeFormat(moment(data.received_at_tmp)) : '-'}
              </StyledButtonLeft>
            </div>
          </>
        );
        MySwal.fire({
          title: 'สำเร็จ!',
          html,
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'delete_success': {
        MySwal.fire({
          title: 'ลบสำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'retaking_success': {
        MySwal.fire({
          title: 'ดึงเรื่องคืนสำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'sentback_success': {
        MySwal.fire({
          title: 'คืนต้นเรื่องสำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'review_success': {
        MySwal.fire({
          title: 'ส่งกลั่นกรองสำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'endorse_success': {
        MySwal.fire({
          title: 'ส่งลงนามสำเร็จ!',
          icon: 'success',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          props.reloadData();
        });
        break;
      }
      case 'delete': {
        MySwal.fire({
          title: 'ต้องการลบ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            onDelete(data);
          } else {
            return;
          }
        });
        break;
      }
      case 'error': {
        let html = '';
        _.filter(data, (v, k) => {
          html += `${v[0]}<br>`;
        });
        MySwal.fire({
          title: 'ไม่สำเร็จ!',
          html,
          icon: 'error',
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          MySwal.close();
        });
        break;
      }
      case 'invalid': {
        MySwal.fire({
          title: 'ต้องการแจ้งหนังสือผิด?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            RejectReceive(data, 19);
          } else {
            return;
          }
        });
        break;
      }
      default:
        break;
    }
  }
  function onDelete(id) {
    displayAlert('loading');
    try {
      const link_delete = props.linkDelete + id + '/delete';
      API.get(link_delete).then((responce) => {
        if (responce.success) {
          displayAlert('delete_success');
        } else {
          displayAlert('error', responce.error);
        }
      });
    } catch (e) { }
  }
  function handleSingleSelected(event, data) {
    props.setSelected(data);
  }
  async function downloadAttachFile(event, data) {
    try {
      const resp = await API.postDownloadFile(data.attach_download, null, 'header');
      const file = new Blob([resp.data], {
        type: resp.headers['content-type'],
      });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = data.file_name;
      fileLink.click();
    } catch (err) {
      console.log('error::' + err);
    }
  }
  const isSelected = (id) => props.selecteds && props.selecteds.indexOf(id) !== -1;
  const isDisabledSelect = (row) => {
    if (props.allowSelect && props.allowSelect == true) {
      return false;
    } else {
      return (props.isReceive && row.check_receive && !props.isStore) != true;
    }
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);
  let _numrow = 1;
  const displayAttaches = (data) => {
    const first_attach =
      data.doc_download && data.doc_type
        ? {
          attach_download: data.doc_download,
          file_name: data.doc_name,
          file_type: data.doc_type,
        }
        : null;
    let attaches = [];
    if (first_attach) attaches.push(first_attach);
    if (!_.isEmpty(data.attach) && _.isArray(data.attach)) {
      attaches = attaches.concat(data.attach);
    }
    const attaches_2d = [];
    while (attaches.length) attaches_2d.push(attaches.splice(0, 4));
    return _.map(attaches_2d, (row) => (
      <div className='col-md-12'>
        <div className='row' style={{ marginBottom: 12, marginRight: 8 }}>
          {_.map(row, (col) => (
            <div className='col-3'>
              <a href={col.attach_download} target='_blank' rel='noopener noreferrer'>
                <StyledUploadedFile alt={`${col.file_type}`} src={toAbsoluteUrl(`/media/files/${col.file_type.toLowerCase()}.svg`)}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadAttachFile(e, col);
                  }} />
              </a>
            </div>
          ))}
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className='kt-portlet__body' style={{ paddingTop: 0 }}>
        <div className='kt-section__content'>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              {props.title && <EnhancedTableToolbar title={props.title || 'Unknown'} numSelected={props.selecteds && props.selecteds.length} />}
              <StyledCustomTable>
                <Table className={classes.table} aria-labelledby='tableTitle' size={'medium'} style={{ color: 0 }}>
                  <EnhancedTableHead
                    numSelected={props.selecteds && props.selecteds.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={props.rows.length}
                    head_rows={props.head_rows}
                    hideSelect={props.hideSelect}
                    showManage={props.showManage}
                    headerStyles={props.headerStyles || {}}
                  />
                  <TableBody>
                    {stableSort(props.rows, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const store_data = props.isStore ? row : null;
                        row = props.isStore ? row.docs_data : row;
                        const isItemSelected = isSelected(row.docs_trans_id);
                        const key = `custom-table-${index + 1}`;
                        const labelId = `enhanced-table-checkbox-${index + 1}`;
                        const disabledSelect = isDisabledSelect(row);
                        const btn_send_forward = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='forward'>ส่งต่อ</TooltipBootstrap>} placement='top'>
                            <ButtonBootstrap
                              style={styleButton}
                              variant='success'
                              onClick={() => props.history.push(`/register-receive-send-forward/${row.docs_trans_id}${props.curr_page ? props.curr_page : ''}`)}
                            >
                              <Icon className='flaticon2-mail-1' />
                            </ButtonBootstrap>
                          </OverlayTrigger>
                        );
                        const btn_receive =
                          props.book_cate_data && props.book_cate_data.length > 0 ? (
                            <ReceiveModal
                              key={row.docs_trans_id}
                              id={row.docs_trans_id}
                              book_cate_data={props.book_cate_data}
                              displayAlert={displayAlert}
                              styleButton={styleButton}
                              received_at={row.received}
                              book_cate_id={row.bookcate}
                            />
                          ) : props.book_cate_data && props.book_cate_data.length > 0 ? (
                            _.map(props.book_cate_data, (value, key) => {
                              return (
                                <OverlayTrigger overlay={<TooltipBootstrap id='receive'>รับ</TooltipBootstrap>} placement='top'>
                                  <ButtonBootstrap
                                    style={styleButton}
                                    variant='success'
                                    onClick={() => {
                                      displayAlert('receive', {
                                        id: row.docs_trans_id,
                                        received_at: row.received_at_tmp,
                                        book_cate_id: row.book_cate_id,
                                      })
                                    }
                                    }
                                  >
                                    <Icon className='flaticon-doc' />
                                  </ButtonBootstrap>
                                </OverlayTrigger>
                              );
                            })
                          ) : null;
                        const btn_reject = (
                          <>
                            <ConfirmRejectReceiveModal
                              key={row.docs_trans_id}
                              id={row.docs_trans_id}
                              displayAlert={displayAlert}
                              styleButton={styleButton}
                            />
                          </>
                        );
                        const btn_retaking = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='retaking'>ดึงเรื่องคืน</TooltipBootstrap>} placement='top'>
                            <ButtonBootstrap
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                marginBottom: 5,
                              }}
                              variant='danger'
                              onClick={() =>
                                displayAlert('retaking', {
                                  id: row.docs_trans_id,
                                })
                              }
                            >
                              <Icon className='flaticon2-fast-back' style={{ fontSize: 14 }} />
                            </ButtonBootstrap>
                          </OverlayTrigger>
                        );
                        const btn_sentback = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='sentback'>คืนต้นเรื่อง</TooltipBootstrap>} placement='top'>
                            <YellowButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                marginBottom: 5,
                                minWidth: 28
                              }}
                              variant='contained'
                              onClick={() =>
                                displayAlert('sentback', {
                                  id: row.docs_trans_id,
                                })
                              }
                            >
                              <Icon className='fas fa-reply' style={{ fontSize: 14, color: '#fff' }} />
                            </YellowButton>
                          </OverlayTrigger>
                        );
                        const btn_store = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='store'>เก็บ</TooltipBootstrap>} placement='top'>
                            <PurpleButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                props.history.push({
                                  pathname: (props.isReceive ? `/register-receive-store/add` : `/register-send-store/add`) + (props.curr_page ? props.curr_page : ''),
                                  state: {
                                    docs_trans_id: row.docs_trans_id,
                                    stores_docs_id: row.stores_docs_id,
                                  },
                                })
                              }
                            >
                              <Icon className='flaticon2-cube' style={{ fontSize: 20 }} />
                            </PurpleButton>
                          </OverlayTrigger>
                        );
                        const btn_stored = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='store'>เก็บ</TooltipBootstrap>} placement='top'>
                            <LightBrownButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                props.history.push({
                                  pathname: (props.isReceive ? `/register-receive-store/add` : `/register-send-store/add`) + (props.curr_page ? props.curr_page : ''),
                                  state: {
                                    docs_trans_id: row.docs_trans_id,
                                    stores_docs_id: row.stores_docs_id,
                                  },
                                })
                              }
                            >
                              <Icon className='flaticon2-cube' style={{ fontSize: 20 }} />
                            </LightBrownButton>
                          </OverlayTrigger>
                        );
                        const btn_store_to_lending = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='lending'>ยืม</TooltipBootstrap>} placement='top'>
                            <OrangeButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                props.history.replace({
                                  pathname: '/lending',
                                  state: {
                                    stores_docs_id: store_data.stores_docs_id,
                                  },
                                })
                              }
                            >
                              <Icon className='flaticon2-open-text-book' style={{ fontSize: 20 }} />
                            </OrangeButton>
                          </OverlayTrigger>
                        );
                        const btn_copy_to_send = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='copy_to_send'>โอนสร้าง</TooltipBootstrap>} placement='top'>
                            <CyanButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                props.history.push({
                                  pathname: '/register-send-add',
                                  state: {
                                    docs_trans_id: row.docs_trans_id,
                                  },
                                })
                              }
                            >
                              <Icon className='flaticon2-copy' style={{ fontSize: 20, color: '#fff' }} />
                            </CyanButton>
                          </OverlayTrigger>
                        );
                        const btn_review = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='review'>ส่งกลั่นกรอง</TooltipBootstrap>} placement='top'>
                            <LightYellowButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                displayAlert('review', {
                                  id: row.docs_trans_id,
                                  eform_trans_id: row.eform_trans_id
                                })
                              }
                            >
                              <Icon className='fas fa-sign-out-alt' style={{ fontSize: 20, color: '#fff' }} />
                            </LightYellowButton>
                          </OverlayTrigger>
                        );
                        const btn_endorse = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='endorse'>ส่งลงนาม</TooltipBootstrap>} placement='top'>
                            <YellowButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                displayAlert('endorse', {
                                  id: row.docs_trans_id,
                                  eform_trans_id: row.eform_trans_id
                                })
                              }
                            >
                              <Icon className='fas fa-pen-nib' style={{ fontSize: 20, color: '#fff' }} />
                            </YellowButton>
                          </OverlayTrigger>
                        );
                        const btn_lending = (
                          <LendingModal
                            id={row.docs_lending_id}
                            reloadData={props.reloadData}
                            stores_docs_id={props.stores_docs_id}
                            styleButton={styleButton}
                          />
                        );
                        const btn_retire = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='lending'>เกษียนหนังสือ</TooltipBootstrap>} placement='top'>
                            <BlueGreyButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() => props.history.push(`/register-receive-retire-form/${row.docs_trans_id}${props.curr_page ? props.curr_page : ''}`)}
                            >
                              <Icon className='flaticon2-layers-2' style={{ fontSize: 20 }} />
                            </BlueGreyButton>
                          </OverlayTrigger>
                        );
                        const btn_ecms = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='lending'>e-CMS</TooltipBootstrap>} placement='top'>
                            <LightGreenButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                            >
                              <Icon className='flaticon2-graphic-design' style={{ fontSize: 20 }} />
                            </LightGreenButton>
                          </OverlayTrigger>
                        );
                        const btn_invalid_doc = (
                          <OverlayTrigger overlay={<TooltipBootstrap id='lending'>แจ้งหนังสือผิด</TooltipBootstrap>} placement='top'>
                            <RedButton
                              style={{
                                marginLeft: 5,
                                padding: 0,
                                width: 28,
                                height: 28,
                                minWidth: 28,
                                marginBottom: 5,
                              }}
                              variant='contained'
                              onClick={() =>
                                displayAlert('invalid', {
                                  id: row.docs_trans_id,
                                })
                              }
                            >
                              <Icon className='flaticon2-delete' style={{ fontSize: 20 }} />
                            </RedButton>
                          </OverlayTrigger>
                        );
                        let show_manage_button = true;
                        if (props.isEForm && props.eform_type === 'wait-send' && row.receive_no !== null) {
                          show_manage_button = false;
                        }
                        return (
                          <TableRow
                            hover
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={key}
                            selected={isItemSelected}
                          >
                            {!props.hideSelect && !props.singleSelect && (
                              <TableCell padding='checkbox'>
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                  disabled={disabledSelect}
                                  onClick={(event) => props.isReceive && row.check_receive && !props.isStore && handleClick(event, row.docs_trans_id)}
                                  onChange={(event) => { props.isRegisterBook && handleClick(event, row.docs_trans_id); }}
                                />
                              </TableCell>
                            )}
                            {!props.hideSelect && props.singleSelect && (
                              <TableCell
                                align='center'
                              >
                                <Link
                                  disabled={disabledSelect}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleSingleSelected(event, row);
                                  }}
                                >
                                  เลือก
                                </Link>
                              </TableCell>
                            )}
                            {props.showManage && (
                              <TableCell
                                style={{
                                  textAlign: props.btnManageAlign || 'center',
                                  width: props.isReceive ? '18rem' : 'auto',
                                }}
                              >
                                {props.linkManage && show_manage_button === true && (
                                  <OverlayTrigger
                                    overlay={<TooltipBootstrap id='manage'>จัดการ</TooltipBootstrap>}
                                    placement='top'
                                  >
                                    <ButtonBootstrap
                                      style={styleButtonManage}
                                      variant='info'
                                      onClick={() => {
                                        let link = props.linkManage;
                                        if (props.isEForm) {
                                          if (link === '/e-form-build/') {
                                            link += row.form_type + '?form_type=' + row.form_type + '&id=' + row.eform_trans_id;
                                          } else {
                                            link += row.eform_trans_id;
                                          }
                                        } else {
                                          link += (props.isStore ? store_data.stores_docs_id : (row.docs_trans_id ? row.docs_trans_id + (props.curr_page ? props.curr_page : '') : row.id));
                                        }
                                        props.history.push(link);
                                      }
                                      }
                                      disabled={props.btnManageDisabled || false}
                                    >
                                      {props.btnManageName}
                                    </ButtonBootstrap>
                                  </OverlayTrigger>
                                )}
                                {props.linkDelete && (
                                  <OverlayTrigger
                                    overlay={<TooltipBootstrap id='delete'>ลบ</TooltipBootstrap>}
                                    placement='top'
                                  >
                                    <ButtonBootstrap
                                      style={styleButton}
                                      variant='danger'
                                      onClick={() => displayAlert('delete', row.id)}
                                      disabled={props.btnDeleteDisabled || false}
                                    >
                                      {props.btnDeleteName}
                                    </ButtonBootstrap>
                                  </OverlayTrigger>
                                )}
                                {props.isEForm && props.eform_type === 'wait-send' && row.receive_no !== null && (
                                  <OverlayTrigger overlay={<TooltipBootstrap id='forward'>ส่งต่อ</TooltipBootstrap>} placement='top'>
                                    <ButtonBootstrap
                                      style={styleButton}
                                      variant='success'
                                      onClick={() => props.history.push(`/e-form-forward/${row.eform_trans_id}`)}
                                    >
                                      <Icon className='flaticon2-mail-1' style={{ padding: 0, margin: 0 }} />
                                    </ButtonBootstrap>
                                  </OverlayTrigger>
                                )}

                                {props.isReceive && row.check_forward && !props.isStore ? btn_send_forward : null} {/*ส่งต่อ*/}
                                {props.isReceive && row.check_receive && !props.isStore ? btn_receive : null} {/*รับ*/}
                                {props.isReceive && row.check_receive && !props.isStore ? btn_reject : null} {/*ปฏิเสธ*/}
                                {props.isLending && !props.isStore ? btn_lending : null} {/**/}
                                {props.isSend && row.check_retaking && !props.isStore ? btn_retaking : null}{/*ดึงเรื่องคืน*/}
                                {(props.isReceive || props.isSend) && row.check_store && !props.isStore ? btn_store : null} {/*จัดเก็บ*/}
                                {(props.isReceive || props.isSend) && row.check_stored && !props.isStore ? btn_stored : null} {/*จัดเก็บ*/}
                                {props.isReceive && row.check_transfer ? btn_copy_to_send : null} {/*โอนสร้าง*/}
                                {props.isReceive && row.check_review ? btn_review : null} {/*ส่งกลั่นกรอง*/}
                                {props.isReceive && row.check_endorse ? btn_endorse : null} {/*ส่งลงนาม*/}
                                {props.isStore ? btn_store_to_lending : null} {/*ยืม*/}
                                {props.isSend && row.check_sentback && !props.isStore ? btn_sentback : null}{/*คืนต้นเรื่อง*/}
                                {/*{props.isReceive && row.check_retire ? btn_retire : null} */}{/*เกษียนหนังสือ*/}
                                {/*{row.check_invalid || row.check_receive || row.check_reject ? btn_invalid_doc : null*/} {/*แจ้งหนังสือผิด*/}
                                {/*{row.check_ecms ? btn_ecms : null}*/}
                              </TableCell>
                            )}
                            {_.map(props.head_rows, (head_row) => {
                              let row_v = row[head_row.id];
                              if (head_row.id == '_numrow') {
                                row_v = _numrow++;
                              }
                              if (head_row.id == 'doc_download') {
                                row_v = row.doc_download || row.attach ? displayAttaches(row) : '-';
                              }
                              if (head_row.id == 'book_cate_id') {
                                const book_cate =
                                  props.book_cate_data && props.book_cate_data.length > 0
                                    ? _.find(props.book_cate_data, (v) => v.book_cate_id == row.book_cate_id && v.name)
                                    : '';
                                row_v = book_cate ? book_cate.name : '-';
                              }
                              if (head_row.id == 'year') {
                                row_v = Number(row.year) + 543;
                              }
                              if (head_row.id == 'date_at' || head_row.id == 'begin_date' || head_row.id == 'end_date') {
                                row_v = dateFormat(moment.unix(Number(row[head_row.id])));
                              }
                              if (
                                (head_row.id == 'received_at' ||
                                  head_row.id == 'sended_at' ||
                                  head_row.id == 'created_at' ||
                                  head_row.id == 'date_stamp' ||
                                  head_row.id == 'date_stamp_to') &&
                                row[head_row.id]
                              ) {
                                if (head_row.id == 'date_stamp' || head_row.id == 'date_stamp_to')
                                  row_v = dateFormat(moment.unix(Number(row[head_row.id])), 'D MMM YY');
                                else
                                  row_v = dateFormat(moment.unix(Number(row[head_row.id])), 'D MMM YY HH:mm');
                              }
                              if (head_row.id == 'send_to' || head_row.id == 'from') {
                                _.filter(row[head_row.id], (v, k) => {
                                  if (k == 0) {
                                    row_v = `- ${v}`;
                                  } else {
                                    row_v += `<br>- ${v}`;
                                  }
                                });
                              }
                              if (head_row.id == 'topic') {
                                if (props.isReceive || props.isSend || props.isViewReceive || props.isViewSend || props.isEForm) {
                                  if (row.speed_type_id && row.speed_type_id != Const.MasterData.SpeedType.Normal) {
                                    row_v = (<div style={{ display: 'inline-flex' }}><i className='fas fa-exclamation-circle icon-xs text-danger' style={{ marginTop: '4px', marginRight: '4px' }} /><span><label>{row_v}</label></span></div>);
                                  }
                                }
                              }
                              if (head_row.id == 'send_no') {
                                row_v = (
                                  <CustomDialog docs_trans_id={row.docs_trans_id} trans_type={props.isReceive ? 1 : 2}>
                                    {props.isStore ? row.doc_no : row.send_no}
                                  </CustomDialog>
                                );
                              }
                              if (props.isEForm && head_row.id == 'doc_no_display') {
                                if (row.receive_no === null) {
                                  row_v = (
                                    <CustomEFormDialog eform_trans_id={row.eform_trans_id} eform_type={props.eform_type}>
                                      {row.doc_no_display}
                                    </CustomEFormDialog>
                                  );
                                }
                                else {
                                  row_v = (
                                    <CustomDialog docs_trans_id={row.docs_trans_id} trans_type={1}>
                                      {row.doc_no_display}
                                    </CustomDialog>
                                  );
                                }
                              }
                              if (head_row.id == 'cate_stores_id') {
                                let sub_cate_stores_label = props.sub_cate_stores_ids_for_display[store_data.sub_cate_stores_id]
                                  ? ` ${props.sub_cate_stores_ids_for_display[store_data.sub_cate_stores_id]}`
                                  : '';
                                row_v = props.cate_stores_ids[store_data.cate_stores_id]
                                  ? `${props.cate_stores_ids[store_data.cate_stores_id]}${sub_cate_stores_label}`
                                  : '-';
                              }
                              if (head_row.id == 'stores_docs_status') {
                                row_v = props.stores_docs_statuses[store_data.stores_docs_status] || '-';
                              }
                              if (row_v === undefined || (row_v && row_v.length == 0)) row_v = '-';
                              return (
                                head_row.id != 'docs_trans_id' &&
                                (head_row.id != 'id' && (row_v || row_v == 0) ? (
                                  <TableCell
                                    style={{
                                      minWidth: head_row.id == 'received_at' ? '6rem' : (head_row.id == 'doc_download' ? '7rem' : '5rem'),
                                      width: props.isReceive && head_row.id == 'topic' ? '10rem' : 'auto',
                                      wordBreak: head_row.id == 'topic' ? 'break-all' : 'unset',
                                    }}
                                    align={head_row.align || 'left'}
                                  >
                                    {head_row.id == 'send_to' || head_row.id == 'from'
                                      ? row_v.split('<br>').map((place) => <p> {place} </p>)
                                      : row_v}
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    {head_row.id == 'send_to' ? '' : '-'}
                                  </TableCell>
                                ))
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    {props.rows.length == 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={props.head_rows.length + (props.hideSelect ? 0 : 1) + (props.showManage ? 1 : 0)}
                          style={{
                            minWidth: '5rem',
                            width: 'auto',
                            height: '2rem',
                          }}
                          align={'center'}
                        >
                          {props.errorMsg || 'ไม่พบข้อมูล'}
                        </TableCell>
                      </TableRow>
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={props.head_rows.length + (props.hideSelect ? 0 : 1) + (props.showManage ? 1 : 0)} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </StyledCustomTable>
              <TablePagination
                /* rowsPerPageOptions={[5, 10, 25, 50, 100]} */
                rowsPerPageOptions={[20]}
                component='div'
                count={props.rows.length}
                // count={123}
                /* rowsPerPage={rowsPerPage} */
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {props.current_page && props.rows.length > 0 && props.last_page > 1 && (
                <Pagination style={{ justifyContent: 'center', padding: 10 }}>
                  {props.current_page > 2 && (
                    <Pagination.First
                      onClick={() => {
                        console.log('GO First');
                        props.handleChangePage(1);
                      }}
                    />
                  )}
                  {props.current_page > 2 && (
                    <Pagination.Prev
                      onClick={() => {
                        console.log('GO Prev');
                        props.handleChangePage(props.current_page - 1);
                      }}
                    />
                  )}
                  {props.current_page > 1 && (
                    <Pagination.Item
                      onClick={() => {
                        console.log('GO', props.current_page - 1);
                        props.handleChangePage(props.current_page - 1);
                      }}
                    >
                      {props.current_page - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{props.current_page}</Pagination.Item>
                  {props.current_page + 1 <= props.last_page && (
                    <Pagination.Item
                      onClick={() => {
                        console.log('GO', props.current_page + 1);
                        props.handleChangePage(props.current_page + 1);
                      }}
                    >
                      {props.current_page + 1}
                    </Pagination.Item>
                  )}
                  {props.current_page + 2 <= props.last_page && (
                    <Pagination.Next
                      onClick={() => {
                        console.log('GO NEXT');
                        props.handleChangePage(props.current_page + 1);
                      }}
                    />
                  )}
                  {props.current_page + 2 <= props.last_page && (
                    <Pagination.Last
                      onClick={() => {
                        console.log('GO LAST');
                        props.handleChangePage(props.last_page);
                      }}
                    />
                  )}
                </Pagination>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
}
const StyledCustomTable = styled.div`
    .MuiButtonBase-root {
        padding: 2px !important;
    }
    .MuiTableCell-head {
        padding: 5px !important;
        font-weight: bold;
    }
    .MuiTableCell-body {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        vertical-align: top;
    }
`;
const StyledUploadedFile = styled.img`
    width: 36px;
    padding-right: 0.5rem;
`;
const StyledLabelRight = styled.label`
    text-align: right;
    color: ${(props) => props.color || '#646c9a'};
`;
const StyledButtonLeft = styled.div`
    text-align: left;
    padding-left: 2%;
    margin-top: 0px;
`;
