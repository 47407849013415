import React from 'react';
import DepartmentAddFormPage from '../department-add-form/DepartmentAddFormPage';
export default function DepartmentAddForm() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <DepartmentAddFormPage />
        </div>
      </div>
    </>
  );
}
