import React from 'react';
import OrganizationPage from '../organization/OrganizationPage'
export default function Organization() {
  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <OrganizationPage />
        </div>
      </div>
    </>
  );
}
