/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import API from '../api';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toAbsoluteUrl } from '../../_metronic/utils/utils';
import moment from 'moment';
import 'moment/locale/th';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import CustomTable from '../widgets/CustomTable';

moment.locale('th');
const fileStyle = { float: 'left', marginTop: '10px' };
const head_rows = [
    {
        id: 'year',
        sortable: true,
        disablePadding: false,
        label: 'ปี',
        align: 'center',
    },
    {
        id: 'book_cate',
        sortable: true,
        disablePadding: false,
        label: 'ประเภทหนังสือ',
        align: 'left',
    },
    {
        id: 'doc_no',
        sortable: true,
        disablePadding: false,
        label: 'เลขหนังสือ',
        align: 'left',
    },
    {
        id: 'date_at',
        sortable: true,
        disablePadding: false,
        label: 'ลงวันที่',
        align: 'center',
    },
    {
        id: 'to_name',
        sortable: true,
        disablePadding: false,
        label: 'ส่งถึง',
        align: 'left',
    },
    {
        id: 'topic',
        sortable: true,
        disablePadding: false,
        label: 'เรื่อง',
        align: 'left',
    }
];
class Class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'doc',
        };
    }
    handleOpenDialog = async () => {
        await API.get(`/console/doc/trans/${this.props.docs_trans_id}`).then(({ success, data }) => {
            if (success) {
                this.setState({
                    open: true,
                    ...data,
                    /* transaction: _.orderBy(data.transaction, ['stamp_at'], ['asc']) */
                    transaction: data.transaction
                });
            }
        });
    };
    dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
        var formatArray = format.split(splitText);
        var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
        var indexFullYear = formatArray.indexOf('YYYY');
        if (indexFullYear != -1) {
            formatArray[indexFullYear] = thaiYear;
        }
        var indexShortYear = formatArray.indexOf('YY');
        if (indexShortYear != -1) {
            formatArray[indexShortYear] = thaiYear.toString().substr(2);
        }
        format = formatArray.join(' ');
        return momentObject.format(format);
    };
    downloadDocFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/doc/' + id, file_name);
    }
    downloadAttachFile = async (event, id, file_name) => {
        await this.downloadFile(event, '/console/static/download/attach/' + id, file_name);
    }
    downloadFile = async (event, url, file_name) => {
        try {
            const resp = await API.postDownloadFile(url, null, 'header');
            const file = new Blob([resp.data], {
                type: resp.headers['content-type'],
            });
            const fileURL = URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = file_name;
            fileLink.click();
        } catch (err) {
            console.log('error::');
            console.log(err);
        }
    }
    render() {
        return (
            <>
                <a onClick={this.handleOpenDialog}>{this.props.children}</a>
                <Dialog maxWidth='lg' fullWidth onEntering={() => null} aria-labelledby='confirmation-dialog-title' open={this.state.open}>
                    <DialogTitle id='confirmation-dialog-title'>
                        หนังสือ
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'doc' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'doc' });
                            }}
                        >
                            Doc
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'file' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'file' });
                            }}
                        >
                            File
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'transaction' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'transaction' });
                            }}
                        >
                            Transaction
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'log' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'log' });
                            }}
                        >
                            Log
                        </Button>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '10px' }}
                            color={this.state.type == 'more' ? 'secondary' : ''}
                            onClick={() => {
                                this.setState({ type: 'more' });
                            }}
                        >
                            More
                        </Button>
                    </DialogTitle>
                    {this.state.type == 'doc' && this.state.doc && (
                        <DialogContent dividers>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    ทะเบียนรับ/ส่ง:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.book_cate_text || '-'}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>
                                    เลขที่หนังสือ:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.doc_no || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    เรื่อง:
                                </StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.state.doc.topic || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    เรียน:
                                </StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.state.doc.to_name || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    ปี พ.ศ.:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.year ? this.state.doc.year + 543 : '-'}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>
                                    ลงวันที่:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>
                                    {this.state.doc.date_at ? this.dateFormat(moment(this.state.doc.date_at)) : '-'}
                                </StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    ชั้นความเร็ว:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.speed_type_text || '-'}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>
                                    ชั้นความลับ:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.secret_type_text || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    ประเภทหนังสือ:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.doc_type_text || '-'}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>
                                    เก็บไว้ที่หน่วยงาน:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.storage_location || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    จำนวน:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.page || '-'}</StyledLabel>
                                <StyledLabel right className='col-md-2 col-form-label'>
                                    เลขทะเบียนรับ:
                                </StyledLabel>
                                <StyledLabel className='col-md-2 col-form-label'>{this.state.doc.receive_no || '-'}</StyledLabel>
                            </div>
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    สิ่งที่ส่งมาด้วย:
                                </StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.state.doc.detail || '-'}</StyledLabel>
                            </div>
                            {_.isArray(this.state.doc.reference_doc) && !_.isEmpty(this.state.doc.reference_doc) ? _.map(this.state.doc.reference_doc, (v, k) => {
                                return (
                                    <div className='row'>
                                        <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                            {k == 0 ? 'หนังสืออ้างอิง:' : ''}
                                        </StyledLabel>
                                        <StyledLabel className='col-md-6 col-form-label'>{v.doc_no && v.doc_no != '' ? `เลขที่หนังสือ: ${v.doc_no}` : ''}{v.topic && v.topic != '' ? `${v.doc_no && v.doc_no != '' ? ', ' : ''}เรื่อง: ${v.topic}` : ''}{v.date_at && v.date_at != '' ? `${(v.doc_no && v.doc_no != '') || (v.topic && v.topic != '') ? ', ' : ''}วันที่: ${this.dateFormat(moment(v.date_at))}` : ''}</StyledLabel>
                                    </div>
                                );
                            }) : (
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                        หนังสืออ้างอิง:
                                    </StyledLabel>
                                    <StyledLabel className='col-md-6 col-form-label'>-</StyledLabel>
                                </div>
                            )}
                            <div className='row'>
                                <StyledLabel right className='col-md-2 offset-2 col-form-label'>
                                    หมายเหตุ:
                                </StyledLabel>
                                <StyledLabel className='col-md-6 col-form-label'>{this.state.doc.remark || '-'}</StyledLabel>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'file' && this.state.file && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 col-form-label'>
                                        ไฟล์แนบ:
                                    </StyledLabel>
                                    <div class="col-md-10">
                                        {this.state.file.download && this.state.file.download.length > 0 ? (
                                        <ul className="m-0 p-0" style={{ listStyle:'none' }}>
                                            {_.map(this.state.file.download, (v, k) => {
                                                return (
                                                    <li>
                                                        <a style={fileStyle} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.downloadDocFile(e, v.attach_id, v.file_name);
                                                        }}>
                                                            <StyledUploadedFile title={v.file_name} src={toAbsoluteUrl(`/media/files/${v.file_type.toLowerCase()}.svg`)} />
                                                        </a>
                                                        <StyledLabel className='col-10 ml-2 col-form-label'>
                                                            {v.created_at && this.dateFormat(moment.unix(v.created_at), 'D MMM YY HH:mm')}
                                                            {(v.div ? ` หน่วยงาน: ${v.div}` : null)}
                                                            {(v.user ? `, ${v.user}` : null)}
                                                        </StyledLabel>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        ) : (
                                            <StyledLabel className='col-md-2 col-form-label'>-</StyledLabel>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 col-form-label'>
                                        สิ่งที่ส่งมาด้วย:
                                    </StyledLabel>
                                    <div class="col-md-10">
                                        {this.state.file.attach && this.state.file.attach.length > 0 ? (
                                            <ul className="m-0 p-0" style={{ listStyle:'none' }}>
                                                {_.map(this.state.file.attach, (v, k) => {
                                                    return (
                                                        <li>
                                                            <a style={fileStyle} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.downloadAttachFile(e, v.attach_id, v.file_name);
                                                            }}>
                                                                <StyledUploadedFile title={v.file_name} src={toAbsoluteUrl(`/media/files/${v.file_type.toLowerCase()}.svg`)} />
                                                            </a>
                                                            <StyledLabel className='col-10 ml-2 col-form-label'>
                                                                {v.file_name + ', '}
                                                                {v.created_at && this.dateFormat(moment.unix(v.created_at), 'D MMM YY HH:mm')}
                                                                {v.div ? ` หน่วยงาน: ${v.div}` : null}
                                                                {v.user ? `, ${v.user}` : null}
                                                            </StyledLabel>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <StyledLabel className='col-md-2 col-form-label'>-</StyledLabel>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <StyledLabel right className='col-md-2 col-form-label'>
                                        ไฟล์หนังสือเพิ่มเติม:
                                    </StyledLabel>
                                    <div class="col-md-10">
                                        {this.state.file.more && this.state.file.more.length > 0 ? (
                                            <ul className="m-0 p-0" style={{ listStyle: 'none' }}>
                                                {_.map(this.state.file.more, (v, k) => {
                                                    return (
                                                        <li>
                                                            <a style={fileStyle} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.downloadAttachFile(e, v.attach_id, v.file_name);
                                                            }}>
                                                                <StyledUploadedFile title={v.file_name} src={toAbsoluteUrl(`/media/files/${v.file_type.toLowerCase()}.svg`)} />
                                                            </a>
                                                            <StyledLabel className='col-10 ml-2 col-form-label'>
                                                                {v.file_name + ', '}
                                                                {v.created_at && this.dateFormat(moment.unix(v.created_at), 'D MMM YY HH:mm')}
                                                                {v.div ? ` หน่วยงาน: ${v.div}` : null}
                                                                {v.user ? `, ${v.user}` : null}
                                                            </StyledLabel>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <StyledLabel className='col-md-2 col-form-label'>-</StyledLabel>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'transaction' && this.state.transaction && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <PerfectScrollbar
                                    options={{
                                        wheelSpeed: 2,
                                        wheelPropagation: false,
                                    }}
                                    style={{
                                        maxHeight: '50vh',
                                        position: 'relative',
                                    }}
                                >
                                    <div className='kt-timeline-v2 ps ps--active-y'>
                                        <div className='kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30'>
                                            {_.map(this.state.transaction, (v, k) => {
                                                return (
                                                    <div className='kt-timeline-v2__item'>
                                                        <span
                                                            className='kt-timeline-v2__item-time'
                                                            style={{
                                                                width: '10rem',
                                                            }}
                                                        >
                                                            {v.stamp_at && this.dateFormat(moment.unix(v.stamp_at), 'D MMM YY HH:mm')}
                                                        </span>
                                                        <div
                                                            className='kt-timeline-v2__item-cricle'
                                                            style={{
                                                                borderRadius: '50%',
                                                                border: '0.89rem solid white',
                                                                zIndex: 1,
                                                                top: '0.2rem',
                                                                left: '10.12rem',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <i
                                                                className={
                                                                    'fa fa-genderless ' +
                                                                    (v.trans_type == 1
                                                                        ? 'kt-font-success'
                                                                        : v.trans_type == 2
                                                                            ? 'kt-font-info'
                                                                            : 'kt-font-danger')
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'table-cell',
                                                                verticalAlign: 'top',
                                                                fontSize: '1rem',
                                                                padding: '0.35rem 0 0 12rem',
                                                                color: '#74788d',
                                                            }}
                                                        >
                                                            {v.doc_status}
                                                            {v.doc_no ? `, ${v.trans_type == 1 && v.doc_status == 'รับ' ? 'เลขทะเบียนรับ' : 'เลขที่หนังสือ'}: ${v.doc_no}` : null}
                                                            {v.year ? `, ปี พ.ศ. ${v.year + 543}` : null}
                                                            {v.user ? `, ${v.user}` : null}
                                                            {v.book_cate ? `, ${v.book_cate}` : null}
                                                            {v.div && <br />}
                                                            {v.div ? `หน่วยงาน: ${v.div}` : null}
                                                            {v.from_text && <br />}
                                                            {v.from_text ? `${v.from_text}` : null}
                                                            {/*{v.process && v.process.length > 0 && <br />}*/}
                                                            {/*{v.process && v.process.length > 0*/}
                                                            {/*    ? 'โปรเซส: ' + _.map(v.process, (v1, k1) => (k1 == 0 ? v1 : ', ' + v1))*/}
                                                            {/*    : null}*/}
                                                            {v.order_remark && <br />}
                                                            {v.order_remark ? `คำสั่งการ: ${v.order_remark}` : null}
                                                            {v.remark && <br />}
                                                            {v.remark ? `หมายเหตุ: ${v.remark}` : null}
                                                        </div>
                                                        <div className='kt-list-pics kt-list-pics--sm kt-padding-l-20' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'log' && this.state.log && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <PerfectScrollbar
                                    options={{
                                        wheelSpeed: 2,
                                        wheelPropagation: false,
                                    }}
                                    style={{
                                        maxHeight: '50vh',
                                        position: 'relative',
                                    }}
                                >
                                    <div className='kt-timeline-v2 ps ps--active-y'>
                                        <div className='kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30'>
                                            {_.map(this.state.log, (v, k) => {
                                                let action = null;
                                                if (!!v.action) {
                                                    if (v.action === 'approve') {
                                                        action = 'อนุมัติ';
                                                    } else if (v.action === 'reject') {
                                                        action = 'ไม่อนุมัติ';
                                                    } 
                                                }
                                                return (
                                                    <div className='kt-timeline-v2__item'>
                                                        <span
                                                            className='kt-timeline-v2__item-time'
                                                            style={{
                                                                width: '10rem',
                                                            }}
                                                        >
                                                            {v.stamp_at && this.dateFormat(moment.unix(v.stamp_at), 'D MMM YY HH:mm')}
                                                        </span>
                                                        <div
                                                            className='kt-timeline-v2__item-cricle'
                                                            style={{
                                                                borderRadius: '50%',
                                                                border: '0.89rem solid white',
                                                                zIndex: 1,
                                                                top: '0.2rem',
                                                                left: '10.12rem',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <i
                                                                className={
                                                                    'fa fa-genderless ' +
                                                                    (v.trans_type == 1
                                                                        ? 'kt-font-success'
                                                                        : v.trans_type == 2
                                                                            ? 'kt-font-info'
                                                                            : 'kt-font-danger')
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'table-cell',
                                                                verticalAlign: 'top',
                                                                fontSize: '1rem',
                                                                padding: '0.35rem 0 0 12rem',
                                                                color: '#74788d',
                                                            }}
                                                        >
                                                            {v.doc_status}
                                                            {v.doc_no ? `, ${v.trans_type == 1 && v.doc_status == 'รับ' ? 'เลขทะเบียนรับ' : 'เลขที่หนังสือ'}: ${v.doc_no}` : null}
                                                            {v.year ? `, ปี พ.ศ. ${v.year + 543}` : null}
                                                            {v.user ? `, ${v.user}` : null}
                                                            {v.book_cate ? `, ${v.book_cate}` : null}
                                                            {v.div && <br />}
                                                            {v.div ? `หน่วยงาน: ${v.div}` : null}
                                                            {v.from_text && <br />}
                                                            {v.from_text ? `${v.from_text}` : null}
                                                            {v.order_remark && <br />}
                                                            {v.order_remark ? `คำสั่งการ: ${v.order_remark}` : null}
                                                            {v.remark && <br />}
                                                            {v.remark ? `หมายเหตุ: ${v.remark}` : null}
                                                            {v.action && <br />}
                                                            {v.action ? `${action}, ${v.action_user} ${v.action_at_txt}` : null}
                                                        </div>
                                                        <div className='kt-list-pics kt-list-pics--sm kt-padding-l-20' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </DialogContent>
                    )}
                    {this.state.type == 'more' && this.state.more && (
                        <DialogContent dividers>
                            <div className='kt-portlet__body'>
                                <CustomTable
                                    title=''
                                    hideSelect={true}
                                    showManage={false}
                                    head_rows={head_rows}
                                    rows={this.state.more ? this.state.more : []}
                                    history={this.props.history}
                                    reloadData={this.handleOpenDialog}
                                    current_page={1}
                                    last_page={1}
                                    rowsPerPage={this.state.more_count}
                                />
                            </div>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <ButtonBootstrap
                            variant='success'
                            onClick={() => {
                                this.setState({ open: false });
                            }}
                        >
                            ตกลง
                        </ButtonBootstrap>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const StyledLabel = styled.label`
    text-align: ${(props) => (props.center ? 'center' : props.right ? 'right' : 'left')};
    ${'' /* color: ${props => props.color || '#646c9a'}; */}
`;
const StyledUploadedFile = styled.img`
    width: 36px;
    padding-right: 0.5rem;
`;
export default Class;