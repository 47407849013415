/* eslint-disable default-case */
import React from 'react';
import { Button as ButtonBootstrap, Tab, Tabs } from 'react-bootstrap';
import { TextField, MenuItem, Button, Radio } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import CustomDownshift from '../../widgets/CustomDownshift';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';

registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
const input = { display: 'none' };
const fileStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
};
const attachStyle = {
  border: '2px solid #48C9B0', borderRadius: 5, height: 40, padding: 4, marginRight: 4, marginBottom: 4, display: 'inline-block',
  overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 240,
  position: 'absolute', marginLeft: '1rem'
};
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      division_data: [],
      sub_division_data: [],
      selected_division: null,
      selected_sub_division: null,
      id: null,
      confirm_register: false,
      confirm_back: false,
      confirm_cancel: false,
      doc_file: null,
      process_file: null,
      doc_trans: 1,
      doc_type_id: null,
      book_cate_id: null,
      fdoc_no: null,
      ldoc_no: null,
      date_at: moment().format('YYYY-MM-DD'),
      from: {
        sub_division_id: [],
        division_id: [],
        department_id: [],
      },
      topic: null,
      to_name: null,
      storage_location: null,
      page: null,
      secret_type_id: null,
      speed_type_id: null,
      detail: null,
      remark: null,
      due_date: moment().format('YYYY-MM-DD'),
      expire_date: moment().format('YYYY-MM-DD'),
      doc_status: '6',
      master_doc_type_data: null,
      master_book_cate_data: null,
      master_secret_data: null,
      master_speed_data: null,
      from_text: null,
      reference: null,
      relate: null,
      department_type: 1,
      receive_remark: null,
      active_tab: 'book_data',
      reference_doc: [],
      received_at: moment().format('YYYY-MM-DD'),
      doc_file_del: false,
      attach: [],
      attach_del: [],
      attach_new: [],
      process_file_del: [],
      process_file_new: [],

      docs_id: null,

      check_edit: false,
      is_received_at: false,
      is_book_cate_id: false,
      is_receive_no: false,
      is_from_name: false,
      is_remark: false,
    };
  }
  displayAlert = (type = 'receive', data = null) => {
    switch (type) {
      case 'back': {
        MySwal.fire({
          title: 'ต้องการกลับ?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: '#5578eb',
          cancelButtonColor: '#fd397a',
          confirmButtonText: 'ยืนยัน!',
          cancelButtonText: 'ยกเลิก',
        }).then(({ value: result }) => {
          if (result) {
            this.props.history.goBack();
          } else {
            return;
          }
        });
        break;
      }
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
    }
  };
  componentWillMount() { this.getData(); }
  getData = async () => {
    curr_page = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.displayAlert('loading');
    const { doc_trans } = this.state;
    try {
      const master_doc_type = await API.get(`/console/doc_type/all`);
      if (master_doc_type.success) { this.setData('master_doc_type_data', master_doc_type.data); }
      const master_book_cate = await API.get(`/console/book_cate/all?trans_type=1&doc_trans=${doc_trans}`);
      if (master_book_cate.success) { this.setData('master_book_cate_data', master_book_cate.data); }
      const master_secret = await API.get(`/console/static/master_secret/all`);
      if (master_secret.success) { this.setData('master_secret_data', master_secret.data); }
      const master_speed = await API.get(`/console/static/master_speed/all`);
      if (master_speed.success) { this.setData('master_speed_data', master_speed.data); }
      await API.get('/console/static/division_and_sub/all?permission=1').then((res) => {
        if (res.success) {
          const division = _.filter(
            res.data,
            (v, k) => k == 'division_id'
          )[0].map((v) => ({
            value: 'main_' + v.id,
            label: v.name,
          }));
          const sub_division = _.filter(
            res.data,
            (v, k) => k == 'sub_division_id'
          )[0].map((v) => ({
            value: 'sub_' + v.id,
            label: v.name,
            division_value: 'main_' + v.division_id,
          }));
          let division_data = [];
          _.forEach(division, (d) => {
            division_data.push(d);
            division_data = division_data.concat(_.filter(sub_division, (v, k) => v.division_value == d.value));
          });
          this.setState({ division_data });
        }
      });
      const id = this.props.history.location.pathname.split('/register-receive-view/').pop().replace(curr_page, '');
      await API.get(`/console/receive/${id}/edit`).then(({ success, data }) => {
        if (success) {
          console.log(data)
          this.setState(
            {
              id,
              ...data,
              year: data.year ? Number(data.year) + 543 : null,
              department_type: data.from_text || data.check_ecms ? 2 : 1,
              received_at: data.received_at ? data.received_at : moment().format('YYYY-MM-DD'),

              is_received_at: (data.received_at == '' || data.received_at == null ? true : false),
              is_book_cate_id: (data.book_cate_id == '' || data.book_cate_id == null ? true : false),
              is_receive_no: (data.fdoc_no == '' || data.fdoc_no == null || data.is_received ? true : false),
              is_from_name: (data.doc_status == '5' || data.is_received ? true : false),
              is_remark: (data.doc_status == '5' || data.is_received ? true : false),

              is_process_file: (data.doc_status == '6' || data.doc_status == '28' || data.doc_status == '29' || data.doc_status == '30' || data.doc_status == '31' ? true : false)
            },
            () => {
              _.filter(data.from, (v, k) => {
                switch (k) {
                  case 'division_id': {
                    let selected_division = null;
                    _.filter(v, (v1) => {
                      v1 = 'main_' + v1;
                      _.filter(this.state.division_data, (v2) => {
                        if (v1 == v2.value) {
                          selected_division = v2;
                        }
                      });
                    });
                    this.setState({ selected_division });
                    break;
                  }
                  case 'sub_division_id': {
                    let selected_division = null;
                    _.filter(v, (v1) => {
                      v1 = 'sub_' + v1;
                      _.filter(this.state.division_data, (v2) => {
                        if (v1 == v2.value) {
                          selected_division = v2;
                        }
                      });
                    });
                    this.setState({ selected_division });
                    break;
                  }
                  case 'department_id': {
                    let selected_division = null;
                    _.filter(v, (v1) => {
                      v1 = 'department_' + v1;
                      _.filter(this.state.department, (v2) => {
                        if (v1 == v2.value) {
                          selected_division = v2;
                        }
                      });
                    });
                    this.setState({ selected_division });
                    break;
                  }
                  default:
                    break;
                }
              });
            }
          );
        }
      });
      MySwal.close();
      this.setState({ loading: false });
    } catch (e) {
      MySwal.close();
      this.setState({ loading: false });
    }
  };
  handleClearFrom = () => {
    this.setState({
      from: {
        sub_division_id: [],
        division_id: [],
        department_id: [],
      },
      from_text: null,
    });
  };
  setData = (key, value) => { this.setState({ [key]: value }); };
  handleChangeDoctrans = async (event) => {
    await this.setData('doc_trans', event.target.value);
    await this.setData('book_cate_id', null);
    this.getData();
  };
  handleChangeDepartmentType = (event) => {
    this.setData('department_type', event.target.value);
    this.handleClearFrom();
  };
  onInputClick = (event) => { event.target.value = ''; }
  downloadDocFile = async (event, id, file_name) => {
    await this.downloadFile(event, '/console/static/download/doc/' + id, file_name);
  }
  downloadAttachFile = async (event, id, file_name) => {
    await this.downloadFile(event, '/console/static/download/attach/' + id, file_name);
  }
  downloadFile = async (event, url, file_name) => {
    try {
      const resp = await API.postDownloadFile(url, null, 'header');
      const file = new Blob([resp.data], {
        type: resp.headers['content-type'],
      });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = file_name;
      fileLink.click();
    } catch (err) {
      console.log('error::');
      console.log(err);
    }
  }
  onChooseFiles = (type, files) => {
    switch (type) {
      case 'doc_file': {
        this.setState({
          doc_file: files[0],
        });
        break;
      }
      case 'attach': {
        if (this.state.attach_new) {
          const attach_new = [...this.state.attach_new, ...files];
          this.setState({ attach_new });
        } else {
          this.setState({
            attach_new: files,
          });
        }
        break;
      }
      case 'process_file': {
        if (this.state.process_file_new) {
          const process_file_new = [...this.state.process_file_new, ...files];
          this.setState({ process_file_new });
        } else {
          this.setState({
            process_file_new: files,
          });
        }
        break;
      }
    }
  };
  onRemoveAttachFile = (e, idx, id) => {
    e.preventDefault();
    let attach = this.state.attach;
    attach.splice(idx, 1);
    this.setState({ attach });
    let attach_del = this.state.attach_del;
    attach_del.push(id);
    this.setState({ attach_del });
  }
  onRemoveAttachNewFile = (e, idx) => {
    e.preventDefault();
    let attach_new = this.state.attach_new;
    attach_new.splice(idx, 1);
    this.setState({ attach_new });
  }
  handleSelectDivision = (selected_division) => this.setState({ selected_division, selected_sub_division: null });
  handleSelectSubDivision = (selected_sub_division) => this.setState({ selected_sub_division, selected_division: null });
  dateFormat = (momentObject, format = 'D MMM YY', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) {
      formatArray[indexFullYear] = thaiYear;
    }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) {
      formatArray[indexShortYear] = thaiYear.toString().substr(2);
    }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  datetimeFormat = (momentObject, format = 'D MMM YY HH:mm', splitText = ' ') => {
    var formatArray = format.split(splitText);
    var thaiYear = parseInt(momentObject.format('YYYY')) + 543;
    var indexFullYear = formatArray.indexOf('YYYY');
    if (indexFullYear != -1) { formatArray[indexFullYear] = thaiYear; }
    var indexShortYear = formatArray.indexOf('YY');
    if (indexShortYear != -1) { formatArray[indexShortYear] = thaiYear.toString().substr(2); }
    format = formatArray.join(' ');
    return momentObject.format(format);
  };
  render() {
    if (this.state.loading) {
      return null;
    } else {
      const {
        doc_type_id,
        book_cate_id,
        fdoc_no,
        topic,
        page,
        secret_type_id,
        speed_type_id,
        detail,
        remark,
        master_doc_type_data,
        master_book_cate_data,
        master_secret_data,
        master_speed_data,
        from_text,
        department_type,
        active_tab,

        //is_received_at,
        //is_book_cate_id,
        //is_receive_no,
        //is_remark,
        //is_process_file
      } = this.state;
      const list_master_doc_type = _.map(master_doc_type_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.doc_type_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_book_cate = _.map(master_book_cate_data, (value, key) => {
        return (
          <MenuItem key={key} value={value.book_cate_id}>
            {value.name}
          </MenuItem>
        );
      });
      const list_master_secret = _.map(master_secret_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      const list_master_speed = _.map(master_speed_data, (value, key) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
      //const can_edit = (check_edit && check_edit == true);
      //const can_edit_receive_at = (is_received_at && is_received_at == true);
      //const can_edit_book_cate = (is_book_cate_id && is_book_cate_id == true);
      //const can_edit_receive_no = (is_receive_no && is_receive_no == true);
      //const can_edit_remark = (is_remark && is_remark == true);
      //const can_edit_process_file = (is_process_file && is_process_file == true);
      const can_edit = false;
      const can_edit_receive_at = false;
      const can_edit_book_cate = false;
      const can_edit_receive_no = false;
      const can_edit_remark = false;
      return (
        <div className='kt-portlet kt-portlet--height-fluid'>
          <Tabs id='controlled-tab-example' activeKey={active_tab} onSelect={(active_tab) => this.setState({ active_tab })}>
            <Tab eventKey='book_data' title='ข้อมูลหนังสือ'>
              <div className='kt-portlet__head'>
                <div className='kt-portlet__head-label'>
                  <h3 className='kt-portlet__head-title'>ลงทะเบียนรับหนังสือ</h3>
                </div>
              </div>
              <br />
              <form className='saveForm' noValidate autoComplete='off'>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>แนบไฟล์หนังสือ</StyledLabelRight>
                  <div className='col-md-4'>
                    <input
                      accept='*'
                      style={input}
                      id='contained-button-file'
                      type='file'
                      onChange={({ target: { files } }) => { this.onChooseFiles('doc_file', files); }}
                      onClick={this.onInputClick}
                      disabled={!can_edit}
                    />
                    {!this.state.is_eform &&
                      <label htmlFor='contained-button-file'>
                        <Button variant='contained' component='span' disabled={!can_edit}>
                          เลือกไฟล์
                        </Button>
                      </label>
                    }
                    {this.state.doc_download && (
                      <div style={attachStyle}>
                        <a onClick={(e) => {
                          e.preventDefault();
                          this.downloadDocFile(e, this.state.docs_trans_id, this.state.doc_name);
                        }}>
                          <StyledUploadedFile
                            title={this.state.doc_name}
                            style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                            src={toAbsoluteUrl(`/media/files/${this.state.doc_type.toLowerCase()}.svg`)} />
                        </a>
                        <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px', display: (!can_edit || this.state.is_eform ? 'none' : 'block') }}>
                          <a onClick={(e) => {
                            this.setState({ doc_download: null });
                            this.setState({ doc_file_del: true });
                            e.preventDefault();
                          }}>
                            <i className='flaticon2-cancel-music icon-xs text-danger' />
                          </a>
                        </div>
                      </div>
                    )}
                    {this.state.doc_file && (
                      <div style={attachStyle}>
                        <StyledUploadedFile
                          title={this.state.doc_file.name}
                          style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                          src={toAbsoluteUrl(`/media/files/${this.state.doc_file.name.split('.').pop().toLowerCase()}.svg`)}
                        />
                        <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{this.state.doc_file.name}</div>
                        <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                          <a
                            onClick={(e) => {
                              this.setState({ doc_file: null });
                              e.preventDefault();
                            }}
                          >
                            <i className='flaticon2-cancel-music icon-xs text-danger' />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *วันที่ลงทะเบียนรับ
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={this.state.received_at ? new Date(this.state.received_at) : new Date(moment().format('YYYY-MM-DD'))}
                      onChange={date => {
                        try {
                          this.setState({ received_at: date ? moment(date).format('YYYY-MM-DD') : null });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      onKeyDown={e => e.preventDefault()}
                      disabled={!can_edit_receive_at}
                    />
                  </div>
                </div>
                <div className='kt-portlet__head'>
                  <div className='kt-portlet__head-label'>
                    <h3 className='kt-portlet__head-title'>รายละเอียดหนังสือรับ</h3>
                  </div>
                </div>
                <br />
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เลือกเล่มทะเบียนรับ
                  </StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      select
                      id='book_cate_id'
                      value={book_cate_id}
                      onChange={(e) => {
                        this.setData('book_cate_id', e.target.value);
                      }}
                      margin='normal'
                      disabled={!can_edit_book_cate}
                    >
                      {list_master_book_cate}
                    </StyledTextField>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-2'>
                    <Radio
                      checked={department_type == 1}
                      onChange={this.handleChangeDepartmentType}
                      value='1'
                      name='department_type'
                      inputProps={{ 'aria-label': 1 }}
                      disabled={!can_edit}
                    />
                    หน่วยงานภายใน
                  </div>
                  <div className='col-md-3'>
                    <Radio
                      checked={department_type == 2}
                      onChange={this.handleChangeDepartmentType}
                      value='2'
                      name='department_type'
                      inputProps={{ 'aria-label': 2 }}
                      disabled={!can_edit}
                    />
                    หน่วยงานภายนอก
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เลขที่หนังสือ
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <StyledTextField
                      required
                      id='fdoc_no'
                      value={fdoc_no}
                      placeholder='XX 0001/0001'
                      onChange={(e) => {
                        this.setData('fdoc_no', e.target.value);
                      }}
                      disabled={!can_edit_receive_no}
                      inputProps={{ maxLength: 255 }}
                    />
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *ลงวันที่
                  </StyledLabelRight>
                  <div className='col-md-4'>
                    <DatePicker
                      dateFormat='d MMM yyyy'
                      selected={new Date(this.state.date_at)}
                      onChange={(date) => {
                        try {
                          this.setState({ date_at: date ? moment(date).format('YYYY-MM-DD') : null });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      onKeyDown={e => e.preventDefault()}
                      disabled={!can_edit}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความลับ</StyledLabelRight>
                  <div className='col-md-4' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      select
                      id='secret_type_id'
                      value={secret_type_id}
                      onChange={(e) => {
                        this.setData('secret_type_id', e.target.value);
                      }}
                      margin='normal'
                      disabled={!can_edit}
                    >
                      {list_master_secret}
                    </StyledTextField>
                  </div>
                  <StyledLabelRight className='col-md-2 col-form-label'>ชั้นความเร็ว</StyledLabelRight>
                  <div className='col-md-3' style={{ marginTop: '-18px' }}>
                    <StyledTextField
                      select
                      id='speed_type_id'
                      value={speed_type_id}
                      onChange={(e) => {
                        this.setData('speed_type_id', e.target.value);
                      }}
                      margin='normal'
                      disabled={!can_edit}
                    >
                      {list_master_speed}
                    </StyledTextField>
                  </div>
                </div>
                {this.state.department_type == 2 && (
                  <div className='form-group row' style={{ display: 'none' }}>
                    <div className='col-md-2'></div>
                    {this.state.check_ecms ? (
                      <ButtonBootstrap
                        variant='success'
                        onClick={() =>
                          this.setState({
                            check_ecms: false,
                            from_text: null,
                            selected_division: [],
                          })
                        }
                        style={{ marginLeft: '10px' }}
                      >
                        พิมพ์ชื่อหน่วยงาน
                      </ButtonBootstrap>
                    ) : (
                      <ButtonBootstrap
                        variant='success'
                        onClick={() =>
                          this.setState({
                            check_ecms: true,
                            from_text: null,
                          })
                        }
                        style={{ marginLeft: '10px' }}
                        disabled={_.isEmpty(this.state.department)}
                      >
                        เลือกหน่วยงาน e-CMS
                      </ButtonBootstrap>
                    )
                    }
                  </div>
                )}
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *จาก
                  </StyledLabelRight>
                  {department_type == 1 ? (
                    <div className='col-md-9'>
                      <CustomAutocomplete
                        placeholder='หน่วยงาน/หน่วยงานย่อย'
                        items={this.state.division_data}
                        selected_item={this.state.selected_division}
                        handleSelectItem={this.handleSelectDivision}
                        disabled={!can_edit}
                      ></CustomAutocomplete>
                    </div>
                  ) : this.state.check_ecms ? (
                    <div className='col-md-9'>
                      <CustomAutocomplete
                        placeholder='หน่วยงาน e-CMS'
                        items={this.state.department}
                        selected_item={this.state.selected_division}
                        handleSelectItem={this.handleSelectDivision}
                      ></CustomAutocomplete>
                    </div>
                  ) : (
                    <div className='col-md-9'>
                      <StyledTextField
                        id='from_text'
                        value={from_text}
                        placeholder='หน่วยงาน'
                        onChange={(e) => {
                          this.setData('from_text', e.target.value);
                        }}
                        disabled={!can_edit}
                        inputProps={{ maxLength: 255 }}
                      />
                    </div>
                  )}
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เรื่อง
                  </StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='note'
                      value={topic}
                      placeholder='หมายเหตุ'
                      multiline
                      rows='4'
                      margin='normal'
                      variant='outlined'
                      onChange={(e) => {
                        this.setData('topic', e.target.value);
                      }}
                      disabled={!can_edit}
                      inputProps={{ maxLength: 255 }}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label' color='red'>
                    *เรียน
                  </StyledLabelRight>
                  <div className='col-md-9' style={{ marginTop: -12 }}>
                    <CustomDownshift
                      _id='to_name'
                      focused_id={this.state.focused_id}
                      suggestions={this.props.cache.to_name}
                      inputValue={this.state.to_name}
                      handleInputChange={(event) => this.setData('to_name', event.target.value)}
                      handleOnFocus={(focused_id) => this.setData('focused_id', focused_id)}
                      disabled={!can_edit}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>จาก</StyledLabelRight>
                  <div className='col-md-9' style={{ marginTop: -12 }}>
                    <CustomDownshift
                      _id='from_name'
                      focused_id={this.state.focused_id}
                      suggestions={this.props.cache.from_name}
                      inputValue={this.state.from_name}
                      handleInputChange={(event) => this.setData('from_name', event.target.value)}
                      handleOnFocus={(focused_id) => this.setData('focused_id', focused_id)}
                      disabled={!can_edit}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <StyledLabelRight className='col-md-2 col-form-label'>หมายเหตุ</StyledLabelRight>
                  <div className='col-md-9'>
                    <StyledTextField
                      id='note'
                      value={remark}
                      placeholder='หมายเหตุ'
                      multiline
                      rows='4'
                      margin='normal'
                      variant='outlined'
                      onChange={(e) => {
                        this.setData('remark', e.target.value);
                      }}
                      disabled={!can_edit_remark}
                    />
                  </div>
                </div>
              </form>
            </Tab>
            <Tab eventKey='book_detail' title='รายละเอียดหนังสือ'>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>ประเภทเอกสาร</StyledLabelRight>
                <div className='col-md-4' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    select
                    id='doc_type_id'
                    value={doc_type_id}
                    onChange={(e) => {
                      this.setData('doc_type_id', e.target.value);
                    }}
                    margin='normal'
                    disabled={!can_edit}
                  >
                    {list_master_doc_type}
                  </StyledTextField>
                </div>
                <StyledLabelRight className='col-md-2 col-form-label'>จำนวน</StyledLabelRight>
                <div className='col-md-2'>
                  <StyledTextField
                    id='page'
                    type='number'
                    value={page}
                    placeholder='หน้า'
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      this.setData('page', e.target.value);
                    }}
                    disabled={!can_edit}
                  />
                </div>
                <StyledLabelLeft className='col-md-1 col-form-label'>หน้า</StyledLabelLeft>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>สถานที่เก็บเอกสาร</StyledLabelRight>
                <div className='col-md-9' style={{ marginTop: '-12px' }}>
                  <CustomDownshift
                    _id='storage_location'
                    focused_id={this.state.focused_id}
                    suggestions={this.props.cache.storage_location}
                    inputValue={this.state.storage_location}
                    handleInputChange={(event) => this.setData('storage_location', event.target.value)}
                    handleOnFocus={(focused_id) => this.setData('focused_id', focused_id)}
                    disabled={!can_edit}
                    maxLength={255}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>สิ่งที่แนบมาด้วย</StyledLabelRight>
                <div className='col-auto'>
                  <input
                    accept='*'
                    style={input}
                    id='attach'
                    type='file'
                    multiple
                    onChange={({ target: { files } }) => {
                      this.onChooseFiles('attach', files);
                    }}
                    onClick={this.onInputClick}
                    disabled={!can_edit}
                  />
                  <label htmlFor='attach'>
                    <Button variant='contained' component='span' disabled={!can_edit}>
                      เลือกไฟล์
                    </Button>
                  </label>
                </div>
                <div className='col-md-7'>
                  {this.state.attach
                    ? _.map(this.state.attach, (v, k) => (
                      <div key={k} style={fileStyle}>
                        <a onClick={(e) => {
                          e.preventDefault();
                          this.downloadAttachFile(e, v.attach_id, v.file_name);
                        }}>
                          <StyledUploadedFile
                            title={v.file_name}
                            style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                            src={toAbsoluteUrl(`/media/files/${v.file_type.toLowerCase()}.svg`)}
                          />
                          <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{v.file_name}</div>
                        </a>
                        <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px', display: (!can_edit ? 'none' : 'block') }}>
                          <a onClick={(e) => this.onRemoveAttachFile(e, k, v.attach_id)}>
                            <i className='flaticon2-cancel-music icon-xs text-danger' />
                          </a>
                        </div>
                      </div>
                    ))
                    : null}
                  {this.state.attach_new
                    ? _.map(
                      this.state.attach_new,
                      (v, k) =>
                        v.name && (
                          <div key={k} style={fileStyle}>
                            <StyledUploadedFile
                              title={v.name}
                              style={{ float: 'left', width: 36, paddingRight: '0.5rem' }}
                              src={toAbsoluteUrl(`/media/files/${v.name.split('.').pop().toLowerCase()}.svg`)}
                            />
                            <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 166 }}>{v.name}</div>
                            <div style={{ float: 'left', marginTop: '2px', marginLeft: '8px' }}>
                              <a onClick={(e) => this.onRemoveAttachNewFile(e, k)}>
                                <i className='flaticon2-cancel-music icon-xs text-danger' />
                              </a>
                            </div>
                          </div>
                        )
                    )
                    : null}
                </div>
              </div>
              <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>รายละเอียด</StyledLabelRight>
                <div className='col-md-9'>
                  <StyledTextField
                    id='detail'
                    value={detail}
                    placeholder='รายละเอียด'
                    multiline
                    rows='4'
                    margin='normal'
                    variant='outlined'
                    onChange={(e) => {
                      this.setData('detail', e.target.value);
                    }}
                    disabled={!can_edit}
                  />
                </div>
              </div>
              {
                _.isArray(this.state.reference_doc) && !_.isEmpty(this.state.reference_doc) &&
                _.map(this.state.reference_doc, (v, k) => {
                  const reference_doc = this.state.reference_doc;
                  return (
                    <div className='form-group row'>
                      <StyledLabelRight className='col-md-2 col-form-label'>
                        {k == 0 ? 'หนังสืออ้างอิง' : ''}
                      </StyledLabelRight>
                      <div className='col-md-2'>
                        <StyledTextField
                          id='outlined-multiline-static'
                          placeholder='เลขที่หนังสือ'
                          multiline
                          margin='normal'
                          value={reference_doc[k].doc_no}
                          onChange={({ target: { value } }) => {
                            reference_doc[k].doc_no = value;
                            this.setState({ reference_doc });
                          }}
                          disabled={!can_edit}
                          inputProps={{ maxLength: 255 }}
                        />
                      </div>
                      <div className='col-md-3'>
                        <StyledTextField
                          id='outlined-multiline-static'
                          placeholder='เรื่อง'
                          multiline
                          margin='normal'
                          value={reference_doc[k].topic}
                          onChange={({ target: { value } }) => {
                            reference_doc[k].topic = value;
                            this.setState({ reference_doc });
                          }}
                          disabled={!can_edit}
                          inputProps={{ maxLength: 255 }}
                        />
                      </div>
                      <div className='col-md-2'>
                        <DatePicker
                          wrapperClassName="mt-38"
                          placeholderText='วันที่'
                          dateFormat='d MMM yyyy'
                          selected={reference_doc[k].date_at ? new Date(reference_doc[k].date_at) : null}
                          onChange={(date) => {
                            try {
                              reference_doc[k].date_at = date ? moment(date).format('YYYY-MM-DD') : null;
                              this.setState({ reference_doc });
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                          disabled={!can_edit}
                        />
                      </div>
                    </div>
                  );
                })
              }
            </Tab>
          </Tabs>
          <div
            className='form-group row'
            style={{
              marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, textAlign: 'center'
            }}
          >
            <StyledButtonRight>
              <ButtonBootstrap variant='secondary' style={{ marginLeft: '5px' }} onClick={() => this.displayAlert('back')}>
                กลับ
              </ButtonBootstrap>
            </StyledButtonRight>
          </div>
          <br />
        </div>
      );
    }
  }
}
const StyledTextField = styled(TextField)`
    margin-left: 8;
    margin-right: 8;
    width: 100%;
    .MuiOutlinedInput-multiline {
        padding: 10px 7px;
        line-height: 1.6;
    }
    .MuiOutlinedInput-inputMultiline {
        padding-top: 10px;
    }
    .MuiInputBase-input {
        padding-top: 10px;
    }
`;
const StyledLabelRight = styled.label`
    text-align: right;
    color: ${(props) => props.color || '#646c9a'};
`;
const StyledLabelLeft = styled.label`
    text-align: 'left';
`;
const StyledButtonLeft = styled.div`
    text-align: left;
    padding-left: 2%;
    margin-top: 0px;
`;
const StyledButtonRight = styled.div`
    text-align: right;
    padding-right: 10px !important;
    margin-top: 6px;
`;
const StyledUploadedFile = styled.img`
    width: 36px;
    padding-right: 0.5rem;
`;
const mapStateToProps = (state) => state.auth;
export default withRouter(connect(mapStateToProps, auth.actions)(Class));