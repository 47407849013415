import React from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import CustomTable from '../../widgets/CustomTable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import API from '../../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../widgets/CustomAutocomplete';
import Const from '../../variables/Const';
registerLocale('th', th);
setDefaultLocale('th');
const MySwal = withReactContent(Swal);
var rows = [];
class Class extends React.Component {
  table = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      query_string: null,
      status: 'all',
      doc_status: null,
      doc_no: null,
      item_no: null,
      keyword: null,
      start_date: moment()
        .add(-60, 'days')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      topic : '',
      receive_no : '',
      book_cate_id: null,
      doc_no : '',
      doc_type_id: null,
      secret_type_id: null,
      speed_type_id: null,
      division_data: [],
      selected_division: null,
      page: 1,
      per_page: Const.Table.RowPerPages,
      selecteds: [],
      master_doc_type_data: null,
      master_book_cate_data: null,
      master_doc_status_data: null,
      master_secret: null,
      master_speed: null,
      cate_stores_ids: [],
      sub_cate_stores_ids: [],
      sub_cate_stores_ids_for_display: [],
      stores_docs_types: [],
      cate_destroy_stores_ids: [],
      stores_docs_statuses: [],
      years: [],
      sub_cate_stores_id_disabled: true,
    };
  }
  unicodeToChar = (text) =>
    typeof text === 'string' && text != ''
      ? text.replace(/\\u[\dA-F]{4}/gi, (match) =>
          String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
        )
      : null;
  getQueryStringFromUrl = (callback) => {
    /**
     * Example: http://localhost:3000/store-paginate/1?cate_stores_id=&stores_docs_type=&cate_destroy_stores_id=&stores_docs_status=&year=2020&trans_type=1
     */
    this.displayAlert('loading');
    const query_string = window.location.search;
    const url_params = new URLSearchParams(query_string);
    const cate_stores_id = this.unicodeToChar(url_params.get('cate_stores_id'));
    const sub_cate_stores_id = this.unicodeToChar(
      url_params.get('sub_cate_stores_id')
    );
    const stores_docs_type = this.unicodeToChar(
      url_params.get('stores_docs_type')
    );
    const cate_destroy_stores_id = this.unicodeToChar(
      url_params.get('cate_destroy_stores_id')
    );
    const stores_docs_status = this.unicodeToChar(
      url_params.get('stores_docs_status')
    );
    const year = this.unicodeToChar(url_params.get('year'));
    const trans_type = this.unicodeToChar(url_params.get('trans_type'));
    const page_id = Number(
      this.props.history.location.pathname.split('/store-paginate/').pop()
    );
    //console.log('page_id -->', page_id);
    //console.log('cate_stores_id -->', cate_stores_id);
    //console.log('sub_cate_stores_id -->', sub_cate_stores_id);
    //console.log('stores_docs_type -->', stores_docs_type);
    //console.log('cate_destroy_stores_id -->', cate_destroy_stores_id);
    //console.log('stores_docs_status -->', stores_docs_status);
    //console.log('year -->', year);
    //console.log('trans_type -->', trans_type);
    let page_title;
    let is_receive = true;
    let displayed_filters = {
      cate_stores_id: true,
      sub_cate_stores_id: true,
      stores_docs_type: true,
      cate_destroy_stores_id: true,
      stores_docs_status: true,
      year: true,
    };
    switch (page_id) {
      case 1: {
        page_title = 'ทะเบียนจัดเก็บหนังสือรับ';
        break;
      }
      case 2: {
        page_title = 'ทะเบียนจัดเก็บหนังสือส่ง';
        is_receive = false;
        break;
      }
      case 3: {
        page_title = 'หนังสือรับเก็บถาวร';
        displayed_filters.cate_destroy_stores_id = false;
        break;
      }
      case 4: {
        page_title = 'หนังสือส่งเก็บถาวร';
        displayed_filters.cate_destroy_stores_id = false;
        is_receive = false;
        break;
      }
      case 5: {
        page_title = 'ทำลายหนังสือรับ';
        displayed_filters.stores_docs_type = false;
        displayed_filters.cate_destroy_stores_id = false;
        break;
      }
      case 6: {
        page_title = 'ทำลายหนังสือส่ง';
        displayed_filters.stores_docs_type = false;
        displayed_filters.cate_destroy_stores_id = false;
        is_receive = false;
        break;
      }
      case 7: {
        page_title = 'ขอทำลายหนังสือรับ';
        displayed_filters.cate_destroy_stores_id = false;
        break;
      }
      case 8: {
        page_title = 'ขอทำลายหนังสือส่ง';
        displayed_filters.cate_destroy_stores_id = false;
        is_receive = false;
        break;
      }
      case 9: {
        page_title = 'ส่งมอบหนังสือรับครบ 20 ปี';
        displayed_filters.cate_destroy_stores_id = false;
        break;
      }
      case 10: {
        page_title = 'ส่งมอบหนังสือส่งครบ 20 ปี';
        displayed_filters.cate_destroy_stores_id = false;
        is_receive = false;
        break;
      }
      default:
        break;
    }
    let head_rows = is_receive
      ? [
          {
            id: 'year',
            sortable: true,
            disablePadding: false,
            label: 'ปี',
            align: 'center',
          },
          {
            id: 'cate_stores_id',
            sortable: true,
            disablePadding: false,
            label: 'ทะเบียนจัดเก็บ',
            align: 'center',
          },
          {
            id: 'stores_docs_status',
            sortable: true,
            disablePadding: false,
            label: 'สถานะหนังสือที่เก็บ',
            align: 'center',
          },
          {
            id: 'doc_download',
            sortable: false,
            disablePadding: false,
            label: 'เอกสาร',
            align: 'center',
          },
          {
            id: 'receive_no',
            sortable: true,
            disablePadding: false,
            label: 'เลขทะเบียนรับ',
            align: 'center',
          },
          {
            id: 'send_no',
            sortable: true,
            disablePadding: false,
            label: 'เลขหนังสือ',
          },
          {
            id: 'date_at',
            sortable: true,
            disablePadding: false,
            label: 'ลงวันที่',
          },
          {
            id: 'from',
            sortable: true,
            disablePadding: false,
            label: 'จาก',
          },
          {
            id: 'topic',
            sortable: true,
            disablePadding: false,
            label: 'เรื่อง',
          },
        ]
      : [
          {
            id: 'year',
            sortable: true,
            disablePadding: false,
            label: 'ปี',
            align: 'center',
          },
          {
            id: 'cate_stores_id',
            sortable: true,
            disablePadding: false,
            label: 'ทะเบียนจัดเก็บ',
            align: 'center',
          },
          {
            id: 'stores_docs_status',
            sortable: true,
            disablePadding: false,
            label: 'สถานะหนังสือที่เก็บ',
            align: 'center',
          },
          {
            id: 'doc_download',
            sortable: false,
            disablePadding: false,
            label: 'เอกสาร',
            align: 'center',
          },
          {
            id: 'send_no',
            sortable: true,
            disablePadding: false,
            label: 'เลขหนังสือ',
          },
          {
            id: 'date_at',
            sortable: true,
            disablePadding: false,
            label: 'ลงวันที่',
          },
          {
            id: 'to_name',
            sortable: true,
            disablePadding: false,
            label: 'ส่งถึง',
          },
          {
            id: 'topic',
            sortable: true,
            disablePadding: false,
            label: 'เรื่อง',
          },
        ];
    this.setState(
      {
        page_id,
        page_title,
        cate_stores_id,
        sub_cate_stores_id,
        stores_docs_type,
        cate_destroy_stores_id,
        stores_docs_status,
        year,
        trans_type,
        displayed_filters,
        head_rows,
        is_receive
      },
      () => {
        if (typeof callback == 'function') callback();
      }
    );
  };
  displayAlert = (type = 'loading', data = null) => {
    switch (type) {
      case 'loading': {
        MySwal.fire({
          title: 'กำลังโหลด...',
          confirmButtonColor: '#5578eb',
          onBeforeOpen: () => MySwal.showLoading(),
          allowOutsideClick: () => !MySwal.isLoading(),
        });
        break;
      }
      default:
        break;
    }
  };
  setSelecteds = (selecteds) => this.setState({ selecteds });
  renderInput = (inputProps) => {
    const { InputProps, ref, ...other } = inputProps;
    return (
      <StyledTextField
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    );
  };
  setSubCateStore = async (cateStoreId, subCateStoreId) => {
      await API.get(
          `/console/static/sub_cate_stores_id/${cateStoreId}`
      ).then((res) => {
          if (res && res.success) {
              this.setState({
                  sub_cate_stores_ids: res.data,
                  sub_cate_stores_id: subCateStoreId,
                  sub_cate_stores_id_disabled: _.isEmpty(res.data)
              });
          } else {
              this.setState({ sub_cate_stores_id_disabled: true });
          }
      })
  };
  getData = async (not_display_loading = false) => {
    if (!not_display_loading) this.displayAlert('loading');
    const data = {
      cate_stores_id: this.state.cate_stores_id,
      sub_cate_stores_id: this.state.sub_cate_stores_id,
      stores_docs_type: this.state.stores_docs_type,
      cate_destroy_stores_id: this.state.cate_destroy_stores_id,
      stores_docs_status: this.state.stores_docs_status,
      year: this.state.year,
      topic: this.state.topic,
      receive_no: this.state.receive_no,
      book_cate_id: this.state.book_cate_id,
      doc_no: this.state.doc_no,
      trans_type: this.state.trans_type,
      docs_age: this.state.docs_age,
      page: this.state.page,
      per_page: this.state.per_page,
    };
    await API.post('/console/stores/list', data).then((res) => {
      if (res && res.success) {
        let has_cate_stores_ids = [];
        _.filter(res.data, async (v, k) => {
          if (has_cate_stores_ids.indexOf(v.cate_stores_id) == -1) {
            has_cate_stores_ids.push(v.cate_stores_id);
            await API.get(
              `/console/static/sub_cate_stores_id/${v.cate_stores_id}`
            ).then((res1) => {
              if (res1 && res1.success) {
                this.setState({
                  sub_cate_stores_ids_for_display: {
                    ...this.state.sub_cate_stores_ids_for_display,
                    ...res1.data,
                  },
                });
              }
              MySwal.close();
            });
          }
        });
        this.setState({
          receive_data: res.data,
          current_page: res.current_page,
          last_page: res.last_page,
          per_page: Const.Table.RowPerPages,
        });
      }
    });
    await API.get('/console/static/docs_age/all').then((res) => {
        if (res && res.success) {
            let docs_age = null;
            _.filter(res.data, (v, k) => {
            if (!docs_age) docs_age = k;
            });
            this.setState({
            docs_ages: res.data,
            //docs_age,
            });
        }
    });
    
    MySwal.close();
  };
  handleChangePage = (page) => {
    this.setState({ page }, () => this.getData());
  };
  handleSelectDivision = (selected_division) => {
    this.setState({ selected_division, selected_sub_division: null });
  };
  getFilters = async (callback) => {
    await API.get('/console/static/cate_stores_id/all').then((res) => {
      if (res && res.success) {
        this.setState({
          cate_stores_ids: res.data,
        });
      }
    });
    await API.get('/console/static/stores_docs_type/all').then((res) => {
      if (res && res.success) {
        this.setState({
          stores_docs_types: res.data,
        });
      }
    });
    await API.get('/console/static/cate_destroy_stores_id/all').then((res) => {
      if (res && res.success) {
        this.setState({
          cate_destroy_stores_ids: res.data,
        });
      }
    });
    await API.get('/console/static/stores_docs_status/all').then((res) => {
      if (res && res.success) {
        this.setState({
          stores_docs_statuses: res.data,
        });
      }
    });
    await API.get('/console/static/year/all').then((res) => {
      if (res && res.success) {
        this.setState({
          years: res.data,
        });
      }
    });
    await API.get(
      '/console/book_cate/all?trans_type=' + this.state.trans_type
    ).then((res) => {
      if (res && res.success) {
        this.setState({ master_book_cate_data: res.data }, () => {
          //console.log(
          //  'this.state.master_book_cate_data -->',
          //  this.state.master_book_cate_data
          //);
        });
      }
    });
    if (this.state.cate_stores_id) {
        await this.setSubCateStore(this.state.cate_stores_id, this.state.sub_cate_stores_id);
    }
    if (typeof callback == 'function') callback();
  };
  componentDidUpdate(prev_props, prev_state) {
    const query_string = window.location.search;
    if (this.state.query_string && this.state.query_string != query_string) {
      this.getQueryStringFromUrl(() =>
        this.getFilters(() => this.getData(true))
      );
    }
  }
  onSubmit = (no_time = false) => {
    const cate_stores_id = this.state.cate_stores_id || '';
    const sub_cate_stores_id = this.state.sub_cate_stores_id || '';
    const stores_docs_type = this.state.stores_docs_type || '';
    const cate_destroy_stores_id = this.state.cate_destroy_stores_id || '';
    const stores_docs_status = this.state.stores_docs_status || '';
    const year = this.state.year || '';
    const topic = this.state.topic || '';
    const receive_no = this.state.receive_no || '';
    const book_cate_id = this.state.book_cate_id || 0;
    const doc_no = this.state.doc_no || '';
    const trans_type = this.state.trans_type || '';
    const path = `${this.state.page_id}?cate_stores_id=${cate_stores_id}&sub_cate_stores_id=${sub_cate_stores_id}&stores_docs_type=${stores_docs_type}&cate_destroy_stores_id=${cate_destroy_stores_id}&stores_docs_status=${stores_docs_status}&year=${year}&trans_type=${trans_type}`;
    this.props.history.push(path);
    try {
      setTimeout(
        () => {
          if (this.table && this.table.current && this.table.current.offsetTop)
            window.scroll({
              behavior: 'smooth',
              left: 0,
              top: this.table.current.offsetTop,
            });
        },
        no_time ? 0 : 250
      );
      } catch (e) { }
      this.setState({ page: 1, }, () => this.getData());
    
  };
  onEnter = _.debounce((e) => {
    if (e.keyCode === 13) {
      this.onSubmit(true);
    }
  }, 250);
  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
    this.getQueryStringFromUrl(() => this.getFilters(() => this.getData(true)));
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }
  render() {
    return (
      <div className='kt-portlet kt-portlet--height-fluid'>
        <div className='kt-portlet__head'>
          <div className='kt-portlet__head-label'>
            <h3 className='kt-portlet__head-title'>{this.state.page_title}</h3>
          </div>
        </div>
        <br />
        <div className='form-group row'>
          {this.state.displayed_filters &&
            this.state.displayed_filters.cate_stores_id && (
              <>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ทะเบียนจัดเก็บ
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    margin='normal'
                    select
                    value={this.state.cate_stores_id || ''}
                    onChange={(e) =>
                      this.setState(
                        {
                          cate_stores_id: String(e.target.value),
                        },
                        () => this.setSubCateStore(e.target.value, null)
                      )
                    }
                  >
                    {_.map(this.state.cate_stores_ids, (label, value) => {
                      return (
                        <MenuItem key={`cate_stores_id_${value}`} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </div>
              </>
            )}
          {this.state.displayed_filters &&
            this.state.displayed_filters.sub_cate_stores_id && (
              <>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ทะเบียนจัดเก็บย่อย
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    disabled={this.state.sub_cate_stores_id_disabled}
                    margin='normal'
                    select
                    value={this.state.sub_cate_stores_id || ''}
                    onChange={(e) =>
                      this.setState({
                        sub_cate_stores_id: String(e.target.value),
                      })
                    }
                  >
                    {_.map(this.state.sub_cate_stores_ids, (label, value) => {
                      return (
                        <MenuItem
                          key={`sub_cate_stores_id_${value}`}
                          value={value}
                        >
                          {label}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </div>
              </>
            )}
          {this.state.displayed_filters && this.state.displayed_filters.year && (
            <>
              <StyledLabelRight className='col-md-1 col-form-label'>
                ปี
              </StyledLabelRight>
              <div className='col-md-2' style={{ marginTop: '-18px' }}>
                <StyledTextField
                  margin='normal'
                  select
                  value={this.state.year || ''}
                  onChange={(e) =>
                    this.setState({
                      year: String(e.target.value),
                    })
                  }
                >
                  {_.map(this.state.years, (label, value) => {
                    return (
                      <MenuItem key={`year_${value}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </div>
            </>
          )}
          {/*this.state.displayed_filters &&
            this.state.displayed_filters.stores_docs_type && (
              <>
                <StyledLabelRight
                  className={`col-md-${
                    !_.isEmpty(this.state.sub_cate_stores_ids) ? 1 : 2
                  } col-form-label`}
                >
                  ประเภทเอกสาร
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    margin='normal'
                    select
                    value={this.state.stores_docs_type || ''}
                    onChange={(e) =>
                      this.setState({
                        stores_docs_type: String(e.target.value),
                      })
                    }
                  >
                    {_.map(this.state.stores_docs_types, (label, value) => {
                      return (
                        <MenuItem
                          key={`stores_docs_type_${value}`}
                          value={value}
                        >
                          {label}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </div>
              </>
            )*/}
        </div>
        <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
            อายุเอกสาร
            </StyledLabelRight>
            <div className='col-md-2' style={{ marginTop: '-18px' }}>
                <StyledTextField
                    id='docs_age'
                    value={this.state.docs_age}
                    select
                    onChange={(e) =>
                      this.setState({
                        docs_age: String(e.target.value),
                      })
                    }
                    margin='normal'
                >
                    {_.map(this.state.docs_ages, (label, value) => {
                    return (
                        <MenuItem key={`docs_age_${value}`} value={value}>
                        {label}
                        </MenuItem>
                    );
                    })}
                </StyledTextField>
            </div>
          {this.state.displayed_filters &&
            this.state.displayed_filters.cate_destroy_stores_id && (
              <>
                <StyledLabelRight className='col-md-2 col-form-label'>
                  ทะเบียนเก็บหรือทำลาย
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    margin='normal'
                    select
                    value={this.state.cate_destroy_stores_id || ''}
                    onChange={(e) =>
                      this.setState({
                        cate_destroy_stores_id: String(e.target.value),
                      })
                    }
                  >
                    {_.map(
                      this.state.cate_destroy_stores_ids,
                      (label, value) => {
                        return (
                          <MenuItem
                            key={`cate_destroy_stores_id_${value}`}
                            value={value}
                          >
                            {label}
                          </MenuItem>
                        );
                      }
                    )}
                  </StyledTextField>
                </div>
              </>
            )}
          {this.state.displayed_filters &&
            this.state.displayed_filters.stores_docs_status && (
              <>
                <StyledLabelRight className='col-md-1 col-form-label'>
                  สถานะหนังสือที่เก็บ
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-18px' }}>
                  <StyledTextField
                    margin='normal'
                    select
                    value={this.state.stores_docs_status || ''}
                    onChange={(e) =>
                      this.setState({
                        stores_docs_status: String(e.target.value),
                      })
                    }
                  >
                    {_.map(this.state.stores_docs_statuses, (label, value) => {
                      return (
                        <MenuItem
                          key={`stores_docs_status_${value}`}
                          value={value}
                        >
                          {label}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </div>
              </>
            )}
        </div>
        <div className='form-group row'>
            <StyledLabelRight className='col-md-2 col-form-label'>
            เลขที่หนังสือ
            </StyledLabelRight>
            <div className='col-md-2'>
            <StyledTextField
                id='doc_no'
                value={this.state.doc_no}
                onChange={(e) =>
                    this.setState({
                        doc_no: String(e.target.value),
                    })
                }
            />
            </div>
            <StyledLabelRight className='col-md-2 col-form-label'>
            ชื่อเรื่อง
            </StyledLabelRight>
            <div className='col-md-2'>
            <StyledTextField
                id='topic'
                value={this.state.topic}
                onChange={(e) =>
                    this.setState({
                        topic: String(e.target.value),
                    })
                }
            />
            </div>
          {this.state.trans_type === '1' && (
            <>
                <StyledLabelRight className='col-md-1 col-form-label'>
                ทะเบียนรับ
                </StyledLabelRight>
                <div className='col-md-2' style={{ marginTop: '-10px' }}>
                <StyledTextField
                    select
                    margin='normal'
                    value={this.state.book_cate_id}
                    onChange={(e) =>
                        this.setState({
                            book_cate_id: String(e.target.value),
                        })
                    }
                >
                    {_.map(this.state.master_book_cate_data, (v, k) => (
                    <MenuItem key={k} value={v.book_cate_id}>
                        {v.name}
                    </MenuItem>
                    ))}
                </StyledTextField>
                </div>
            </>
            )}
                
          {this.state.trans_type === '2' && (
            <>
            <label className='col-md-1 col-form-label'>
            ทะเบียนส่ง
            </label>
            <div className='col-md-2' style={{ marginTop: '-10px' }}>
            <StyledTextField
                select
                margin='normal'
                value={this.state.book_cate_id}
                onChange={(e) =>
                    this.setState({
                        book_cate_id: String(e.target.value),
                    })
                }
            >
                {_.map(this.state.master_book_cate_data, (v, k) => (
                <MenuItem key={k} value={v.book_cate_id}>
                    {v.name}
                </MenuItem>
                ))}
            </StyledTextField>
            </div>
              </>
            )}
        </div>
        {this.state.trans_type === '1' && (
        <>
            <div className='form-group row'>
                <StyledLabelRight className='col-md-2 col-form-label'>
                เลขทะเบียนรับ
                </StyledLabelRight>
                <div className='col-md-2'>
                    <StyledTextField
                        id='receive_no'
                        value={this.state.receive_no}
                        onChange={(e) =>
                            this.setState({
                                receive_no: String(e.target.value),
                            })
                        }
                    />
                </div>
            </div>
        </>
        )}
        <div
          className='form-group row'
          style={{ marginBottom: 0, paddingLeft: 10, paddingTop: 0 }}
        >
          <StyledButtonRight className='col-md-6'>
            <Button
              variant='success'
              onClick={() => {
                this.onSubmit();
              }}
            >
              ค้นหา
            </Button>
          </StyledButtonRight>
        </div>
        <div ref={(ref) => (this.table = ref)}></div>
        <CustomTable
          title={this.state.page_title}
          showManage={true}
          head_rows={this.state.head_rows || []}
          book_cate_data={this.state.master_book_cate_data}
          cate_stores_ids={this.state.cate_stores_ids}
          sub_cate_stores_ids_for_display={
            this.state.sub_cate_stores_ids_for_display
          }
          stores_docs_statuses={this.state.stores_docs_statuses}
          rows={this.state.receive_data ? this.state.receive_data : rows}
          selecteds={this.state.selecteds}
          setSelecteds={this.setSelecteds}
          history={this.props.history}
          linkManage={
            this.state.is_receive
              ? '/register-receive-store/'
              : '/register-send-store/'
          }
          btnManageName={
            <Icon className='flaticon-edit-1' style={{ fontSize: 18 }} />
          }
          btnManageAlign='left'
          isReceive={false}
          isStore
          reloadData={this.getData}
          current_page={this.state.current_page}
          last_page={this.state.last_page}
          rowsPerPage={this.state.per_page}
          handleChangePage={this.handleChangePage}
          btnManageDisabled={false}
          btnDeleteDisabled={false}
          hideSelect={true}
        />
      </div>
    );
  }
}
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding-top: 14px;
  }
`;
const StyledLabelRight = styled.label`
  text-align: right;
`;
const StyledButtonLeft = styled.div`
  text-align: left;
  padding-left: 2% !important;
  margin-top: 6px;
`;
const StyledButtonRight = styled.div`
  text-align: right;
  padding-right: 2.5% !important;
  margin-top: 6px;
`;
const mapStateToProps = (state) => ({
  ecs_permission: state.auth.user.role.ecs_permission,
});
export default withRouter(connect(mapStateToProps)(Class));
